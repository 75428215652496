import { useSelector } from "react-redux";
import { RootState } from "store";
import { PageSection } from "../PageSection";
import { Rates } from "../Rates";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";

export const ViewShipmentRates = () => {
  const { currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  return (
    <PageSection title="Selected Service">
      <Rates
        loadingStatus={ApiLoadingStatus.SUCCESS}
        allowSelect={true}
        isInternationalShipment={
          currentShipment?.fromAddressCountryIso !==
          currentShipment?.toAddressCountryIso
        }
        checkedRates={[]}
        rates={
          // Neil says we should only show the selected rate in the shipment panel
          currentShipment?.rates?.filter((rate) => {
            return rate.isSelected;
          }) || []
        }
        selectedRate={
          currentShipment?.rates.find((rate) => {
            return rate.isSelected;
          }) || null
        }
      />
    </PageSection>
  );
};
