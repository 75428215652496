import React from "react";
import { useSelectContextProvider } from "./SelectContextProvider";
import VirtualList from "react-tiny-virtual-list";
import { Checkmark16 } from "@carbon/icons-react";
import { SelectMenuItem } from "./SelectMenuItem";

export const SelectMenuItems = <T,>({ itemHeight }: { itemHeight: number }): JSX.Element => {
  const {
    filteredOptions,
    options,
    query,
    optionTemplate,
    onChange,
    cursor,
    dropdownHeight,
    value,
    disabledFilter,
  } = useSelectContextProvider();

  const data = filteredOptions.length > 0 ? filteredOptions : options || [];

  const visibleItems = Math.ceil(dropdownHeight / itemHeight);

  return (
    <>
      {query !== "" && filteredOptions.length === 0 ? (
        <div className="p-3 text-sm leading-none text-center text-gray-400">
          No results for {query}
        </div>
      ) : (
        <VirtualList
          width="100%"
          height={
            data.length > visibleItems
              ? dropdownHeight
              : data.length * itemHeight
          }
          itemCount={data.length}
          itemSize={itemHeight}
          renderItem={({ index, style }) => {
            const option = data[index];

            const isDisabled = disabledFilter ? disabledFilter(option) : false;

            return (
              <SelectMenuItem
                key={index}
                style={style}
                index={index}
                onClick={() => {
                  onChange && onChange(option);
                }}
                isDisabled={isDisabled}
                isFocused={index === cursor}
              >
                {optionTemplate
                  ? React.createElement(optionTemplate, { ...option })
                  : option.label}

                {option?.value === value?.value && (
                  <div className="ml-auto text-green-500">
                    <Checkmark16 />
                  </div>
                )}
              </SelectMenuItem>
            );
          }}
        />
      )}
    </>
  );
};
