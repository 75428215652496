import { DividerHorizontalIcon, CheckIcon } from "@radix-ui/react-icons";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import { ReactNode } from "react";
import { useControllableState } from "hooks/useControllable";
import { CheckboxTestIds } from "./CheckboxTestIds.enum";
import styled from "styled-components";

export type ICheckbox = RadixCheckbox.CheckboxProps & {
  children?: ReactNode;
  testId?: string;
};

const StyledCheckbox = styled(RadixCheckbox.Root)`
  border: 1px solid;
  border-color: ${(props) => props.theme.formControlBorderColorDefault};
  border-radius: ${(props) => props.theme.radii.sm};
  height: ${(props) => props.theme.space[5]};
  width: ${(props) => props.theme.space[5]};
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  &[data-state="checked"] {
    border-color: ${(props) => props.theme.colors.primary[10]};
    background-color: ${(props) => props.theme.colors.primary[10]};
  }
`;

export const Checkbox = (props: ICheckbox) => {
  const {
    checked: checkedProp,
    children,
    testId,
    onCheckedChange,
    checked,
    ...rest
  } = props;

  // const [checked, onChange] = useControllableState({
  //   // value: checkedProp,
  //   defaultValue: false,
  //   onChange: onCheckedChange,
  // });

  return (
    <div className="flex items-center">
      <StyledCheckbox
        checked={checkedProp}
        onCheckedChange={(checked) => {
          onCheckedChange(checked);
        }}
        {...rest}
        {...(testId && { "data-testid": testId })}
      >
        <RadixCheckbox.Indicator
          data-testid={CheckboxTestIds.CHECKBOX_CHECKED_INDICATOR}
        >
          {checked === "indeterminate" && <DividerHorizontalIcon />}
          {checked === true && <CheckIcon />}
        </RadixCheckbox.Indicator>
      </StyledCheckbox>

      {children && <div className="ml-2">{children}</div>}
    </div>
  );
};
