import { useDispatch, useSelector } from "react-redux";
import {
  setValidatedData,
  setValidatingStatus,
} from "store/createBulkShipmentsSlice/index.slice";
import { RootState } from "store";
import { useWizardContextProvider, WizardHeader } from "../../Wizard";
import { formatValidateShipments } from "services/api/bulkShipments";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { hasProfiles } from "../utils/hasProfiles.util";
import { CreateBulkShipmentStep1FormPanel } from "./CreateBulkShipmentStep1FormPanel";
import { CreateBulkShipmentStep1NoProfilesPanel } from "./CreateBulkShipmentStep1NoProfilesPanel";
import { closeModal } from "store/modalSlice";
import { ModalBody } from "components/atoms/Modal";
import { toast } from "react-hot-toast";
import { GENERIC_TOAST_ERROR_MESSAGE } from "constants/strings";

export const CreateBulkShipmentStep1 = () => {
  const dispatch = useDispatch();

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { onNextStep } = useWizardContextProvider();

  const { profiles, loadingStatus: profilesLoadingStatus } = useSelector(
    (state: RootState) => {
      return state.bulkShipmentProfilesSlice;
    }
  );

  const { selectedProfileUUID, data, validatingStatus } = useSelector(
    (state: RootState) => {
      return state.createBulkShipmentsSlice;
    }
  );

  const handleSubmit = async () => {
    dispatch(setValidatingStatus(ApiLoadingStatus.LOADING));

    const { error, data: validatedData } = await formatValidateShipments(
      salesAccountUUID,
      {
        shipmentImportProfileUUID: selectedProfileUUID,
        shipments: data.data,
      }
    );

    if (error) {
      dispatch(setValidatingStatus(ApiLoadingStatus.FAILED));
      toast.error(GENERIC_TOAST_ERROR_MESSAGE);
    }

    if (!error) {
      dispatch(setValidatedData(validatedData));
      dispatch(setValidatingStatus(ApiLoadingStatus.SUCCESS));
      onNextStep();
    }
  };

  return (
    <ModalBody>
      <WizardHeader
        title="Bulk shipment upload"
        onClose={() =>
          dispatch(closeModal({ modalName: "createBulkShipment" }))
        }
      />
      {hasProfiles(profilesLoadingStatus, profiles) ? (
        <CreateBulkShipmentStep1FormPanel
          handleSubmit={handleSubmit}
          isSubmitting={validatingStatus === ApiLoadingStatus.LOADING}
        />
      ) : (
        <CreateBulkShipmentStep1NoProfilesPanel />
      )}
    </ModalBody>
  );
};
