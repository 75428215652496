import { fetcher, IFetchResponseSingle } from "services/api";
import { FetchChangelogsResponseDto } from "./dtos/fetch-changelogs.response.dto";
import { apiPaths } from "../../../enum/paths.enum";

export const fetchChangelogs = async (): Promise<
  IFetchResponseSingle<FetchChangelogsResponseDto>
> => {
  return await fetcher({
    url: apiPaths.changelogs,

    method: "GET",
  });
};
