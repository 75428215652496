import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { VirtualWarehousesSliceInitialSlice } from "./VirtualWarehousesSliceInitialState.const";
import { AppThunk, RootState } from "store";
import { IVirtualWarehousesSlice } from "./VirtualWarehousesSlice.interface";
import { fetchVirtualWarehouses } from "services/api/virtualWarehouses";

export const virtualWarehousesSlice = createSlice({
  name: "virtualWarehousesSlice",
  initialState: VirtualWarehousesSliceInitialSlice,
  reducers: {
    setData: (
      state,
      action: PayloadAction<IVirtualWarehousesSlice["items"]>
    ) => {
      state.items = action.payload;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<IVirtualWarehousesSlice["loadingStatus"]>
    ) => {
      state.loadingStatus = action.payload;
    },
  },
});

export const { setData, setLoadingStatus } = virtualWarehousesSlice.actions;

export const fetchVirtualWarehousesThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setLoadingStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    try {
      const { data, error } = await fetchVirtualWarehouses(salesAccountUUID);

      if (!error) {
        dispatch(setData(data));
        dispatch(setLoadingStatus(ApiLoadingStatus.SUCCESS));
      }

      if (error) {
        throw error;
      }
    } catch (e) {
      dispatch(setLoadingStatus(ApiLoadingStatus.FAILED));
    }
  };
};
