import { Modal } from "components/atoms/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeModal } from "store/modalSlice";
import { Drawer } from "components/molecules";
import { AnimatePresence } from "framer-motion";
import { modals } from "store/modalSlice/modalSlice.types";
import { createElement } from "react";
import { Portal } from "react-portal";
import { Backdrop } from "components/atoms/Backdrop";
import { ModalComponentsMap } from "./ModalComponentsMap";

export const Modals = () => {
  const {
    modalSlice: { modals },
  } = useSelector<RootState, RootState>((state) => state);

  const dispatch = useDispatch();

  return (
    <AnimatePresence>
      {Object.keys(modals).map((key: modals, i: number) => {
        const modal = modals[key];

        const { name, size, type, showBackdrop, closeOnOverlayClick } = modal;

        const modalCount = Object.keys(modals).length;

        const props = {
          testId: name,
          key: i,
          size: size,
          isOnTop: modalCount > 1 ? i > 0 : true,
          isOpen: true,
          closeOnOverlayClick,
          isCentered: true,
          handleClose: () => dispatch(closeModal({ modalName: name })),
        };

        return (
          <Portal key={i}>
            {showBackdrop && <Backdrop />}

            {type === "drawer" ? (
              <Drawer {...props}>
                {createElement(ModalComponentsMap[key])}
              </Drawer>
            ) : (
              <>
                <Modal {...props}>
                  {createElement(ModalComponentsMap[key])}
                </Modal>
              </>
            )}
          </Portal>
        );
      })}
    </AnimatePresence>
  );
};
