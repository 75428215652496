import { Roles } from "enum/roles.enum";
import { ReactNode } from "react";

export const RoleGuard = ({
  userRoles = [],
  showForRoles,
  children,
}: {
  userRoles: Roles[];
  showForRoles: Roles[];
  children: ReactNode;
}) => {
  let show = false;

  userRoles.map((role) => {
    if (showForRoles.includes(role)) {
      show = true;
    }
  });

  return <>{show ? children : ""}</>;
};
