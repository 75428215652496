import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { LabelSizes } from "enum/label-sizes.enum";
import {  Meta, Query } from "interfaces/api.interface";
import { ILabelJob } from "models/labelJob/labelJob.model";
import { IShipment } from "models/shipment/shipment.model";
import { createLabelJob, fetchLabelJobs } from "services/api/labels";
import { FetchLabelJobsResponseDto } from "services/api/labels/dtos/fetch-label-jobs.response.dto";
import { AppThunk, RootState } from "store";

interface ILabelJobsSlice {
  createLoadingStatus: ApiLoadingStatus;
  currentLabelJobUUID: ILabelJob["uuid"] | null;
  loadingStatus: ApiLoadingStatus;
  items: ILabelJob[];
  meta: Meta;
}

export const LabelJobsInitialState: ILabelJobsSlice = {
  createLoadingStatus: ApiLoadingStatus.IDLE,
  currentLabelJobUUID: null,
  loadingStatus: ApiLoadingStatus.IDLE,
  items: [],
  meta: {
    pageCount: 0,
    offset: 0,
    limit: 0,
    recordCount: 0,
  },
};

export const labelJobsSlice = createSlice({
  name: "labelJobsSlice",
  initialState: LabelJobsInitialState,
  reducers: {
    setData: (state, action: PayloadAction<FetchLabelJobsResponseDto>) => {
      state.items = action.payload.data || LabelJobsInitialState.items;

      state.meta = action.payload.meta || LabelJobsInitialState.meta;

      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },
    setCreateLoadingStatus: (
      state,
      action: PayloadAction<ILabelJobsSlice["createLoadingStatus"]>
    ) => {
      state.createLoadingStatus = action.payload;
    },
    setStatus: (
      state,
      action: PayloadAction<ILabelJobsSlice["loadingStatus"]>
    ) => {
      state.loadingStatus = action.payload;
    },
    setCurrentLabelJobUUID: (
      state,
      action: PayloadAction<ILabelJobsSlice["currentLabelJobUUID"]>
    ) => {
      state.currentLabelJobUUID = action.payload;
    },
  },
});

export const {
  setData,
  setStatus,
  setCreateLoadingStatus,
  setCurrentLabelJobUUID,
} = labelJobsSlice.actions;

export const fetchLabelJobsThunk = (
  query: Query<ILabelJob, { downloaded: "true" | "false" }>
): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      try {
        const { data, error, meta } = await fetchLabelJobs(
          salesAccountUUID,
          query
        );

        if (!error) {
          dispatch(
            setData({
              meta,
              data,
            })
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
};

export const createLabelJobThunk = (
  shipmentIds: IShipment["id"][]
): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setCreateLoadingStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    try {
      const { error } = await createLabelJob(salesAccountUUID, {
        shipmentIds,
        labelSize: LabelSizes.x6,
        processAsync: true,
      });

      if (!error) {
        dispatch(setCreateLoadingStatus(ApiLoadingStatus.SUCCESS));
      }

      if (error) {
        dispatch(setCreateLoadingStatus(ApiLoadingStatus.FAILED));
      }
    } catch (e) {
      dispatch(setCreateLoadingStatus(ApiLoadingStatus.FAILED));
    }
  };
};
