import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { IQuotedRate } from "models/quote/quote.model";
import { AppThunk, RootState } from "store";
import { closeModal, openModal } from "store/modalSlice";
import { groupedItems } from "utils/groupItems";
import { CreateShipmentAddressType } from "../createShipmentSlice.types";
import {
  onChangeReceiverAddressField,
  onChangeReceiverCountry,
  onChangeSenderAddressField,
  onChangeSenderCountry,
  setConvertingFromQuoteStatus,
  setIsConvertingFromQuote,
  setPackages,
  setRates,
  setRatesLoadingStatus,
  setSelectedRate,
} from "../index.slice";

export const convertQuoteToShipmentThunk = (rate: IQuotedRate): AppThunk => {
  return async (dispatch, getState) => {
    const {
      quotesSlice: { currentQuote },
    } = getState() as RootState;

    // The modal needs to be loaded first, before anything else happens. Inside the address component is an effect hook that
    // Resets the county / state when the country value changes. Inside the form, is also a hook that sets the countries to their defaults.
    // So we first need to load the modal, let everything mount and run, and only then set the values.

    if (currentQuote) {
      await dispatch(setIsConvertingFromQuote(true));

      await dispatch(openModal({ name: "createShipment" }));

      await dispatch(closeModal({ modalName: "viewQuote" }));

      await dispatch(setConvertingFromQuoteStatus("LOADING"));

      await dispatch(setPackages(groupedItems(currentQuote.packages)));

      await dispatch(onChangeSenderCountry(currentQuote.fromAddressCountryIso));

      await dispatch(
        onChangeSenderAddressField({
          type: CreateShipmentAddressType.NEW,
          zip: currentQuote.fromAddressZip,
        })
      );

      await dispatch(onChangeReceiverCountry(currentQuote.toAddressCountryIso));

      await dispatch(
        onChangeReceiverAddressField({
          type: CreateShipmentAddressType.NEW,
          zip: currentQuote.toAddressZip,
        })
      );

      await dispatch(setSelectedRate(rate));

      await dispatch(setRates([{ ...rate }]));

      await dispatch(setRatesLoadingStatus(ApiLoadingStatus.SUCCESS));

      await dispatch(setConvertingFromQuoteStatus("READY"));
    }
  };
};
