import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { HTMLProps, Ref } from "react";
import styled, { useTheme } from "styled-components";

export type IButton = {
  innerRef?: Ref<HTMLButtonElement>;
  isBlock?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  dropdownIsDisabled?: boolean;
  variant?: "solid" | "outline" | "ghost";
  type?: "submit" | "button";
  size?: "xs" | "sm" | "default" | "lg";
  colorScheme?: "primary" | "default" | "danger" | "selected";
  hoverColorScheme?: "danger" | "success";
  testId?: string;
  isDownload?: boolean;
  options?: { name: string; onClick: Function }[];
} & Pick<
  HTMLProps<HTMLButtonElement>,
  "id" | "onClick" | "children" | "className"
>;

type state = "hover" | "active" | "disabled" | "default";

export const getButtonBackgroundColor = (
  colorScheme: IButton["colorScheme"],
  state: state,
  variant: IButton["variant"],
  hoverColorScheme?: IButton["hoverColorScheme"]
) => {
  const theme = useTheme();

  switch (colorScheme) {
    case "default":
      switch (variant) {
        case "outline":
          switch (state) {
            default:
              return "transparent";
          }
        case "solid":
          switch (state) {
            case "default":
              return theme.buttonDefaultBackgroundColor;
            case "hover":
              switch (hoverColorScheme) {
                case "danger":
                  return theme.colors.red[10];

                default:
                  return theme.buttonDefaultBackgroundColorHover;
              }
          }
      }

    case "primary":
      return theme.buttonPrimaryBackgroundColor;

    case "selected":
      return theme.colors.green[9];

    case "danger":
      switch (state) {
        case "default":
          return theme.colors.red[10];

        case "hover":
          return theme.colors.red[8];
      }
  }
};

export const getButtonTextColor = (colorScheme: IButton["colorScheme"]) => {
  const theme = useTheme();

  switch (colorScheme) {
    case "default":
      return theme.buttonDefaultTextColor;

    case "primary":
      return "white";

    case "selected":
      return "white";
  }
};

export const DropdowButton = ({
  children,
  options,
  isLoading,
  colorScheme,
  isDisabled,
}: IButton) => {
  const theme = useTheme();

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <MenuButton
        disabled={isLoading || isDisabled}
        style={{
          backgroundColor: getButtonBackgroundColor(
            colorScheme,
            "default",
            "solid"
          ),
          color: getButtonTextColor(colorScheme),
        }}
        className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-50"
      >
        {children}
        <ChevronDownIcon
          aria-hidden="true"
          className="-mr-1 h-5 w-5 text-gray-400"
        />
      </MenuButton>

      <MenuItems
        transition
        className="absolute border right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        style={{
          borderRadius: theme.borderRadius,
          backgroundColor: theme.dropdownBackgroundColor,
          borderColor: theme.dropdownBorderColor,
          boxShadow: theme.shadows["2xl"],
        }}
      >
        <div className="py-1">
          {options?.map((item) => (
            <MenuItem key={item.name}>
              <a
                onClick={() => item.onClick()}
                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 "
              >
                {item.name}
              </a>
            </MenuItem>
          ))}
        </div>
      </MenuItems>
    </Menu>
  );
};
