import { Close } from "components/atoms/Close";
import { Heading } from "components/atoms/Heading";
import { IWizardStep, UseWizardReturn } from "hooks/useWizard";
import { useTheme } from "styled-components";
import { createContext } from "utils/react/createContext";

export const WizardHeader = ({
  title,
  onClose,
}: {
  title: string;
  onClose(): void;
}) => {
  const { onPrevStep, canGoBack, stepsCount, activeStepIndex } =
    useWizardContextProvider();

  const theme = useTheme();

  return (
    <div>
      <div className="relative flex items-center mb-4">
        {canGoBack && (
          <div
            onClick={onPrevStep}
            className="text-sm cursor-pointer text-muted hover:underline"
          >
            Back
          </div>
        )}
        <div className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
          <div className="inline-flex items-center h-8 px-5 text-sm leading-none rounded-full bg-gray3 text-muted">
            {activeStepIndex + 1} of {stepsCount}
          </div>
        </div>
        <div className="ml-auto">
          <Close onClick={onClose} />
        </div>
      </div>
      <Heading size="2xl" className="mb-8 text-center">
        {title}
      </Heading>
    </div>
  );
};

export const [WizardContextProvider, useWizardContextProvider] =
  createContext<UseWizardReturn>();

export const Wizard = ({ steps }: { steps: IWizardStep[] }) => {
  const { activeStepIndex } = useWizardContextProvider();

  return (
    <>
      {steps.map((step, i) => {
        return (
          <div
            key={i}
            data-testid={step.testId}
            style={{
              display: i !== activeStepIndex ? "none" : "block",
            }}
          >
            {step.component}
          </div>
        );
      })}
    </>
  );
};
