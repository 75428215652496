import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalSizes } from "components/atoms/Modal";
import { ModalSliceActions } from "./modalSlice.actions";
import { modals } from "./modalSlice.types";
import { IModalComponents } from "./modalComponents.const";
import { ModalSliceInitialState } from "./modalSliceInitialState.const";

export type IModal = {
  name: modals;
  closeOnOverlayClick?: boolean;
  showBackdrop?: boolean;
  isCentered?: boolean;
  type?: "modal" | "drawer";
  size?: ModalSizes;
};

export type IModals = {
  [key in modals]?: IModal;
};

export const modalSlice = createSlice({
  name: "modalSlice",
  initialState: ModalSliceInitialState,
  reducers: {
    [ModalSliceActions.OPEN_MODAL]: (state, action: PayloadAction<IModal>) => {
      state.modals = {
        ...state.modals,
        [action.payload.name]: {
          name: action.payload.name,
          isCentered: action.payload.isCentered || false,
          closeOnOverlayClick:
            action.payload.closeOnOverlayClick !== undefined
              ? action.payload.closeOnOverlayClick
              : IModalComponents[action.payload.name].closeOnOverlayClick !==
                undefined
              ? IModalComponents[action.payload.name].closeOnOverlayClick
              : true,
          type: action.payload.type,
          showBackdrop:
            action.payload.showBackdrop !== undefined
              ? action.payload.showBackdrop
              : IModalComponents[action.payload.name].showBackdrop !== undefined
              ? IModalComponents[action.payload.name].showBackdrop
              : true,
          size: action.payload.size
            ? action.payload.size
            : IModalComponents[action.payload.name].defaultSize,
        },
      };
      state.isOpen = true;
    },
    [ModalSliceActions.CLOSE_MODAL]: (
      state,
      action: PayloadAction<{ modalName: modals }>
    ) => {
      delete state.modals[action.payload.modalName];

      if (Object.keys(state.modals).length === 0) {
        state.isOpen = false;
      }
    },
    [ModalSliceActions.SET_MODAL_SIZE]: (
      state,
      action: PayloadAction<{ name: modals; size: ModalSizes }>
    ) => {
      if (state.modals[action.payload.name]) {
        state.modals[action.payload.name] = {
          ...state.modals[action.payload.name],
          size: action.payload.size,
        };
      }
    },
  },
});

export const { openModal, closeModal, setModalSize } = modalSlice.actions;

// export const modalHandlingMiddleware: Middleware<RootState, RootState> =
//   (storeAPI) => (next) => (action) => {
//     if (
//       closeModal.match(action) &&
//       action.payload.modalName === "createShipment" &&
//       !storeAPI.getState().modalSlice.modals.confirmCloseCreateShipmentModal
//     ) {
//       storeAPI.dispatch(openModal({ name: "confirmCloseCreateShipmentModal" }));
//       return;
//     }

//     if (
//       closeModal.match(action) &&
//       action.payload.modalName === "createQuote" &&
//       storeAPI.getState().modalSlice.modals.confirmCloseCreateShipmentModal
//     ) {
//       storeAPI.dispatch(
//         closeModal({ modalName: "confirmCloseCreateShipmentModal" })
//       );
//     }

//     return next(action);
//   };
