import { Alert } from "components/atoms/Alert";
import { Button } from "components/atoms/Button";
import { ModalBody, ModalFooter, ModalHeader } from "components/atoms/Modal";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { useDispatch } from "react-redux";
import {
  setConvertingFromQuoteStatus,
  setIsConvertingFromQuote,
  setRates,
  setRatesLoadingStatus,
  setSelectedRate,
} from "store/createShipmentSlice/index.slice";
import { closeModal } from "store/modalSlice";

export const ConfirmRefetchShipmentRates = () => {
  const dispatch = useDispatch();

  return (
    <>
      <ModalHeader>Rates need updating</ModalHeader>
      <ModalBody>
        <Alert>Changing these details will require new rates. Proceed?</Alert>
      </ModalBody>
      <ModalFooter>
        <div className="ml-auto">
          <Button
            colorScheme="danger"
            onClick={() => {
              dispatch(setRates([]));
              dispatch(setSelectedRate(null));
              dispatch(setRatesLoadingStatus(ApiLoadingStatus.IDLE));
              dispatch(setConvertingFromQuoteStatus(ApiLoadingStatus.IDLE));
              dispatch(setIsConvertingFromQuote(false));
              dispatch(
                closeModal({ modalName: "confirmRefetchShipmentRates" })
              );
            }}
          >
            Yes
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
