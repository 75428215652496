import React from "react";
import styles from "./Table.module.scss";

export const TableWrapper = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <div className={styles.TableWrapper}>{children}</div>;
};
