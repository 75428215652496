import NumericInput, { NumericInputProps } from "react-numeric-input";
import styled from "styled-components";
import { getButtonHeight } from "../Button";
import { IInput } from "components/atoms/Input";

export type INumberInputProps = {
  testId?: string;
  inputSize?: IInput["size"];
} & Omit<NumericInputProps, "size">;

const StyledNumberInput = styled(NumericInput)<{ inputSize: IInput["size"] }>`
  width: 100%;
  appearance: none;
  font-weight: normal;
  outline: none;
  border: 1px solid;
  border-color: ${(props) => props.theme.formControlBorderColorDefault};
  background-color: ${(props) => props.theme.formControlBackgroundColorDefault};
  padding-left: 12px;
  font-size: 14px;
  border-radius: ${(props) => props.theme.borderRadius};
  height: ${(props) => getButtonHeight(props.inputSize)};
`;

export const NumberInput = ({
  step = 0.1,
  precision = 2,
  testId,
  inputSize = "default",
  ...rest
}: INumberInputProps) => {
  return (
    <StyledNumberInput
      step={step}
      data-testid={testId}
      precision={precision}
      noStyle
      strict
      inputSize={inputSize}
      {...rest}
    />
  );
};
