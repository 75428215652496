import { getThemeVariable } from "theme/getThemeVariable.theme";
import {} from "theme/theme-spacing.theme";
import { ThemeSpacingVariableNames } from "theme/theme-variable-names.theme";

export enum AvatarSizes {
  "xs" = "xs",
  "sm" = "sm",
  "md" = "md",
}

const AvatarSizesConfig: { [key in AvatarSizes]: ThemeSpacingVariableNames } = {
  [AvatarSizes.xs]: "space5",
  [AvatarSizes.sm]: "space8",
  [AvatarSizes.md]: "space9",
};

export const Avatar = ({
  initial,
  size = AvatarSizes.md,
}: {
  initial: string;
  size: AvatarSizes;
}) => {
  return (
    <div
      className="flex items-center justify-center w-full h-full font-semibold leading-none uppercase rounded-md bg-gray4 text-md text-gray11"
      style={{
        width: getThemeVariable(AvatarSizesConfig[size]),
        height: getThemeVariable(AvatarSizesConfig[size]),
      }}
    >
      {initial}
    </div>
  );
};
