import { Attribute, AttributeTextCell } from "components/atoms/Attribute";
import { PageSection } from "../PageSection";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { RoleGuard } from "components/atoms/RoleGuard";
import { Roles } from "enum/roles.enum";
import { Checkmark16, Close16 } from "@carbon/icons-react";
// import { PrintCountry } from "components/atoms/PrintCountry";
import { Stack } from "components/atoms/Stack";
import { PrintCountry } from "components/atoms/PrintCountry";

export const ViewQuoteModealDetails = () => {
  const { currentQuote } = useSelector((state: RootState) => {
    return state.quotesSlice;
  });

  const { salesAccounts } = useSelector((state: RootState) => {
    return state.salesAccountSlice;
  });

  const { users } = useSelector((state: RootState) => {
    return state.usersSlice;
  });

  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { countries } = useSelector((state: RootState) => {
    return state.countriesSlice;
  });

  return (
    <PageSection title="Details">
      <Stack spacing={5}>
        <Attribute label="Origin country">
          <AttributeTextCell>
            <PrintCountry
              countryName={
                countries.find(
                  (country) =>
                    country?.isoCode?.toUpperCase() ===
                    currentQuote?.fromAddressCountryIso?.toUpperCase()
                )?.name || null
              }
              countryCode={
                currentQuote?.fromAddressCountryIso?.toLowerCase() || ""
              }
            />
          </AttributeTextCell>
        </Attribute>
        <Attribute label="Origin zip">
          <AttributeTextCell>{currentQuote?.fromAddressZip}</AttributeTextCell>
        </Attribute>
        <Attribute label="Destination country">
          <AttributeTextCell>
            <PrintCountry
              countryName={
                countries.find(
                  (country) =>
                    country?.isoCode?.toUpperCase() ===
                    currentQuote?.toAddressCountryIso?.toUpperCase()
                )?.name || null
              }
              countryCode={
                currentQuote?.toAddressCountryIso?.toLowerCase() || ""
              }
            />
          </AttributeTextCell>
        </Attribute>
        <Attribute label="Destination zip">
          <AttributeTextCell>{currentQuote?.toAddressZip}</AttributeTextCell>
        </Attribute>
        <RoleGuard
          userRoles={loggedInUser["https://connexx.com/roles"]}
          showForRoles={[Roles.ITD_STAFF]}
        >
          <>
            <Attribute label="Customer">
              <AttributeTextCell>
                {
                  salesAccounts.find((customer) => {
                    return customer.id === currentQuote?.salesAccountId;
                  })?.tradeName
                }
              </AttributeTextCell>
            </Attribute>
          </>
        </RoleGuard>
        <RoleGuard
          userRoles={loggedInUser["https://connexx.com/roles"]}
          showForRoles={[Roles.ITD_STAFF]}
        >
          <>
            <Attribute label="Created by">
              <AttributeTextCell>
                {
                  users.find((user) => {
                    return user.id === currentQuote?.createdBy;
                  })?.email
                }
              </AttributeTextCell>
            </Attribute>
          </>
        </RoleGuard>
        {/* <Attribute
          label={`Chargeable weight (${settings?.defaultSettings?.weightUnit})`}
        >
          <AttributeTextCell>
            {currentQuote?.quoteResponseItems[0].chargeableWeight}
          </AttributeTextCell>
        </Attribute> */}
        {/* <Attribute label="Created at">
          <AttributeTextCell>
            {format(
              parseISO(currentQuote?.quoteResponseItems[0]?.createdAt),
              DATE_FORMAT
            )}
          </AttributeTextCell>
        </Attribute> */}
        <Attribute label="Created by admin">
          <AttributeTextCell>
            {currentQuote?.generatedByAdmin ? (
              <div className="text-green-500">
                <Checkmark16 />
              </div>
            ) : (
              <div className="text-red-500">
                <Close16 />
              </div>
            )}
          </AttributeTextCell>
        </Attribute>
      </Stack>
    </PageSection>
  );
};
