import { forwardRef, HTMLAttributes, ReactNode, Ref } from "react";
import cx from "classnames";

type IPanelHeader = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const PanelHeader = forwardRef(
  (props: IPanelHeader, ref: Ref<HTMLDivElement>) => {
    const { children, className, ...rest } = props;

    return (
      <header className={cx("px-5 pt-3", className)} {...rest} ref={ref}>
        {children}
      </header>
    );
  }
);

PanelHeader.displayName = "PanelHeader";
