import { useTheme } from "styled-components";
import { DrawerClose } from "./DrawerClose";
import { useDrawerContext } from "./DrawerContext";

export const DrawerHeader = ({ children }: { children: React.ReactNode }) => {
  const { handleClose } = useDrawerContext();

  const theme = useTheme();

  return (
    <div
      className="flex items-center flex-shrink-0 border-b"
      style={{
        height: theme.navigationHeight,
        borderColor: theme.borderColor,
        paddingLeft: theme.gutter,
        paddingRight: theme.gutter,
      }}
    >
      {children}
      <div className="ml-auto">
        <DrawerClose onClick={handleClose} />
      </div>
    </div>
  );
};
