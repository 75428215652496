import { Divider } from "components/atoms/Divider";
import { DrawerHeader } from "components/molecules/Drawer/DrawerHeader";
import { DrawerTitle } from "components/molecules/Drawer/DrawerTitle";
import { useSelector } from "react-redux";
import { RootState } from "store";

import { Stack } from "components/atoms/Stack";
import { ViewShipmentRates } from "./ViewShipmentRates";
import { ViewShipmentPickUp } from "./ViewShipmentPickUp";
import { ViewShipmentErrors } from "./ViewShipmentErrors";
import { ViewShipmentInsurance } from "./ViewShipmentInsurance";
import { ViewShipmentLoading } from "./ViewShipmentLoading";
import { ViewShipmentLoadingError } from "./ViewShipmentLoadingError";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { ViewShipmentSummary } from "./ViewShipmentSummary";
import { ErrorBoundary } from "components/atoms/ErrorBoundary";
import { Center } from "components/atoms/Center";
import { Error } from "components/atoms/Error";
import { ViewShipmentModalItemContents } from "./ViewShipmentPackages/ViewShipmentContents";
import { ViewShipmentPackages } from "./ViewShipmentPackages";

export const ViewShipmentModal = () => {
  const { currentShipment, currentShipmentLoadingStatus } = useSelector(
    (state: RootState) => {
      return state.shipmentsSlice;
    }
  );

  const isLoading = currentShipmentLoadingStatus === ApiLoadingStatus.LOADING;

  const isError = currentShipmentLoadingStatus === ApiLoadingStatus.FAILED;

  return (
    <ErrorBoundary
      FallbackComponent={({ resetErrorBoundary }) => {
        return (
          <div className="w-full h-screen">
            <Center>
              <Error buttonLabel="Refresh" onClick={resetErrorBoundary} />
            </Center>
          </div>
        );
      }}
    >
      {isLoading ? (
        <ViewShipmentLoading />
      ) : isError ? (
        <ViewShipmentLoadingError />
      ) : (
        <>
          <DrawerHeader>
            <DrawerTitle>
              Shipment #{currentShipment?.customerReference}
            </DrawerTitle>
          </DrawerHeader>
          <div className="flex-1 overflow-scroll">
            <ViewShipmentErrors />
            <Stack divider={<Divider />} childClassName="px-5 py-5">
              <ViewShipmentSummary />
              <ViewShipmentPickUp />
              <ViewShipmentInsurance />
              <ViewShipmentPackages />
              <ViewShipmentRates />
            </Stack>
          </div>
        </>
      )}
    </ErrorBoundary>
  );
};
