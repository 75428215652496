import { KeyboardKeys } from "enum/keyboardkeys.enum";
import { motion } from "framer-motion";
import { useOnClickOutside } from "hooks/useOutsideClick";
import { ReactNode, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTheme } from "styled-components";
import { DrawerContext } from "./DrawerContext";

const animation = {
  visible: { x: 0 },
  hidden: { x: 500 },
};

export const Sizes = {
  xs: 300,
  sm: 500,
  md: 700,
  lg: 800,
  xl: 900,
  "2xl": 1000,
  "3xl": 1100,
};

export type ModalSizes =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "full";

export const Drawer = ({
  children,
  isOpen,
  handleClose,
  size = "sm",
  testId,
}: {
  children: ReactNode;
  isOpen: boolean;
  handleClose(): void;
  size: ModalSizes;
  testId?: string;
}) => {
  useHotkeys(
    KeyboardKeys.ESCAPE,
    () => {
      handleClose();
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  const theme = useTheme();

  const ref = useRef<HTMLDivElement>();

  useOnClickOutside([ref], () => {
    handleClose();
  });

  return (
    <DrawerContext value={{ isOpen, handleClose }}>
      {isOpen && (
        <motion.div
          ref={ref}
          key="drawer"
          variants={animation}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ duration: 0.25, stiffness: 30 }}
          className="fixed top-0 right-0 flex flex-col w-full h-full overflow-hidden border-l"
          style={{
            maxWidth: Sizes[size],
            borderColor: theme.borderColor,
            backgroundColor: theme.modalBackgroundColor,
            boxShadow: theme.shadows["lg"],
          }}
          onClick={(e) => e.stopPropagation()}
          {...(testId && { "data-testid": testId })}
        >
          {children}
        </motion.div>
      )}
    </DrawerContext>
  );
};
