import { ModalBody, ModalFooter } from "components/atoms/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeModal } from "store/modalSlice";
import toast from "react-hot-toast";
import { bulkCancelShipments } from "services/api/shipments";
import { setSelectedShipmentIds } from "store/shipmentsSlice/index.slice";
import { GENERIC_TOAST_ERROR_MESSAGE } from "constants/strings";
import { Alert } from "components/atoms/Alert";
import { Button } from "components/atoms/Button";
import { fetchShipmentsThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import { Stack } from "components/atoms/Stack";

export const ConfirmBulkDeleteShipmentsModal = () => {
  const dispatch = useDispatch();

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { selectedShipmentIds } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const handleConfirm = async () => {
    const { error } = await bulkCancelShipments(salesAccountUUID, {
      shipmentIds: selectedShipmentIds,
    });

    if (!error) {
      dispatch(closeModal({ modalName: "confirmBulkDeleteShipmentsModal" }));
      dispatch(fetchShipmentsThunk());
      dispatch(setSelectedShipmentIds([]));
      toast.success("Shipment cancelled");
    }

    if (error) {
      toast.error(GENERIC_TOAST_ERROR_MESSAGE);
    }
  };

  return (
    <>
      <ModalBody>
        <Alert>Are you sure you want to cancel these shipments?</Alert>
      </ModalBody>
      <ModalFooter>
        <div className="ml-auto">
          <Button colorScheme="danger" onClick={handleConfirm} className="mr-4">
            Yes
          </Button>
          <Button
            onClick={() => {
              dispatch(
                closeModal({ modalName: "confirmBulkDeleteShipmentsModal" })
              );
            }}
          >
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
