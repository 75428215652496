import { TableInstance } from "react-table";
import { TableRow } from "./TableRow";
import * as ScrollArea from "@radix-ui/react-scroll-area";

type ITableBodyProps<T extends object> = {
  page: TableInstance<T>["page"];
  getTableBodyProps: TableInstance<T>["getTableBodyProps"];
  visibleColumns: TableInstance<T>["visibleColumns"];
  prepareRow: TableInstance<T>["prepareRow"];
  isLoading: boolean;
  onRowClick?: (row: T) => void;
  isChildTable?: boolean;
  erroredColumns?: { column: string; indices: number[] }[];
};

export const TableBody = <T extends object>({
  getTableBodyProps,
  prepareRow,
  page,
  onRowClick,
  erroredColumns,
}: ITableBodyProps<T>) => {
  return (
    <ScrollArea.Root
      className="flex flex-1 w-full overflow-hidden ScrollAreaRoot"
      type="always"
    >
      <ScrollArea.Viewport
        {...getTableBodyProps()}
        className="flex-1 overflow-x-hidden overflow-y-scroll"
      >
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow
              row={row}
              key={i}
              onRowClick={onRowClick}
              erroredColumns={erroredColumns}
            />
          );
        })}
      </ScrollArea.Viewport>

      <div className="fixed right-0" style={{ height: 1000 }}>
        <ScrollArea.Scrollbar
          className="ScrollAreaScrollbar"
          orientation="vertical"
        >
          <ScrollArea.Thumb className="ScrollAreaThumb" />
        </ScrollArea.Scrollbar>
      </div>
      <div className="fixed bottom-0" style={{ width: 1000 }}>
        <ScrollArea.Scrollbar
          className="ScrollAreaScrollbar top-1"
          orientation="horizontal"
        >
          <ScrollArea.Thumb className="ScrollAreaThumb" />
        </ScrollArea.Scrollbar>
      </div>
      <ScrollArea.Corner className="ScrollAreaCorner" />
    </ScrollArea.Root>
  );
};
