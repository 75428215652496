import { ReactNode, useEffect } from "react";
import { ErrorBoundary } from "components/atoms/ErrorBoundary";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { loginThunk } from "store/authSlice";
import { RootState } from "store";
import { fetchSalesAccountsThunk } from "store/salesAccountSlice/index.slice";
import { IUser } from "models/user/user.model";
import { fetchUsersThunk } from "store/usersSlice/index.slice";
import { isUserAdmin } from "utils/isUserAdmin";
import {
  isUserAdminForSalesAccounts,
  isUserSalesPeopleForSalesAccounts,
} from "utils/isUserAdminForSalesAccounts";
import { fetchBulkShipmentProfilesThunk } from "store/bulkShipmentProfilesSlice/index.slice";
import {
  fetchCompanyAddressesThunk,
  fetchCompanyContactsThunk,
  fetchSettingsThunk,
} from "store/settingsSlice/index.slice";
import { fetchShippingWhitelistsThunk } from "store/shippingWhitelistsSlice/index.slice";
import { fetchCurrentLabelJobsThunk } from "store/currentLabelJobsSlice/index.slice";
import { PusherData } from "components/organisms/PusherData";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { Center } from "components/atoms/Center";
import { Error } from "components/atoms/Error";
import { getAppLoadingState } from "./LayoutAuthenticated.utils";
import { fetchIntegrationsThunk } from "store/integrationsSlice/index.slice";
import { Loading } from "components/pages/Loading";
import { fetchVirtualWarehousesThunk } from "store/warehousesSlice/index.slice";
import TopBanner from "components/atoms/TopBanner";

export const LayoutAuthenticated = ({ children }: { children?: ReactNode }) => {
  const dispatch = useDispatch();

  const {
    user,
    getAccessTokenSilently,
    loginWithRedirect,
    isLoading: isLoadingUser,
    isAuthenticated,
  } = useAuth0<IUser>();

  const { accessToken, salesAccountID, alertType } = useSelector(
    (state: RootState) => {
      return state.authSlice;
    }
  );

  const isOnStop = alertType === "onstop";

  const { loadingStatus: usersLoadingStatus } = useSelector(
    (state: RootState) => {
      return state.usersSlice;
    }
  );

  const { loadingStatus: customersLoadingStatus } = useSelector(
    (state: RootState) => {
      return state.salesAccountSlice;
    }
  );

  const { loadingStatus: bulkShipmentProfilesLoadingStatus } = useSelector(
    (state: RootState) => {
      return state.bulkShipmentProfilesSlice;
    }
  );

  const { loadingBaseStatus: settingsLoadingStatus } = useSelector(
    (state: RootState) => {
      return state.settingsSlice;
    }
  );

  const { loadingStatus: virtualWarehousesLoadingStatus } = useSelector(
    (state: RootState) => {
      return state.virtualWarehousesSlice;
    }
  );

  const { loadingStatus: changelogsLoadingStatus } = useSelector(
    (state: RootState) => {
      return state.changelogsSlice;
    }
  );

  const isAdmin = isUserAdmin(user);
  const isAdminForSalesAccounts = isUserAdminForSalesAccounts(user);
  const isUserSalesPeople = isUserSalesPeopleForSalesAccounts(user);

  const isUserRoleDefined =
    (user && user["https://connexx.com/roles"].length > 0) || false;

  useEffect(() => {
    if (user && getAccessTokenSilently) {
      getAccessTokenSilently({
        audience: process.env.NEXT_PUBLIC_AUDIENCE,
      }).then((resp) => {
        window["accessToken"] = resp;
        dispatch(loginThunk(user, resp));
      });
    }
  }, [user, getAccessTokenSilently]);

  useEffect(() => {
    if (!isAuthenticated && !isLoadingUser) {
      loginWithRedirect();
    }
  }, [isLoadingUser, user]);

  useEffect(() => {
    // Fetch customer data
    if (isAuthenticated && accessToken && !isAdmin) {
      dispatch(fetchBulkShipmentProfilesThunk());
      dispatch(fetchSettingsThunk());
      dispatch(fetchCompanyAddressesThunk());
      dispatch(fetchCompanyContactsThunk());
      dispatch(fetchShippingWhitelistsThunk());
      dispatch(fetchCurrentLabelJobsThunk());
      dispatch(fetchIntegrationsThunk());
      dispatch(fetchVirtualWarehousesThunk());
    }
    // Fetch admin only data
    if (isAuthenticated && accessToken && isAdmin) {
      dispatch(fetchSalesAccountsThunk());
      dispatch(fetchUsersThunk());
    }
    // Fetch both
    if (
      isAuthenticated &&
      accessToken &&
      (isAdminForSalesAccounts || isUserSalesPeople)
    ) {
      dispatch(fetchBulkShipmentProfilesThunk());
      dispatch(fetchSettingsThunk());
      dispatch(fetchCompanyAddressesThunk());
      dispatch(fetchCompanyContactsThunk());
      dispatch(fetchShippingWhitelistsThunk());
      dispatch(fetchCurrentLabelJobsThunk());
      dispatch(fetchIntegrationsThunk());
      dispatch(fetchVirtualWarehousesThunk());
      dispatch(fetchSalesAccountsThunk());
      dispatch(fetchUsersThunk());
    }
  }, [isAuthenticated, accessToken, salesAccountID]);

  const loadingStatus = getAppLoadingState({
    isAdmin,
    virtualWarehousesLoadingStatus,
    usersLoadingStatus,
    settingsLoadingStatus,
    customersLoadingStatus,
    bulkShipmentProfilesLoadingStatus,
  });

  return (
    <ErrorBoundary
      FallbackComponent={({ resetErrorBoundary }) => {
        return (
          <div className="flex items-center w-screen h-screen">
            <Center>
              <Error onClick={resetErrorBoundary} />
            </Center>
          </div>
        );
      }}
    >
      {isLoadingUser ? (
        <Loading />
      ) : isUserRoleDefined ? (
        <>
          <div>{isOnStop ? <TopBanner /> : null}</div>
          {loadingStatus === ApiLoadingStatus.FAILED ? (
            <div className="flex items-center w-screen h-screen">
              <Center>
                <Error
                  buttonLabel="Refresh"
                  onClick={() => {
                    location.reload();
                  }}
                />
              </Center>
            </div>
          ) : loadingStatus === ApiLoadingStatus.LOADING ? (
            <Loading />
          ) : (
            <PusherData>{children}</PusherData>
          )}
        </>
      ) : (
        <Loading />
      )}
    </ErrorBoundary>
  );
};
