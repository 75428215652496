import { Select } from "components/atoms/Select";
import { ISelectOption } from "components/atoms/Select/UseSelect";
import { ICurrency } from "models/currency/currency.model";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { FormControl, IFormControl } from "../FormControl";
import getSymbolFromCurrency from "currency-symbol-map";

export const CurrencySelect = ({
  onChange,
  value,
  label,
}: {
  onChange(value: ICurrency["code"]): void;
  value: ICurrency["code"];
} & IFormControl) => {
  const { currenciesSelectOptions } = useSelector((state: RootState) => {
    return state.currenciesSlices;
  });

  const currency = currenciesSelectOptions.find((currency) => {
    return currency.value === value;
  });

  return (
    <FormControl label={label}>
      <Select<ISelectOption<ICurrency, string>>
        value={currency}
        onChange={(option) => onChange(option.value)}
        placeholder="Select"
        optionTemplate={({ value }) => {
          return (
            <>
              {value} - {getSymbolFromCurrency(value)}
            </>
          );
        }}
        options={currenciesSelectOptions}
      />
    </FormControl>
  );
};
