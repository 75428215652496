import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  CreateAddress,
  CreateShipmentAddressType,
} from "store/createShipmentSlice/createShipmentSlice.types";
import {
  onChangeReceiverAddressField,
  onChangeReceiverCountry,
  clearReceiverAddress,
  setReceiverAddressType,
  onTouchReceiverAddressField,
  setRecipientAddressZip,
  setRecipientSaveToAddressBook,
  setSavedReceiverAddressEditMode,
} from "store/createShipmentSlice/index.slice";
import { openModal } from "store/modalSlice";
import { AddressDirection, AddressSelector } from ".";

export const CreateShipmentRecipient = () => {
  const dispatch = useDispatch();

  const { values, errors, touched, submitCount } = useSelector(
    (state: RootState) => {
      return state.createShipmentSlice;
    }
  );

  return (
    <AddressSelector
      editModeSavedAddress={values.receiver.editModeSavedAddress}
      onEditSavedAddress={() => {
        dispatch(setSavedReceiverAddressEditMode());
      }}
      direction={AddressDirection.RECIPIENT}
      saveToAddressBook={values.receiver.saveToAddressBook}
      id={values.receiver.id}
      errors={errors.receiver}
      showErrors={submitCount > 0}
      touched={touched.receiver}
      firstName={values.receiver.firstName}
      vatNumber={values.receiver.vatNumber}
      eoriNumber={values.receiver.eoriNumber}
      lastName={values.receiver.lastName}
      addressLine1={values.receiver.addressLine1}
      addressLine2={values.receiver.addressLine2}
      addressLine3={values.receiver.addressLine3}
      onChangeSaveAddress={(value) => {
        dispatch(setRecipientSaveToAddressBook(value));
      }}
      onChangeType={(type) => {
        dispatch(setReceiverAddressType(type));

        if (
          !values.receiver.id &&
          type === CreateShipmentAddressType.ADDRESS_BOOK
        ) {
          dispatch(openModal({ name: "selectReceiverAddress" }));
        }
      }}
      onChangeZip={(value) => {
        dispatch(setRecipientAddressZip(value));
      }}
      type={values.receiver.type}
      city={values.receiver.city}
      zip={values.receiver.zip}
      countyState={values.receiver.countyState}
      countryIsoCode={values.receiver.countryIsoCode}
      onChange={(key, value) =>
        dispatch(
          onChangeReceiverAddressField<Partial<CreateAddress>>({
            [key]: value,
          })
        )
      }
      onTouch={(key) => dispatch(onTouchReceiverAddressField(key))}
      onRemoveAddress={() => {
        dispatch(clearReceiverAddress());
      }}
      onToggleSelectAddress={() => {
        dispatch(
          setReceiverAddressType(CreateShipmentAddressType.ADDRESS_BOOK)
        );
        dispatch(openModal({ name: "selectReceiverAddress" }));
      }}
      onChangeCountry={(value) => {
        dispatch(onChangeReceiverCountry(value));
      }}
      company={values.receiver.company}
      email={values.receiver.email}
      phone={values.receiver.phone}
    />
  );
};
