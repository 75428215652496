import { Select } from "components/atoms/Select";
import { ISelectOption } from "components/atoms/Select/UseSelect";

export const ClassSelect = ({
  value: valueProp,
  isDisabled,
  onChange,
}: {
  value: string;
  isDisabled?: boolean;
  onChange(value: ISelectOption<object>);
}) => {
  const typeOptions: ISelectOption<object>[] = [
    { label: "1.1", value: 0 },
    { label: "1.2", value: 1 },
    { label: "1.3", value: 2 },
    { label: "1.4", value: 3 },
    { label: "1.5", value: 4 },
    { label: "1.6", value: 5 },
    { label: "2.1", value: 6 },
    { label: "2.2", value: 7 },
    { label: "2.3", value: 8 },
    { label: "3", value: 9 },
    { label: "4.1", value: 10 },
    { label: "4.2", value: 11 },
    { label: "4.3", value: 12 },
    { label: "5.1", value: 13 },
    { label: "5.2", value: 14 },
    { label: "6.1", value: 15 },
    { label: "6.2", value: 16 },
    { label: "7", value: 17 },
    { label: "8", value: 18 },
    { label: "9", value: 19 },
  ];

  const value = valueProp
    ? typeOptions.find((type) => type.label === valueProp)
    : null;

  return (
    <Select<ISelectOption<object>>
      value={value}
      options={typeOptions}
      isDisabled={isDisabled}
      isSearchable={false}
      onChange={onChange}
    />
  );
};
