import { Center } from "components/atoms/Center";
import { ReactNode } from "react";

type ITableCellLeftSlotProps = {
  children: ReactNode;
};

export const TableCellLeftSlot = (props: ITableCellLeftSlotProps) => {
  const { children } = props;

  return (
    <div className="flex-shrink-0 w-5 h-5 mr-2">
      <Center>{children}</Center>
    </div>
  );
};
