import { ShipmentContentsFieldNames } from "./shipment-field-names.enum";

export enum BulkUploadColumnName {
  "warehouseName" = "warehouseName",
  "customerReference" = "customerReference",
  "reasonForExport" = "reasonForExport",
  iossNumber = "iossNumber",
  invoiceDate = "invoiceDate",
  invoiceNumber = "invoiceNumber",
  orderNumber = "orderNumber",
  "fromAddressFirstName" = "fromAddressFirstName",
  "fromAddressLastName" = "fromAddressLastName",
  "fromAddressCompany" = "fromAddressCompany",
  "fromAddressPhone" = "fromAddressPhone",
  "fromAddressEmail" = "fromAddressEmail",
  "fromAddressStreet1" = "fromAddressStreet1",
  "fromAddressStreet2" = "fromAddressStreet2",
  "fromAddressStreet3" = "fromAddressStreet3",
  "fromAddressCity" = "fromAddressCity",
  "fromAddressCountyState" = "fromAddressCountyState",
  "fromAddressZip" = "fromAddressZip",
  "fromAddressCountryIso" = "fromAddressCountryIso",
  "fromAddressEoriNumber" = "fromAddressEoriNumber",
  "fromAddressVatNumber" = "fromAddressVatNumber",
  "toAddressFirstName" = "toAddressFirstName",
  "toAddressLastName" = "toAddressLastName",
  "toAddressCompany" = "toAddressCompany",
  "toAddressPhone" = "toAddressPhone",
  "toAddressEmail" = "toAddressEmail",
  "toAddressStreet1" = "toAddressStreet1",
  "toAddressStreet2" = "toAddressStreet2",
  "toAddressStreet3" = "toAddressStreet3",
  "toAddressCity" = "toAddressCity",
  "toAddressCountyState" = "toAddressCountyState",
  "toAddressZip" = "toAddressZip",
  "toAddressCountryIso" = "toAddressCountryIso",
  "toAddressEoriNumber" = "toAddressEoriNumber",
  "toAddressVatNumber" = "toAddressVatNumber",
  "customerReference2" = "customerReference2",
  "dangerousGoods" = "dangerousGoods",
}

export enum BulkUploadPackageFieldNames {
  "packageType" = "packageType",
  "packageWidth" = "packageWidth",
  "packageHeight" = "packageHeight",
  "packageLength" = "packageLength",
  "packageWeight" = "packageWeight",
}

export enum PackagesExtra {
  NumberOfPackages = "numberOfPackages",
}

export enum BulkUploadItemFieldNames {
  "itemDescription" = "itemDescription",
  "itemOriginCountryIso" = "itemOriginCountryIso",
  "itemQuantity" = "itemQuantity",
  "itemValue" = "itemValue",
  "itemValueCurrency" = "itemValueCurrency",
  "itemSku" = "itemSku",
  "itemHsCode" = "itemHsCode",
  "itemWeight" = "itemWeight",
  "manufacturerDetails" = "manufacturerDetails",
  "shippingMethod" = "shippingMethod",
  "custom" = "custom",
  "totalShipmentItemQuantity" = "totalShipmentItemQuantity",
}

export const ShipmentConsignmentItemErrorsMapping: {
  [key in BulkUploadItemFieldNames]: ShipmentContentsFieldNames;
} = {
  [BulkUploadItemFieldNames.itemDescription]:
    ShipmentContentsFieldNames.description,
  [BulkUploadItemFieldNames.itemOriginCountryIso]:
    ShipmentContentsFieldNames.countryOfOrigin,
  [BulkUploadItemFieldNames.itemQuantity]: ShipmentContentsFieldNames.quantity,
  [BulkUploadItemFieldNames.itemValue]: ShipmentContentsFieldNames.itemValue,
  [BulkUploadItemFieldNames.itemValueCurrency]:
    ShipmentContentsFieldNames.currency,
  [BulkUploadItemFieldNames.itemSku]: ShipmentContentsFieldNames.sku,
  [BulkUploadItemFieldNames.itemHsCode]:
    ShipmentContentsFieldNames.commodityCode,
  [BulkUploadItemFieldNames.itemWeight]: ShipmentContentsFieldNames.weight,
  [BulkUploadItemFieldNames.manufacturerDetails]:
    ShipmentContentsFieldNames.manufacturerDetails,
  [BulkUploadItemFieldNames.shippingMethod]:
    ShipmentContentsFieldNames.shippingMethod,
  [BulkUploadItemFieldNames.custom]: ShipmentContentsFieldNames.custom,
  [BulkUploadItemFieldNames.totalShipmentItemQuantity]:
    ShipmentContentsFieldNames.totalShipmentItemQuantity,
};
