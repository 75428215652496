import { ReactNode } from "react";
import { AnimatePresence } from "framer-motion";
import { usePopoverContext } from "../PopoverContext";
import styled from "styled-components";

const StyledPopoverContent = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.dropdownBackgroundColor};
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.gray[7]};
  border-radius: ${(props) => props.theme.borderRadius};
`;

export const PopoverContent = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { isOpen, setPopoverElement, popperPosition, maxW } =
    usePopoverContext();

  return (
    <AnimatePresence>
      {isOpen && (
        <StyledPopoverContent
          ref={setPopoverElement}
          style={{
            ...popperPosition.popper,
            maxWidth: maxW,
          }}
        >
          {children}
        </StyledPopoverContent>
      )}
    </AnimatePresence>
  );
};
