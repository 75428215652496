import { DrawerHeader } from "components/molecules/Drawer/DrawerHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { DrawerTitle } from "components/molecules/Drawer/DrawerTitle";
import { fetchCurrentLabelJobsThunk } from "store/currentLabelJobsSlice/index.slice";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { Loader } from "components/atoms/Loader";
import { useEffect } from "react";
import { closeModal } from "store/modalSlice";
import { paths } from "enum/paths.enum";
import { Tag24 } from "@carbon/icons-react";
import { LabelJob } from "./LabelsDrawerCard";
import { useRouter } from "next/router";
import { Center } from "components/atoms/Center";
import { Error } from "components/atoms/Error";
import { Stack } from "components/atoms/Stack";
import { Heading } from "components/atoms/Heading";

export const LabelsDrawer = () => {
  const { items, loadingStatus } = useSelector((state: RootState) => {
    return state.currentLabelJobsSlice;
  });

  const dispatch = useDispatch();

  const router = useRouter();

  useEffect(() => {
    dispatch(fetchCurrentLabelJobsThunk());
  }, [dispatch]);

  const handleRedirectToLabelsPage = () => {
    dispatch(closeModal({ modalName: "labels" }));
    router.push(paths.LABELS_INDEX);
  };
  return (
    <>
      <DrawerHeader>
        <DrawerTitle>Labels</DrawerTitle>
      </DrawerHeader>

      <div className="flex flex-col flex-1 overflow-hidden">
        {loadingStatus === ApiLoadingStatus.LOADING ? (
          <Center>
            <Loader />
          </Center>
        ) : loadingStatus === ApiLoadingStatus.FAILED ? (
          <Error />
        ) : (
          <>
            {items.length > 0 ? (
              <>
                <div className="px-6 py-3 text-sm leading-none text-center bg-gray6">
                  <div>
                    Labels created in last 24 hours.{" "}
                    <div
                      onClick={handleRedirectToLabelsPage}
                      className="inline-block underline cursor-pointer"
                    >
                      See all labels
                    </div>
                  </div>
                </div>
                <div className="flex flex-col flex-1 p-5 overflow-scroll">
                  <Stack spacing={3}>
                    {items.map((item, key) => {
                      return <LabelJob item={item} key={key} />;
                    })}
                  </Stack>
                </div>
              </>
            ) : (
              <div className="flex-1 p-12 overflow-scroll text-center">
                <div className="inline-block w-16 h-16 mb-6 rounded-full bg-gray5">
                  <Center>
                    <Tag24 />
                  </Center>
                </div>
                <Heading size="lg" className="mb-3">
                  No labels created in last 24 hours
                </Heading>
                <div className="text-gray10">
                  See all historical labels{" "}
                  <div
                    className="inline-block underline cursor-pointer"
                    onClick={handleRedirectToLabelsPage}
                  >
                    here
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
