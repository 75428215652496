export enum ApiPath {
  "None" = 0,
  "Evri" = 1,
  "Yodel" = 2,
  "UPS (Normal)" = 3,
  "UPS (DSV)" = 4,
  "Fedex (Normal)" = 5,
  "Fedex (DSV)" = 6,
  "DPD" = 7,
  "Amazon" = 8,
  "Royal Mail" = 9,
  "Royal Mail (Popal)" = 11,
  "Fedex Domestic USA" = 10,
  "Starlinks" = 12,
}
