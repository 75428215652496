import { Divider } from "components/atoms/Divider";
import { Stack } from "components/atoms/Stack";
import { NumberedSection } from "components/organisms/NumberedSection";
import { NumberedSectionBody } from "components/organisms/NumberedSection/NumberedSectionBody";
import { NumberedSectionHeader } from "components/organisms/NumberedSection/NumberedSectionHeader";
import { NumberedSectionTestIds } from "components/organisms/NumberedSection/NumberedSectionTestIds.enum";
import { cloneElement } from "react";
import { CreateFlowSteps, getVisibleSteps } from "./CreateShipmentForm.config";

export const CreateShipmentFormSteps = ({
  steps,
}: {
  steps: CreateFlowSteps;
}) => {
  return (
    <Stack spacing={0} divider={<Divider />}>
      {Object.keys(getVisibleSteps(steps)).map((key, index) => {
        return (
          <NumberedSection
            key={key}
            testId={`${NumberedSectionTestIds.NUMBERED_SECTON}_${key}`}
          >
            <NumberedSectionHeader
              number={(getVisibleSteps(steps)[key].stepOrder || index) + 1}
              isQuote={steps?.CREATE_QUOTE_PACKAGES ? true : false}
            >
              {getVisibleSteps(steps)[key].title}
            </NumberedSectionHeader>
            <NumberedSectionBody>
              {cloneElement(getVisibleSteps(steps)[key].component)}
            </NumberedSectionBody>
          </NumberedSection>
        );
      })}
    </Stack>
  );
};
