export enum DomesticCarriersDatabaseIDs {
  EVRI = "1",
  YODEL = "2",
  UPS = "16",
  FEDEX = "24",
  AMAZON = "39",
}

export enum DomesticCarriersDatabaseIDsNew {
  HERMES = 1,
  EVRI = 1,
  YODEL = 2,
}

export enum IntlCarriersDatabaseIDs {
  //express
  DHL = 26,
  "DHL PARCEL" = 35,
  Fedex = 24,
  TNT = 25,
  UPS = 16, // note that this is for financial company 1
  "XIAMEN HUIXEN EXPRESS" = 37,
  XDP = 38,
  FedexUSA = 47,
  //intl
  "B2C Europe" = 36,
  DPD = 27,
  "Evri EU" = 1,
  Fastway = 32,
  Landmark = 2,
  P2P = 15,
  "Spring Global" = 23,
  AMAZON = 39,
  RoyalMail = 41, //live and staging
  STARLINKS = 74,
}
