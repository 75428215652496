import { IQuotesSlice } from "./QuotesSlice.interface";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { SortDirection } from "interfaces/api.interface";
import { DEFAULT_META } from "constants/misc";

export const QuotesSliceInitialState: IQuotesSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  currentQuote: null,
  currentQuoteLoadingStatus: ApiLoadingStatus.IDLE,
  items: [],
  query: {
    offset: 0,
    filters: {
      salesAccountId: null,
    },
    sort: {
      createdDate: SortDirection.DESC,
    },
  },
  meta: { ...DEFAULT_META },
  quoteDownloadSalesAccount: null,
};
