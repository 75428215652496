import { Model } from "models/types/model.type";
import { ModalAddressColumns } from "./address.columns";

export interface IAddress {
  id: string;
  uuid: string;
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  email: string;
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  city: string;
  countyState: string | null;
  zip: string;
  countryIsoCode: string;
  createdBy: string;
  createdDate: string;
  code?: string;
  branch?: string;
  eoriNumber?: string;
  mobile?: string;
  residentialAddress?: boolean;
  editingAddress?: boolean;
  vatNumber?: string;
  isSenderDefault?: boolean;
}

export enum AddressTypes {
  "SENDER" = "SENDER",
  "RECEIVER" = "RECEIVER",
}

export const AddressModel: Model<IAddress> = {
  id: "id",
  displayKey: "id",
  name: "Quote",
  searchItem: () => {},
  columns: ModalAddressColumns,
  schema: {},
};
