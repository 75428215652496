export enum CreateBulkShipmentSliceActions {
  "SET_DATA" = "setData",
  "SET_VALIDATING_STATUS" = "setValidatingStatus",
  "SET_VALIDATED_DATA" = "setValidatedData",
  "SET_IS_READY" = "setIsReady",
  "SET_CREATED_SHIPMENTS" = "setCreatedShipments",
  "SET_PRINT_LATER" = "setPrintLater",
  "SET_PRINT_LABEL_SIZE" = "setPrintLabelSize",
  "SET_API_ERROR" = "setApiError",
  "SET_FILE_CONTAINS_HEADER_ROW" = "setFileContainsHeaderRow",
  "SET_FILE_NAME" = "setFileName",
  "SET_SUBMIT_STATUS" = "setSubmitStatus",
  "SET_SUBMIT_COUNT" = "setSubmitCount",
  "SET_PROFILE_UUID" = "setProfileUUID",
}
