import { apiPaths, customerApiBase } from "enum/paths.enum";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { IShipment } from "models/shipment/shipment.model";
import { post } from "services/post";
import { CommercialInvoiceBase64ResponseDto } from "./dtos/commercialInvoicePdf.dto";

export const commercialInvoiceBase64 = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: {
    shipmentId: IShipment["id"];
  }
): Promise<CommercialInvoiceBase64ResponseDto> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.commercialInvoiceBase64,
    values
  );
};
