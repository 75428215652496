import { useEffect } from 'react';

export function useOnClickOutside(refs: any, handler: any) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      let isCurrent = false;

      refs.map((r) => {
        if (!r.current || r.current.contains(event.target)) {
          isCurrent = true;
        }
      });

      if (!isCurrent) {
        handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, handler]);
}
