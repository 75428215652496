import { apiPaths, customerApiBase } from "enum/paths.enum";
import { ICompanyContact } from "models/companyContact/companyContact.model";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import {
  fetcher,
  IFetchPostResponse,
  IFetchResponseSingle,
} from "services/api";
import { post } from "services/post";
import { FetchCompanyContactsResponseDto } from "./dtos/fetch-company-contacts.response.dto";

export const fetchCompanyContacts = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<IFetchResponseSingle<FetchCompanyContactsResponseDto>> => {
  return await fetcher({
    url: customerApiBase + "/" + salesAccountUUID + apiPaths.companyContacts,
    method: "GET",
  });
};

export const setDefaultCompanyContact = async (
  salesAccountUUID: ISalesAccount["uuid"],
  companyContactId: ICompanyContact["id"]
): Promise<IFetchPostResponse<FetchCompanyContactsResponseDto>> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.companyContacts +
      "/set-default",
    {
      contactId: companyContactId,
    }
  );
};
