import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isModalClosed } from "store/modalSlice/modalSlice.actions";
import { modals } from "store/modalSlice/modalSlice.types";
import { ICreateSingleLabelSlice } from "./createSingleLabelSlice.interface";
import { CreateSingleLabelSliceInitialState } from "./createSingleLabelSliceInititialState.const";

export const createSingleLabelSlice = createSlice({
  name: "createSingleLabelSlice",
  initialState: CreateSingleLabelSliceInitialState,
  reducers: {
    setLoadingStatus: (
      state,
      action: PayloadAction<{
        status: ICreateSingleLabelSlice["loadingStatus"];
        labelJob: ICreateSingleLabelSlice["labelJob"];
      }>
    ) => {
      state.loadingStatus = action.payload.status;
      if (action.payload.labelJob) state.labelJob = action.payload.labelJob;
    },
    setLoadingCommercialInvoiceStatus: (
      state,
      action: PayloadAction<{
        status: ICreateSingleLabelSlice["loadingCommercialInvoiceStatus"];
      }>
    ) => {
      state.loadingCommercialInvoiceStatus = action.payload.status;
    },
    setLoadingCN22Status: (
      state,
      action: PayloadAction<{
        status: ICreateSingleLabelSlice["loadingCN22Status"];
      }>
    ) => {
      state.loadingCN22Status = action.payload.status;
    },
    setLoadingPackingSlipStatus: (
      state,
      action: PayloadAction<{
        status: ICreateSingleLabelSlice["loadingPackingSlipStatus"];
      }>
    ) => {
      state.loadingPackingSlipStatus = action.payload.status;
    },
    setCreateLabelInBrowserStatus: (
      state,
      action: PayloadAction<{
        status: ICreateSingleLabelSlice["createLabelInBrowserStatus"];
      }>
    ) => {
      state.createLabelInBrowserStatus = action.payload.status;
    },
    setLabelJob: (
      state,
      action: PayloadAction<ICreateSingleLabelSlice["labelJob"]>
    ) => {
      state.labelJob = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isModalClosed,
      (state, action: { payload: { modalName: modals } }) => {
        if (
          action.payload.modalName === "createSingleLabel" ||
          action.payload.modalName === "viewShipment"
        ) {
          Object.assign(state, CreateSingleLabelSliceInitialState);
        }
      }
    );
  },
});

export const {
  setLabelJob,
  setLoadingStatus,
  setLoadingCommercialInvoiceStatus,
  setLoadingCN22Status,
  setLoadingPackingSlipStatus,
  setCreateLabelInBrowserStatus,
} = createSingleLabelSlice.actions;
