export enum ViewShipmentModalTestIds {
  "VIEW_SHIPMENT_PRINT_LABEL_BUTTON" = "VIEW_SHIPMENT_PRINT_LABEL_BUTTON",
  "VIEW_SHIPMENT_PRINT_BASE64_LABEL_BUTTON" = "VIEW_SHIPMENT_PRINT_BASE64_LABEL_BUTTON",
  "VIEW_SHIPMENT_PRINT_BASE64_COMMERCIAL_INVOICE_BUTTON" = "VIEW_SHIPMENT_PRINT_BASE64_COMMERCIAL_INVOICE_BUTTON",
  "VIEW_SHIPMENT_CANCEL_SHIPMENT_BUTTON" = "VIEW_SHIPMENT_CANCEL_SHIPMENT_BUTTON",
  "VIEW_SHIPMENT_EDIT_SHIPMENT_BUTTON" = "VIEW_SHIPMENT_EDIT_SHIPMENT_BUTTON",
  "VIEW_SHIPMENT_ERRORS_PANEL" = "VIEW_SHIPMENT_ERRORS_PANEL",
  "VIEW_SHIPMENT_LOADING_PANEL" = "VIEW_SHIPMENT_LOADING_PANEL",
  "VIEW_SHIPMENT_LOADING_ERROR_PANEL" = "VIEW_SHIPMENT_LOADING_ERROR_PANEL",
  "VIEW_SHIPMENT_CUTOFF_TIME_MESSAGE" = "VIEW_SHIPMENT_CUTOFF_TIME_MESSAGE",
}
