import { TableCellLeftSlot } from "../TableCellLeftSlot";

export const TableLogoCell = ({ label, logoUrl }) => {
  return (
    <div className="flex items-center">
      <TableCellLeftSlot>
        <div
          className="w-5 h-5 bg-white bg-center bg-cover border rounded-md shadow-sm border-gray6"
          style={{
            backgroundPosition: "center",
            backgroundSize: "85%",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${logoUrl})`,
          }}
        />
      </TableCellLeftSlot>

      {label}
    </div>
  );
};
