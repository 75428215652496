import { DomesticCarriersDatabaseIDs } from "enum/carrier-mappings.enum";
import { CarrierTypes } from "enum/carrier-types.enum";
import { ICarrier } from "models/carrier/carrier.model";
import { ISupplier } from "models/supplier/supplier.model";

export const printSupplierName = (
  supplier: ISupplier,
  carrierId: ICarrier["id"],
  type: CarrierTypes
) => {
  return type === CarrierTypes.INTERNATIONAL ||
    carrierId === DomesticCarriersDatabaseIDs.EVRI
    ? supplier.alias
    : supplier.name;
};
