import { RootState } from "store";
import { TimePicker, ConfigProvider, theme } from "antd";
import { useSelector } from "react-redux";
import { Dayjs } from "dayjs";

interface Props {
  onChange: (values: [Dayjs, Dayjs], formatString: [string, string]) => void;
  value?: [Dayjs, Dayjs];
  disabled?: boolean;
}

export const TimePickerRangeComponent = ({
  onChange,
  value,
  disabled,
}: Props) => {
  const { theme: connexxTheme } = useSelector((state: RootState) => {
    return state.tableSlice;
  });

  const format = "HH:mm";
  return (
    <ConfigProvider
      theme={{
        algorithm:
          connexxTheme === "dark"
            ? theme.darkAlgorithm
            : theme.defaultAlgorithm,
      }}
    >
      <TimePicker.RangePicker
        value={value}
        format={format}
        changeOnBlur
        popupClassName={`noFooterTimePick`}
        onChange={onChange}
        disabled={disabled}
      />
    </ConfigProvider>
  );
};
