import { InternalLink } from "components/atoms/InternalLink";
import { paths } from "enum/paths.enum";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { isUserAdmin } from "utils/isUserAdmin";
import { PermissionCheck } from "utils/PermissionCheck/PermissionCheck";
import { NavigationItem } from "../NavigationItem";
import { NavigationLink } from "../NavigationLink";
import { NavigationLinks } from "../NavigationLinks";
import { CustomerSelect } from "components/molecules/CustomerSelect";

export const NavigationPrimaryLinks = () => {
  const { pathname } = useRouter();

  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const permissions = new PermissionCheck({ user: loggedInUser });

  const { shippingWhitelists } = useSelector((state: RootState) => {
    return state.shippingWhitelistsSlice;
  });

  const containsUpsOrFedex = [];
  shippingWhitelists.forEach((element) => {
    if (
      (element.carrierId === "16" && element.supplier.apiPath !== 4) ||
      (element.carrierId === "24" && element.supplier.apiPath !== 6)
    ) {
      containsUpsOrFedex.push(element);
    }
  });

  return (
    <div className="hidden h-full md:flex">
      <NavigationLinks>
        {permissions.shipments.canView() && (
          <InternalLink href={paths.SHIPMENTS_INDEX + "?status=READY"}>
            <NavigationItem>
              <NavigationLink isActive={pathname === paths.SHIPMENTS_INDEX}>
                Shipments
              </NavigationLink>
            </NavigationItem>
          </InternalLink>
        )}
        <InternalLink
          href={
            isUserAdmin(loggedInUser)
              ? paths.DASHBOARD_INDEX
              : paths.QUOTES_INDEX
          }
        >
          <NavigationItem>
            <NavigationLink
              isActive={
                isUserAdmin(loggedInUser)
                  ? pathname === paths.DASHBOARD_INDEX
                  : pathname === paths.QUOTES_INDEX
              }
            >
              Quotes
            </NavigationLink>
          </NavigationItem>
        </InternalLink>
        {permissions.bulkShipments.canView() && (
          <InternalLink href={paths.BULK_SHIPMENTS_INDEX}>
            <NavigationItem>
              <NavigationLink
                isActive={pathname === paths.BULK_SHIPMENTS_INDEX}
              >
                Uploads
              </NavigationLink>
            </NavigationItem>
          </InternalLink>
        )}
        {permissions.pickup.canView() && containsUpsOrFedex.length && (
          <InternalLink href={paths.PICKUPS_INDEX}>
            <NavigationItem>
              <NavigationLink isActive={pathname === paths.PICKUPS_INDEX}>
                Pickups
              </NavigationLink>
            </NavigationItem>
          </InternalLink>
        )}
        {permissions.salesAccounts.canChange() && (
          <NavigationItem>
            <div style={{ width: 300 }}>
              <CustomerSelect />
            </div>
          </NavigationItem>
        )}
      </NavigationLinks>
    </div>
  );
};
