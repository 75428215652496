import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GENERIC_TOAST_SAVED_MESSAGE } from "constants/strings";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { IShipmentProfile } from "models/shipmentProfile/shipmentProfile.model";
import { toast } from "react-hot-toast";
import { updateBulkShipmentProfile } from "services/api/shipmentProfiles";
import { AppThunk, RootState } from "store";
import { fetchBulkShipmentProfilesThunk } from "store/bulkShipmentProfilesSlice/index.slice";
import { closeModal } from "store/modalSlice";
import { isModalClosed } from "store/modalSlice/modalSlice.actions";
import { modals } from "store/modalSlice/modalSlice.types";
import { ICreateOrUpdateBulkShipmentProfileSlice } from "./createOrUpdateBulkShipmentProfileSlice.interface";
import {
  CreateOrUpdateBulkShipmentProfileSliceInitialState,
  generateDefaultMappings,
} from "./createOrUpdateBulkShipmentProfileSliceInitialState.const";

export const createOrUpdateBulkShipmentProfileSlice = createSlice({
  name: "createOrUpdateBulkShipmentProfileSlice",
  initialState: CreateOrUpdateBulkShipmentProfileSliceInitialState,
  reducers: {
    setSubmittingStatus: (
      state,
      action: PayloadAction<
        ICreateOrUpdateBulkShipmentProfileSlice["submittingStatus"]
      >
    ) => {
      state.submittingStatus = action.payload;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<
        ICreateOrUpdateBulkShipmentProfileSlice["loadingStatus"]
      >
    ) => {
      state.loadingStatus = action.payload;
    },
    setFileContainsHeaderRow: (
      state,
      action: PayloadAction<
        ICreateOrUpdateBulkShipmentProfileSlice["fileContainsHeaderRow"]
      >
    ) => {
      state.fileContainsHeaderRow = action.payload;
    },
    setEditingProfileUUID: (
      state,
      action: PayloadAction<
        ICreateOrUpdateBulkShipmentProfileSlice["editingProfileUUID"]
      >
    ) => {
      state.editingProfileUUID = action.payload;
    },
    setEditingProfile: (
      state,
      action: PayloadAction<
        ICreateOrUpdateBulkShipmentProfileSlice["editingProfile"]
      >
    ) => {
      state.editingProfile = action.payload;
    },
    setCreateProfileMappings: (
      state,
      action: PayloadAction<ICreateOrUpdateBulkShipmentProfileSlice["mappings"]>
    ) => {
      state.mappings = action.payload;
    },
    setCreateProfileData: (
      state,
      action: PayloadAction<ICreateOrUpdateBulkShipmentProfileSlice["data"]>
    ) => {
      state.data = action.payload;
    },
    setCreateProfileTitle: (
      state,
      action: PayloadAction<ICreateOrUpdateBulkShipmentProfileSlice["title"]>
    ) => {
      state.title = action.payload;
    },
    setProfileToDelete: (state, action: PayloadAction<IShipmentProfile>) => {
      state.profileToDelete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isModalClosed,
      (state, action: { payload: { modalName: modals } }) => {
        if (action.payload.modalName === "confirmDeleteShipmentProfile") {
          state.profileToDelete = null;
        }

        if (
          action.payload.modalName === "createBulkShipmentProfile" ||
          action.payload.modalName === "editBulkShipmentProfile"
        ) {
          Object.assign(
            state,
            CreateOrUpdateBulkShipmentProfileSliceInitialState
          );
        }
      }
    );
  },
});

export const {
  setSubmittingStatus,
  setFileContainsHeaderRow,
  setEditingProfile,
  setEditingProfileUUID,
  setCreateProfileData,
  setCreateProfileTitle,
  setCreateProfileMappings,
  setLoadingStatus,
  setProfileToDelete,
} = createOrUpdateBulkShipmentProfileSlice.actions;

export const resetMappedDataThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setCreateProfileMappings(generateDefaultMappings()));
  };
};

export const updateBulkShipmentProfileThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
      createOrUpdateBilkShipmentProfileSlice: {
        editingProfileUUID,
        title,
        mappings,
      },
    } = getState() as RootState;

    if (salesAccountUUID && editingProfileUUID) {
      dispatch(setSubmittingStatus(ApiLoadingStatus.LOADING));

      const { error, message } = await updateBulkShipmentProfile(
        salesAccountUUID,
        editingProfileUUID,
        {
          profileName: title,
          mappings,
        }
      );

      if (!error) {
        toast.success(GENERIC_TOAST_SAVED_MESSAGE);
        dispatch(closeModal({ modalName: "editBulkShipmentProfile" }));
        dispatch(fetchBulkShipmentProfilesThunk());
      }

      if (error) toast.error(message || "");

      dispatch(setSubmittingStatus(ApiLoadingStatus.IDLE));
    }
  };
};
