import { AppThunk } from "store";
import { downloadQuotesReport } from "services/api/quotes";

export const downloadQuoteReportThunk = (salesAccountId: number): AppThunk => {
  return async (dispatch, getState) => {
    const response = await downloadQuotesReport({ salesAccountId });
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
    const downloadLink = document.createElement("a");
    const fileName = `Quote Report ${new Date()
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-")}.xlsx`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
};
