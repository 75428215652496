import { Table } from "components/atoms/Table";
import { Models } from "models/types/models.type";
import { ApiLoadingStatus, Meta } from "interfaces/api.interface";
import { TableProps } from "components/atoms/Table/types";
import { ErrorBoundary } from "components/atoms/ErrorBoundary";
import { Error } from "components/atoms/Error";
import { Column } from "react-table";

type IData<T extends object> = {
  model?: Models;
  loadingStatus: ApiLoadingStatus;
  meta?: Meta;
  columns: Column<T>[]; // Allow columns to be overriden if not the default set specified on the model
  data: T[];
  onNextPage?(offset: number): void;
  onPrevPage?(offset: number): void;
  tableRowQuantityChange?(): void;
  onItemClick?(item: T): void;
  allowExport?: boolean;
  defaultColumnSize?: {
    minWidth: number;
    width: number;
  };
  userColumns?: Object;
  hideSearch?: boolean;
  hideDownload?: boolean;
  hiddenColumns?: string[];
  manualPagination?: boolean;
} & Pick<
  TableProps<T>,
  | "filters"
  | "onSearchSubmit"
  | "onRefresh"
  | "handleColumnSort"
  | "sort"
  | "downloadOptions"
  | "TableButtons"
  | "FilterComponent"
>;

export const Data = <T extends object>({
  onItemClick,
  onSearchSubmit,
  onRefresh,
  data = [],
  columns,
  filters,
  meta = {} as Meta,
  loadingStatus,
  sort,
  handleColumnSort,
  onNextPage,
  onPrevPage,
  tableRowQuantityChange,
  allowExport,
  downloadOptions,
  TableButtons,
  FilterComponent,
  defaultColumnSize, // this doesn't work yet
  userColumns,
  hiddenColumns,
  hideSearch,
  hideDownload,
  manualPagination,
}: IData<T>) => {
  const { limit, recordCount, offset, pageCount } = meta;

  const handleRowClick = (row: T) => {
    onItemClick && onItemClick(row);
  };

  const handleNextPage = () => {
    onNextPage && onNextPage(offset + limit);
  };

  const handlePrevPage = () => {
    onPrevPage && onPrevPage(offset - limit);
  };

  return (
    <ErrorBoundary
      fallbackRender={() => {
        return <Error />;
      }}
    >
      <Table<T>
        onNextPage={handleNextPage}
        onPreviousPage={handlePrevPage}
        data={data}
        onSearchSubmit={onSearchSubmit}
        onRefresh={onRefresh}
        columns={columns}
        filters={filters}
        manualPagination={manualPagination}
        isLoading={loadingStatus === "LOADING"}
        recordCount={recordCount}
        pageCount={pageCount}
        pageIndex={offset / limit}
        pageSize={limit}
        {...(sort && { sort })}
        {...(handleColumnSort && { handleColumnSort })}
        {...(onItemClick && { onRowClick: handleRowClick })}
        allowExport={allowExport}
        downloadOptions={downloadOptions}
        TableButtons={TableButtons}
        FilterComponent={FilterComponent}
        tableRowQuantityChange={tableRowQuantityChange}
        userColumns={userColumns}
        hiddenColumns={hiddenColumns}
        hideSearch={hideSearch}
        hideDownload={hideDownload}
      />
    </ErrorBoundary>
  );
};
