import "jspdf-autotable";
import JsPDF from "jspdf";
import { format } from "date-fns";
import Papa from "papaparse";
import * as XLSX from "xlsx";

//for exporting data https://codesandbox.io/s/github/gargroh/react-table-plugins/tree/master/examples/export-data?file=/src/App.js:183-290

export function getExportFileBlob({
  columns,
  data,
  fileType,
  fileName,
}: {
  columns: any;
  data: any[][];
  fileType: "csv" | "xlsx" | "pdf" | "rawCSV";
  fileName: string;
}) {
  const headerType = columns.map((col) => col.type);
  const formattedData = data.map((row) => {
    return row.map((value, i) => {
      if (headerType[i] === "amount") {
        return value / 100;
      } else if (headerType[i] === "exchangeRate") {
        return value / 1000000;
      } else {
        return value;
      }
    });
  });

  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);

    // to do, change raw csv to use all data sent from server and unformatted

    const csvString = Papa.unparse({
      fields: headerNames,
      data: formattedData,
    });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        let value = row[index];
        if (headerType[index] === "amount") {
          value = row[index] / 100;
        } else if (headerType[index] === "exchangeRate") {
          value = value / 1000000;
        }
        obj[col] = value;
      });
      return obj;
    });

    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new JsPDF();
    //@ts-ignore
    doc.autoTable({
      head: [headerNames],
      body: formattedData,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

export function getExportFileName(exportFileName) {
  const fn = ({ fileType, all }) => {
    return `${exportFileName} ${format(new Date(), "dd-mm-yyyy")}`;
  };

  return fn;
}
