import { statuses } from "enum/shipment-statuses.enum";
import { IShipment } from "models/shipment/shipment.model";
import { isLabelGeneratingForShipment } from "utils/isLabelGeneratingForShipment";

export const isShipmentPrintable = (shipment: IShipment): boolean => {
  return (
    shipment.status !== statuses.error &&
    !isLabelGeneratingForShipment(shipment)
  );
};
