import { LabelJobStatus } from "enum/label-job-status.enum";
import { ILabelJob } from "models/labelJob/labelJob.model";
import { getPackagesWithErrors } from "utils/getPackagesWithErrors";

type ILabelJobErrorMessageProps = {
  item: ILabelJob;
  handleRetry(): void;
  handleViewErrors(): void;
};

export const LabelJobCardErrorMessage = (props: ILabelJobErrorMessageProps) => {
  const { item, handleRetry, handleViewErrors } = props;

  const errorPackages = getPackagesWithErrors(item);

  return (
    <>
      {(item.status === LabelJobStatus.ERROR || errorPackages.length) > 0 && (
        <div className="px-6 py-2 text-sm text-center">
          {item.status === LabelJobStatus.ERROR ? (
            <div>
              Error creating labels. Please{" "}
              <span onClick={handleRetry} className="underline cursor-pointer">
                try again
              </span>
              .
            </div>
          ) : (
            <div>
              Labels for {errorPackages.length} shipments couldn&apos;t be
              generated.
              <span
                onClick={handleViewErrors}
                className="underline cursor-pointer"
              >
                See errors
              </span>
              .
            </div>
          )}
        </div>
      )}
    </>
  );
};
