import { HTMLProps } from "react";
import * as SwitchPrimitive from "@radix-ui/react-switch";
import { useControllableState } from "hooks/useControllable";
import styled from "styled-components";

const StyledSwitch = styled(SwitchPrimitive.Root)`
  all: "unset";
  cursor: pointer;
  box-shadow: 0 0 0 1px rgba(00, 00, 00, 0.05) inset;
  border-radius: 9999px;
  width: 32px;
  height: 18px;
  background-color: ${(props) => props.theme.formControlBackgroundColorDefault};

  &[data-state="checked"] {
    background-color: ${(props) => props.theme.colors.green[10]};
    border-color: ${(props) => props.theme.colors.green[10]};
  }

  
`;

const StyledThumb = styled(SwitchPrimitive.Thumb)`
  display: block;
  background-color: white;
  box-shadow: 0 0 3px 0 rgba(00, 00, 00, 0.3);
  border-radius: 9999px;

  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;

  width: 14px;
  height: 14px;

  &[data-state="checked"] {
    transform: translateX(15px);
  }
`;

type ISwitch = SwitchPrimitive.SwitchProps &
  Pick<
    HTMLProps<HTMLButtonElement>,
    "id" | "onClick" | "children" | "className"
  >;

export const Switch = (props: ISwitch) => {
  const { checked, onCheckedChange, ...rest } = props;

  const [value, setValue] = useControllableState({
    value: checked,
    onChange: onCheckedChange,
  });

  return (
    <StyledSwitch checked={value} onCheckedChange={setValue} {...rest}>
      <StyledThumb />
    </StyledSwitch>
  );
};
