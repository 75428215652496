import { ITheme, IThemeColorAliases, IThemeComponents } from "theme/theme.type";
import { IThemeColors } from "theme/theme-colors.theme";
import { IThemeShadows } from "theme/theme-shadows.theme";
import { ThemeBase } from "theme/base/base.theme";

export const ThemeShadows: IThemeShadows = {
  sm: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
  base: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  md: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
  lg: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
  xl: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  "2xl": "0 25px 50px -12px rgb(0 0 0 / 0.25)",
};

export const ThemeColors: IThemeColors = {
  primary: {
    1: "#0f1720",
    2: "#0f1b2d",
    3: "#10243e",
    4: "#102a4c",
    5: "#0f3058",
    6: "#0d3868",
    7: "#0a4481",
    8: "#0954a5",
    9: "#0091ff",
    10: "#369eff",
    11: "#52a9ff",
    12: "#eaf6ff",
  },
  blue: {
    1: "#0f1720",
    2: "#0f1b2d",
    3: "#10243e",
    4: "#102a4c",
    5: "#0f3058",
    6: "#0d3868",
    7: "#0a4481",
    8: "#0954a5",
    9: "#0091ff",
    10: "#369eff",
    11: "#52a9ff",
    12: "#eaf6ff",
  },
  pink: {
    1: "#161616",
    2: "#1c1c1c",
    3: "#232323",
    4: "#282828",
    5: "#2e2e2e",
    6: "#343434",
    7: "#3e3e3e",
    8: "#505050",
    9: "#707070",
    10: "#7e7e7e",
    11: "#a0a0a0",
    12: "#ededed",
  },
  purple: {
    1: "#161616",
    2: "#1c1c1c",
    3: "#232323",
    4: "#282828",
    5: "#2e2e2e",
    6: "#343434",
    7: "#3e3e3e",
    8: "#505050",
    9: "#707070",
    10: "#7e7e7e",
    11: "#a0a0a0",
    12: "#ededed",
  },
  yellow: {
    1: "#161616",
    2: "#1c1c1c",
    3: "#232323",
    4: "#282828",
    5: "#2e2e2e",
    6: "#343434",
    7: "#3e3e3e",
    8: "#505050",
    9: "#707070",
    10: "#7e7e7e",
    11: "#a0a0a0",
    12: "#ededed",
  },
  orange: {
    1: "#1f1206",
    2: "#2b1400",
    3: "#391a03",
    4: "#441f04",
    5: "#4f2305",
    6: "#5f2a06",
    7: "#763205",
    8: "#943e00",
    9: "#f76808",
    10: "#ff802b",
    11: "#ff8b3e",
    12: "#feeadd",
  },
  red: {
    1: "#1f1315",
    2: "#291415",
    3: "#3c181a",
    4: "#481a1d",
    5: "#541b1f",
    6: "#671e22",
    7: "#822025",
    8: "#aa2429",
    9: "#e5484d",
    10: "#f2555a",
    11: "#ff6369",
    12: "#feecee",
  },
  green: {
    1: "#0d1912",
    2: "#0c1f17",
    3: "#0f291e",
    4: "#113123",
    5: "#133929",
    6: "#164430",
    7: "#1b543a",
    8: "#236e4a",
    9: "#30a46c",
    10: "#3cb179",
    11: "#4cc38a",
    12: "#e5fbeb",
  },
  gray: {
    1: "#161618",
    2: "#1c1c1f",
    3: "#232326",
    4: "#28282c",
    5: "#2e2e32",
    6: "#34343a",
    7: "#3e3e44",
    8: "#504f57",
    9: "#706f78",
    10: "#7e7d86",
    11: "#a09fa6",
    12: "#ededef",
  },
};

export const ThemeColorAliases: IThemeColorAliases = {
  backgroundColor: ThemeColors.gray[1],
  backgroundColorHover: ThemeColors.gray[3],
  backgroundColorActive: ThemeColors.gray[3],

  backgroundColorInverse: ThemeColors.gray[2],
  backgroundColorInverseHover: ThemeColors.gray[3],

  borderColor: ThemeColors.gray[7],
  borderColorStrong: ThemeColors.gray[6],

  textColorPrimary: ThemeColors.gray[12],
  textColorActive: ThemeColors.gray[12],
  textColorMuted: ThemeColors.gray[10],
  textColorHover: ThemeColors.gray[12],
};

export const ThemeComponents: IThemeComponents = {
  buttonDefaultBackgroundColor: ThemeColors.gray[2],
  buttonDefaultBackgroundColorHover: ThemeColors.gray[4],
  buttonDefaultBorderColor: ThemeColors.gray[7],
  buttonDefaultTextColor: ThemeColors.gray[12],
  buttonDefaultBorderColorHover: "white",

  buttonPrimaryBackgroundColor: ThemeColors.blue[10],
  buttonPrimaryBorderColor: ThemeColors.blue[10],

  cardBackgroundColor: ThemeColors.gray[2],
  cardBorderColor: ThemeColorAliases.borderColorStrong,
  cardBorderColorHover: ThemeColors.gray[5],
  cardBackgroundColorHover: ThemeColors.gray[5],

  formControlBorderColorDefault: ThemeColors.gray[7],
  formControlBackgroundColorDefault: ThemeColors.gray[2],

  dropdownBackgroundColor: ThemeColors.gray[1],
  dropdownBorderColor: ThemeColorAliases.borderColorStrong,

  panelBorderColor: ThemeColorAliases.borderColorStrong,

  modalBackgroundColor: ThemeColors.gray[1],

  modalBorderColor: ThemeColors.gray[4],

  navigationBackgroundColor: ThemeColors.gray[1],

  tooltipBackgroundColor: ThemeColors.gray[3],
  tooltipTextColor: ThemeColors.gray[12],
};

export const ThemeDark: ITheme = {
  ...ThemeColorAliases,
  ...ThemeComponents,
  ...ThemeBase,
  colors: {
    ...ThemeColors,
  },
  shadows: {
    ...ThemeShadows,
  },
};

export const ThemeDarkElevation1: ITheme = {
  ...ThemeDark,
  backgroundColor: ThemeColors.gray[2],
  borderColor: ThemeColors.gray[3],
  borderColorStrong: ThemeColors.gray[7],
  backgroundColorInverse: ThemeColors.gray[3],
  buttonDefaultBackgroundColor: ThemeColors.gray[4],
  buttonDefaultBackgroundColorHover: ThemeColors.gray[5],
  buttonDefaultBorderColor: ThemeColors.gray[4],
  buttonDefaultBorderColorHover: ThemeColors.gray[5],
};
