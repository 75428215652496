import { forwardRef, Ref } from "react";
import styled from "styled-components";
import { getButtonHeight } from "../Button";

export type InputVariants = "outline" | "unstyled" | "flushed" | "filled";

export type InputSizes = "sm" | "default" | "lg";

export type InputStates = "error" | "normal" | "valid";

export const StyledInput = styled.input<{
  inputSize: InputSizes;
  hasLeftElement?: boolean;
}>`
  width: 100%;
  appearance: none;
  font-weight: normal;
  outline: none;
  border: 1px solid;
  border-color: ${(props) => props.theme.formControlBorderColorDefault};
  background-color: ${(props) => props.theme.formControlBackgroundColorDefault};
  padding-left: ${(props) =>
    props.hasLeftElement ? props.theme.space[9] : props.theme.space[4]};
  font-size: ${(props) => props.theme.fontSizes.sm};
  border-radius: ${(props) => props.theme.borderRadius};
  height: ${(props) => getButtonHeight(props.inputSize)};
`;

export interface IInput {
  id?: string;
  placeholder?: string;
  type?: string;
  tabIndex?: number;
  name?: string;
  size?: InputSizes;
  autoComplete?: boolean;
  variant?: InputVariants;
  defaultValue?: string;
  value?: string | number;
  autoFocus?: boolean;
  isInline?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  className?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onFocus?: (e: React.FocusEvent) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onKeyUp?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onClick?: (e: React.MouseEvent) => void;
  onBlur?: (e: any) => void;
  testId?: string;
  hasLeftElement?: boolean;
}

export const Input = forwardRef((props: IInput, ref: Ref<HTMLInputElement>) => {
  const {
    placeholder,
    type,
    id,
    name,
    autoComplete = false,
    defaultValue,
    isDisabled,
    onKeyDown,
    onPaste,
    onKeyUp,
    onChange,
    value,
    tabIndex = 0,
    onFocus,
    onBlur,
    size = "default",
    testId,
    autoFocus,
    isRequired,
    hasLeftElement,
  } = props;

  return (
    <StyledInput
      tabIndex={tabIndex}
      id={id}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onPaste={onPaste}
      defaultValue={defaultValue}
      value={value}
      name={name}
      autoComplete={autoComplete === false ? "off" : "on"}
      placeholder={placeholder}
      autoFocus={autoFocus}
      disabled={isDisabled}
      inputSize={size}
      ref={ref}
      hasLeftElement={hasLeftElement}
      {...(isRequired && { required: true })}
      {...(testId && { "data-testid": testId })}
    />
  );
});

Input.displayName = "Input";
