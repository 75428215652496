import styled from "styled-components";
import { getThemeVariable } from "theme/getThemeVariable.theme";

const StyledLink = styled.div<Pick<INavigationLinkProps, "isActive">>`
  font-weight: 500;
  display: flex;
  border-radius: ${(props) => props.theme.borderRadius};
  font-size: ${(props) => props.theme.fontSizes.sm};
  background-color: ${({ isActive }) =>
    isActive ? (props) => props.theme.backgroundColorActive : "transparent"};
  height: ${(props) => props.theme.space[9]};
  padding-bottom: 1px;
  padding-left: ${(props) => props.theme.gutter};
  padding-right: ${(props) => props.theme.gutter};
  align-items: center;
  transition: color, background-color ${getThemeVariable("textTransitionSpeed")};
  color: ${({ isActive }) =>
    isActive ? (props) => props.theme.textColorActive : "currentColor"};

  &:hover {
    color: ${(props) => props.theme.textColorHover};
    background-color: ${(props) => props.theme.backgroundColorHover};
  }
`;

type INavigationLinkProps = {
  isActive: boolean;
  children: string;
};

export const NavigationLink = ({
  children,
  isActive,
}: INavigationLinkProps) => {
  return <StyledLink isActive={isActive}>{children}</StyledLink>;
};
