import { SettingsAdjust16 } from "@carbon/icons-react";
import { Button } from "components/atoms/Button";
import { Stack } from "components/atoms/Stack";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setFilterSidebarOpenStatus } from "store/tableSlice/index.slice";
import { useTheme } from "styled-components";
import { TableColumnToggle } from "../TableColumnToggle";
import { TableSearch } from "../TableSearch";
import { TableProps } from "../types";
import { TableControls } from "../TableControls";
import { IconButton } from "../../IconButton";
import { FaRedo } from "react-icons/fa";

export type ITableToolbarProps<D extends object> = Pick<
  TableProps<D>,
  | "filters"
  | "onSearchSubmit"
  | "onRefresh"
  | "allowExport"
  | "recordCount"
  | "downloadOptions"
  | "TableButtons"
  | "userColumns"
  | "hideSearch"
  | "hideDownload"
> & { useTableData: any; exportFunction: () => void };

export const TableToolbar = <D extends object>(
  props: ITableToolbarProps<D>
) => {
  const {
    filters,
    onSearchSubmit,
    onRefresh,
    allowExport,
    recordCount,
    useTableData,
    downloadOptions,
    exportFunction,
    TableButtons,
    userColumns,
    hideSearch,
    hideDownload,
  } = props;

  const dispatch = useDispatch();

  const { isFilterSidebarOpen } = useSelector((state: RootState) => {
    return state.tableSlice;
  });

  const theme = useTheme();

  return (
    <div
      className="relative flex items-center justify-between border-b h-14"
      style={{
        borderColor: theme.borderColor,
        paddingLeft: theme.gutter,
        paddingRight: theme.gutter,
      }}
    >
      <Stack spacing={4} direction="horizontal">
        {filters && (
          <Button
            onClick={() => {
              dispatch(setFilterSidebarOpenStatus(!isFilterSidebarOpen));
            }}
          >
            <SettingsAdjust16 className="mr-2" />
            Filters
          </Button>
        )}
        <TableColumnToggle
          {...useTableData}
          userColumns={userColumns ? userColumns : null}
        />
        {TableButtons ? <TableButtons /> : null}
      </Stack>

      <div className="flex flex-row ml-auto">
        {onSearchSubmit && !hideSearch && (
          <div className="flex">
            <TableSearch
              records={recordCount || 0}
              handleSubmit={onSearchSubmit}
            />
          </div>
        )}

        {allowExport && !hideDownload && (
          <div className="flex ml-4">
            <TableControls
              exportData={() => {
                exportFunction();
              }}
              downloadOptions={downloadOptions}
            />
          </div>
        )}
        {onRefresh && (
          <div
            className="ml-4"
            onClick={() => {
              onRefresh();
            }}
          >
            <IconButton icon={FaRedo} />
          </div>
        )}
      </div>
    </div>
  );
};
