import { useEffect } from "react";
import { ModalHeader } from "components/atoms/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { RuleForm } from "../RuleForm";
import { CreateRuleAppliesTo } from "services/api/rules/dtos/create-rule.request.dto";
import { closeModal } from "store/modalSlice";
import {
  DEFAULT_CONDITION,
  saveRuleThunk,
  setAppliesTo,
  setCarrierId,
  setConditions,
  setName,
  setShippingWhitelistId,
  setVirtualWarehouseId,
} from "store/createOrEditRuleSlice/index.slice";

export const EditRuleModal = () => {
  const { rules } = useSelector((state: RootState) => {
    return state.rulesSlice;
  });

  const { editingRuleUUID, submittingStatus } = useSelector(
    (state: RootState) => {
      return state.createOrEditRuleSlice;
    }
  );

  const dispatch = useDispatch();

  const editingRule = rules.find((rule) => rule.uuid === editingRuleUUID);

  useEffect(() => {
    if (editingRule) {
      dispatch(setName(editingRule.name));
      dispatch(setCarrierId(editingRule.carrierId));
      dispatch(setVirtualWarehouseId(editingRule.virtualWarehouseId));
      dispatch(
        setConditions(
          editingRule.conditions.length > 0
            ? editingRule.conditions
            : [{ ...DEFAULT_CONDITION }]
        )
      );
      dispatch(setShippingWhitelistId(editingRule.shippingWhitelistId));
      dispatch(
        setAppliesTo(
          editingRule.conditions.length > 0
            ? CreateRuleAppliesTo.CONDITIONAL
            : CreateRuleAppliesTo.ALL
        )
      );
    }
  }, [editingRule, dispatch]);

  return (
    <>
      <ModalHeader>Edit rule</ModalHeader>
      <RuleForm
        handleSubmit={() => dispatch(saveRuleThunk())}
        isSubmitting={submittingStatus === "LOADING"}
        submitButtonLabel="Save changes"
        onCancel={() => {
          dispatch(closeModal({ modalName: "editRule" }));
        }}
      />
    </>
  );
};
