import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { SortDirection } from "interfaces/api.interface";
import { DEFAULT_META } from "constants/misc";
import { Meta, Query } from "interfaces/api.interface";
import { cloneDeep } from "lodash";
import { AppThunk } from "store";
import {
  fetchPickUpAddressesThunk,
  fetchPickupsThunk,
} from "store/pickupSlice/thunks/fetchPickUpShipmentsThunk";
import { isModalClosed } from "store/modalSlice/modalSlice.actions";
import { modals } from "store/modalSlice/modalSlice.types";

export type IPickupSliceFilters = {
  search?: string;
  salesAccountId?: number;
  fromDate?: string;
  toDate?: string;
  fromAddressStreet_1?: string;
  fromAddressCity?: string;
  fromAddressZip?: string;
  fromAddressCountryIso?: string;
};

export type IPickupShipment = {
  address: string;
  containsPickup: boolean;
  createdDate: string;
  prn: string;
  quantity: number;
  recepient: string;
  reference: string;
  selected: boolean;
  service: string;
  shipmentId: string;
  tracking: string;
  weight: string;
};

export type IPickupAddressObj = {
  fromAddress: string;
  fromAddressStreet1: string;
  fromAddressCity: string;
  fromAddressZip: string;
  fromAddressCountryIso: string;
  carrierID: string;
  hide: boolean;
  id: number;
};

export type IPickupData = {
  ups: IPickupAddressObj[];
  fedex: IPickupAddressObj[];
};

export type Sort = { createdDate: string };

export interface IPickupSlice {
  loadingAddressStatus: ApiLoadingStatus;
  loadingShipmentStatus: ApiLoadingStatus;
  loadingPickupsStatus: ApiLoadingStatus;
  loadingShipmentsFromPickupsStatus: ApiLoadingStatus;
  createLoadingStatus: ApiLoadingStatus;
  addressData: IPickupData;
  shipmentData: IPickupShipment[];
  pickupsData: any[];
  shipmentsFromPickupsData: any[];
  addressMeta: Meta;
  shipmentMeta: Meta;
  pickupsMeta: Meta;
  shipmentsFromPickupsMeta: Meta;
  addressQuery: Query<Sort, IPickupSliceFilters>;
  pickupsQuery: Query<Sort, IPickupSliceFilters>;
  shipmentsFromPickupsQuery: Query<Sort, IPickupSliceFilters>;
  addressDateRangeDays: "1d" | "2d" | "7d" | "30d" | "1y" | "None";
  setPickupsDateRangeDays: "1d" | "2d" | "7d" | "30d" | "1y" | "None";
  pickupDate: string;
  pickupFromTime: string;
  pickupToTime: string;
  selectedShipment: any;
  pickupInstructions: string;
  selectedShipmentIds: string[];
}

const fromDate = new Date();

const toDate = new Date();

const addHours = (date, hours) => {
  date.setHours(date.getHours() + hours);

  return date;
};

const initialState: IPickupSlice = {
  loadingAddressStatus: ApiLoadingStatus.IDLE,
  loadingShipmentStatus: ApiLoadingStatus.IDLE,
  loadingPickupsStatus: ApiLoadingStatus.IDLE,
  loadingShipmentsFromPickupsStatus: ApiLoadingStatus.IDLE,
  createLoadingStatus: ApiLoadingStatus.IDLE,
  addressData: null,
  shipmentData: [],
  pickupsData: [],
  shipmentsFromPickupsData: [],
  addressMeta: { ...DEFAULT_META },
  shipmentMeta: { ...DEFAULT_META },
  pickupsMeta: { ...DEFAULT_META },
  shipmentsFromPickupsMeta: { ...DEFAULT_META },
  addressQuery: {
    offset: 0,
    filters: {
      salesAccountId: null,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
    },
    sort: {
      createdDate: SortDirection.DESC,
    },
  },
  pickupsQuery: {
    offset: 0,
    filters: {
      salesAccountId: null,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
    },
    sort: {
      createdDate: SortDirection.DESC,
    },
  },
  shipmentsFromPickupsQuery: {
    offset: 0,
    filters: {
      salesAccountId: null,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
    },
    sort: {
      createdDate: SortDirection.DESC,
    },
  },
  addressDateRangeDays: "1d",
  setPickupsDateRangeDays: "1d",
  pickupDate: new Date().toISOString(),
  pickupFromTime: new Date().toISOString(),
  pickupToTime: addHours(new Date(), 4).toISOString(),
  selectedShipment: null,
  pickupInstructions: "",
  selectedShipmentIds: [],
};

export const pickupSlice = createSlice({
  name: "tableSlice",
  initialState,
  reducers: {
    setAddressData: (
      state,
      action: PayloadAction<{ data: IPickupData; meta: Meta }>
    ) => {
      state.addressData = action.payload.data || initialState.addressData;

      state.addressMeta = action.payload.meta || initialState.addressMeta;
    },

    setShipmentData: (
      state,
      action: PayloadAction<{ data: IPickupShipment[]; meta: Meta }>
    ) => {
      state.shipmentData = action.payload.data || initialState.shipmentData;

      state.shipmentMeta = action.payload.meta || initialState.shipmentMeta;
      state.selectedShipmentIds = action.payload.meta.shipmentIds;
    },

    setPickupsData: (
      state,
      action: PayloadAction<{ data: any; meta: Meta }>
    ) => {
      state.pickupsData = action.payload.data || initialState.pickupsData;
      state.pickupsMeta = action.payload.meta || initialState.pickupsMeta;
    },

    setShipmentsFromPickupsData: (
      state,
      action: PayloadAction<{ data: any; meta: Meta }>
    ) => {
      state.shipmentsFromPickupsData =
        action.payload.data || initialState.shipmentsFromPickupsData;
      state.shipmentsFromPickupsMeta =
        action.payload.meta || initialState.shipmentsFromPickupsMeta;
    },

    setAddressStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingAddressStatus = action.payload;
    },

    setShipmentStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingShipmentStatus = action.payload;
    },

    setPickupsStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingPickupsStatus = action.payload;
    },

    setShipmentsFromPickupsStatus: (
      state,
      action: PayloadAction<ApiLoadingStatus>
    ) => {
      state.loadingShipmentsFromPickupsStatus = action.payload;
    },

    setCreateStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.createLoadingStatus = action.payload;
    },

    setSelectedShipment: (state, action: PayloadAction<any>) => {
      state.selectedShipment = action.payload;
    },

    setAddressQueryOffset: (
      state,
      action: PayloadAction<IPickupSlice["addressQuery"]["offset"]>
    ) => {
      state.addressQuery.offset = action.payload;
    },

    setAddressQueryFromDate: (
      state,
      action: PayloadAction<IPickupSlice["addressQuery"]["filters"]["fromDate"]>
    ) => {
      state.addressQuery.filters.fromDate = action.payload;
    },

    setAddressQueryToDate: (
      state,
      action: PayloadAction<IPickupSlice["addressQuery"]["filters"]["toDate"]>
    ) => {
      state.addressQuery.filters.toDate = action.payload;
    },

    setAddressDateRangeDays: (
      state,
      action: PayloadAction<IPickupSlice["addressDateRangeDays"]>
    ) => {
      state.addressDateRangeDays = action.payload;
    },

    setPickupsQueryOffset: (state, action: PayloadAction<number>) => {
      state.pickupsQuery = {
        ...state.pickupsQuery,
        offset: action.payload,
      };
    },

    setPickupsQuerySort: (
      state,
      action: PayloadAction<IPickupSlice["pickupsQuery"]["sort"]>
    ) => {
      state.pickupsQuery.sort = action.payload;
    },

    setPickupsQueryFromDate: (
      state,
      action: PayloadAction<IPickupSlice["pickupsQuery"]["filters"]["fromDate"]>
    ) => {
      state.pickupsQuery.filters.fromDate = action.payload;
    },

    setPickupsQueryToDate: (
      state,
      action: PayloadAction<IPickupSlice["pickupsQuery"]["filters"]["toDate"]>
    ) => {
      state.pickupsQuery.filters.toDate = action.payload;
    },

    setShipmentsFromPickupsQueryOffset: (
      state,
      action: PayloadAction<IPickupSlice["shipmentsFromPickupsQuery"]["offset"]>
    ) => {
      state.shipmentsFromPickupsQuery.offset = action.payload;
    },

    setPickupsDateRangeDays: (
      state,
      action: PayloadAction<IPickupSlice["setPickupsDateRangeDays"]>
    ) => {
      state.setPickupsDateRangeDays = action.payload;
    },

    setAddressVisibility: (
      state,
      action: PayloadAction<{ carrier: string; address: string; data: any }>
    ) => {
      let newData = cloneDeep(action.payload.data);
      const index = action.payload.data[action.payload.carrier]
        .map((e) => e.fromAddress)
        .indexOf(action.payload.address);

      newData[action.payload.carrier] = newData[action.payload.carrier].map(
        (element, i) => {
          let hide = true;
          if (index === i) {
            hide = !element.hide;
          }

          return { ...element, hide };
        }
      );
      state.addressData = newData;
    },

    setShipmentSelection: (
      state,
      action: PayloadAction<{ shipmentId: string; selected: boolean }>
    ) => {
      const shipmentData = state.shipmentData.map((shipment) => {
        let selected = shipment.selected;
        if (shipment.shipmentId === action.payload.shipmentId) {
          selected = action.payload.selected;
        }
        return { ...shipment, selected };
      });
      state.shipmentData = shipmentData;

      let selectedShipmentIds = [...state.selectedShipmentIds];
      if (action.payload.selected) {
        if (!state.selectedShipmentIds.includes(action.payload.shipmentId)) {
          selectedShipmentIds.push(action.payload.shipmentId);
        }
      } else {
        selectedShipmentIds = selectedShipmentIds.filter(
          (id) => id !== action.payload.shipmentId
        );
      }
      state.selectedShipmentIds = selectedShipmentIds;
    },

    setShipmentSelectionAll: (state, action: PayloadAction<boolean>) => {
      const shipmentData = state.shipmentData.map((shipment) => {
        return { ...shipment, selected: action.payload };
      });
      state.shipmentData = shipmentData;
      if (action.payload) {
        state.selectedShipmentIds = state.shipmentMeta.shipmentIds;
      } else {
        state.selectedShipmentIds = [];
      }
    },

    setHideAll: (state, action: PayloadAction<IPickupData>) => {
      let newData = cloneDeep(action.payload);
      newData.fedex = newData?.fedex.map((element) => {
        let value = { ...element, hide: true };
        return { ...value };
      });
      newData.ups = newData?.ups.map((element) => {
        let value = { ...element, hide: true };
        return { ...value };
      });
      state.addressData = newData;
    },

    setPickupDate: (state, action: PayloadAction<string>) => {
      state.pickupDate = action.payload;
      const newDate = action.payload.split("T")[0];
      const fromTime = state.pickupFromTime.split("T")[1];
      const toTime = state.pickupToTime.split("T")[1];
      state.pickupFromTime = newDate + "T" + fromTime;
      state.pickupToTime = newDate + "T" + toTime;
    },

    setPickupToTime: (state, action: PayloadAction<string>) => {
      state.pickupToTime = action.payload;
    },

    setPickupFromTime: (state, action: PayloadAction<string>) => {
      state.pickupFromTime = action.payload;
    },

    setPickupInstructions: (state, action: PayloadAction<any>) => {
      state.pickupInstructions = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      isModalClosed,
      (state, action: { payload: { modalName: modals } }) => {
        if (action.payload.modalName === "pickUpRequestModal") {
          state.pickupDate = new Date().toISOString();
          state.pickupFromTime = new Date().toISOString();
          state.pickupToTime = addHours(new Date(), 4).toISOString();
          state.pickupInstructions = "";
          state.createLoadingStatus = ApiLoadingStatus.IDLE;
        }
        if (action.payload.modalName === "pickUpModal") {
          state.addressQuery.filters.fromDate = fromDate.toISOString();
          state.addressQuery.filters.toDate = toDate.toISOString();
          state.addressDateRangeDays = "1d";
        }
        if (action.payload.modalName === "viewPickupModal") {
          state.selectedShipment = null;
        }
      }
    );
  },
});

export const {
  setAddressStatus,
  setShipmentStatus,
  setPickupsStatus,
  setShipmentsFromPickupsStatus,
  setCreateStatus,
  setAddressData,
  setShipmentData,
  setPickupsData,
  setShipmentsFromPickupsData,
  setAddressQueryOffset,
  setAddressQueryFromDate,
  setAddressQueryToDate,
  setAddressDateRangeDays,
  setPickupsQueryOffset,
  setPickupsQuerySort,
  setPickupsQueryFromDate,
  setPickupsQueryToDate,
  setPickupsDateRangeDays,
  setShipmentsFromPickupsQueryOffset,
  setAddressVisibility,
  setShipmentSelection,
  setShipmentSelectionAll,
  setHideAll,
  setPickupDate,
  setPickupToTime,
  setPickupFromTime,
  setSelectedShipment,
  setPickupInstructions,
} = pickupSlice.actions;

export const changeCreatePickupDateRangeDaysThunk = (
  dateRangeDay: "1d" | "2d" | "7d" | "30d" | "1y" | "None"
): AppThunk => {
  return async (dispatch) => {
    dispatch(setAddressDateRangeDays(dateRangeDay));
    let fromDate = new Date();
    const toDate = new Date();
    const isLeapYear = (year) => new Date(year, 1, 29).getMonth() === 1;
    if (dateRangeDay === "1d") {
      fromDate = new Date();
    } else if (dateRangeDay === "2d") {
      fromDate.setDate(fromDate.getDate() - 1);
    } else if (dateRangeDay === "7d") {
      fromDate.setDate(fromDate.getDate() - 7);
    } else if (dateRangeDay === "30d") {
      fromDate.setDate(fromDate.getDate() - 30);
    } else if (dateRangeDay === "1y") {
      fromDate.setDate(
        fromDate.getDate() - (isLeapYear(toDate.getFullYear()) ? 366 : 365)
      );
    }
    await dispatch(setAddressQueryFromDate(fromDate.toISOString()));
    await dispatch(setAddressQueryToDate(toDate.toISOString()));
    dispatch(fetchPickUpAddressesThunk());
  };
};

export const changeViewPickupDateRangeDaysThunk = (
  dateRangeDay: "1d" | "2d" | "7d" | "30d" | "1y" | "None"
): AppThunk => {
  return async (dispatch) => {
    dispatch(setPickupsDateRangeDays(dateRangeDay));
    let fromDate = new Date();
    const toDate = new Date();
    const isLeapYear = (year) => new Date(year, 1, 29).getMonth() === 1;
    if (dateRangeDay === "1d") {
      fromDate = new Date();
    } else if (dateRangeDay === "2d") {
      fromDate.setDate(fromDate.getDate() - 1);
    } else if (dateRangeDay === "7d") {
      fromDate.setDate(fromDate.getDate() - 7);
    } else if (dateRangeDay === "30d") {
      fromDate.setDate(fromDate.getDate() - 30);
    } else if (dateRangeDay === "1y") {
      fromDate.setDate(
        fromDate.getDate() - (isLeapYear(toDate.getFullYear()) ? 366 : 365)
      );
    }
    await dispatch(setPickupsQueryFromDate(fromDate.toISOString()));
    await dispatch(setPickupsQueryToDate(toDate.toISOString()));
    dispatch(fetchPickupsThunk());
  };
};
