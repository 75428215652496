import { ReactNode } from "react";
import { useTheme } from "styled-components";

export enum ModalFooterTestIds {
  "MODAL_FOOTER" = "MODAL_FOOTER",
}

export type IModalFooterProps = {
  children: ReactNode;
  testId?: string;
};

export const ModalFooter = (props: IModalFooterProps) => {
  const { children, testId } = props;

  const theme = useTheme();

  return (
    <footer
      {...(testId && { "data-testid": testId })}
      className="flex py-4 border-t"
      style={{
        paddingLeft: theme.gutter,
        paddingRight: theme.gutter,
        borderColor: theme.borderColor,
      }}
    >
      {children}
    </footer>
  );
};
