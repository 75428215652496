import { Row } from "react-table";
import styles from "./Table.module.scss";
import { TableBodyCell } from "./TableBodyCell";
import cx from "classnames";
import styled from "styled-components";

type ITableRowProps<T extends object> = {
  row: Row<T>;
  onRowClick(row: Row["original"]): void;
  erroredColumns?: { column: string; indices: number[] }[];
};

const StyledTableRow = styled.div`
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.borderColor};

  &:hover {
    background-color: ${(props) => props.theme.backgroundColorHover};
  }
`;

export const TableRow = <T extends object>(props: ITableRowProps<T>) => {
  const { row, onRowClick, erroredColumns } = props;

  return (
    <StyledTableRow
      className={cx({
        [styles["TableRow--isClickable"]]: onRowClick,
      })}
      {...row.getRowProps()}
      {...(onRowClick && {
        onClick: () => onRowClick(row.original),
      })}
    >
      {row.cells.map((cell, index) => {
        return (
          <TableBodyCell
            cell={cell}
            key={index}
            erroredColumns={erroredColumns}
          />
        );
      })}
    </StyledTableRow>
  );
};
