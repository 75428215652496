import {
  DEFAULT_MIN_HEIGHT_CM,
  DEFAULT_MIN_LENGTH_CM,
  DEFAULT_MIN_WEIGHT_GRAMS,
  DEFAULT_MIN_WIDTH_CM,
} from "constants/misc";
import { FORM_REQUIRED_FIELD_ERROR } from "constants/strings";
import { GenericErrorCodes } from "enum/error-codes/generic-error-codes.enum";
import { PackageErrorCodes } from "enum/error-codes/package-error-codes.enum";
import * as yup from "yup";

export const CreateShipmentPackageValidationSchema = yup.object({
  height: yup
    .number()
    .min(
      DEFAULT_MIN_HEIGHT_CM,
      PackageErrorCodes.PACKAGE_MINIMUM_HEIGHT_NOT_MET
    )
    .required(GenericErrorCodes.FIELD_REQUIRED),
  width: yup
    .number()
    .min(DEFAULT_MIN_WIDTH_CM, PackageErrorCodes.PACKAGE_MINIMUM_WIDTH_NOT_MET)
    .required(FORM_REQUIRED_FIELD_ERROR),
  quantity: yup.number().min(1).required(GenericErrorCodes.FIELD_REQUIRED),
  length: yup
    .number()
    .min(
      DEFAULT_MIN_LENGTH_CM,
      PackageErrorCodes.PACKAGE_MINIMUM_LENGTH_NOT_MET
    )
    .required(GenericErrorCodes.FIELD_REQUIRED),
  weight: yup
    .number()
    .min(
      DEFAULT_MIN_WEIGHT_GRAMS,
      PackageErrorCodes.PACKAGE_MINIMUM_WEIGHT_NOT_MET
    )
    .required(GenericErrorCodes.FIELD_REQUIRED),
});
