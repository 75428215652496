import { FormControl } from "components/molecules/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { State } from "country-state-city";
import { postcodeValidatorExistsForCountry } from "postcode-validator";
import { Switch } from "components/atoms/Switch";
import { Stack } from "components/atoms/Stack";
import { Input } from "components/atoms/Input";
import { CreateShipmentAddressSelectorTestIds } from "./CreateShipmentAddressSelectorTestIds.enum";
import { isZipRequired } from "utils/zip/isZipRequired.util";
import { useEffect } from "react";
import { CreateShipmentValidator } from "store/createShipmentSlice/validators/CreateShipmentsSlice.validator";
import { AddressDirection, IAddressSelector } from ".";
import { CreateShipmentAddressType } from "store/createShipmentSlice/createShipmentSlice.types";
import { setErrors } from "store/createShipmentSlice/index.slice";
// import { CountrySelectTailwind } from "components/molecules/CountrySelect/tailwindCountrySelect";
import { StateSelectTailwind } from "components/molecules/StateSelect/tailwindStateSelect";
import { CountrySelect } from "components/molecules/CountrySelect";
import { StateSelect } from "components/molecules/StateSelect";

type IAddressSelectorFormProps = Omit<
  IAddressSelector,
  | "id"
  | "onChangeType"
  | "onToggleSelectAddress"
  | "onRemoveAddress"
  | "saveToAddressBook"
  | "type"
  | "onEditSavedAddress"
> & {
  saveAddressToAddressBook: boolean;
};

export const AddressSelectorForm = (props: IAddressSelectorFormProps) => {
  const {
    editModeSavedAddress,
    saveAddressToAddressBook,
    firstName,
    lastName,
    company,
    email,
    phone,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    countyState,
    zip,
    countryIsoCode,
    onChange,
    onTouch,
    onChangeCountry,
    onChangeZip,
    errors,
    touched,
    showErrors,
    direction,
    onChangeSaveAddress,
    eoriNumber,
    vatNumber,
  } = props;

  const states = State.getStatesOfCountry(countryIsoCode);

  const hasZip = postcodeValidatorExistsForCountry(countryIsoCode);

  const { countries } = useSelector((state: RootState) => {
    return state.countriesSlice;
  });

  const { errors: otherErrors } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const { errors: addressErrors } = new CreateShipmentValidator({
      countries,
    }).validateAddress(direction === AddressDirection.SENDER ? "from" : "to", {
      saveToAddressBook: saveAddressToAddressBook,
      type: CreateShipmentAddressType.NEW,
      id: "",
      firstName,
      lastName,
      email,
      phone,
      company,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      zip,
      countyState,
      countryIsoCode,
    });

    if (direction === AddressDirection.SENDER) {
      dispatch(
        setErrors({
          ...otherErrors,
          sender: addressErrors,
        })
      );
    }

    if (direction === AddressDirection.RECIPIENT) {
      dispatch(
        setErrors({
          ...otherErrors,
          receiver: addressErrors,
        })
      );
    }
  }, [
    saveAddressToAddressBook,
    firstName,
    lastName,
    company,
    email,
    phone,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    countyState,
    zip,
    countryIsoCode,
    direction,
    eoriNumber,
    vatNumber,
  ]);

  return (
    <>
      {editModeSavedAddress ? null : (
        <div className="flex items-center mb-3 border rounded-md bg-gray2 px-4 py-2.5 border-gray4">
          <Switch
            checked={saveAddressToAddressBook}
            onCheckedChange={onChangeSaveAddress}
          />
          <div className="ml-3 text-sm font-medium leading-none">
            Save to address book?
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 gap-5">
        <div>
          <Stack spacing={4}>
            <FormControl label="Country" isRequired={true}>
              {/* <CountrySelectTailwind
                onChange={(value) => {
                  onChangeCountry(value);
                }}
                value={countryIsoCode}
              /> */}
              <CountrySelect
                testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_COUNTRY}`}
                onChange={(value) => {
                  onChangeCountry(value);
                }}
                value={countryIsoCode}
              />
            </FormControl>
            <FormControl
              label="Company"
              errorMessage={
                ((touched.company || showErrors) && errors.company) || null
              }
              isRequired={direction === AddressDirection.SENDER ? true : false}
            >
              {({ isRequired }) => {
                return (
                  <Input
                    testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_COMPANY}`}
                    onBlur={() => onTouch("company")}
                    isRequired={isRequired}
                    onChange={(e) => onChange("company", e.target.value)}
                    value={company}
                  />
                );
              }}
            </FormControl>
            <FormControl
              label="Address line 1"
              isRequired={true}
              errorMessage={
                ((touched.addressLine1 || showErrors) && errors.addressLine1) ||
                null
              }
            >
              <Input
                testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_LINE_1}`}
                onBlur={() => onTouch("addressLine1")}
                onChange={(e) => {
                  onChange("addressLine1", e.target.value);
                }}
                value={addressLine1}
              />
            </FormControl>
            <FormControl
              label="Address line 2"
              isRequired={false}
              errorMessage={
                ((touched.addressLine2 || showErrors) && errors.addressLine2) ||
                null
              }
              testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_LINE_2}`}
            >
              <Input
                testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_LINE_2}`}
                onBlur={() => onTouch("addressLine2")}
                onChange={(e) => onChange("addressLine2", e.target.value)}
                value={addressLine2 || ""}
              />
            </FormControl>

            <FormControl
              label="City"
              htmlFor="city"
              isRequired={true}
              errorMessage={
                ((touched.city || showErrors) && errors.city) || null
              }
              testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_CITY}`}
            >
              <Input
                name="city"
                testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_CITY}`}
                onBlur={() => onTouch("city")}
                onChange={(e) => onChange("city", e.target.value)}
                value={city}
              />
            </FormControl>
            <FormControl
              label="County / state"
              errorMessage={
                ((touched.countyState || showErrors) && errors.countyState) ||
                null
              }
            >
              {/* <StateSelectTailwind
                onChange={(value) => {
                  onChange("countyState", value);
                }}
                isDisabled={states.length === 0}
                countryIsoCode={countryIsoCode}
                value={countyState || ""}
              /> */}
              <Input
                testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_COUNTY_STATE}`}
                onBlur={() => onTouch("countyState")}
                onChange={(e) => onChange("countyState", e.target.value)}
                value={countyState}
              />
              {/* <StateSelect
                testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_COUNTY_STATE}`}
                onChange={(value) => {
                  onChange("countyState", value);
                }}
                isDisabled={states.length === 0}
                countryIsoCode={countryIsoCode}
                value={countyState || ""}
              /> */}
            </FormControl>
            {/* Gibraltar Dubai and Virgin Islands officially does not have zipcodes but carriers expect you to specific ones for these locations */}
            {(hasZip ||
              countryIsoCode === "GI" ||
              countryIsoCode === "AE" ||
              countryIsoCode === "IC" ||
              countryIsoCode === "VG") && (
              <div style={{ maxWidth: 150 }}>
                <FormControl
                  label="Postcode / Zip"
                  isRequired={isZipRequired(countryIsoCode)}
                  errorMessage={(touched.zip || showErrors) && errors.zip}
                >
                  <Input
                    testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_ZIP}`}
                    onBlur={() => onTouch("zip")}
                    onChange={(e) => onChangeZip(e.target.value)}
                    value={zip}
                  />
                </FormControl>
              </div>
            )}
          </Stack>
        </div>
        <div>
          <Stack spacing={4}>
            <div className="grid grid-cols-2 gap-4">
              <FormControl
                label="First name"
                errorMessage={
                  ((touched.firstName || showErrors) && errors.firstName) ||
                  null
                }
              >
                <Input
                  testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_FIRSTNAME}`}
                  onBlur={() => onTouch("firstName")}
                  onChange={(e) => onChange("firstName", e.target.value)}
                  value={firstName}
                />
              </FormControl>
              <FormControl
                label="Last name"
                isRequired={
                  direction === AddressDirection.SENDER ? false : true
                }
                errorMessage={
                  ((touched.lastName || showErrors) && errors.lastName) || null
                }
              >
                <Input
                  testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_LASTNAME}`}
                  onBlur={() => onTouch("lastName")}
                  onChange={(e) => onChange("lastName", e.target.value)}
                  value={lastName}
                />
              </FormControl>
            </div>
            <FormControl
              label="Email"
              isRequired={
                direction === AddressDirection.SENDER
                  ? true
                  : countryIsoCode !== "GB"
                  ? true
                  : false
              }
              errorMessage={
                ((touched.email || showErrors) && errors.email) || null
              }
            >
              <Input
                testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_EMAIL}`}
                onBlur={() => onTouch("email")}
                onChange={(e) => onChange("email", e.target.value)}
                value={email}
              />
            </FormControl>
            <FormControl
              label="Contact number"
              isRequired={true}
              errorMessage={
                ((touched.phone || showErrors) && errors.phone) || null
              }
            >
              <Input
                testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_PHONE}`}
                onBlur={() => onTouch("phone")}
                onChange={(e) => onChange("phone", e.target.value)}
                value={phone}
              />
            </FormControl>
            <div className="grid grid-cols-2 gap-4">
              <FormControl label="EORI Number">
                <Input
                  // testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_FIRSTNAME}`}
                  onBlur={() => onTouch("eoriNumber")}
                  onChange={(e) => onChange("eoriNumber", e.target.value)}
                  value={eoriNumber}
                />
              </FormControl>
              <FormControl label="VAT number">
                <Input
                  // testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM_ADDRESS_LASTNAME}`}
                  onBlur={() => onTouch("vatNumber")}
                  onChange={(e) => onChange("vatNumber", e.target.value)}
                  value={vatNumber}
                />
              </FormControl>
            </div>
          </Stack>
        </div>
      </div>
    </>
  );
};
