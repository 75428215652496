import { useSelector } from "react-redux";
import { RootState } from "store";
import { ShipmentErrors } from "../../ShipmentErrors";
import { hasErrors } from "../../ShipmentErrors/ShipmentErrors.utils";
import { Heading } from "components/atoms/Heading";
import { ViewShipmentModalTestIds } from "../ViewShipmentTestIds.enum";
import { Panel } from "components/atoms/Panel";

export const ViewShipmentErrors = () => {
  const { currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  return (
    <>
      {hasErrors(currentShipment) && (
        <div className="p-5 pb-0">
          <Panel testId={ViewShipmentModalTestIds.VIEW_SHIPMENT_ERRORS_PANEL}>
            <div className="px-4 py-3 text-white bg-red9 rounded-t-md">
              <Heading size="sm">Errors</Heading>
            </div>
            <div className="px-4 py-4">
              <ShipmentErrors shipment={currentShipment} />
            </div>
          </Panel>
        </div>
      )}
    </>
  );
};
