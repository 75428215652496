import { fetchAdminQuotes, fetchQuotes } from "services/api/quotes";
import { AppThunk, RootState } from "store";
import { isUserAdmin } from "utils/isUserAdmin";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { setStatus, setData } from "./index.slice";

export const fetchQuotesThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID, loggedInUser },
      quotesSlice: { query },
    } = getState() as RootState;

    if (isUserAdmin(loggedInUser)) {
      try {
        const { data, error, meta } = await fetchAdminQuotes(query);

        if (!error) {
          dispatch(
            setData({
              meta,
              data,
            })
          );
        }
      } catch (e) {
        console.log(e);
      }
    } else if (salesAccountUUID) {
      try {
        const { data, error, meta } = await fetchQuotes(
          salesAccountUUID,
          query
        );

        if (!error) {
          dispatch(
            setData({
              meta,
              data,
            })
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
};
