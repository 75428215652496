import { useEffect } from "react";
import { ModalHeader } from "components/atoms/Modal";
import { CreateShipmentForm } from "../CreateShipmentForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeModal, openModal } from "store/modalSlice";
import toast from "react-hot-toast";
import { ShipmentTypes } from "enum/shipment-types.enum";
import { fetchShipmentsThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import { finaliseShipmentThunk } from "store/createShipmentSlice/thunks/finaliseShipmentThunk.thunk";
import { fetchRatesThunk } from "store/createShipmentSlice/thunks/fetchRatesThunk.thunk";

export const CreateShipmentModal = () => {
  const dispatch = useDispatch();

  const createShipment = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const isCreatingQuote = createShipment.type === ShipmentTypes.QUOTE;

  const isEditingShipment = createShipment.editingShipmentUUID;

  useEffect(() => {
    if (createShipment.submittingStatus === "SUCCESS") {
      if (!createShipment.printLater) {
        dispatch(openModal({ name: "createSingleLabel" }));
      }
      toast.success("Shipment created!");
      dispatch(fetchShipmentsThunk());
      dispatch(closeModal({ modalName: "createShipment" }));
    }

    if (createShipment.submittingStatus === "FAILED") {
      toast.error(createShipment.submittingErrorMessage);
    }
  }, [createShipment.submittingStatus]);

  useEffect(() => {
    if (createShipment.ratesError) {
      toast.error(createShipment.ratesError);
    }
  }, [createShipment.ratesError]);

  const handleFinaliseShipment = async ({
    printLater,
  }: {
    printLater: boolean;
  }) => {
    dispatch(finaliseShipmentThunk({ printLater }));
  };

  const handleFetchRates = () => {
    dispatch(fetchRatesThunk());
  };

  return (
    <>
      <ModalHeader>
        {isCreatingQuote
          ? "Create quote"
          : isEditingShipment
          ? `${
              createShipment.duplicateOrEdit === "EDIT"
                ? "Editing"
                : "Duplicating"
            } shipment #${createShipment.editingShipmentID}`
          : "Create shipment"}
      </ModalHeader>
      <CreateShipmentForm
        handleFinaliseShipment={handleFinaliseShipment}
        handleFetchRates={handleFetchRates}
      />
    </>
  );
};
