import { useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { useSelectContextProvider } from "./SelectContextProvider";
import { SelectDropdownSearch } from "./SelectDropdownSearch";
import { useHotkeys } from "react-hotkeys-hook";
import { mergeRefs } from "utils/react/mergeRefs";
import styled from "styled-components";

const StyledSelectedDropddown = styled.div`
  background-color: ${(props) => props.theme.dropdownBackgroundColor};
  border: 1px solid;
  border-color: ${(props) => props.theme.dropdownBorderColor};
  border-radius: ${(props) => props.theme.borderRadius};
`;

export const SelectDropdown = ({
  children,
  isOpen,
  width,
  maxHeight = 300,
}: {
  isOpen: boolean;
  children: React.ReactNode;
  width?: number;
  maxHeight?: number;
}): JSX.Element => {
  const {
    setPopoverElement,
    popperPosition,
    triggerElement,
    filteredOptions,
    options,
    isFiltered,
    setFocused,
    cursor,
    onChange,
    optionRefs,
    isSearchable,
    testId,
    setCursor,
  } = useSelectContextProvider();

  const scrollableRef = useRef<HTMLDivElement>(null);

  const itemHeight = optionRefs.current[0]?.clientHeight || 30;

  const handleDown = () => {
    if (
      cursor < (isFiltered ? filteredOptions.length - 1 : options.length - 1)
    ) {
      setCursor(cursor + 1);

      scrollableRef.current
        ? (scrollableRef.current.scrollTop =
            scrollableRef.current.scrollTop + itemHeight)
        : null;
    }
  };

  const handleUp = () => {
    if (cursor > 0) {
      setCursor(cursor - 1);

      scrollableRef.current
        ? (scrollableRef.current.scrollTop =
            scrollableRef.current.scrollTop - itemHeight)
        : null;
    }
  };

  useHotkeys(
    "*",
    (e) => {
      if (e.key === "ArrowDown") {
        handleDown();
      }

      if (e.key === "ArrowUp") {
        handleUp();
      }

      if (e.key === "Enter") {
        onChange(isFiltered ? filteredOptions[cursor] : options[cursor]);
      }
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <div
          ref={mergeRefs([setPopoverElement])}
          onBlur={() => setFocused(false)}
          style={{
            ...popperPosition.popper,
            width: width ? width : triggerElement?.scrollWidth,
          }}
          {...(testId && { "data-testid": `${testId}_SelectDropdown` })}
        >
          <StyledSelectedDropddown>
            {isSearchable && <SelectDropdownSearch />}
            <div style={{ maxHeight }} className="py-1" ref={scrollableRef}>
              {children}
            </div>
          </StyledSelectedDropddown>
        </div>
      )}
    </AnimatePresence>
  );
};
