import { DEFAULT_WEIGHT_UNIT } from "constants/misc";
import { WeightUnits, WeightUnitSettings } from "interfaces/weight-units.type";
import {
  convertDatabaseToValue,
  convertGramsToKilos,
} from "utils/convertGramsToKilos";
import { convertKilosToPounds } from "utils/convertPoundsToKilos";

export const PrintWeight = ({
  weightUnit = DEFAULT_WEIGHT_UNIT,
  value,
}: {
  weightUnit: WeightUnits;
  value: number;
}) => {
  return (
    <>
      {weightUnit === WeightUnits.kg
        ? convertGramsToKilos(convertDatabaseToValue(value))
        : convertKilosToPounds(
            convertGramsToKilos(convertDatabaseToValue(value))
          )}{" "}
      {WeightUnitSettings[weightUnit].label}
    </>
  );
};
