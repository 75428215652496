export enum ModalNames {
  "selectReceiverAddress" = "selectReceiverAddress",
  "selectSenderAddress" = "selectSenderAddress",
  "labels" = "labels",
  "confirmRefetchShipmentRates" = "confirmRefetchShipmentRates",
  "confirmCloseCreateShipmentModal" = "confirmCloseCreateShipmentModal",
  "confirmDeleteShipmentProfile" = "confirmDeleteShipmentProfile",
  "confirmCancelShipment" = "confirmCancelShipment",
  "confirmCancelPickup" = "confirmCancelPickup",
  "confirmBulkDeleteShipmentsModal" = "confirmBulkDeleteShipmentsModal",
  "createQuotePalletSwitchWarningModal" = "createQuotePalletSwitchWarningModal",
  "createBulkShipment" = "createBulkShipment",
  "createSingleLabel" = "createSingleLabel",
  "editBulkShipmentProfile" = "editBulkShipmentProfile",
  "createRule" = "createRule",
  "createReceiverAddress" = "createReceiverAddress",
  "createCompanyAddress" = "createCompanyAddress",
  "createBulkShipmentProfile" = "createBulkShipmentProfile",
  "createShipment" = "createShipment",
  "editRule" = "editRule",
  "customerOnStopWarning" = "customerOnStopWarning",
  "viewQuote" = "viewQuote",
  "viewShipment" = "viewShipment",
  "shipmentReport" = "shipmentReport",
  "quoteReport" = "quoteReport",
  "helpContactInfo" = "helpContactInfo",
  "pickUpModal" = "pickUpModal",
  "pickUpRequestModal" = "pickUpRequestModal",
  "viewPickupModal" = "viewPickupModal",
}
