import { IconButton } from "components/atoms/IconButton";
import { PanelTitle } from "components/atoms/Panel/PanelTitle";
import { FaTrash } from "react-icons/fa";
import { CreateShipmentContentsTestIds } from "./CreateShipmentFormContentsTestIds.enum";

type ICreateShipmentFormContentsRowHeader = {
  number: number;
  onRemove(row: number): void;
};

export const CreateShipmentFormContentsRowHeader = (
  props: ICreateShipmentFormContentsRowHeader
) => {
  const { number, onRemove } = props;

  return (
    <div className="flex items-center">
      <div className="mb-4">
        <PanelTitle>Item #{number + 1}</PanelTitle>
      </div>
      {number !== 0 && (
        <IconButton
          icon={FaTrash}
          data-testid={`${CreateShipmentContentsTestIds.SHIPMENT_CONTENTS_REMOVE_ROW}_${number}`}
          onClick={() => onRemove(number)}
          className="ml-auto"
        />
      )}
    </div>
  );
};
