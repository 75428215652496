import { useState } from "react";
import { useDisclosure } from "hooks/useDisclosure";
import { IPopover } from "./Popover";
import { usePopper } from "react-popper";
import { useEffect } from "react";

export type UsePopoverProps = IPopover;

export const usePopover = (props: UsePopoverProps) => {
  const {
    isOpen: isOpenProp,
    placement,
    trigger,
    offset = [0, 0],
    onClose: onCloseProp,
    strategy = "fixed",
    triggerElementRef,
    maxW = 400,
  } = props;

  const { isOpen, onToggle, onOpen, onClose } = useDisclosure({
    isOpen: isOpenProp,
    onClose: onCloseProp,
  });

  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(
    triggerElementRef || null
  );

  useEffect(() => {
    if (triggerElementRef) {
      setTriggerElement(triggerElementRef);
    }
  }, [triggerElementRef]);

  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>(null);

  const { styles: popperPosition } = usePopper(triggerElement, popoverElement, {
    strategy,
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset,
        },
      },
    ],
  });

  const handleOpen = () => {
    onOpen();
  };

  const getPopoverProps = (props = {}) => ({
    ...props,
    isOpen,
    onToggle,
  });

  return {
    ...props,
    getPopoverProps,
    maxW,
    popperPosition,
    trigger,
    popoverElement,
    isOpen,
    setTriggerElement,
    setPopoverElement,
    onClose,
    onOpen: handleOpen,
    onToggle,
  };
};

export type UsePopoverReturn = ReturnType<typeof usePopover>;
