import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchQuotesResponseDto } from "services/api/quotes/dtos/fetch-quotes.response.dto";
import { FetchSingleQuoteResponseDto } from "services/api/quotes/dtos/fetch-single-quote-response.dto";
import { isModalClosed } from "store/modalSlice/modalSlice.actions";
import { modals } from "store/modalSlice/modalSlice.types";
import { QuotesSliceInitialState } from "./QuotesSliceInitialState.const";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { IQuoteSliceFilters, IQuotesSlice } from "./QuotesSlice.interface";

export const quotesSlice = createSlice({
  name: "quotesSlice",
  initialState: QuotesSliceInitialState,
  reducers: {
    setData: (state, action: PayloadAction<FetchQuotesResponseDto>) => {
      state.items = action.payload.data || QuotesSliceInitialState.items;

      state.meta = action.payload.meta || QuotesSliceInitialState.meta;

      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },
    setStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
    setQuerySearch: (
      state,
      action: PayloadAction<IQuoteSliceFilters["search"]>
    ) => {
      if (state.query.filters) state.query.filters.search = action.payload;
    },
    setQuerySalesAccountID: (
      state,
      action: PayloadAction<IQuoteSliceFilters["salesAccountId"]>
    ) => {
      if (state.query.filters)
        state.query.filters.salesAccountId = action.payload;
    },
    setQueryOffset: (state, action: PayloadAction<number>) => {
      state.query = {
        ...state.query,
        offset: action.payload,
      };
    },
    setQuerySort: (
      state,
      action: PayloadAction<IQuotesSlice["query"]["sort"]>
    ) => {
      state.query.sort = action.payload;
    },
    setCurrentQuote: (
      state,
      action: PayloadAction<FetchSingleQuoteResponseDto["data"]>
    ) => {
      state.currentQuote = action.payload || null;

      state.currentQuoteLoadingStatus = ApiLoadingStatus.SUCCESS;
    },
    setCurrentQuoteLoadingStatus: (
      state,
      action: PayloadAction<ApiLoadingStatus>
    ) => {
      state.currentQuoteLoadingStatus = action.payload;
    },
    setQuoteDownloadSalesAccount: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.quoteDownloadSalesAccount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isModalClosed,
      (state, action: { payload: { modalName: modals } }) => {
        if (action.payload.modalName === "viewQuote") {
          state.currentQuote = null;
          state.currentQuoteLoadingStatus = ApiLoadingStatus.IDLE;
        }
        if (action.payload.modalName === "quoteReport") {
          state.quoteDownloadSalesAccount = null;
        }
      }
    );
  },
});

export const {
  setData,
  setStatus,
  setQuerySort,
  setQueryOffset,
  setQuerySearch,
  setQuerySalesAccountID,
  setCurrentQuoteLoadingStatus,
  setCurrentQuote,
  setQuoteDownloadSalesAccount,
} = quotesSlice.actions;
