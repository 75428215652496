import { AppThunk, RootState } from "store";
import { createPickup, cancelPickup } from "services/api/shipments";
import { closeModal } from "store/modalSlice";
import dayjs from "dayjs";
import {
  fetchEligiblePickUpShipmentsThunk,
  fetchPickupsThunk,
} from "store/pickupSlice/thunks/fetchPickUpShipmentsThunk";
import { fetchShipmentsThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import { PickupShipmentRequestDto } from "services/api/shipments/dtos/pickup-shipment.request.dto";
import toast from "react-hot-toast";
import { setCreateStatus } from "store/pickupSlice/index.slice";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";

export const createPickUpRequestThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setCreateStatus(ApiLoadingStatus.LOADING));
    const {
      authSlice: { salesAccountUUID, loggedInUser },
      pickupSlice: {
        pickupDate,
        pickupFromTime,
        pickupToTime,
        pickupInstructions,
        selectedShipmentIds,
      },
      usersSlice: { users },
    } = getState() as RootState;

    const shipmentIDs: number[] = [];
    const cancelingRequiredShipmentIDs: number[] = [];
    selectedShipmentIds.forEach((element) => {
      shipmentIDs.push(parseInt(element));
      cancelingRequiredShipmentIDs.push(parseInt(element));
    });
    const newDate = pickupDate.split("T")[0];
    const fromTime = pickupFromTime.split("T")[1];
    const toTime = pickupToTime.split("T")[1];
    const fromDate = dayjs(new Date(newDate + "T" + fromTime)).format(
      "HH:mm:ss"
    );
    const toDate = dayjs(new Date(newDate + "T" + toTime)).format("HH:mm:ss");
    const puDate = dayjs(new Date(pickupDate)).format("YYYY-MM-DD");
    const UserID = users.find((user) => user.email === loggedInUser.email).id;
    const body: PickupShipmentRequestDto = {
      UserID,
      ShipmentIDs: shipmentIDs,
      PickupDate: puDate,
      PickupFrom: fromDate,
      PickupTo: toDate,
      SpecialInstructions: pickupInstructions,
    };
    const cancelBody: PickupShipmentRequestDto = {
      UserID,
      ShipmentIDs: cancelingRequiredShipmentIDs,
    };

    if (cancelingRequiredShipmentIDs.length) {
      await cancelPickup(salesAccountUUID, cancelBody);
    }
    const { data }: any = await createPickup(salesAccountUUID, body);
    if (!data.error) {
      await dispatch(setCreateStatus(ApiLoadingStatus.SUCCESS));
      dispatch(fetchEligiblePickUpShipmentsThunk());
      dispatch(fetchPickupsThunk());
      dispatch(fetchShipmentsThunk());
      dispatch(closeModal({ modalName: "pickUpRequestModal" }));
      toast.success("Pickup created");
    } else {
      dispatch(closeModal({ modalName: "pickUpRequestModal" }));
      toast.error(data.message.message);
    }
  };
};
