import {
  Attribute,
  AttributeEmpty,
  AttributeTextCell,
} from "components/atoms/Attribute";
import { Panel } from "components/atoms/Panel";
import { PrintCurrency } from "components/atoms/PrintCurrency";
import { PrintWeight } from "components/atoms/PrintWeight";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Stack } from "components/atoms/Stack";
import { PrintCountry } from "components/atoms/PrintCountry";
import { IShipmentPackage } from "models/shipmentPackage/shipmentPackage.model";
import { PanelBody } from "components/atoms/Panel/PanelBody";
import { PanelHeader } from "components/atoms/Panel/PanelHeader";
import { PanelTitle } from "components/atoms/Panel/PanelTitle";
import { Divider } from "components/atoms/Divider";

export const ViewShipmentModalItemContents = ({
  packageId,
}: {
  packageId: IShipmentPackage["index"];
}) => {
  const { currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });

  const { countries } = useSelector((state: RootState) => {
    return state.countriesSlice;
  });

  const packageItemDescriptions = currentShipment?.itemDescriptions.filter(
    (item) => item.shipmentPackageIndex === packageId
  );

  return (
    <Panel>
      <Stack divider={<Divider />}>
        {packageItemDescriptions.map((itemDescription, i) => {
          return (
            <div key={itemDescription.shipmentPackageIndex}>
              <PanelHeader>
                <PanelTitle>Item {i + 1}</PanelTitle>
              </PanelHeader>
              <PanelBody>
                <Stack spacing={3}>
                  <Attribute label="Commodity code">
                    {itemDescription.commodityCode ? (
                      <AttributeTextCell>
                        {itemDescription.commodityCode}
                      </AttributeTextCell>
                    ) : (
                      <AttributeEmpty />
                    )}
                  </Attribute>
                  <Attribute label="Item Weight">
                    {itemDescription.weight ? (
                      <AttributeTextCell>
                        <PrintWeight
                          value={itemDescription.weight}
                          weightUnit={settings?.weightUnit}
                        />
                      </AttributeTextCell>
                    ) : (
                      <AttributeEmpty />
                    )}
                  </Attribute>
                  <Attribute label="Value">
                    <AttributeTextCell>
                      <PrintCurrency
                        value={(itemDescription.itemValue / 100).toFixed(2)}
                        currencyCode={itemDescription.currency}
                      />
                    </AttributeTextCell>
                  </Attribute>
                  <Attribute label="Description">
                    {itemDescription.description ? (
                      <AttributeTextCell>
                        {itemDescription.description}
                      </AttributeTextCell>
                    ) : (
                      <AttributeEmpty />
                    )}
                  </Attribute>
                  <Attribute label="Quantity">
                    {itemDescription.quantity ? (
                      <AttributeTextCell>
                        {itemDescription.quantity}
                      </AttributeTextCell>
                    ) : (
                      <AttributeEmpty />
                    )}
                  </Attribute>
                  <Attribute label="SKU">
                    {itemDescription.sku ? (
                      <AttributeTextCell>
                        {itemDescription.sku}
                      </AttributeTextCell>
                    ) : (
                      <AttributeEmpty />
                    )}
                  </Attribute>
                  <Attribute label="Manufacturer details">
                    {itemDescription.manufacturerDetails ? (
                      <AttributeTextCell>
                        {itemDescription.manufacturerDetails}
                      </AttributeTextCell>
                    ) : (
                      <AttributeEmpty />
                    )}
                  </Attribute>
                  <Attribute label="Country of origin">
                    {itemDescription.countryOfOrigin ? (
                      <AttributeTextCell>
                        <PrintCountry
                          countryCode={itemDescription.countryOfOrigin}
                          countryName={
                            countries.find(
                              (country) =>
                                country?.isoCode?.toUpperCase() ===
                                itemDescription.countryOfOrigin?.toUpperCase()
                            )?.name || null
                          }
                        />
                      </AttributeTextCell>
                    ) : (
                      <AttributeEmpty />
                    )}
                  </Attribute>
                  <Attribute label="Shipping method">
                    {itemDescription.shippingMethod ? (
                      <AttributeTextCell>
                        {itemDescription.shippingMethod}
                      </AttributeTextCell>
                    ) : (
                      <AttributeEmpty />
                    )}
                  </Attribute>
                </Stack>
              </PanelBody>
            </div>
          );
        })}
      </Stack>
    </Panel>
  );
};
