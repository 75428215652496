export const TableLinkCell = ({
  value,
  onClick,
  title,
}: {
  value: string;
  title?: string;
  onClick(e: React.MouseEvent): void;
}) => {
  return (
    <div
      title={title ? title : value}
      onClick={onClick}
      className="overflow-hidden underline whitespace-nowrap text-ellipsis"
    >
      {value}
    </div>
  );
};
