import { IQuotedRate } from "models/quote/quote.model";
import { IShipmentPackageGrouped } from "models/shipmentPackage/shipmentPackage.model";
import { CalculateRateValues } from "utils/calculateRateTotals";
import {
  calculateTotalChargeableWeight,
  calculateTotalItems,
} from "utils/calculateWeights";
import {
  convertDatabaseToValue,
  convertGramsToKilos,
} from "utils/convertGramsToKilos";
import { formatDate } from "utils/formatDate";
import { CreateShipmentFormTestIds } from "../../common/CreateShipmentFormTestIds.enum";
import { useSelector } from "react-redux";
import { RootState } from "store";

export type ICreateQuoteFormEmailSummary = {
  checkedRates: IQuotedRate[];
  quoteDate: Date;
  packages: IShipmentPackageGrouped[];
  fromCountryIso: string;
  toCountryIso: string;
};

export const CreateQuoteFormEmailSummary = (
  props: ICreateQuoteFormEmailSummary
) => {
  const { quoteDate, fromCountryIso, toCountryIso, packages, checkedRates } =
    props;

  const isInternationalShipment = fromCountryIso !== toCountryIso;

  const containsAmazon = checkedRates.find((rate) => {
    return rate.carrier.name === "Amazon";
  });
  const { financialCompany } = useSelector(
    (state: RootState) => {
      return state.authSlice;
    }
  );
  
  return (
    <div
      style={{ fontSize: "13px" }}
      data-testid={
        CreateShipmentFormTestIds.CREATE_SHIPMENT_FORM_EMAIL_RATES_SECTION
      }
    >
      <table style={{ border: "1px solid black", width: "100%" }}>
        <tbody>
          <tr style={{ height: 36, borderBottom: "1px solid black" }}>
            <td style={{ padding: "0 12px" }}>Date of Quotation:</td>
            <td style={{ padding: "0 12px" }}>
              {formatDate(quoteDate.toISOString())}
            </td>
          </tr>
          <tr style={{ height: 36, borderBottom: "1px solid black" }}>
            <td style={{ padding: "0 12px" }}>Origin Country:</td>
            <td style={{ padding: "0 12px" }}>{fromCountryIso}</td>
          </tr>
          <tr style={{ height: 36, borderBottom: "1px solid black" }}>
            <td style={{ padding: "0 12px" }}>Destination Country:</td>
            <td style={{ padding: "0 12px" }}>{toCountryIso}</td>
          </tr>
          <tr style={{ height: 36, borderBottom: "1px solid black" }}>
            <td style={{ padding: "0 12px" }}>No. of Packages:</td>
            <td style={{ padding: "0 12px" }}>
              {calculateTotalItems(packages)}
            </td>
          </tr>
          <tr style={{ height: 36, borderBottom: "1px solid black" }}>
            <td style={{ padding: "0 12px" }}>Chargeable Weight:</td>
            <td style={{ padding: "0 12px" }}>
              {/* To do: calculate chargeable weight with Amazon as the edge case, need to pass in the carrier name */}
              {convertGramsToKilos(
                calculateTotalChargeableWeight(packages, "notamazon")
              )}
              kg
              {containsAmazon ? (
                <span>
                  ,{" "}
                  {convertGramsToKilos(
                    calculateTotalChargeableWeight(packages, "Amazon")
                  )}
                  kg (Amazon only)
                </span>
              ) : null}
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ height: "10px" }}></div>
      <h2 style={{ fontSize: "14px", fontWeight: "bold" }}>Packages</h2>
      <div style={{ height: "10px" }}></div>
      <table style={{ border: "1px solid black", width: "100%" }}>
        <tbody>
          {packages.map((p, index) => {
            return (
              <tr
                style={{ height: 36, borderBottom: "1px solid black" }}
                key={index}
              >
                <td style={{ padding: "0 12px" }}>Package {index + 1}</td>
                <td style={{ padding: "0 12px" }}>
                  {convertDatabaseToValue(p.width)}cm x{" "}
                  {convertDatabaseToValue(p.height)}cm x{" "}
                  {convertDatabaseToValue(p.length)}cm x{" "}
                  {convertGramsToKilos(convertDatabaseToValue(p.weight))}kg x{" "}
                  {p.quantity}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ height: "10px" }}></div>
      <h2 style={{ fontSize: "14px", fontWeight: "bold" }}>Rates</h2>
      <div style={{ height: "10px" }}></div>
      <table
        style={{
          border: "1px solid black",
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        <thead>
          <tr style={{ borderBottom: "1px solid black", height: "32px" }}>
            <th
              style={{
                padding: "0 12px",
                borderRight: "1px solid black",
                textAlign: "left",
              }}
            >
              Carrier / Service
            </th>
            <th
              style={{
                padding: "0 12px",
                borderRight: "1px solid black",
                textAlign: "left",
              }}
            >
              Freight price
            </th>
            <th
              style={{
                padding: "0 12px",
                borderRight: "1px solid black",
                textAlign: "left",
              }}
            >
              Fuel Surcharge
            </th>
            <th
              style={{
                padding: "0 12px",
                borderRight: "1px solid black",
                textAlign: "left",
              }}
            >
              VAT
            </th>
            <th
              style={{
                padding: "0 12px",
                borderRight: "1px solid black",
                textAlign: "left",
              }}
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {checkedRates.map((rate, i) => {
            const rateValues = CalculateRateValues({
              rate,
              isInternationalShipment,
              financialCompany: financialCompany || 1,
            });

            return (
              <tr
                key={i}
                style={{ height: 45, borderBottom: "1px solid black" }}
              >
                <td
                  style={{
                    padding: "0 12px",
                    borderRight: "1px solid black",
                  }}
                >
                  {rate.carrier.name} - {rate.service.name}
                </td>
                <td
                  style={{
                    padding: "0 12px",
                    borderRight: "1px solid black",
                  }}
                >
                  £{rateValues.netPrice / 100 || 0}
                </td>
                <td
                  style={{
                    padding: "0 12px",
                    borderRight: "1px solid black",
                  }}
                >
                  £{rateValues.fuelSurcharge / 100 || 0}
                </td>
                <td
                  style={{
                    padding: "0 12px",
                    borderRight: "1px solid black",
                  }}
                >
                  £{rateValues.vat / 100 || 0}
                </td>

                <td
                  style={{
                    padding: "0 12px",
                    borderRight: "1px solid black",
                  }}
                >
                  £{rateValues.total / 100 || 0}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ height: "16px" }}></div>
      {/* <div style={{ fontSize: "12px" }}>
        <ul
          style={{
            marginLeft: "12px",
            listStyleType: "initial",
          }}
        >
          <li>
            The above price includes freight & fuel surcharge on the freight
            cost only.
          </li>
          <li>
            These prices are correct at the time of quotation and is valid till
            the end of the week when fuel surcharge is subject to change.
          </li>
          <li>
            The prices in some instances may not include local destination /
            charges.
          </li>
          <li>
            Shipments may incur duty & taxes levied by customs in the
            destination country.The above quotes do not include duty & taxes
            charges.
          </li>

          <li>
            Please refer to your additional surcharges sheet that includes all
            surcharges. If you have not received the latest surcharge sheet
            please reach out to your account representative to request one.
          </li>
          <li>
            Each Carrier reserves the right to audit each parcel. If the Audited
            Chargeable Weight differs from the quoted weight (based on the
            weight & dimensions supplied at time of quote) then final billable
            price will be adjusted according to the Carrier’s Audited weight.
          </li>
        </ul>
      </div> */}
    </div>
  );
};
