import { addDays, isAfter, isBefore, parseISO } from "date-fns";
import { IShipmentPackage } from "models/shipmentPackage/shipmentPackage.model";

export const isLabelCancellable = (
  shipmentPackages: IShipmentPackage[],
  currentDateTime: Date
) => {
  console.log(
    "shipmentPackages[0].labelCreatedDate",
    shipmentPackages[0].labelCreatedDate
  );
  const labelCreatedDate = parseISO(shipmentPackages[0].labelCreatedDate);

  let cutoffDate = new Date(labelCreatedDate);

  cutoffDate.setHours(17);

  cutoffDate.setMinutes(45);

  cutoffDate.setSeconds(0);

  console.log("labelCreatedDate", labelCreatedDate);
  console.log("cutoffDate", cutoffDate);
  console.log(currentDateTime, "currentDateTime");

  if (isAfter(labelCreatedDate, cutoffDate)) {
    cutoffDate = addDays(labelCreatedDate, 1);
  }

  // return isBefore(currentDateTime, cutoffDate);

  // get back to this
  return true;
};
