import { FORM_REQUIRED_FIELD_ERROR } from "constants/strings";
import { IRule, RuleCondition } from "models/rule/rule.model";
import * as Yup from "yup";

export enum CreateRuleAppliesTo {
  "CONDITIONAL" = "CONDITIONAL",
  "ALL" = "ALL",
}

export const CreateRuleValidationSchema = Yup.object({
  name: Yup.string().required(FORM_REQUIRED_FIELD_ERROR),
  shippingWhitelistId: Yup.string().required(FORM_REQUIRED_FIELD_ERROR),
  carrierId: Yup.string().required(FORM_REQUIRED_FIELD_ERROR),
  appliesTo: Yup.mixed().oneOf(Object.values(CreateRuleAppliesTo)),
  conditions: Yup.array().when("type", {
    is: CreateRuleAppliesTo.CONDITIONAL,
    then: Yup.array().of(
      Yup.object().shape({
        condition: Yup.string(),
        operator: Yup.string(),
        value: Yup.string(),
      })
    ),
  }),
});

export type CreateRuleFormValues = {
  name: string;
  conditions: RuleCondition[];
  appliesTo: CreateRuleAppliesTo;
  carrierId: string;
  shippingWhitelistId: IRule["id"];
};

export type CreateRuleRequestDto = Omit<CreateRuleFormValues, "appliesTo" | "carrierId">;
