import { Query } from "interfaces/api.interface";
import { FetchQuotesResponseDto } from "./dtos/fetch-quotes.response.dto";
import { IQuoteJob, IQuotedRate } from "models/quote/quote.model";
import {
  FetchSingleExternalQuoteResponseDto,
  FetchSingleQuoteResponseDto,
} from "./dtos/fetch-single-quote-response.dto";
import { fetchAll, fetchSingle } from "services/get";
import { apiAdminPaths, apiPaths, customerApiBase } from "enum/paths.enum";
import { post } from "services/post";
import { fetcher, IFetchPostResponse } from "services/api";
import { ICreateQuoteRequestDto } from "./dtos/create-quote.request.dto";
import { AdminCreateQuoteRequestDto } from "./dtos/admin-create-quote.request.dto";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { CreateQuoteResponseDto } from "./dtos/create-quote.response.dto";
import { DownloadQuotesRequestDto } from "./dtos/download-quotes.request.dto";

export const fetchQuotes = async (
  salesAccountUUID: ISalesAccount["uuid"],
  query?: Query<IQuoteJob, { search?: string }>
): Promise<FetchQuotesResponseDto> => {
  return await fetchAll(
    customerApiBase + "/" + salesAccountUUID + apiPaths.quotes,
    query
  );
};

export const fetchAdminQuotes = async (
  query?: Query<IQuoteJob, { search?: string }>
): Promise<FetchQuotesResponseDto> => {
  return await fetchAll(apiAdminPaths.quotes, query);
};

export const createQuote = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: ICreateQuoteRequestDto
): Promise<CreateQuoteResponseDto> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.quotes,
    values
  );
};

export const createAdminQuote = async (
  values: AdminCreateQuoteRequestDto
): Promise<IFetchPostResponse<IQuoteJob>> => {
  return await post(apiAdminPaths.quotes, values);
};

export const fetchQuote = async (
  salesAccountUUID: ISalesAccount["uuid"],
  uuid: IQuoteJob["uuid"]
): Promise<FetchSingleQuoteResponseDto> => {
  return await fetchSingle(
    customerApiBase + "/" + salesAccountUUID + apiPaths.quotes + "/" + uuid
  );
};
export const fetchExternalQuote = async (
  salesAccountUUID: ISalesAccount["uuid"],
  id: IQuoteJob["id"]
): Promise<FetchSingleExternalQuoteResponseDto> => {
  return await fetchAll(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.quotes +
      "/external/" +
      id
  );
};
export const fetchAdminQuote = async (
  jobNumber: IQuoteJob["jobNumber"]
): Promise<FetchSingleQuoteResponseDto> => {
  return await fetchSingle(apiAdminPaths.quotes + "/" + jobNumber);
};

export const sendQuoteEmail = async (values: {
  quoteJobUUID: IQuoteJob["uuid"];
  emails: string[];
  selectedRates: IQuotedRate["uuid"][];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<IFetchPostResponse<any>> => {
  return await fetcher({
    method: "POST",
    url: apiAdminPaths.sendQuotesEmail,
    body: values,
  });
};

export const downloadQuotesReport = async (
  values?: DownloadQuotesRequestDto
): Promise<IFetchPostResponse<string>> => {
  return await post(apiAdminPaths.quotes + "/downloadQuotesReport", values);
};
