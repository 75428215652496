import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiLoadingStatus} from "enum/api-loading-status.enum";
import {IChangelog} from "models/changelog/changelog.model";
import {fetchChangelogs} from "services/api/changelogs";
import {AppThunk} from "store";

interface IChangelogsSlice {
  loadingStatus: ApiLoadingStatus;
  changelogs: IChangelog[];
}

export const ChangelogsSliceInitialState: IChangelogsSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  changelogs: [],
};

export const changelogsSlice = createSlice({
  name: "changelogsSlice",
  initialState: ChangelogsSliceInitialState,
  reducers: {
    setData: (state, action: PayloadAction<IChangelog[]>) => {
      state.changelogs = action.payload;
      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },

    setStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
  },
});

export const { setData, setStatus } = changelogsSlice.actions;

export const fetchChangelogsThunk = (): AppThunk => {
  return async (dispatch) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));

      const { data, error } = await fetchChangelogs();

      if (data && !error) {
        dispatch(setData(data));
      }
  };
};
