import { Loader } from "components/atoms/Loader";
import { ModalBody, ModalHeader } from "components/atoms/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { PageSection } from "../PageSection";
import { Rates } from "../Rates";
import { ViewQuoteModealDetails } from "./ViewQuoteModalDetails";
import { useState } from "react";
import { IQuotedRate } from "models/quote/quote.model";
import { isAfter, parseISO, startOfDay } from "date-fns";
import { closeModal, openModal } from "store/modalSlice";
import { PermissionCheck } from "utils/PermissionCheck/PermissionCheck";
import { groupedItems } from "utils/groupItems";
import { fetchQuotesThunk } from "store/quotesSlice/QuotesSliceFetchQuotesThunk.Thunk";
import { Center } from "components/atoms/Center";
import { Error } from "components/atoms/Error";
import { Stack } from "components/atoms/Stack";
import { Button } from "components/atoms/Button";
import { Divider } from "components/atoms/Divider";
import { setFormType } from "store/createShipmentSlice/index.slice";
import { ShipmentTypes } from "enum/shipment-types.enum";
import { CreateQuoteFormEmailSummary } from "../CreateShipmentForm/CreateQuoteForm/CreateQuoteFormEmailSummary";
import { convertQuoteToShipmentThunk } from "store/createShipmentSlice/thunks/convertQuoteToShipmentsThunk.thunk";

export const ViewQuoteModal = () => {
  const { currentQuote, currentQuoteLoadingStatus } = useSelector(
    (state: RootState) => {
      return state.quotesSlice;
    }
  );

  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const [isConverting, setConverting] = useState<boolean>(false);

  const [localRate, setLocalRate] = useState<IQuotedRate | null>(null);

  const [checkedRates, setCheckedRates] = useState<IQuotedRate[]>([]);

  const dispatch = useDispatch();

  const isLoading = currentQuoteLoadingStatus === "LOADING";

  const isError = currentQuoteLoadingStatus === "FAILED";

  const isSuccess = currentQuoteLoadingStatus === "SUCCESS";

  const handleConvertQuoteToShipment = async () => {
    if (localRate) {
      setConverting(true);

      await dispatch(convertQuoteToShipmentThunk(localRate));

      setConverting(false);
    }
  };

  const isQuoteConvertibleToShipment = (date: string): boolean => {
    return (
      !isAfter(startOfDay(new Date()), startOfDay(parseISO(date))) || false
    );
  };

  const permissions = new PermissionCheck({ user: loggedInUser });

  return (
    <>
      {isLoading ? (
        <div className="h-40">
          <Center>
            <Loader />
          </Center>
        </div>
      ) : isError ? (
        <div className="h-40">
          <Center>
            <Error />
          </Center>
        </div>
      ) : isSuccess ? (
        <>
          <ModalHeader>Quote #{currentQuote?.jobNumber}</ModalHeader>
          {!isQuoteConvertibleToShipment(currentQuote?.createdDate) && (
            <div className="px-5 py-3 text-sm text-center text-red11 bg-red3">
              This quote has expired. Please{" "}
              <span
                className="underline cursor-pointer"
                onClick={() => {
                  dispatch(closeModal({ modalName: "viewQuote" }));
                  dispatch(setFormType(ShipmentTypes.QUOTE));
                  dispatch(openModal({ name: "createShipment" }));
                }}
              >
                create a new one
              </span>
              .
            </div>
          )}
          <ModalBody>
            <Stack spacing={6} divider={<Divider />}>
              <ViewQuoteModealDetails />
              <PageSection title="Rates" headingSize="lg">
                <Rates
                  onSelectRate={(rate) => {
                    setLocalRate(rate?.id === localRate?.id ? null : rate);
                  }}
                  isCheckable={permissions.quotes.canEmailQuote()}
                  selectedRate={localRate}
                  allowSelect={
                    isQuoteConvertibleToShipment(currentQuote?.createdDate) &&
                    permissions.quotes.canConvertQuoteToShipment()
                  }
                  onCheckRate={(rate) => {
                    const isAlreadySelected = checkedRates.find(
                      (selectedRate) => selectedRate.id === rate.id
                    );

                    if (isAlreadySelected) {
                      setCheckedRates(
                        checkedRates.filter(
                          (selectedRate) => selectedRate.id !== rate.id
                        )
                      );
                    } else {
                      setCheckedRates([...checkedRates, rate]);
                    }
                  }}
                  handleRefetch={() => dispatch(fetchQuotesThunk())}
                  loadingStatus={currentQuoteLoadingStatus}
                  checkedRates={checkedRates}
                  rates={currentQuote?.rates || []}
                  isInternationalShipment={
                    currentQuote.fromAddressCountryIso !==
                    currentQuote.toAddressCountryIso
                  }
                />
              </PageSection>
              {currentQuote && permissions.quotes.canEmailQuote() && (
                <div className="p-8">
                  <CreateQuoteFormEmailSummary
                    checkedRates={checkedRates}
                    quoteDate={
                      currentQuote?.createdDate
                        ? parseISO(currentQuote?.createdDate)
                        : new Date()
                    }
                    fromCountryIso={currentQuote?.fromAddressCountryIso}
                    toCountryIso={currentQuote?.toAddressCountryIso}
                    packages={groupedItems(currentQuote?.packages)}
                  />
                </div>
              )}
              {isQuoteConvertibleToShipment(currentQuote?.createdDate) &&
                permissions.quotes.canConvertQuoteToShipment() && (
                  <Button
                    size="lg"
                    colorScheme="primary"
                    isDisabled={!localRate}
                    isLoading={isConverting}
                    onClick={handleConvertQuoteToShipment}
                  >
                    Create shipment
                  </Button>
                )}
            </Stack>
          </ModalBody>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
