import cx from "classnames";
import styles from "./Select.module.scss";
import { useSelectContextProvider } from "./SelectContextProvider";

export const SelectMenuItem = ({
  children,
  onClick,
  isFocused,
  index,
  isDisabled,
  style,
}: {
  children?: React.ReactNode;
  onClick?(): void;
  isFocused?: boolean;
  index: number;
  style?: React.CSSProperties;
  isDisabled?: boolean;
}) => {
  const { optionRefs } = useSelectContextProvider();

  return (
    <div
      ref={(element) => {
        optionRefs.current[index] = element;
      }}
      className={cx(
        styles.SelectMenuItem,
        "px-3 py-2 cursor-pointer leading-none text-sm flex items-center hover:bg-gray3",
        {
          "bg-gray4": isFocused,
          "cursor-not-allowed opacity-60": isDisabled,
        }
      )}
      {...(style && { style })}
      {...(onClick && { onClick: () => onClick() })}
    >
      {children}
    </div>
  );
};
