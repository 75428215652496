import { ITheme, IThemeColorAliases, IThemeComponents } from "theme/theme.type";
import { IThemeColors } from "theme/theme-colors.theme";
import { ThemeShadows } from "theme/dark/dark.theme";
import { ThemeBase } from "theme/base/base.theme";

export const ThemeColors: IThemeColors = {
  primary: {
    1: "#0f1720",
    2: "#0f1b2d",
    3: "#10243e",
    4: "#102a4c",
    5: "#0f3058",
    6: "#0d3868",
    7: "#0a4481",
    8: "#0954a5",
    9: "#0091ff",
    10: "#369eff",
    11: "#52a9ff",
    12: "#eaf6ff",
  },
  blue: {
    1: "#0f1720",
    2: "#0f1b2d",
    3: "#10243e",
    4: "#102a4c",
    5: "#0f3058",
    6: "#0d3868",
    7: "#0a4481",
    8: "#0954a5",
    9: "#0091ff",
    10: "#369eff",
    11: "#52a9ff",
    12: "#eaf6ff",
  },
  pink: {
    1: "#161616",
    2: "#1c1c1c",
    3: "#232323",
    4: "#282828",
    5: "#2e2e2e",
    6: "#343434",
    7: "#3e3e3e",
    8: "#505050",
    9: "#707070",
    10: "#7e7e7e",
    11: "#a0a0a0",
    12: "#ededed",
  },
  purple: {
    1: "#161616",
    2: "#1c1c1c",
    3: "#232323",
    4: "#282828",
    5: "#2e2e2e",
    6: "#343434",
    7: "#3e3e3e",
    8: "#505050",
    9: "#707070",
    10: "#7e7e7e",
    11: "#a0a0a0",
    12: "#ededed",
  },
  yellow: {
    1: "#161616",
    2: "#1c1c1c",
    3: "#232323",
    4: "#282828",
    5: "#2e2e2e",
    6: "#343434",
    7: "#3e3e3e",
    8: "#505050",
    9: "#707070",
    10: "#7e7e7e",
    11: "#a0a0a0",
    12: "#ededed",
  },
  orange: {
    1: "#fefcfb",
    2: "#fef8f4",
    3: "#fff1e7",
    4: "#ffe8d7",
    5: "#ffdcc3",
    6: "#ffcca7",
    7: "#ffb381",
    8: "#fa934e",
    9: "#f76808",
    10: "#ed5f00",
    11: "#bd4b00",
    12: "#451e11",
  },
  red: {
    1: "#fffcfc",
    2: "#fff8f8",
    3: "#ffefef",
    4: "#ffe5e5",
    5: "#fdd8d8",
    6: "#f9c6c6",
    7: "#f3aeaf",
    8: "#eb9091",
    9: "#e5484d",
    10: "#dc3d43",
    11: "#cd2b31",
    12: "#381316",
  },
  green: {
    1: "#fbfefc",
    2: "#f2fcf5",
    3: "#e9f9ee",
    4: "#ddf3e4",
    5: "#ccebd7",
    6: "#b4dfc4",
    7: "#92ceac",
    8: "#5bb98c",
    9: "#30a46c",
    10: "#299764",
    11: "#18794e",
    12: "#153226",
  },
  gray: {
    1: "#fbfcfd",
    2: "#f8f9fa",
    3: "#f1f3f5",
    4: "#eceef0",
    5: "#e6e8eb",
    6: "#dfe3e6",
    7: "#d7dbdf",
    8: "#c1c8cd",
    9: "#889096",
    10: "#7e868c",
    11: "#687076",
    12: "#11181c",
  },
};

export const ThemeColorAliases: IThemeColorAliases = {
  backgroundColor: "white",
  backgroundColorHover: ThemeColors.gray[3],
  backgroundColorActive: ThemeColors.gray[3],
  backgroundColorInverse: "",
  backgroundColorInverseHover: "",
  borderColor: ThemeColors.gray[7],
  borderColorStrong: ThemeColors.gray[8],

  textColorPrimary: ThemeColors.gray[12],
  textColorActive: ThemeColors.gray[12],
  textColorMuted: ThemeColors.gray[10],
  textColorHover: ThemeColors.gray[12],
};

export const ThemeComponents: IThemeComponents = {
  buttonDefaultBackgroundColor: ThemeColors.gray[3],
  buttonDefaultBackgroundColorHover: ThemeColors.gray[5],

  buttonDefaultBorderColor: ThemeColors.gray[7],
  buttonDefaultTextColor: ThemeColors.gray[12],
  buttonDefaultBorderColorHover: "black",

  buttonPrimaryBackgroundColor: ThemeColors.blue[10],
  buttonPrimaryBorderColor: ThemeColors.blue[10],

  cardBackgroundColor: ThemeColorAliases.backgroundColor,
  cardBorderColor: ThemeColorAliases.borderColorStrong,
  cardBorderColorHover: ThemeColors.gray[5],
  cardBackgroundColorHover: ThemeColors.gray[2],

  formControlBorderColorDefault: ThemeColors.gray[7],
  formControlBackgroundColorDefault: ThemeColors.gray[2],

  dropdownBackgroundColor: ThemeColorAliases.backgroundColor,
  dropdownBorderColor: ThemeColorAliases.borderColorStrong,

  panelBorderColor: ThemeColorAliases.borderColorStrong,

  modalBackgroundColor: ThemeColorAliases.backgroundColor,
  modalBorderColor: ThemeColors.gray[4],

  navigationBackgroundColor: ThemeColorAliases.backgroundColor,

  tooltipBackgroundColor: ThemeColors.gray[1],
  tooltipTextColor: ThemeColors.gray[12],
};

export const ThemeLight: ITheme = {
  ...ThemeColorAliases,
  ...ThemeComponents,
  ...ThemeBase,
  colors: {
    ...ThemeColors,
  },
  shadows: {
    ...ThemeShadows,
  },
};

export const ThemeLightElevation1: ITheme = {
  ...ThemeLight,
  backgroundColor: ThemeColors.gray[2],
  borderColor: ThemeColors.gray[3],
  borderColorStrong: ThemeColors.gray[8],
  backgroundColorInverse: ThemeColors.gray[3],
  buttonDefaultBackgroundColor: ThemeColors.gray[4],
  buttonDefaultBackgroundColorHover: ThemeColors.gray[5],
  buttonDefaultBorderColor: ThemeColors.gray[4],
  buttonDefaultBorderColorHover: ThemeColors.gray[5],
};
