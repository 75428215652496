import { Roles } from "enum/roles.enum";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { IUser } from "models/user/user.model";
import { roleGuard } from "./roleGuard";

export const isAdminMode = (
  loggedInUser: IUser,
  salesAccountUUID: ISalesAccount["uuid"]
): boolean => {
  return (
    roleGuard(loggedInUser["https://connexx.com/roles"], [Roles.ITD_STAFF]) &&
    !salesAccountUUID
  );
};
