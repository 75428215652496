import { Select } from "components/atoms/Select";
import { ISelectOption } from "components/atoms/Select/UseSelect";
import { PackageTypes } from "enum/package-types.enum";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const DangerousGoodsSelect = ({
  value: valueProp,
  isDisabled,
  onChange,
}: {
  value: { label: string; value: number } | null;
  isDisabled?: boolean;
  onChange(value: ISelectOption<object, PackageTypes>);
}) => {
  const { dangerousGoodsCategories } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const value = valueProp
    ? dangerousGoodsCategories.find((type) => type.value === valueProp?.value)
    : null;

  return (
    <Select<ISelectOption<object>>
      isNullable={true}
      value={value}
      options={dangerousGoodsCategories}
      isDisabled={isDisabled}
      isSearchable={false}
      onChange={onChange}
    />
  );
};
