import { Divider } from "components/atoms/Divider";
import { ResizeablePanelHandle } from "components/atoms/ResizeablePanelHandle";
import { Stack } from "components/atoms/Stack";
import { createElement } from "react";
import { Rnd } from "react-rnd";
import { useTheme } from "styled-components";
import { Filter } from "./types/filter.types";

export const TableFilter = <T,>({
  filters,
  FilterComponent,
}: {
  filters: {
    [key: string]: Filter<T>;
  };
  FilterComponent?: React.ElementType;
}) => {
  const theme = useTheme();

  return (
    <Rnd
      style={{
        position: "relative",
        borderColor: theme.borderColor,
        display: "flex",
        zIndex: 0,
      }}
      className="flex-col h-full border-r overflow-auto"
      default={{ x: 0, y: 0, height: "100%", width: 240 }}
      disableDragging={true}
      enableResizing={{ right: true }}
      minWidth={200}
      resizeHandleWrapperStyle={{
        width: "3px",
      }}
      resizeHandleStyles={{
        right: {
          right: 0,
          width: "3px",
        },
      }}
      resizeHandleComponent={{ right: <ResizeablePanelHandle /> }}
      maxWidth={300}
    >
      <div className="flex">{FilterComponent ? <FilterComponent /> : null}</div>
      <Divider />
      <Stack divider={<Divider />}>
        {Object.keys(filters).map((key) => {
          const { component, ...rest } = filters[key];

          return <div key={key}>{createElement(component, { ...rest })}</div>;
        })}
      </Stack>
    </Rnd>
  );
};
