import { Button } from "components/atoms/Button";
import { useAppDispatch } from "store";
import { openModal } from "store/modalSlice";
import { CreateBulkShipmentTestIds } from "../../CreateBulkShipmentTestIds.enum";

export const CreateBulkShipmentStep1NoProfilesPanel = () => {
  const dispatch = useAppDispatch();

  const handleCreateProfile = () => {
    dispatch(openModal({ name: "createBulkShipmentProfile" }));
  };

  return (
    <div
      className="text-center"
      data-testid={
        CreateBulkShipmentTestIds.CREATE_BULK_SHIPMENT_STEP_1_NO_PROFILES_PANEL
      }
    >
      <div className="mb-4">
        You need to create a bulk shipment profile first.
      </div>
      <Button
        size="lg"
        colorScheme="primary"
        isBlock
        onClick={handleCreateProfile}
      >
        Create profile
      </Button>
    </div>
  );
};
