import cx from "classnames";

interface IBackdropProps {
  children?: React.ReactNode;
  onClick?(): void;
}

export const Backdrop = ({ children, onClick }: IBackdropProps) => {
  return (
    <div
      className={cx(
        "fixed top-0 left-0 w-screen h-screen overflow-scroll bg-black bg-opacity-75"
      )}
      {...(onClick && { onClick })}
    >
      {children}
    </div>
  );
};
