import { apiPublicPaths } from "enum/paths.enum";
import { fetcher, IFetchResponseSingle } from "services/api";
import { FetchCountriesResponseDto } from "./dtos/fetch-countries.response.dto";

export const fetchCountries = async (): Promise<
  IFetchResponseSingle<FetchCountriesResponseDto>
> => {
  return await fetcher({
    url: apiPublicPaths.countries,
    method: "GET",
  });
};
