import {
  Attribute,
  Attributes,
  AttributeTextCell,
} from "components/atoms/Attribute";
import { DEFAULT_WEIGHT_UNIT } from "constants/misc";
import { WeightUnits, WeightUnitSettings } from "interfaces/weight-units.type";
import { ShipmentStatusSettings } from "models/shipment/shipment.model";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { calculateTotalChargeableWeight } from "utils/calculateWeights";
import { convertGramsToKilos } from "utils/convertGramsToKilos";
import { convertKilosToPounds } from "utils/convertPoundsToKilos";
import { roundToNearestHalf } from "utils/calculate/roundToNearestHalf.utils";
import { groupedItems } from "utils/groupItems";
import { Label } from "components/atoms/Label";
import { TableUserCell } from "components/atoms/Table/cells/TableUserCell";
import { ShipmentToFrom } from "./ViewShipmentFrom";
import { Stack } from "components/atoms/Stack";
import { ViewShipmentButtons } from "./ViewShipmentButtons";
import { useState } from "react";
import { getTrackingUrl } from "utils/getTrackingUrl";
import { getShipmentDirection } from "utils/getShipmentDirection";
import { DateAndSource } from "./ViewShipmentSummaryDateAndSource";

export const ViewShipmentSummary = () => {
  const { currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const carrierName = currentShipment?.carrier?.name || "";

  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });

  const { financialCompany } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const WEIGHT_UNIT = settings?.weightUnit
    ? WeightUnitSettings[settings?.weightUnit]?.label
    : WeightUnitSettings[DEFAULT_WEIGHT_UNIT].label;

  let showMoreTackingCodesHidden = true;
  const [showMore, setShowMore] = useState(false);
  if (currentShipment?.trackingCodes.length > 2) {
    showMoreTackingCodesHidden = false;
  }

  let totalPrice = 0;
  currentShipment.rates.map((rate) => {
    if (
      rate.supplier.id === currentShipment.supplier.id &&
      rate.service.id === currentShipment.service.id
    ) {
      const fuelSurcharge =
        Math.floor(
          parseInt(rate.netPrice) *
            (parseInt(rate.fuelSurchargePercent) / 10000)
        ) || 0;

      const vat =
        !(
          currentShipment?.fromAddressCountryIso !==
          currentShipment?.toAddressCountryIso
        ) && financialCompany != 8
          ? Math.floor((parseInt(rate.netPrice) + fuelSurcharge) * 0.2)
          : 0;

      totalPrice = (parseInt(rate.netPrice) + vat + fuelSurcharge) / 100;
    }
  });
  return (
    <Stack spacing={8}>
      <ShipmentToFrom />
      <Attributes>
        <Attribute label="Status">
          <AttributeTextCell>
            <Label
              hasStatusDot={true}
              color={
                ShipmentStatusSettings[currentShipment.status]?.color || "gray"
              }
            >
              {ShipmentStatusSettings[currentShipment.status]?.label ||
                currentShipment.status}
            </Label>
          </AttributeTextCell>
        </Attribute>
        {currentShipment.status === "cancelled" ? (
          <div className="text-md text-center text-muted">
            Note: This shipment has generated a label. If you use this label,
            you will be billed.
          </div>
        ) : null}
        <Attribute label="Tracking Codes">
          {showMore ? (
            <>
              {currentShipment?.trackingCodes.map((element, index) => {
                return (
                  <AttributeTextCell
                    key={index}
                    className={`${
                      index ? "mt-2" : ""
                    } flex cursor-pointer text-blue10 hover:underline w-fit`}
                  >
                    <a
                      href={
                        element
                          ? getTrackingUrl(
                              element,
                              currentShipment?.carrier?.id || null,
                              getShipmentDirection(
                                currentShipment.fromAddressCountryIso,
                                currentShipment.toAddressCountryIso
                              )
                            )
                          : "#"
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="inline-block"
                    >
                      {element}
                    </a>
                  </AttributeTextCell>
                );
              })}
            </>
          ) : (
            <AttributeTextCell className="flex flex-row">
              <a
                href={
                  currentShipment?.trackingCodes[0]
                    ? getTrackingUrl(
                        currentShipment?.trackingCodes[0],
                        currentShipment?.carrier?.id || null,
                        getShipmentDirection(
                          currentShipment.fromAddressCountryIso,
                          currentShipment.toAddressCountryIso
                        )
                      )
                    : "#"
                }
                target="_blank"
                rel="noreferrer"
                className="flex cursor-pointer text-blue10 hover:underline"
              >
                {currentShipment?.trackingCodes[0]}
              </a>
              {currentShipment?.trackingCodes.length > 1 ? "," : ""}
              <a
                href={
                  currentShipment?.trackingCodes[1]
                    ? getTrackingUrl(
                        currentShipment?.trackingCodes[1],
                        currentShipment?.carrier?.id || null,
                        getShipmentDirection(
                          currentShipment.fromAddressCountryIso,
                          currentShipment.toAddressCountryIso
                        )
                      )
                    : "#"
                }
                target="_blank"
                rel="noreferrer"
                className="flex cursor-pointer text-blue10 hover:underline"
              >
                {currentShipment?.trackingCodes[1]}
              </a>
              {currentShipment?.trackingCodes.length > 2 ? "..." : ""}
            </AttributeTextCell>
          )}

          {showMoreTackingCodesHidden ? null : (
            <div
              className="mt-2 cursor-pointer text-blue10 hover:underline w-fit"
              onClick={() => {
                setShowMore(!showMore);
              }}
            >
              {showMore ? "Show Less" : "Show More"}
            </div>
          )}
        </Attribute>
        <Attribute label="Customer reference">
          <AttributeTextCell>
            {currentShipment?.customerReference}
          </AttributeTextCell>
        </Attribute>
        <Attribute label="Created at">
          <DateAndSource />
        </Attribute>
        <Attribute label="Created by">
          <AttributeTextCell>
            <TableUserCell email={currentShipment?.createdByUser?.email} />
          </AttributeTextCell>
        </Attribute>
        <Attribute label="Chargeable weight">
          <AttributeTextCell>
            {roundToNearestHalf(
              WEIGHT_UNIT === WeightUnits.pound
                ? convertKilosToPounds(
                    convertGramsToKilos(
                      calculateTotalChargeableWeight(
                        groupedItems(currentShipment.packages),
                        carrierName
                      )
                    )
                  )
                : convertGramsToKilos(
                    calculateTotalChargeableWeight(
                      groupedItems(currentShipment.packages),
                      carrierName
                    )
                  )
            )}
            {WEIGHT_UNIT}
          </AttributeTextCell>
        </Attribute>
        {/*evri and yodel and amazoncurrently do not return a price */}
        {currentShipment.carrier?.id === "1" ||
        currentShipment.carrier?.id === "2" ||
        currentShipment.carrier?.id === "39" ||
        totalPrice === 0 ? null : (
          <Attribute label="Price">
            <AttributeTextCell>£{totalPrice}</AttributeTextCell>
          </Attribute>
        )}

        <Attribute label="Reason for Export">
          <AttributeTextCell>
            {currentShipment.reasonForExport}
          </AttributeTextCell>
        </Attribute>
        <Attribute label="Duty or Tax Paid By">
          <AttributeTextCell>
            {currentShipment.dutyOrTaxPaidBy}
          </AttributeTextCell>
        </Attribute>
      </Attributes>
      <ViewShipmentButtons />
    </Stack>
  );
};
