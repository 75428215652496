import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { Input } from "../Input";

interface Props {
  date: Date;
  setDate: (date: Date) => void;
  showTimeSelect?: boolean;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  disabled?: boolean;
}

export const DatePickerComponent = ({
  date,
  setDate,
  showTimeSelect,
  minDate,
  maxDate,
  dateFormat,
  disabled,
}: Props) => {
  const InputRef = ({ value, onClick, onChange }, ref) => (
    <Input
      onFocus={onClick}
      //below line seems to break calendar focus
      // ref={ref}
      value={value}
      onChange={onChange}
    ></Input>
  );

  const ExampleCustomInput = forwardRef(InputRef);

  return (
    <div>
      <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect={showTimeSelect}
        selected={date}
        onChange={(date) => {
          setDate(date as Date);
        }}
        //@ts-expect-error forwardref
        customInput={<ExampleCustomInput />}
        dateFormat={dateFormat}
        disabled={disabled}
      />
    </div>
  );
};
