import "styles/global.scss";
import "styles/nprogress.scss";
import "react-datepicker/dist/react-datepicker.css";
import React, { ReactElement, useEffect } from "react";
import { NextPage } from "next";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress";
import { Toaster } from "components/atoms/Toaster";
import { AnimatePresence } from "framer-motion";
import { wrapper } from "store";
import { useDispatch } from "react-redux";
import { LayoutDashboard } from "components/layouts/LayoutDashboard";
import { Error } from "components/atoms/Error";
import { ErrorBoundary } from "components/atoms/ErrorBoundary";
import { ConfigEnvironmentSchema } from "config/config.schema";
import { cleanEnv } from "envalid";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { fetchCountriesThunk } from "store/countriesSlice/index.slice";
import { Modals } from "components/organisms/Modals";
import { fetchCurrenciesThunk } from "store/currenciesSlice/index.slice";
import { LayoutAuthenticated } from "components/layouts/LayoutAuthenticated";
import {
  getLocalStorageItem,
  LocalStorageKeys,
} from "utils/react/localStorage";
import { setFilterSidebarOpenStatus } from "store/tableSlice/index.slice";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Environments, getEnvironment } from "utils/getEnvironment";
import { Center } from "components/atoms/Center";
import { setCurrentTimeUTC } from "store/tickerSlice/index.slice";
import { useInterval } from "react-use";
import { ReduxThemeProvider } from "components/atoms/ReduxThemeProvider";
import { Maintenance } from "components/pages/Maintenance";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

type AppLayoutProps = {
  Component: PageWithLayoutType;
  pageProps: any;
};

type PageWithLayoutType = NextPage & {
  getLayout(component: JSX.Element): React.ReactNode;
};

// See: https://github.com/bugsnag/bugsnag-js/issues/1638
if (!(Bugsnag as any)._client)
  Bugsnag.start({
    apiKey: "b3b66f44dddd4c8d1e42f18de547d16b",
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: [Environments.production, Environments.staging],
    releaseStage: getEnvironment(),
  });

const BugsnagErrorBoundary =
  Bugsnag && Bugsnag.getPlugin("react").createErrorBoundary(React);

function CustomApp({ Component, pageProps }: AppLayoutProps) {
  const router = useRouter();

  const dispatch = useDispatch();

  // Validate .env
  if (typeof window === "undefined")
    cleanEnv(process.env, ConfigEnvironmentSchema);

  const getLayout = Component.getLayout
    ? (page: ReactElement) => {
        const WrappedComponent = () => <>{Component.getLayout(page)}</>;
        const ProtectedComponent = withAuthenticationRequired(WrappedComponent);
        return <ProtectedComponent />;
      }
    : (page: ReactElement) => {
        const Component = () => <LayoutDashboard>{page}</LayoutDashboard>;
        const ProtectedComponent = withAuthenticationRequired(Component);
        return <ProtectedComponent />;
      };

  // This data is required both for logged in users and those that are not, so fetching here.
  useEffect(() => {
    dispatch(fetchCountriesThunk());
    dispatch(fetchCurrenciesThunk());
  }, []);

  useEffect(() => {
    const isTableFilterVisible =
      JSON.parse(
        getLocalStorageItem(LocalStorageKeys.CONNEXX_SHOW_TABLE_FILTERS)
      ) || true;

    dispatch(setFilterSidebarOpenStatus(isTableFilterVisible));
  }, []);

  // Set the timer once app is ready
  useEffect(() => {
    dispatch(setCurrentTimeUTC());
  }, []);

  // Update the timer every 30 seconds thereafter
  useInterval(() => {
    dispatch(setCurrentTimeUTC());
  }, 30000);

  return (
    <ReduxThemeProvider>
      <BugsnagErrorBoundary>
        <ErrorBoundary
          FallbackComponent={({ resetErrorBoundary }) => {
            return (
              <div className="w-screen h-screen">
                <Center>
                  <Error buttonLabel="Refresh" onClick={resetErrorBoundary} />
                </Center>
              </div>
            );
          }}
        >
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link rel="icon" type="image/x-icon" href="/images/favicon.ico" />
          </Head>
          <>
            {process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "true" ? (
              <Maintenance />
            ) : (
              <>
                <Toaster />
                <Modals />
                <AnimatePresence exitBeforeEnter>
                  <Auth0Provider
                    domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL || ""}
                    clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ""}
                    redirectUri={process.env.NEXT_PUBLIC_AUTH0_BASE_URL}
                    scope={process.env.NEXT_PUBLIC_AUTH0_SCOPE}
                    audience={process.env.NEXT_PUBLIC_AUDIENCE}
                    useRefreshTokens
                    skipRedirectCallback={router.pathname.includes("/redirect")}
                  >
                    <LayoutAuthenticated>
                      {getLayout(
                        <Component {...pageProps} key={router?.route} />
                      )}
                    </LayoutAuthenticated>
                  </Auth0Provider>
                </AnimatePresence>
              </>
            )}
          </>
        </ErrorBoundary>
      </BugsnagErrorBoundary>
    </ReduxThemeProvider>
  );
}

export default wrapper.withRedux(CustomApp);
