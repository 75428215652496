import { DEFAULT_DIMENSIONS_UNIT } from "constants/misc";
import {
  DimensionUnits,
  DimensionUnitSettings,
} from "interfaces/dimension-units.type";

export const getDimensionUnitSettings = (dimensionUnit: DimensionUnits) => {
  return dimensionUnit && DimensionUnitSettings[dimensionUnit]
    ? DimensionUnitSettings[dimensionUnit]
    : DimensionUnitSettings[DEFAULT_DIMENSIONS_UNIT];
};
