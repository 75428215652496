import { CurrencySelect } from "components/molecules/CurrencySelect";
import { FormControl } from "components/molecules/FormControl";
import { NumberInput } from "components/atoms/NumberInput";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { Checkbox } from "components/atoms/Checkbox";
import {
  setInsuranceAmount,
  setInsuranceCurrency,
  setUseInsurance,
} from "store/createShipmentSlice/index.slice";
import {
  convertValueToDatabase,
  convertDatabaseToValue,
} from "utils/convertGramsToKilos";

export const InsuranceFormMain = () => {
  const dispatch = useDispatch();
  const { insurance } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  return (
    <div>
      <div className="mb-4">
        <FormControl label="">
          <Checkbox
            checked={insurance?.useInsurance}
            onCheckedChange={(checked) => {
              dispatch(setUseInsurance(checked as boolean));
            }}
            // disabled={!shouldAllowPickUpForm}
          >
            Enable
          </Checkbox>
        </FormControl>
      </div>
      <div className="flex">
        <div className="w-1/4 mr-2">
          <FormControl label="Amount">
            <NumberInput
              value={convertDatabaseToValue(insurance?.insuranceAmount)}
              onChange={(value) => {
                const valueConverted = !isNaN(value)
                  ? convertValueToDatabase(value)
                  : 0;
                dispatch(setInsuranceAmount(valueConverted));
              }}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                e.target.select();
              }}
              disabled={!insurance?.useInsurance}
            />
          </FormControl>
        </div>
        <div className="w-1/4">
          <FormControl label="Currency" isRequired={false}>
            <CurrencySelect
              onChange={(value) => {
                dispatch(setInsuranceCurrency(value));
              }}
              value={insurance?.insuranceAmountCurrency}
              isDisabled={!insurance?.useInsurance}
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
};
