import { apiPublicPaths } from "enum/paths.enum";
import { fetcher, IFetchResponseSingle } from "services/api";
import { FetchCurrenciesResponseDto } from "./dtos/fetch-currencies.response.dto";

export const fetchCurrencies = async (): Promise<
  IFetchResponseSingle<FetchCurrenciesResponseDto>
> => {
  return await fetcher({
    url: apiPublicPaths.currencies,
    method: "GET",
  });
};
