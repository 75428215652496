import { AppThunk, RootState } from "store";
import { downloadShipmentReport } from "services/api/shipments";

export const downloadShipmentReportThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
      shipmentsSlice: { reportShipmentFrom, reportShipmentTo },
    } = getState() as RootState;

    const dateFrom = reportShipmentFrom.split("T")[0];
    const dateTo = reportShipmentTo.split("T")[0];
    const response = await downloadShipmentReport(salesAccountUUID, {
      dateFrom,
      dateTo,
    });
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
    const downloadLink = document.createElement("a");
    const fileName = `Shipment Report ${dateFrom}-${dateTo}.xlsx`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
};
