import { DEFAULT_DIMENSIONS_UNIT } from "constants/misc";
import {
  DimensionUnits,
  DimensionUnitSettings,
} from "interfaces/dimension-units.type";
import { convertDatabaseToValue } from "utils/convertGramsToKilos";
import { convertCmToInches } from "utils/convertInchesToCm";

export const PrintDimension = ({
  dimensionUnit = DEFAULT_DIMENSIONS_UNIT,
  value,
}: {
  dimensionUnit: DimensionUnits;
  value: number;
}) => {
  return (
    <>
      {dimensionUnit === DimensionUnits.cm
        ? convertDatabaseToValue(value)
        : convertCmToInches(convertDatabaseToValue(value))}{" "}
      {DimensionUnitSettings[dimensionUnit].label}
    </>
  );
};
