import { Select } from "components/atoms/Select";
import { ISelectOption } from "components/atoms/Select/UseSelect";
import { tableRowQuantity } from "enum/table-row-quantity.enum";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const TableRowQuantitySelect = ({
  isDisabled,
  onChange,
}: {
  isDisabled?: boolean;
  onChange(value: ISelectOption<object, tableRowQuantity>);
}) => {
  const { numberOfRows, numberOfRowsSelected } = useSelector(
    (state: RootState) => {
      return state.tableSlice;
    }
  );

  return (
    <div style={{ width: 65 }}>
      <Select<ISelectOption<object, tableRowQuantity>>
        value={numberOfRowsSelected}
        options={numberOfRows}
        isDisabled={isDisabled}
        isSearchable={false}
        onChange={onChange}
      />
    </div>
  );
};
