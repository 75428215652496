import { Button } from "components/atoms/Button";
import { ButtonGroup } from "components/atoms/ButtonGroup";
import { IconButton } from "components/atoms/IconButton";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownToggle,
} from "components/molecules/Dropdown";
import { DropdownMenuSection } from "components/molecules/Dropdown/DropdownMenuSection";
import { FaChevronDown } from "react-icons/fa";
import { CreateShipmentFormTestIds } from "../../common/CreateShipmentFormTestIds.enum";
import { Checkbox } from "components/atoms/Checkbox";
import { useDispatch } from "react-redux";
import { setDisclaimerChecked } from "store/createShipmentSlice/index.slice";
import { useSelector } from "react-redux";
import { PermissionCheck } from "utils/PermissionCheck/PermissionCheck";
import { RootState } from "store";

export const CreateShipmentFormFooter = ({
  rates,
  submittingStatus,
  handleFinaliseShipment,
  ratesLoadingStatus,
  selectedRate,
  handleFetchRates,
  isDisclaimerAccepted,
}) => {
  const dispatch = useDispatch();

  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const permissions = new PermissionCheck({ user: loggedInUser });

  const canSubmit =
    selectedRate && isDisclaimerAccepted && permissions.shipments.canGenerate();

  return (
    <div className="p-12 pt-0 pl-20">
      {rates.length > 0 ? (
        <div>
          <div className="mb-2">
            <Checkbox
              checked={isDisclaimerAccepted}
              onCheckedChange={(checked) => {
                dispatch(setDisclaimerChecked(checked as boolean));
              }}
            >
              <div className="font-medium" style={{ fontSize: 13 }}>
                I have read and agree to the above disclaimers.{" "}
                <span className="text-red10">*</span>
              </div>
            </Checkbox>
          </div>
          <Dropdown mode="onClick" placement="bottom-end">
            {({ onToggle }) => {
              const isSubmitting = submittingStatus === "LOADING";

              return (
                <>
                  <ButtonGroup>
                    <Button
                      colorScheme="primary"
                      onClick={() =>
                        handleFinaliseShipment({ printLater: false })
                      }
                      size="lg"
                      isDisabled={!canSubmit}
                      isLoading={isSubmitting}
                    >
                      Create &amp; Print Label
                    </Button>
                    {!isSubmitting && (
                      <DropdownToggle>
                        <IconButton
                          colorScheme="primary"
                          onClick={onToggle}
                          isDisabled={!canSubmit}
                          icon={FaChevronDown}
                          size="lg"
                        />
                      </DropdownToggle>
                    )}
                  </ButtonGroup>
                  <DropdownMenu width={200}>
                    <DropdownMenuSection>
                      <DropdownMenuItem
                        onClick={() => {
                          if (canSubmit) {
                            handleFinaliseShipment({ printLater: true });
                          }
                        }}
                      >
                        Create shipment
                      </DropdownMenuItem>
                    </DropdownMenuSection>
                  </DropdownMenu>
                </>
              );
            }}
          </Dropdown>
        </div>
      ) : (
        <div>
          <Button
            testId={
              CreateShipmentFormTestIds.CREATE_SHIPMENT_FORM_FETCH_RATES_BUTTON
            }
            colorScheme="primary"
            onClick={handleFetchRates}
            size="lg"
            isLoading={ratesLoadingStatus === "LOADING"}
            // isDisabled={rates.length > 0 && !isDisclaimerAccepted}
          >
            {rates.length > 0 ? "Create shipment" : "Get rates"}
          </Button>
        </div>
      )}
    </div>
  );
};
