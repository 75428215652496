import { CreateAddressModal } from "components/organisms/CreateAddressModal";
import { useDispatch } from "react-redux";
import { fetchAddressesThunk } from "store/addressSlice/index.slice";
import { closeModal } from "store/modalSlice";

export const CreateReceiverAddressModal = () => {
  const dispatch = useDispatch();
  return (
    <CreateAddressModal
      onClose={() => {
        dispatch(closeModal({ modalName: "createReceiverAddress" }));
      }}
      onSuccess={() => {
        dispatch(fetchAddressesThunk({ offset: 0 }));
        dispatch(closeModal({ modalName: "createReceiverAddress" }));
      }}
    />
  );
};
