import { forwardRef, HTMLAttributes, ReactNode, Ref } from "react";
import styled from "styled-components";

const StyledPanel = styled.div`
  border: 1px solid;
  border-color: ${(props) => props.theme.panelBorderColor};
  border-radius: ${(props) => props.theme.borderRadius};
`;

type IPanel = {
  children: ReactNode;
  testId?: string;
} & HTMLAttributes<HTMLDivElement>;

export const Panel = forwardRef((props: IPanel, ref: Ref<HTMLDivElement>) => {
  const { children, testId, ...rest } = props;

  return (
    <StyledPanel ref={ref} {...(testId && { "data-testid": testId })} {...rest}>
      {children}
    </StyledPanel>
  );
});

Panel.displayName = "Panel";
