import { useRef } from "react";
import { ModalBody } from "components/atoms/Modal";
import { ModalHeader } from "components/atoms/Modal/ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import { FormControl } from "components/molecules/FormControl";
import { DatePickerComponent } from "components/atoms/datePicker";
import { TimePickerRangeComponent } from "components/atoms/timePickerRange";
import dayjs from "dayjs";
import { RootState } from "store";
import {
  setPickupDate,
  setPickupToTime,
  setPickupFromTime,
  setPickupInstructions,
} from "store/pickupSlice/index.slice";
import { Button } from "components/atoms/Button";
import { createPickUpRequestThunk } from "store/pickupSlice/thunks/createPickUpRequestThunk";
import { Input } from "components/atoms/Input";

export const PickUpRequestModal = (): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const {
    pickupDate,
    pickupFromTime,
    pickupToTime,
    pickupInstructions,
    createLoadingStatus,
  } = useSelector((state: RootState) => {
    return state.pickupSlice;
  });

  const handleTimeRangeChange = (time, timeString) => {
    const addHours = (date, hours) => {
      date.setHours(date.getHours() + hours);

      return date;
    };

    const newDate = new Date(pickupDate).toISOString().split("T")[0];
    const fromTime = new Date().toISOString().split("T")[1];
    const toTime = addHours(new Date(), 4).toISOString().split("T")[1];

    dispatch(
      setPickupFromTime(
        time ? time[0]["$d"].toISOString() : newDate + "T" + fromTime
      )
    );
    dispatch(
      setPickupToTime(
        time ? time[1]["$d"].toISOString() : newDate + "T" + toTime
      )
    );
  };
  return (
    <>
      <ModalHeader>Request pick up</ModalHeader>
      <ModalBody>
        <FormControl label="">
          <div style={{ width: "400px" }}>
            <div className="flex items-center mb-2">
              <FormControl label="Pickup Date" isRequired={false}>
                <DatePickerComponent
                  showTimeSelect={false}
                  date={new Date(pickupDate)}
                  setDate={(date) => {
                    dispatch(setPickupDate(date.toISOString()));
                  }}
                  dateFormat="dd-MM-yyyy"
                  minDate={new Date()}
                />
              </FormControl>
            </div>
            <div className="flex items-center mb-2">
              <FormControl label="Time Range (Local Time)" isRequired={false}>
                <TimePickerRangeComponent
                  value={[
                    dayjs(dayjs(pickupFromTime).format("HH:mm"), "HH:mm"),
                    dayjs(dayjs(pickupToTime).format("HH:mm"), "HH:mm"),
                  ]}
                  onChange={handleTimeRangeChange}
                />
              </FormControl>
            </div>
            <div className="items-center mb-2 w-full">
              <FormControl
                label="Pickup Instructions"
                isRequired={false}
                className="flex-grow"
              >
                <Input
                  value={pickupInstructions}
                  onChange={(e) => {
                    dispatch(setPickupInstructions(e.target.value));
                  }}
                  ref={ref}
                />
              </FormControl>
            </div>
          </div>
        </FormControl>
        <Button
          isBlock
          onClick={() => {
            dispatch(createPickUpRequestThunk());
          }}
          size="lg"
          isLoading={createLoadingStatus === "LOADING"}
          colorScheme="primary"
          className="mt-4"
        >
          Create Pickup
        </Button>
      </ModalBody>
    </>
  );
};
