export enum CreateShipmentFormTestIds {
  "CREATE_SHIPMENT_FORM_SENDER_SECTION" = "CREATE_SHIPMENT_FORM_SENDER_SECTION",
  "CREATE_SHIPMENT_FORM_RECEIVER_SECTION" = "CREATE_SHIPMENT_FORM_RECEIVER_SECTION",
  "CREATE_SHIPMENT_FORM_PACKAGES_SECTION" = "CREATE_SHIPMENT_FORM_PACKAGES_SECTION",
  "CREATE_SHIPMENT_FORM_CONTENT_DESCRIPTIONS_SECTION" = "CREATE_SHIPMENT_FORM_CONTENT_DESCRIPTIONS_SECTION",
  "CREATE_SHIPMENT_FORM_DETAILS_SECTION" = "CREATE_SHIPMENT_FORM_DETAILS_SECTION",
  "CREATE_SHIPMENT_FORM_RATES_SECTION" = "CREATE_SHIPMENT_FORM_RATES_SECTION",
  "CREATE_SHIPMENT_FORM_FETCH_RATES_BUTTON" = "CREATE_SHIPMENT_FORM_FETCH_RATES_BUTTON",
  "CREATE_SHIPMENT_FORM_EMAIL_RATES_SECTION" = "CREATE_SHIPMENT_EMAIL_RATES_SECTION",
}
