import { createSlice } from "@reduxjs/toolkit";
import { TickerSliceInitialState } from "./tickerSliceInitialState.const";

export const tickerSlice = createSlice({
  name: "ticker",
  initialState: TickerSliceInitialState,
  reducers: {
    setCurrentTimeUTC: (state) => {
      state.currentTimeUTC = new Date().toISOString();
    },
  },
});

export const { setCurrentTimeUTC } = tickerSlice.actions;
