import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { CreateQuoteSteps } from "../CreateShipmentForm/CreateShipmentForm.config";
import { CreateShipmentRates } from "../common/CreateShipmentFormRates";
import { CreateShipmentFormSteps } from "../CreateShipmentForm/CreateShipmentFormSteps";
import { CreateQuoteFooter } from "./CreateQuoteFooter";
import { isUserAdmin } from "utils/isUserAdmin";
import { closeModal, openModal } from "store/modalSlice";
import { SalesAccountStatus } from "enum/sales-account-status.enum";
import { fetchRatesThunk } from "store/createShipmentSlice/thunks/fetchRatesThunk.thunk";
import { Divider } from "components/atoms/Divider";

export const CreateQuoteForm = () => {
  const dispatch = useDispatch();

  const createShipment = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { salesAccounts } = useSelector((state: RootState) => {
    return state.salesAccountSlice;
  });

  const handleSubmitQuote = (status : string) => {
    // Admin should be able to quote, even if customer is on stop – but a warning should be shown.
    // Requested here: https://github.com/ITD-Global/itd-connexx/issues/129
    if (status === "SUCCESS") {
      dispatch(closeModal({ modalName: "createShipment" }))
    }
    if (isUserAdmin(loggedInUser)) {
      const customer = salesAccounts.find(
        (salesAccount) => salesAccount.uuid === createShipment.salesAccountUUID
      );
      if (customer?.status === SalesAccountStatus.STOPPED)
        dispatch(openModal({ name: "customerOnStopWarning" }));
      else dispatch(fetchRatesThunk());
    } else {
      dispatch(fetchRatesThunk());
    }
  };

  return (
    <>
      <CreateShipmentFormSteps steps={CreateQuoteSteps()} />
      {createShipment.ratesLoadingStatus !== ApiLoadingStatus.IDLE && (
        <>
          <Divider />
          <CreateShipmentRates />
        </>
      )}

      {createShipment.currentQuoteReference ? (
        <div className="flex justify-center mb-8">
          <div className="flex text-2xl font-bold mr-2">Reference: </div>
          <div className="flex text-2xl">
            {createShipment.currentQuoteReference}
          </div>
        </div>
      ) : null}

      <CreateQuoteFooter
        handleSubmit={handleSubmitQuote}
        loadingStatus={createShipment.ratesLoadingStatus}
      />
    </>
  );
};
