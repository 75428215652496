import { Close16 } from "@carbon/icons-react";
import { useControllableState } from "hooks/useControllable";
import { useState } from "react";
import { isArray } from "lodash"
import { Center } from "../Center";
import { Input } from "../Input";
import { KeyboardEvent, ChangeEvent } from "react";

export const TagInput = ({
  value: valueProp,
  onChange: onChangeProp,
}: {
  value?: string[];
  onChange?(values: string[]): void;
}) => {
  const [tags, setTags] = useControllableState({
    value: valueProp,
    defaultValue: [],
    onChange: onChangeProp,
  });

  const [isKeyReleased, setIsKeyReleased] = useState<boolean>(false);

  const [input, setInput] = useState("");

  const deleteTag = (index: number) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInput(value);
  };

  const handleClear = () => {
    setTags([]);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    const trimmedInput = input.trim();

    if (
      key === "," ||
      (key === "Enter" && trimmedInput.length && !tags.includes(trimmedInput))
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  return (
    <div>
      <Input
        value={input}
        type="text"
        onPaste={(event) => {
          event.preventDefault();

          const value: string = event.clipboardData.getData("Text");

          if (value.length > 0) {
            let values = value.split(",").filter((value) => {
              return value !== "";
            });

            values = values.map((value) => value.trim());

            setTags((prevState) => [...prevState, ...values]);
          }
        }}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={onChange}
        placeholder="Start typing..."
      />

      {tags && isArray(tags) && (
        <>
          {tags.length > 0 && (
            <div
              className="my-2 text-xs leading-none text-right text-gray-500 underline cursor-pointer"
              onClick={handleClear}
            >
              Clear all
            </div>
          )}
          {tags.map((tag, index) => {
            return (
              <div
                key={index}
                className={
                  "inline-flex bg-gray-200 rounded-md mt-1 mr-1 items-center"
                }
              >
                <div className="px-2 text-xs leading-none">{tag}</div>
                <div
                  className="w-5 h-6 bg-gray-300 cursor-pointer rounded-r-md"
                  onClick={() => deleteTag(index)}
                >
                  <Center>
                    <Close16 />
                  </Center>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
