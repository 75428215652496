import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISelectOption } from "components/atoms/Select/UseSelect";
import { ICurrency } from "models/currency/currency.model";
import { fetchCurrencies } from "services/api/currencies";
import { AppThunk } from "store";
import { sortBy } from "lodash";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";

export interface ICurrenciesSlice {
  loadingStatus: ApiLoadingStatus;
  currencies: ICurrency[];
  currenciesSelectOptions: ISelectOption<ICurrency, string>[];
}

export const CurrenciesSliceInitialState: ICurrenciesSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  currencies: [],
  currenciesSelectOptions: [],
};

const generateOptions = (
  currencies: ICurrency[]
): ISelectOption<ICurrency, string>[] => {
  const result: ISelectOption<ICurrency, string>[] = [];

  currencies.map((currency) => {
    result.push({
      label: currency.code,
      value: currency.code,
      ...currency,
    });
  });

  return sortBy(result, "label");
};

export const currenciesSlice = createSlice({
  name: "currenciesSlice",
  initialState: CurrenciesSliceInitialState,
  reducers: {
    setData: (state, action: PayloadAction<ICurrenciesSlice["currencies"]>) => {
      state.currencies = action.payload;

      state.currenciesSelectOptions = generateOptions(action.payload);

      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },
    setStatus: (
      state,
      action: PayloadAction<ICurrenciesSlice["loadingStatus"]>
    ) => {
      state.loadingStatus = action.payload;
    },
  },
});

export const { setData, setStatus } = currenciesSlice.actions;

export const fetchCurrenciesThunk = (): AppThunk => {
  return async (dispatch) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));

    const { data, error } = await fetchCurrencies();

    if (error || !data) {
      dispatch(setStatus(ApiLoadingStatus.FAILED));
    } else {
      dispatch(setData(data));
    }
  };
};
