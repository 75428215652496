import { DATE_FORMAT } from "constants/dateFormat";
import { format, isValid, parseISO } from "date-fns";

/**
 * Formats the date to a standard format: dd MMM yyyy HH:mm
 * @param date
 */

export const formatDate = (date: string): string | null => {
  return isValid(parseISO(date)) ? format(parseISO(date), DATE_FORMAT) : null;
};
