import { useToaster } from "react-hot-toast";
import { Portal } from "../Portal";
import cx from "classnames";
import { CheckmarkFilled20, WarningFilled20 } from "@carbon/icons-react";
import { useTheme } from "styled-components";

export const Toaster = () => {
  const { toasts, handlers } = useToaster();

  const theme = useTheme();

  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  return (
    <div onMouseEnter={startPause} onMouseLeave={endPause}>
      {toasts.map((toast) => {
        const offset = calculateOffset(toast, {
          reverseOrder: false,
          gutter: 4,
          defaultPosition: "top-center",
        });

        const ref = (el) => {
          if (el && !toast.height) {
            const height = el.getBoundingClientRect().height;
            updateHeight(toast.id, height);
          }
        };

        const { type } = toast;

        const isSuccess = type === "success";

        const isError = type === "error";

        return (
          <Portal key={toast.id}>
            <div
              ref={ref}
              className={"px-3 py-2.5 top-2 text-sm fixed left-1/2 inline-flex"}
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: theme.borderRadius,
                boxShadow: theme.shadows["2xl"],
                transform: `translateY(${offset}px) translateX(-50%)`,
              }}
            >
              <>
                <div
                  className={cx("mr-2", {
                    "text-green9": isSuccess,
                    "text-red9": isError,
                  })}
                >
                  {isSuccess ? <CheckmarkFilled20 /> : <WarningFilled20 />}
                </div>
                {toast.message}
              </>
            </div>
          </Portal>
        );
      })}
    </div>
  );
};
