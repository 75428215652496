import cx from "classnames";
import { Tabs as _Tabs, useTabState, usePanelState } from "@bumaga/tabs";
import styles from "./Tabs.module.scss";
import { createContext } from "utils/react/createContext";
import { MaybeRenderProp } from "utils/react/maybeRenderProp";
import { cloneElement, isValidElement, ReactNode } from "react";
import { useTheme } from "styled-components";

export const TabsNavigation = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): JSX.Element => {
  return (
    <div className={cx("flex border-b border-gray6", className)}>
      {children}
    </div>
  );
};

interface ITabs {
  isFitted: boolean;
}

type ITabsContext = Pick<ITabs, "isFitted">;

export const [TabsContextProvider, useTabsContextProvider] =
  createContext<ITabsContext>();

export const TabsNavigationItem = ({
  children,
  isActive,
  onClick,
  style,
}: {
  children: ReactNode;
  isActive?: boolean;
  onClick?(): void;
  style?: React.CSSProperties;
}): JSX.Element => {
  const { isFitted } = useTabsContextProvider();

  const theme = useTheme();

  return (
    <div
      onClick={onClick}
      className={cx(styles.TabsNavigationItem, {
        [styles["TabsNavigationItem--isActive"]]: isActive,
        [styles["TabsNavigationItem--isFitted"]]: isFitted,
      })}
    >
      <div
        className="inline-block px-4 py-2.5 leading-none rounded-lg bg-gray3"
        style={style}
      >
        {children}
      </div>
      {isActive === true && (
        <div
          className="absolute left-0 w-full"
          style={{
            height: "3px",
            bottom: "-1px",
            backgroundColor: theme.colors.primary[10],
          }}
        />
      )}
    </div>
  );
};

export const Tab = ({
  children,
}: {
  // @ts-ignore
  children: MaybeRenderProp<{ isActive: boolean; onClick: any }>;
}): JSX.Element => {
  const { onClick, isActive } = useTabState();

  return (
    children &&
    isValidElement(children) &&
    // @ts-ignore
    cloneElement(children, { isActive, onClick })
  );
};

export const Tabs = ({
  children,
  isFitted,
}: {
  children: ReactNode;
  isFitted?: boolean;
}): JSX.Element => {
  return (
    <TabsContextProvider value={{ isFitted }}>
      <_Tabs>{children}</_Tabs>
    </TabsContextProvider>
  );
};

export const TabPanel = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const isActive = usePanelState();

  return isActive ? <div className="TabPanel">{children}</div> : null;
};
