import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import { Stack } from "../Stack";

type IRadioGroupProps = RadixRadioGroup.RadioGroupProps;

export const RadioGroup = (props: IRadioGroupProps) => {
  const { children, ...rest } = props;

  return (
    <RadixRadioGroup.Root {...rest}>
      <Stack spacing={4}>{children}</Stack>
    </RadixRadioGroup.Root>
  );
};
