import { apiPaths, customerApiBase } from "enum/paths.enum";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { IRule } from "models/rule/rule.model";
import { fetcher, IFetchResponseSingle } from "services/api";
import { CreateRuleRequestDto } from "./dtos/create-rule.request.dto";
import { FetchRulesResponseDto } from "./dtos/fetch-rules.response.dto";
import { EditRuleRequestDto } from "./dtos/update-rule-request.dto";
import { ReorderRulesRequestDto } from "./dtos/reoorder-rules-request.dto";

export const fetchRules = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<IFetchResponseSingle<FetchRulesResponseDto>> => {
  return await fetcher({
    url: customerApiBase + "/" + salesAccountUUID + apiPaths.rules,
    method: "GET",
  });
};

export const createRule = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: CreateRuleRequestDto
): Promise<IFetchResponseSingle<FetchRulesResponseDto>> => {
  return await fetcher({
    url: customerApiBase + "/" + salesAccountUUID + apiPaths.rules,
    method: "POST",
    body: values,
  });
};

export const updateRule = async (
  salesAccountUUID: ISalesAccount["uuid"],
  ruleUUID: IRule["uuid"],
  values: EditRuleRequestDto
): Promise<IFetchResponseSingle<FetchRulesResponseDto>> => {
  return await fetcher({
    url:
      customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.rules +
      "/" +
      ruleUUID,
    method: "PATCH",
    body: values,
  });
};

export const toggleRuleStatus = async (
  salesAccountUUID: ISalesAccount["uuid"],
  ruleUUID: IRule["uuid"]
): Promise<IFetchResponseSingle<FetchRulesResponseDto>> => {
  return await fetcher({
    url:
      customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.rules +
      "/" +
      ruleUUID +
      "/toggle-status",
    method: "PATCH",
  });
};

export const reorderRules = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: ReorderRulesRequestDto
): Promise<IFetchResponseSingle<FetchRulesResponseDto>> => {
  return await fetcher({
    url:
      customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.rules +
      "/reorder-rules",
    method: "PATCH",
    body: values,
  });
};

export const deleteRule = async (
  salesAccountUUID: ISalesAccount["uuid"],
  ruleUUID: IRule["uuid"]
): Promise<IFetchResponseSingle<FetchRulesResponseDto>> => {
  return await fetcher({
    url:
      customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.rules +
      "/" +
      ruleUUID,
    method: "DELETE",
  });
};
