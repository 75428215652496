import { Button } from "components/atoms/Button";
import { Input } from "components/atoms/Input";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setCreateProfileTitle } from "store/createOrUpdateBulkShipmentProfileSlice/index.slice";
import { closeModal, setModalSize } from "store/modalSlice";
import { useWizardContextProvider, WizardHeader } from "../Wizard";

export const BulkShipmentProfileWizardFinalize = ({
  handleSubmit,
}: {
  handleSubmit(): void;
}) => {
  const { activeStepIndex } = useWizardContextProvider();

  const dispatch = useDispatch();

  const handleSetTitle = (value) => {
    dispatch(setCreateProfileTitle(value));
  };

  const { loadingStatus, title } = useSelector((state: RootState) => {
    return state.createOrUpdateBilkShipmentProfileSlice;
  });

  useEffect(() => {
    if (activeStepIndex === 2) {
      dispatch(setModalSize({ name: "createBulkShipmentProfile", size: "sm" }));
    }
  }, [activeStepIndex]);

  return (
    <>
      <WizardHeader
        title="Give it a name"
        onClose={() =>
          dispatch(closeModal({ modalName: "createBulkShipmentProfile" }))
        }
      />

      <div className="mb-4">
        <Input
          value={title}
          size="lg"
          autoFocus
          onChange={(e) => handleSetTitle(e.target.value)}
          placeholder="Profile name"
        />
      </div>
      <Button
        isBlock
        size="lg"
        colorScheme="primary"
        onClick={handleSubmit}
        isLoading={loadingStatus === "LOADING"}
        isDisabled={title.length === 0}
      >
        Create profile
      </Button>
    </>
  );
};
