import { Wizard, WizardContextProvider } from "../Wizard";
import { useWizard } from "hooks/useWizard";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { setModalSize } from "store/modalSlice";
import { RootState, useAppDispatch } from "store";
import { setSettingsData } from "store/settingsSlice/index.slice";
import { fetchSettings } from "services/api/settings";
import { CreateBulkShipmentModalSteps } from "./CreateBulkShipmentModalSteps.config";

export const CreateBulkShipmentModal = () => {
  const dispatch = useAppDispatch();

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  useEffect(() => {
    fetchSettings(salesAccountUUID).then(({ data, error }) => {
      if (!error) {
        dispatch(setSettingsData(data));
      }
    });
  }, []);

  const wizard = useWizard({
    steps: CreateBulkShipmentModalSteps,
  });

  useEffect(() => {
    if (wizard.activeStepIndex > 0) {
      dispatch(setModalSize({ name: "createBulkShipment", size: "2xl" }));
    }

    if (wizard.activeStepIndex === 0) {
      dispatch(setModalSize({ name: "createBulkShipment", size: "sm" }));
    }
  }, [wizard.activeStepIndex]);

  return (
    <WizardContextProvider value={{ ...wizard }}>
      <Wizard steps={CreateBulkShipmentModalSteps} />
    </WizardContextProvider>
  );
};
