import { FaDownload } from "react-icons/fa";
import { IconButton } from "../IconButton";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownToggle,
} from "components/molecules/Dropdown";
import { Portal } from "react-portal";
import { DropdownMenuGroup } from "components/molecules/Dropdown/DropdownMenuGroup";

interface Props {
  exportData: () => void;
  downloadOptions?: { label: string; onClick: () => void }[];
}

export const TableControls = (props: Props) => {
  const { exportData, downloadOptions } = props;
  return (
    <Dropdown mode="onClick" placement="bottom-end">
      <DropdownToggle>
        <IconButton icon={FaDownload} />
      </DropdownToggle>
      <Portal>
        <DropdownMenu width={240}>
          <DropdownMenuGroup>
            {/* <DropdownMenuItem onClick={exportData}>
              Download Current Page (CSV)
            </DropdownMenuItem> */}
            {downloadOptions?.map((option, index) => {
              return (
                <DropdownMenuItem key={index} onClick={option.onClick}>
                  {option.label}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuGroup>
        </DropdownMenu>
      </Portal>
    </Dropdown>
  );
};
