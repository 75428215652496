import { INumberedSectionProps } from "./NumberedSectionProps.type";

export const NumberedSection = (props: INumberedSectionProps) => {
  const { children, testId } = props;

  return (
    <section {...(testId && { "data-testid": testId })} className="p-10">
      {children}
    </section>
  );
};
