import { useCallback, useRef } from "react";
import { useSafeLayoutEffect } from "./useSafeLayoutEffect";

export function useCallbackRef<T extends (...args: any[]) => any>(
  fn: T | undefined
): T {
  const ref = useRef(fn);

  useSafeLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback(((...args) => ref.current?.(...args)) as T, []);
}
