import { Flag } from "../Flag";

export const PrintCountry = ({
  countryName,
  countryCode,
}: {
  countryName: string | null;
  countryCode: string | null;
}) => {
  if (countryCode && countryName) {
    return (
      <div className="flex items-center">
        <div className="mr-2">
          <Flag countryCode={countryCode} />
        </div>
        {countryName}
      </div>
    );
  } else {
    return <></>;
  }
};
