import { IShipmentProfile } from "models/shipmentProfile/shipmentProfile.model";
import { MappingColumns } from "store/createOrUpdateBulkShipmentProfileSlice/createOrUpdateBulkShipmentProfileSliceInitialState.const";

export const generateColumns = (): {
  attribute: string;
  mappedAttribute: string | null;
}[] => {
  const data: { attribute: string; mappedAttribute: null }[] = [];

  Object.keys(MappingColumns).map((key) => {
    data.push({
      attribute: key,
      mappedAttribute: null,
    });
  });

  return data;
};

export const checkIfColumnMapped = (
  mappings: IShipmentProfile["mappings"],
  columnIndex: number
): boolean => {
  return Object.keys(mappings).some((k) => {
    return mappings[k] === columnIndex;
  });
};

export const getSampleValues = (
  data: unknown[],
  keyIndex: number
): string[] | number[] => {
  const values = [];

  for (let i = 0; i < data.length; i++) {
    values.push(data[i][keyIndex]);
  }

  return values;
};
