import { KeyboardKeys } from "enum/keyboardkeys.enum";
import NextLink, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import React, { HTMLProps } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export type IInternalLink = LinkProps & HTMLProps<HTMLAnchorElement>;

export const InternalLink = (props: IInternalLink) => {
  const { href, className, children, ...rest } = props;

  const ref = useHotkeys<HTMLAnchorElement>(KeyboardKeys.ENTER, () => {
    router.push(href);
  });

  const router = useRouter();

  return (
    <NextLink href={href} legacyBehavior>
      <a href={href} className={className} ref={ref} {...rest}>
        {children}
      </a>
    </NextLink>
  );
};
