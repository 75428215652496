import { IconButton } from "components/atoms/IconButton";
import { PanelHeader } from "components/atoms/Panel/PanelHeader";
import { PanelTitle } from "components/atoms/Panel/PanelTitle";
import { FaTrash } from "react-icons/fa";
import { PackagesTestIds } from "./CreateShipmentFormPackagesTestIds.enum";

export const CreateShipmentFormPackagesHeader = ({
  number,
  onRemove,
}: {
  number: number;
  onRemove(number: number): void;
}) => {
  return (
    <PanelHeader className="flex items-center">
      <PanelTitle>Package #{number + 1}</PanelTitle>

      {number !== 0 && (
        <IconButton
          icon={FaTrash}
          onClick={() => onRemove(number)}
          className="ml-auto"
          testId={`${PackagesTestIds.PACKAGES_REMOVE_PACKAGE}_${number}`}
        />
      )}
    </PanelHeader>
  );
};
