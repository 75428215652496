import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLoadingStatus } from "interfaces/api.interface";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { IUser } from "models/user/user.model";
import {
  fetchMe,
  userSalesAccountSwitch,
  updateUserColumns,
} from "services/api/users";
import { AppThunk, RootState } from "store";

interface IAuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  accessToken: string;
  loggedInUser: IUser;
  loadingStatus: ApiLoadingStatus;
  salesAccountUUID: null | ISalesAccount["uuid"];
  salesAccountID: null | ISalesAccount["id"];
  financialCompany: number;
  userColumns: any;
  alertType: string;
}

export const AuthSliceInitialState: IAuthState = {
  isAuthenticated: false,
  isLoading: true,
  accessToken: "",
  salesAccountUUID: null,
  salesAccountID: null,
  financialCompany: 0,
  loadingStatus: "IDLE",
  loggedInUser: {} as IUser,
  userColumns: null,
  alertType: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: AuthSliceInitialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ user: IUser; accessToken: string }>
    ) => {
      state.loggedInUser = action.payload.user;
      state.isLoading = false;
      state.accessToken = action.payload.accessToken;
      state.isAuthenticated = true;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<IAuthState["loadingStatus"]>
    ) => {
      state.loadingStatus = action.payload;
    },
    setSalesAccountUUID: (
      state,
      action: PayloadAction<IAuthState["salesAccountUUID"]>
    ) => {
      state.salesAccountUUID = action.payload;
    },
    setFinancialCompany: (
      state,
      action: PayloadAction<IAuthState["financialCompany"]>
    ) => {
      state.financialCompany = action.payload;
    },
    setSalesAccountID: (
      state,
      action: PayloadAction<IAuthState["salesAccountID"]>
    ) => {
      state.salesAccountID = action.payload;
    },
    setAlertType: (state, action: PayloadAction<IAuthState["alertType"]>) => {
      state.alertType = action.payload;
    },
    setUserColumns: (
      state,
      action: PayloadAction<IAuthState["userColumns"]>
    ) => {
      state.userColumns = action.payload;
    },
  },
});

export const {
  setUser,
  setSalesAccountUUID,
  setSalesAccountID,
  setLoadingStatus,
  setFinancialCompany,
  setUserColumns,
  setAlertType,
} = authSlice.actions;

export const changeSalesAccountThunk = (
  user,
  accessToken,
  salesAccountID
): AppThunk => {
  return async (dispatch) => {
    await userSalesAccountSwitch({ salesAccountID });
    await dispatch(loginThunk(user, accessToken));
  };
};

export const loginThunk = (user, accessToken): AppThunk => {
  return async (dispatch) => {
    await dispatch(fetchCurrentUserThunk());
    await dispatch(setUser({ user, accessToken }));
  };
};

export const fetchCurrentUserThunk = (): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoadingStatus("LOADING"));

    const { data, error } = await fetchMe();
    if (error) {
      dispatch(setLoadingStatus("FAILED"));
    } else {
      dispatch(setLoadingStatus("SUCCESS"));
      await dispatch(setFinancialCompany(data.financialCompany));
      await dispatch(setSalesAccountUUID(data.salesAccountUuid));
      await dispatch(setSalesAccountID(data.salesAccountId));
      await dispatch(setAlertType(data.alertType));
      await dispatch(
        setUserColumns(
          data.columns
            ? data.columns
            : {
                shipmentsTable: {
                  errors: true,
                  carrier: true,
                  service: true,
                  createdBy: true,
                  createdDate: true,
                  trackingCodes: true,
                  trackingStatus: true,
                  customerReference: true,
                  customerReference2: false,
                  toAddressFirstName: true,
                  fromAddressFirstName: true,
                  shouldPickUp: false,
                  orderNumber: false,
                  upsPickupPrnNumber: false,
                  sku: false,
                  description: false,
                  packageQuantity: false,
                  integrationStoreName: false,
                },
              }
        )
      );
    }
  };
};

export const updateUserColumnThunk = (columns, table): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { userColumns },
    } = getState() as RootState;

    const noHiddenColumns = { ...userColumns[table] };
    Object.keys(noHiddenColumns).forEach((v) => (noHiddenColumns[v] = true));

    const updatedColumns = {
      ...userColumns,
      [table]: columns.length
        ? {
            ...noHiddenColumns,
            ...columns.reduce((obj, key) => {
              obj[key] = false;
              return obj;
            }, {}),
          }
        : { ...noHiddenColumns },
    };
    await dispatch(setUserColumns(updatedColumns));
    await updateUserColumns(updatedColumns);
  };
};
