import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  addPackage,
  onTouchItemField,
  setErrors,
  setPackages,
  setItems,
} from "store/createShipmentSlice/index.slice";
import { CreateShipmentValidator } from "store/createShipmentSlice/validators/CreateShipmentsSlice.validator";
import { openModal } from "store/modalSlice";
import { MAX_ENVELOPPE_WEIGHT_GRAMS } from "constants/misc";
import { useEffect } from "react";
import {
  convertKilosToGrams,
  convertValueToDatabase,
} from "utils/convertGramsToKilos";
import { PackageTypes } from "enum/package-types.enum";
import { DimensionUnits } from "interfaces/dimension-units.type";
import { convertInchesToCm } from "utils/convertInchesToCm";
import { WeightUnits } from "interfaces/weight-units.type";
import { convertPoundsToKilos } from "utils/convertPoundsToKilos";
import { PackageRow } from "./CreateShipmentFormPackagesRow";
import { CreateShipmentFormPackagesAddRow } from "./CreateShipmentFormPackagesAddRow";
import { CreateShipmentFormPackagesTotals } from "./CreateShipmentFormPackagesTotals";
import {
  checkFreightWarningThresholdMet,
  checkMaxGirthMet,
  checkMaxHeightMet,
  checkMaxPackageWeightMet,
} from "./utils/CreateShipmentFormPackages.utils";
import { CreateShipmentFormPackagesFreightWarning } from "./CreateShipmentFormPackagesFreightWarning";
import { Panel } from "components/atoms/Panel";

export const CreateShipmentPackages = ({
  showContents,
}: {
  showContents: boolean;
}) => {
  const dispatch = useDispatch();

  const createShipment = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });

  const { countries } = useSelector((state: RootState) => {
    return state.countriesSlice;
  });

  const { values, touched, errors } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  useEffect(() => {
    const { errors: packageErrors } = new CreateShipmentValidator({
      countries,
    }).validatePackages(values.items, values.contents);

    dispatch(
      setErrors({
        ...errors,
        items: packageErrors,
      })
    );
  }, [touched, countries]);

  const handleRemovePackage = (number: number) => {
    dispatch(
      setPackages(values.items.filter((item, index) => index !== number))
    );
    dispatch(
      setItems(
        values.contents.filter((content) => content.packageId !== number)
      )
    );
  };

  const handleTouchPackageField = (
    number: number,
    key: "length" | "type" | "width" | "height" | "weight" | "quantity"
  ) => {
    dispatch(
      onTouchItemField({
        id: number,
        key,
      })
    );
  };

  const handleChangePackageQuantity = (number, value) => {
    const p = values.items[number];
    const clone = [...values.items];
    if (p) clone[number] = { ...p, quantity: value };
    dispatch(setPackages(clone));
  };

  const handleChangePackageHeight = (number, value) => {
    const p = values.items[number];
    const clone = [...values.items];

    const heightInCm = convertValueToDatabase(
      settings?.dimensionsUnit === DimensionUnits.inch
        ? convertInchesToCm(value)
        : value
    );

    if (
      checkMaxHeightMet({ ...p, height: heightInCm }) ||
      checkMaxGirthMet({ ...p, height: heightInCm })
    ) {
      alert("Max girth or height met");
      if (p) clone[number] = { ...p, height: heightInCm };
      dispatch(setPackages(clone));
    } else {
      if (p) clone[number] = { ...p, height: heightInCm };
      dispatch(setPackages(clone));
    }
  };

  const handleChangePackageWidth = (number, value) => {
    const p = values.items[number];
    const clone = [...values.items];

    const widthInCm = convertValueToDatabase(
      settings?.dimensionsUnit === DimensionUnits.inch
        ? convertInchesToCm(value)
        : value
    );

    if (checkMaxGirthMet({ ...p, width: widthInCm })) {
      alert("Max girth or height met");
      if (p) clone[number] = { ...p, width: widthInCm };
      dispatch(setPackages(clone));
    } else {
      if (p) clone[number] = { ...p, width: widthInCm };
      dispatch(setPackages(clone));
    }
  };

  const handleChangePackageWeight = (number, value) => {
    const p = values.items[number];
    const clone = [...values.items].map((item) => {
      return { ...item };
    });

    const weightInGrams = convertValueToDatabase(
      settings?.weightUnit === WeightUnits.pound
        ? convertKilosToGrams(convertPoundsToKilos(value))
        : convertKilosToGrams(value)
    );

    if (p) clone[number] = { ...p, weight: weightInGrams };

    if (
      p.type === PackageTypes.Envelope &&
      weightInGrams >= MAX_ENVELOPPE_WEIGHT_GRAMS
    ) {
      clone.map((item) => {
        item.type = PackageTypes.Pak;
      });
    }

    if (checkMaxPackageWeightMet(weightInGrams)) {
      if (!settings?.divideTotalPackageWeightByQuantityInUi) {
        dispatch(openModal({ name: "createQuotePalletSwitchWarningModal" }));
      } else {
        dispatch(setPackages(clone));
      }
    } else {
      dispatch(setPackages(clone));
    }
  };

  const handleChangePackageLength = (number: number, value: number) => {
    const p = values.items[number];

    const clone = [...values.items];

    const lengthInCm = convertValueToDatabase(
      settings?.dimensionsUnit === DimensionUnits.inch
        ? convertInchesToCm(value)
        : value
    );

    if (checkMaxGirthMet({ ...p, length: lengthInCm })) {
      alert("Max girth met");
      if (p) clone[number] = { ...p, length: lengthInCm };
      dispatch(setPackages(clone));
    } else {
      if (p) clone[number] = { ...p, length: lengthInCm };
      dispatch(setPackages(clone));
    }
  };

  const handleChangePackageType = (value: PackageTypes) => {
    const cloned = [...values.items].map((item) => {
      return { ...item };
    });

    cloned.map((item) => {
      item.type = value;
    });

    dispatch(setPackages(cloned));
  };

  const handleAddPackage = () => {
    dispatch(addPackage(settings));
  };

  const handleChangeClass = (number, value) => {
    const p = values.items[number];
    const clone = [...values.items].map((item) => {
      return { ...item };
    });
    if (p) clone[number] = { ...p, class: value };

    dispatch(setPackages(clone));
  };

  const handleChangePackingGroup = (number, value) => {
    const p = values.items[number];
    const clone = [...values.items].map((item) => {
      return { ...item };
    });
    if (p) clone[number] = { ...p, packingGroup: value };

    dispatch(setPackages(clone));
  };

  const handleChangeDGNetWeight = (number, value) => {
    const p = values.items[number];
    const clone = [...values.items].map((item) => {
      return { ...item };
    });

    const weightInGrams = convertValueToDatabase(
      settings?.weightUnit === WeightUnits.pound
        ? convertKilosToGrams(convertPoundsToKilos(value))
        : convertKilosToGrams(value)
    );

    if (p) clone[number] = { ...p, dgNetWeight: weightInGrams };

    dispatch(setPackages(clone));
  };

  const handleChangeDryIceWeight = (number, value) => {
    const p = values.items[number];
    const clone = [...values.items].map((item) => {
      return { ...item };
    });

    const weightInGrams = convertValueToDatabase(
      settings?.weightUnit === WeightUnits.pound
        ? convertKilosToGrams(convertPoundsToKilos(value))
        : convertKilosToGrams(value)
    );

    if (p) clone[number] = { ...p, dryIceWeight: weightInGrams };

    dispatch(setPackages(clone));
  };

  const handleChangeLithiumCategory = (number, value) => {
    const p = values.items[number];
    const clone = [...values.items].map((item) => {
      return { ...item };
    });
    if (p) clone[number] = { ...p, lithiumCategory: value };

    dispatch(setPackages(clone));
  };

  const handleChangeDGUNNumber = (number, value) => {
    const p = values.items[number];
    const clone = [...values.items].map((item) => {
      return { ...item };
    });
    if (p) clone[number] = { ...p, dgunNumber: value };

    dispatch(setPackages(clone));
  };

  const handleChangeDgPackage = (number) => {
    const p = values.items[number];
    const clone = [...values.items].map((item) => {
      return { ...item };
    });

    if (p)
      clone[number] = {
        ...p,
        dgPackage: !p.dgPackage,
        lithiumCategory: "",
        dryIceWeight: 0,
        dgunNumber: 0,
        class: "",
        packingGroup: "",
        dgNetWeight: 0,
      };
    dispatch(setPackages(clone));
  };

  return (
    <>
      {/* {checkFreightWarningThresholdMet(createShipment.values.items) && (
        <div className="mb-4">
          <CreateShipmentFormPackagesFreightWarning />
        </div>
      )} */}

      {values.items.map((item, index) => {
        return (
          <Panel className="mb-4" key={index}>
            <PackageRow
              showContents={showContents}
              number={index}
              item={item}
              touched={touched.items[index]}
              errors={errors.items[index]}
              showErrors={createShipment.submitCount > 0}
              onTouchQuantity={() => handleTouchPackageField(index, "quantity")}
              onTouchWidth={() => handleTouchPackageField(index, "width")}
              onTouchHeight={() => handleTouchPackageField(index, "height")}
              onTouchLength={() => handleTouchPackageField(index, "length")}
              onTouchWeight={() => handleTouchPackageField(index, "weight")}
              onChangeQuantity={(value) =>
                handleChangePackageQuantity(index, value)
              }
              onChangeWidth={(value) => handleChangePackageWidth(index, value)}
              onChangeHeight={(value) =>
                handleChangePackageHeight(index, value)
              }
              onChangeLength={(value) => {
                handleChangePackageLength(index, value);
              }}
              onChangeWeight={(value) =>
                handleChangePackageWeight(index, value)
              }
              onChangeType={handleChangePackageType}
              onRemove={handleRemovePackage}
              onChangeClass={(value) => handleChangeClass(index, value)}
              onChangePackingGroup={(value) =>
                handleChangePackingGroup(index, value)
              }
              onChangeDGNetWeight={(value) =>
                handleChangeDGNetWeight(index, value)
              }
              onChangeDryIceWeight={(value) =>
                handleChangeDryIceWeight(index, value)
              }
              onChangeLithiumCategory={(value) =>
                handleChangeLithiumCategory(index, value)
              }
              onChangeDGUNNumber={(value) =>
                handleChangeDGUNNumber(index, value)
              }
              onChangeDGPackage={() => handleChangeDgPackage(index)}
            />
          </Panel>
        );
      })}
      <div className="mb-4">
        <CreateShipmentFormPackagesAddRow handleAddPackage={handleAddPackage} />
      </div>

      <CreateShipmentFormPackagesTotals />
    </>
  );
};
