import { useEffect, useRef } from "react";
import cx from "classnames";
import { AnimatePresence, motion, Variants } from "framer-motion";
import ScrollLock from "react-scrolllock";
import { ModalContent } from "./ModalContent";
import { ModalContext } from "./ModalContext";
import styles from "./Modal.module.scss";
import { useHotkeys } from "react-hotkeys-hook";
import { KeyboardKeys } from "enum/keyboardkeys.enum";
import { tabbable } from "tabbable";
import { FocusLock } from "../FocusLock";

export const Sizes = {
  xs: 300,
  sm: 500,
  md: 700,
  lg: 800,
  xl: 900,
  "2xl": 1000,
  "3xl": 1100,
};

export type ModalSizes =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "full";

export interface IModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  isCentered?: boolean;
  isOnTop?: boolean;
  closeOnOverlayClick?: boolean;
  size?: ModalSizes;
  trapFocus?: boolean;
  isFullWidth?: boolean;
  scrollMode?: "inner" | "outside";
  testId?: string;

  handleClose?(): void;
}

const animation: Variants = {
  visible: { opacity: 1, scale: 1, y: -30 },
  hidden: { opacity: 0, scale: 0.99, y: 0 },
};

export const Modal = ({
  children,
  isOpen = false,
  handleClose = () => undefined,
  size = "sm",
  closeOnOverlayClick = true,
  trapFocus = true,
  isFullWidth,
  isCentered,
  testId,
  isOnTop = true,
  scrollMode = "outside",
}: IModalProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useHotkeys(
    KeyboardKeys.ESCAPE,
    () => {
      handleClose();
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  useEffect(() => {
    if (contentRef && contentRef.current) {
      const elements = tabbable(contentRef.current);
      elements.length > 0 && elements[0].focus();
    }
  }, [isOpen, contentRef]);

  return (
    <FocusLock disabled={!isOpen} autoFocus={false} returnFocus={true}>
      <ScrollLock isActive={isOpen} />
      <ModalContext
        value={{
          handleClose,
          trapFocus,
          size,
          isCentered,
          isFullWidth,
          scrollMode,
          isOpen,
          isOnTop,
        }}
      >
        <AnimatePresence>
          {isOpen && (
            <motion.div
              key={1}
              variants={animation}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ duration: 0.2, stiffness: 30 }}
              className={cx(styles.Modal__Container, "elevation--1", {
                [styles["Modal__Container--isFullWidth"]]: isFullWidth,
              })}
              {...(testId && { "data-testid": testId })}
            >
              <ModalContent innerRef={contentRef}>{children}</ModalContent>
            </motion.div>
          )}
        </AnimatePresence>
      </ModalContext>
    </FocusLock>
  );
};
