import { IFetchResponsePaginated, IFetchResponseSingle } from "services/api";
import { fetchAll, fetchSingle } from "services/get";
import { FetchShipmentProfilesResponseDto } from "./dtos/fetch-shipment-profiles.response.dto";
import { post } from "services/post";
import { apiPaths, customerApiBase } from "enum/paths.enum";
import { CreateShipmentProfileRequestDto } from "./dtos/create-shipment-profile.request.dto";
import { IShipmentProfile } from "models/shipmentProfile/shipmentProfile.model";
import { del } from "services/del";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { IBulkShipment } from "models/bulkShipment/bulkShipment.model";
import { patch } from "services/patch";
import { UpdateShipmentProfileRequestDto } from "./dtos/update-shipment-profile-request.dto";

export const fetchBulkShipmentProfiles = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<FetchShipmentProfilesResponseDto> => {
  return await fetchAll(
    customerApiBase + "/" + salesAccountUUID + apiPaths.shipmentProfiles,
    null
  );
};

export const fetchBulkShipmentProfile = async (
  salesAccountUUID: ISalesAccount["uuid"],
  bulkShipmentProfileUUID: IBulkShipment["uuid"]
): Promise<IFetchResponseSingle<IShipmentProfile>> => {
  return await fetchSingle(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipmentProfiles +
      "/" +
      bulkShipmentProfileUUID
  );
};

export const createBulkShipmentProfile = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: CreateShipmentProfileRequestDto
): Promise<IFetchResponseSingle<FetchShipmentProfilesResponseDto>> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.shipmentProfiles,
    values
  );
};

export const updateBulkShipmentProfile = async (
  salesAccountUUID: ISalesAccount["uuid"],
  shipmentImportProfileUUID: IShipmentProfile["uuid"],
  values: UpdateShipmentProfileRequestDto
): Promise<IFetchResponseSingle<FetchShipmentProfilesResponseDto>> => {
  return await patch(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipmentProfiles +
      "/" +
      shipmentImportProfileUUID,
    values
  );
};

export const deleteBulkShipmentProfile = async (
  salesAccountUUID: ISalesAccount["uuid"],
  shipmentImportProfileUUID: IShipmentProfile["uuid"]
): Promise<IFetchResponseSingle<FetchShipmentProfilesResponseDto>> => {
  return await del(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.shipmentProfiles +
      "/" +
      shipmentImportProfileUUID
  );
};
