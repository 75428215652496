export type IThemeRadiiScale = "sm" | "md" | "lg";

export type IThemeRadii = {
  [key in IThemeRadiiScale]: string;
};

export const ThemeRadii: IThemeRadii = {
  sm: "5px",
  md: "8px",
  lg: "10px",
};
