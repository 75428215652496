import { Button } from "components/atoms/Button";
import { LabelJobStatus } from "enum/label-job-status.enum";
import { ILabelJob } from "models/labelJob/labelJob.model";
import { countTotalLabelJobLabels } from "utils/countTotalLabelJobLabels";
import { getPackagesWithErrors } from "utils/getPackagesWithErrors";

export const LabelJobCardFooter = ({
  item,
  handleDownloadClick,
}: {
  item: ILabelJob;
  handleDownloadClick(item: ILabelJob): void;
}) => {
  const errorPackages = getPackagesWithErrors(item);

  const totalLabels = countTotalLabelJobLabels(item);

  return (
    <Button
      isLoading={item.status === LabelJobStatus.PENDING}
      isBlock
      isDisabled={item.status === LabelJobStatus.ERROR}
      colorScheme="primary"
      size="lg"
      onClick={() => handleDownloadClick(item)}
    >
      {item.status === LabelJobStatus.ERROR
        ? "Labels failed"
        : `Download ${totalLabels - errorPackages.length} labels`}
    </Button>
  );
};
