import { Stack } from "components/atoms/Stack";
import { Panel } from "components/atoms/Panel";
import { Divider } from "components/atoms/Divider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setShowMore } from "store/shipmentsSlice/index.slice";
import { PageSection } from "../PageSection";
import { useTheme } from "styled-components";

export interface Props {
  trackingCode: string;
}

export const ShipmentTracking = ({ trackingCode }: Props) => {
  const { showMore, currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });
  const dispatch = useDispatch();
  const theme = useTheme();
  const barcodeTracking =
    currentShipment.packageTrackingData.validBarcodes.find(
      (element) => element.barcode === trackingCode
    );

  const trackingEvents = barcodeTracking?.events
    .map((element) => ({
      trackingEvent: element.trackingEvent,
      statusTime: Date.parse(element.statusTime),
    }))
    .sort((a, b) => b.statusTime - a.statusTime)
    .map((element) => {
      return {
        ...element,
        statusTime: new Date(element.statusTime).toISOString(),
      };
    });

  return (
    <PageSection>
      <Panel className="text-sm">
        {trackingEvents ? (
          <>
            {showMore ? (
              <Stack divider={<Divider />}>
                {trackingEvents.map((element, index) => {
                  const StatusTime = element.statusTime
                    .toString()
                    .replace(":00.000Z", "");
                  const Date = StatusTime.split("T")[0];
                  const Time = StatusTime.split("T")[1];
                  let trackingEvent = element.trackingEvent;
                  if (trackingEvent) {
                    trackingEvent = trackingEvent?.split("_")?.join(" ");
                    trackingEvent =
                      trackingEvent?.charAt(0)?.toUpperCase() +
                      trackingEvent?.slice(1);
                  }
                  return (
                    <div
                      key={index}
                      className="flex flex-row w-full justify-between items-center px-5 py-4"
                    >
                      <div className="flex">{trackingEvent}</div>{" "}
                      <div className="flex">
                        <div className="flex">{Time}</div>{" "}
                        <div className="flex ml-4">{Date}</div>
                      </div>
                    </div>
                  );
                })}
              </Stack>
            ) : (
              <Stack divider={<Divider />}>
                <div
                  key={0}
                  className="flex flex-row w-full justify-between items-center px-5 py-4"
                >
                  <div className="flex">{trackingEvents[0].trackingEvent}</div>{" "}
                  <div className="flex">
                    <div className="flex">
                      {
                        trackingEvents[0].statusTime
                          .toString()
                          .replace(":00.000Z", "")
                          .split("T")[1]
                      }
                    </div>{" "}
                    <div className="flex ml-4">
                      {
                        trackingEvents[0].statusTime
                          .toString()
                          .replace(":00.000Z", "")
                          .split("T")[0]
                      }
                    </div>
                  </div>
                </div>
              </Stack>
            )}
          </>
        ) : (
          <Stack divider={<Divider />}>
            <div className="flex items-center px-5 py-4">
              No Tracking information can be found
            </div>
          </Stack>
        )}
      </Panel>
      {trackingEvents?.length > 1 ? (
        <div
          className="flex items-center justify-center px-5 pt-4"
          onClick={() => {
            dispatch(setShowMore(!showMore));
          }}
        >
          <div
            className="hover:underline cursor-pointer"
            style={{
              color: theme.colors["blue"][10],
            }}
          >
            Show {showMore ? "Less" : "More"}
          </div>
        </div>
      ) : null}
    </PageSection>
  );
};
