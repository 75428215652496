import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_META } from "constants/misc";
import { Meta, Query, SortDirection } from "interfaces/api.interface";
import { IAddress } from "models/address/address.model";
import {
  deleteAddress,
  fetchAddresses,
  setDefaultAddress,
  removeDefaultAddress,
  getDefaultAddress,
} from "services/api/addresses";
import { FetchAddressesResponseDto } from "services/api/addresses/dtos/fetch-addresses.response.dto";
import { AppThunk, RootState } from "store";
import { isModalClosed } from "store/modalSlice/modalSlice.actions";
import { modals } from "store/modalSlice/modalSlice.types";
import toast from "react-hot-toast";
import { GENERIC_TOAST_DELETED_MESSAGE } from "constants/strings";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { setDefaultFromAddress } from "store/createShipmentSlice/index.slice";

export interface IAddressesSlice {
  loadingStatus: ApiLoadingStatus;
  editingAddress: IAddress | null;
  query: Query<IAddress, { search?: string }>;
  items: IAddress[];
  meta: Meta;
}

export const AddressSliceInitialState: IAddressesSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  editingAddress: null,
  query: {
    filters: { search: "" },
    sort: {
      createdDate: SortDirection.DESC,
    },
  },
  meta: {
    ...DEFAULT_META,
  },
  items: [],
};

export const addressSlice = createSlice({
  name: "addressSlice",
  initialState: AddressSliceInitialState,
  reducers: {
    setData: (state, action: PayloadAction<FetchAddressesResponseDto>) => {
      state.items = action.payload.data || AddressSliceInitialState.items;

      state.meta = action.payload.meta || AddressSliceInitialState.meta;

      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },
    setQuerySort: (
      state,
      action: PayloadAction<IAddressesSlice["query"]["sort"]>
    ) => {
      state.query.sort = action.payload;
    },
    setQuerySearch: (
      state,
      action: PayloadAction<IAddressesSlice["query"]["filters"]["search"]>
    ) => {
      state.query.filters.search = action.payload;
      state.query.offset = 0;
    },
    setStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
    setEditingAddress: (state, action: PayloadAction<IAddress | null>) => {
      state.editingAddress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isModalClosed,
      (state, action: { payload: { modalName: modals } }) => {
        if (
          action.payload.modalName === "createReceiverAddress" ||
          action.payload.modalName === "createCompanyAddress"
        ) {
          state.editingAddress = null;
        }
      }
    );
  },
});

export const {
  setData,
  setStatus,
  setEditingAddress,
  setQuerySort,
  setQuerySearch,
} = addressSlice.actions;

export const fetchDefaultAddressThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const { data } = await getDefaultAddress(salesAccountUUID);
      if (data) {
        dispatch(setDefaultFromAddress(data));
      }
    }
  };
};

export const fetchAddressesThunk = (query: Query): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));
    const {
      authSlice: { salesAccountUUID },
      addressSlice,
      tableSlice: { numberOfRowsSelected },
    } = getState() as RootState;

    const {
      filters: { search },
    } = addressSlice.query;
    if (salesAccountUUID) {
      const { data, error, meta } = await fetchAddresses(salesAccountUUID, {
        ...query,
        filters: { ...query.filters, ...(search && { search }) },
        limit: numberOfRowsSelected.value,
      });

      if (!error) {
        dispatch(setData({ data, meta }));
      }
    }
  };
};

export const deleteAddressThunk = (addressUuid: IAddress["uuid"]): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      await deleteAddress(salesAccountUUID, addressUuid);

      dispatch(fetchAddressesThunk({ offset: 0 }));

      toast.success(GENERIC_TOAST_DELETED_MESSAGE);
    }
  };
};

export const setAsFromAddressDefaultForSingleShipmentThunk = (
  addressUuid: IAddress["uuid"]
) => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const response = await setDefaultAddress(salesAccountUUID, addressUuid);
      dispatch(fetchAddressesThunk({ offset: 0 }));

      if (response?.statusCode === 200) {
        toast.success("Default address set successfully");
      } else {
        toast.error("Failed to set default address");
      }
    }
  };
};

export const removeAddressDefaultForSingleShipmentThunk = (
  addressUuid: IAddress["uuid"]
) => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const response = await removeDefaultAddress(
        salesAccountUUID,
        addressUuid
      );

      dispatch(fetchAddressesThunk({ offset: 0 }));

      if (response?.statusCode === 200) {
        toast.success("Removed default address successfully");
      } else {
        toast.error("Failed to remove default address");
      }
    }
  };
};

export const searchAddressThunk = (query: string): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQuerySearch(query));

    dispatch(fetchAddressesThunk({ offset: 0 }));
  };
};
