import { DEFAULT_FROM_COUNTRY_ISO_CODE } from "constants/misc";
import { ShipmentDirections } from "enum/shipment-directions.enum";

export const getShipmentDirection = (
  fromCountryIsoCode: string,
  toCountryIsoCode: string
): ShipmentDirections => {
  return fromCountryIsoCode === toCountryIsoCode
    ? ShipmentDirections.DOMESTIC
    : fromCountryIsoCode === DEFAULT_FROM_COUNTRY_ISO_CODE &&
      toCountryIsoCode !== fromCountryIsoCode
    ? ShipmentDirections.EXPORT
    : ShipmentDirections.IMPORT;
};
