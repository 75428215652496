import { Roles } from "enum/roles.enum";
import { IUser } from "models/user/user.model";

export const isUserAdminForSalesAccounts = (user: IUser): boolean => {
  return (
    (user &&
      user["https://connexx.com/roles"]?.includes(
        Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS
      )) ||
    false
  );
};

export const isUserSalesPeopleForSalesAccounts = (user: IUser): boolean => {
  return (
    (user &&
      user["https://connexx.com/roles"]?.includes(
        Roles.ITD_SALES_PEOPLE_FOR_SALES_ACCOUNTS
      )) ||
    false
  );
};
