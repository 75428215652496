import { CreateShipmentContentsErrors } from "store/createShipmentSlice/createShipmentSlice.types";

export const isContentsRowValid = (
  errors: CreateShipmentContentsErrors
): boolean => {
  let isValid = true;

  errors &&
    Object.keys(errors).map((error) => {
      if (errors[error]) isValid = false;
    });

  return isValid;
};
