import { ILabelJob } from "models/labelJob/labelJob.model";
import { IShipmentPackage } from "models/shipmentPackage/shipmentPackage.model";

export const getPackagesWithErrors = (item: ILabelJob): IShipmentPackage[] => {
  const errorPackages: IShipmentPackage[] = [];

  item.shipments.map((shipment) => {
    errorPackages.push(
      ...shipment.packages.filter(
        (p) => p.errors !== null && p.errors.length > 0
      )
    );
  });

  return errorPackages;
};
