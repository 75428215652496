import cx from "classnames";
import { createElement, ReactNode } from "react";
import { IThemeFontSizeScale } from "theme/theme-font-sizes.theme";

export type IHeading = {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  size?: IThemeFontSizeScale;
  weight?: "600" | "700" | "800";
  children: ReactNode;
  className?: string;
};

export const Heading = ({
  children,
  as = "h1",
  weight = "600",
  size = "xl",
  className,
}: IHeading) => {
  return createElement(
    as,
    {
      className: cx("leading-none", className),
      style: {
        fontSize: `var(--fontSizes${size})`,
        fontWeight: weight,
      },
    },
    children
  );
};
