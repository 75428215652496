import { Button } from "components/atoms/Button";
import { Checkbox } from "components/atoms/Checkbox";
import { Select } from "components/atoms/Select";
import {
  generateSelectOptions,
  ISelectOption,
} from "components/atoms/Select/UseSelect";
import { Stack } from "components/atoms/Stack";
import { FormControl } from "components/molecules/FormControl";
import { Dropzone } from "components/organisms/DropZone";
import { sortBy } from "lodash";
import { IShipmentProfile } from "models/shipmentProfile/shipmentProfile.model";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store";
import Papa from "papaparse";
import {
  setData,
  setFileContainsHeaderRow,
  setFileName,
  setProfileUUID,
} from "store/createBulkShipmentsSlice/index.slice";
import { openModal } from "store/modalSlice";
import { CreateBulkShipmentTestIds } from "../../CreateBulkShipmentTestIds.enum";

export type ICreateBulkShipmentStep1FormPanelProps = {
  isSubmitting: boolean;
  handleSubmit(): void;
};

export const CreateBulkShipmentStep1FormPanel = (
  props: ICreateBulkShipmentStep1FormPanelProps
) => {
  const [files, setFiles] = useState<File[]>([]);

  const { isSubmitting, handleSubmit } = props;

  const dispatch = useAppDispatch();

  const { selectedProfileUUID, fileContainsHeaderRow } = useSelector(
    (state: RootState) => {
      return state.createBulkShipmentsSlice;
    }
  );

  const { profiles } = useSelector((state: RootState) => {
    return state.bulkShipmentProfilesSlice;
  });

  const options = sortBy(
    generateSelectOptions({
      data: profiles,
      labelKey: "profileName",
      valueKey: "id",
    }),
    (item) => {
      return item.profileName.toLowerCase();
    }
  );

  const handleParseFile = () => {
    if (files[0]) {
      dispatch(setFileName(files[0].name));

      Papa.parse(files[0], {
        header: false, // Always set to false as we use column indexes to get values
        skipEmptyLines: true,
        complete: (results) => {
          const data = fileContainsHeaderRow
            ? results.data.slice(1)
            : results.data;
          const trimmedData = data.map((element: string[]) => {
            const value = element.map((string) => {
              return string.trim();
            });
            return value;
          });
          dispatch(
            setData({
              ...results,
              data: trimmedData,
            })
          );
        },
      });
    }
  };

  const handleSetFileContainsHeaderRow = () => {
    dispatch(setFileContainsHeaderRow(!fileContainsHeaderRow));
  };

  const handleSetProfile = (uuid: IShipmentProfile["uuid"]) => {
    dispatch(setProfileUUID(uuid));
  };

  const handleSetFiles = (files: File[]) => {
    setFiles(files);
  };

  const handleOpenCreateProfileModal = () => {
    dispatch(openModal({ name: "createBulkShipmentProfile" }));
  };

  useEffect(() => {
    handleParseFile();
  }, [files]);

  useEffect(() => {
    handleParseFile();
  }, [fileContainsHeaderRow]);

  const isSubmitButtonDisabled = files.length === 0 || !selectedProfileUUID;

  return (
    <div
      data-testid={
        CreateBulkShipmentTestIds.CREATE_BULK_SHIPMENT_STEP_1_FORM_PANEL
      }
    >
      <Stack spacing={4}>
        <FormControl label="Select profile">
          <Select<ISelectOption<IShipmentProfile>>
            value={
              options.find((option) => {
                return option.uuid === selectedProfileUUID;
              }) || null
            }
            placeholder="Select profile"
            onChange={(option) => {
              handleSetProfile(option.uuid);
            }}
            options={options}
          />
          <div
            data-testid={
              CreateBulkShipmentTestIds.CREATE_BULK_SHIPMENT_STEP_1_CREATE_PROFILE_LINK
            }
            className="inline-block mt-3 text-sm leading-none cursor-pointer text-muted hover:underline"
            onClick={handleOpenCreateProfileModal}
          >
            Create new profile
          </div>
        </FormControl>

        <FormControl label="Select shipments file">
          <Dropzone
            onChange={handleSetFiles}
            files={files}
            testId={
              CreateBulkShipmentTestIds.CREATE_BULK_SHIPMENT_STEP_1_FILE_DROPZONE
            }
            fileType={"csv"}
          />
        </FormControl>

        <div>
          <Checkbox
            testId={
              CreateBulkShipmentTestIds.CREATE_BULK_SHIPMENT_STEP_1_HEADER_ROW_CHECKBOX
            }
            checked={fileContainsHeaderRow}
            onCheckedChange={handleSetFileContainsHeaderRow}
          >
            Does this file have a header row?
          </Checkbox>
        </div>

        <Button
          testId={
            CreateBulkShipmentTestIds.CREATE_BULK_SHIPMENT_STEP_1_SUBMIT_BUTTON
          }
          size="lg"
          isDisabled={isSubmitButtonDisabled}
          isLoading={isSubmitting}
          colorScheme="primary"
          isBlock
          onClick={handleSubmit}
        >
          Next
        </Button>
      </Stack>
    </div>
  );
};
