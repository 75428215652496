import { forwardRef, HTMLAttributes, ReactNode, Ref } from "react";

type IPanelTitle = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const PanelTitle = forwardRef(
  (props: IPanelTitle, ref: Ref<HTMLDivElement>) => {
    const { children, ...rest } = props;

    return (
      <h2 className="font-medium" {...rest} ref={ref}>
        {children}
      </h2>
    );
  }
);

PanelTitle.displayName = "PanelTitle";
