import { ApiLoadingStatus } from "enum/api-loading-status.enum";

export const getAppLoadingState = ({
  isAdmin,
  virtualWarehousesLoadingStatus,
  customersLoadingStatus,
  usersLoadingStatus,
  settingsLoadingStatus,
  bulkShipmentProfilesLoadingStatus,
}: {
  isAdmin: boolean;
  virtualWarehousesLoadingStatus: ApiLoadingStatus;
  customersLoadingStatus: ApiLoadingStatus;
  usersLoadingStatus: ApiLoadingStatus;
  settingsLoadingStatus: ApiLoadingStatus;
  bulkShipmentProfilesLoadingStatus: ApiLoadingStatus;
}) => {
  const requiredData = isAdmin
    ? [usersLoadingStatus, customersLoadingStatus]
    : [
        settingsLoadingStatus,
        bulkShipmentProfilesLoadingStatus,
        virtualWarehousesLoadingStatus,
      ];

  return requiredData.filter((status) => status === ApiLoadingStatus.SUCCESS)
    .length === requiredData.length
    ? ApiLoadingStatus.SUCCESS
    : requiredData.filter((status) => status === ApiLoadingStatus.FAILED)
        .length > 0
    ? ApiLoadingStatus.FAILED
    : ApiLoadingStatus.LOADING;
};
