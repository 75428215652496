import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { ISettingsSlice } from "./settingsSlice.interface";

export const SettingsSliceInitialState: ISettingsSlice = {
  loadingBaseStatus: ApiLoadingStatus.IDLE,
  salesAccount: null,
  settings: null,
  loadingCompanyAddressesStatus: ApiLoadingStatus.IDLE,
  companyAddresses: [],
  loadingCompanyContactsStatus: ApiLoadingStatus.IDLE,
  companyContacts: [],
};
