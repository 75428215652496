import { useCallback, useState, useEffect, useRef } from "react";
import { useControllableState } from "hooks/useControllable";
import { useDisclosure } from "hooks/useDisclosure";
import { InputSizes } from "components/atoms/Input";
import { usePopper } from "react-popper";
import { isArray } from "lodash";

export type ISelectOption<T, K = string | number> = T & {
  label: string;
  value: K;
};

export const generateSelectOptions = <T,>({
  data,
  labelKey,
  valueKey,
}: {
  data: T[];
  labelKey: keyof T;
  valueKey: keyof T;
}): ISelectOption<T>[] => {
  const options: ISelectOption<T>[] = [];

  isArray(data) &&
    data &&
    data.map((obj: T) => {
      options.push({
        label: obj[labelKey as string],
        value: obj[valueKey as string],
        ...obj,
      });
    });

  return options;
};

export interface UseSelectProps<T> {
  name?: string;
  testId?: string;
  isNullable?: boolean;
  value?: ISelectOption<T>;
  defaultValue?: ISelectOption<T>;
  dropdownWidth?: number;
  dropdownHeight?: number;
  size?: InputSizes;
  itemHeight?: number; // Added prop
  onSetPopoverElementRef?(ref: HTMLDivElement): void;
  focusGroup?: string;
  hideArrows?: boolean;
  tabIndex?: number;
  options: ISelectOption<T>[];
  placeholder?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  customFilter?(option: ISelectOption<T>, query: string): void;
  disabledFilter?(option: ISelectOption<T>): boolean;
  optionTemplate?(option: ISelectOption<T>): JSX.Element;
  onToggle?(): void;
  closeOnChange?: boolean;
  onChange?: (nextValue: ISelectOption<T> | null) => void;
}

export const useSelect = <T,>(props: UseSelectProps<T>) => {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();

  const {
    onChange: onChangeProp,
    size = "default",
    isInvalid,
    closeOnChange = true,
    name,
    isSearchable = true,
    isNullable,
    dropdownHeight = 300,
    itemHeight = 40, // Default value for itemHeight
    hideArrows,
    tabIndex = 0,
    value: valueProp,
    customFilter,
    isDisabled,
    focusGroup,
    defaultValue,
    disabledFilter,
    dropdownWidth,
    placeholder,
    onSetPopoverElementRef,
    optionTemplate,
    options = [],
    testId,
  } = props;

  const [triggerElement, setTriggerElement] =
    useState<HTMLDivElement | null>(null);

  const optionRefs = useRef([]);

  const [isFiltered, setFiltered] = useState(false);

  const [isFocused, setFocused] = useState(false);

  const [menuElement, setMenuElement] = useState<HTMLDivElement | null>(null);

  const [popoverElement, setPopoverElement] =
    useState<HTMLDivElement | null>(null);

  useEffect(() => {
    onSetPopoverElementRef && onSetPopoverElementRef(popoverElement);
  }, [popoverElement, isOpen]);

  const [cursor, setCursor] = useState(0);

  const { styles: popperPosition } = usePopper(triggerElement, popoverElement, {
    strategy: "absolute",
    placement: "bottom-start",
  });

  const [value, setValue] = useControllableState({
    defaultValue: defaultValue || null,
    value: valueProp,
    onChange: onChangeProp,
  });

  const [query, setQuery] = useState<string>("");

  const [filteredOptions, setFilteredOptions] = useState<ISelectOption<T>[]>(
    []
  );

  const handleClose = () => {
    setFilteredOptions([]);
    setQuery("");
    setCursor(0);
    onClose();
  };

  const onChange = useCallback(
    (value: ISelectOption<T>) => {
      setValue(value);
      closeOnChange && handleClose();
    },
    [setValue]
  );

  const onSearchKeyDown = (query: string) => {
    setQuery(query);
  };

  // const handleOutsideClick = (e) => {
  //   if (isOpen) {
  //     if (popoverElement && !popoverElement.contains(e.target)) {
  //       onClose();
  //     }
  //   }
  // };
  
  useEffect(() => {
    if (query.length > 0) {
      const data = options.filter((option: ISelectOption<T>) => {
        return customFilter
          ? customFilter(option, query)
          : option.label.toLowerCase().startsWith(query.toLowerCase());
      });

      setFiltered(true);
      setFilteredOptions(data);
    } else {
      setFiltered(false);
      setFilteredOptions([]);
    }

    setCursor(0);
  }, [query]);

  return {
    testId,
    onToggle,
    onClose: handleClose,
    onOpen,
    onChange,
    onSearchKeyDown,
    isOpen,
    isFiltered,
    isDisabled,
    isSearchable,
    isInvalid,
    isNullable,
    focusGroup,
    filteredOptions,
    name,
    itemHeight,
    tabIndex,
    query,
    value,
    size,
    dropdownWidth,
    dropdownHeight,
    hideArrows,
    placeholder,
    triggerElement,
    options,
    optionTemplate,
    popperPosition,
    popoverElement,
    isFocused,
    setFocused,
    cursor,
    setCursor,
    optionRefs,
    setPopoverElement,
    setTriggerElement,
    disabledFilter,
    menuElement,
    setMenuElement,
  };
};

export type UseSelectReturn = ReturnType<typeof useSelect>;
