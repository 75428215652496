import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import styled, { useTheme } from "styled-components";
import { HTMLProps, Ref } from "react";
import { Loader } from "../Loader";

export type IButton = {
  innerRef?: Ref<HTMLButtonElement>;
  isBlock?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  dropdownIsDisabled?: boolean;
  variant?: "solid" | "outline" | "ghost";
  type?: "submit" | "button";
  size?: "xs" | "sm" | "default" | "lg";
  colorScheme?: "primary" | "default" | "danger" | "selected";
  hoverColorScheme?: "danger" | "success";
  testId?: string;
  isDownload?: boolean;
  options?: { name: string; onClick: Function }[];
} & Pick<
  HTMLProps<HTMLButtonElement>,
  "id" | "onClick" | "children" | "className"
>;

export const getButtonFontSize = (size: IButton["size"]) => {
  const theme = useTheme();

  switch (size) {
    case "sm":
      return theme.formControlFontSizeSmall;
    case "default":
      return theme.formControlFontSizeSmall;
    case "lg":
      return theme.formControlFontSizeSmall;
  }
};

type state = "hover" | "active" | "disabled" | "default";

export const getButtonBackgroundColor = (
  colorScheme: IButton["colorScheme"],
  state: state,
  variant: IButton["variant"],
  hoverColorScheme?: IButton["hoverColorScheme"]
) => {
  const theme = useTheme();

  switch (colorScheme) {
    case "default":
      switch (variant) {
        case "outline":
          switch (state) {
            default:
              return "transparent";
          }
        case "solid":
          switch (state) {
            case "default":
              return theme.buttonDefaultBackgroundColor;
            case "hover":
              switch (hoverColorScheme) {
                case "danger":
                  return theme.colors.red[10];

                default:
                  return theme.buttonDefaultBackgroundColorHover;
              }
          }
      }

    case "primary":
      return theme.buttonPrimaryBackgroundColor;

    case "selected":
      return theme.colors.green[9];

    case "danger":
      switch (state) {
        case "default":
          return theme.colors.red[10];

        case "hover":
          return theme.colors.red[8];
      }
  }
};

export const getButtonBorderColor = (
  colorScheme: IButton["colorScheme"],
  state: state,
  variant: IButton["variant"],
  hoverColorScheme?: IButton["hoverColorScheme"]
) => {
  const theme = useTheme();

  switch (colorScheme) {
    case "default":
      switch (variant) {
        case "outline":
          switch (state) {
            case "default":
              return theme.buttonDefaultBorderColor;

            case "hover":
              return theme.buttonDefaultBorderColorHover;
          }

        case "solid":
          switch (state) {
            case "default":
              return theme.buttonDefaultBackgroundColor;

            case "hover":
              return theme.buttonDefaultBackgroundColorHover;
          }
      }

    case "primary":
      switch (variant) {
        case "outline":

        case "solid":
          switch (state) {
            case "default":
              return theme.buttonPrimaryBorderColor;

            case "hover":
              return theme.buttonPrimaryBackgroundColor;
          }
      }

    case "danger": {
      return theme.colors.red[10];
    }

    case "selected": {
      return "white";
    }
  }
};

export const getButtonTextColor = (colorScheme: IButton["colorScheme"]) => {
  const theme = useTheme();

  switch (colorScheme) {
    case "default":
      return theme.buttonDefaultTextColor;

    case "primary":
      return "white";

    case "selected":
      return "white";
  }
};

export const ButtonDropdownGroup = ({
  children,
  options,
  isLoading,
  colorScheme,
  isDisabled,
  dropdownIsDisabled,
  onClick,
}: IButton) => {
  const theme = useTheme();

  const Button = styled.button`
    background-color: ${getButtonBackgroundColor(
      colorScheme,
      "default",
      "solid"
    )};
    color: ${getButtonTextColor(colorScheme)};
    border-color: ${getButtonBorderColor(colorScheme, "default", "solid")};

    :hover {
      background-color: ${colorScheme === "default"
        ? theme.buttonDefaultBackgroundColorHover
        : colorScheme === "danger"
        ? theme.colors.red[8]
        : ""};
      border-color: white;
    }
  `;

  return (
    <div className="flex w-full rounded-md shadow-sm">
      <Button
        type="button"
        className={`flex w-full items-center justify-center rounded-l-md px-3 py-2 text-sm font-semibold focus:z-10 disabled:cursor-not-allowed disabled:opacity-50 mr-0.5`}
        disabled={isLoading || isDisabled}
        onClick={onClick}
      >
        <div>
          {isLoading ? (
            <div
              className="flex items-center justify-center h-full"
              style={{ minWidth: 30 }}
            >
              <Loader />
            </div>
          ) : (
            <>{children}</>
          )}
        </div>
      </Button>

      <Menu as="div" className="block relative -ml-px self-center">
        <MenuButton
          className="relative inline-flex items-center rounded-r-md px-3 py-2 text-gray-400 hover:bg-gray-50 focus:z-10 disabled:cursor-not-allowed disabled:opacity-50"
          disabled={isLoading || dropdownIsDisabled}
          style={{
            backgroundColor: getButtonBackgroundColor(
              colorScheme,
              "default",
              "solid"
            ),
            color: getButtonTextColor(colorScheme),
            height: 38,
          }}
        >
          <span className="sr-only">Open options</span>
          <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
        </MenuButton>
        <MenuItems
          transition
          className="absolute border right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          style={{
            borderRadius: theme.borderRadius,
            backgroundColor: theme.dropdownBackgroundColor,
            borderColor: theme.dropdownBorderColor,
            boxShadow: theme.shadows["2xl"],
          }}
        >
          <div className="py-1" style={{ width: 200 }}>
            {options?.map((item) => (
              <MenuItem key={item.name}>
                <a
                  onClick={() => item.onClick()}
                  className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 "
                >
                  {item.name}
                </a>
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
};
