import { SimpleTable } from "components/atoms/SimpleTable";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import cx from "classnames";
import { ChevronDown16 } from "@carbon/icons-react";
import { RootState } from "store";
import { BulkUploadColumnName } from "enum/bulk-upload-column-names.enum";
import { IShipmentProfile } from "models/shipmentProfile/shipmentProfile.model";
import { setCreateProfileMappings } from "store/createOrUpdateBulkShipmentProfileSlice/index.slice";
import { isArray } from "lodash";
import {
  checkIfColumnMapped,
  generateColumns,
  getSampleValues,
} from "./BulkShipmentProfileWizard.utils";
import { Center } from "components/atoms/Center";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/atoms/Popover";
import { Portal } from "react-portal";
import { MappingColumns } from "store/createOrUpdateBulkShipmentProfileSlice/createOrUpdateBulkShipmentProfileSliceInitialState.const";

export const BulkShipmentProfileMappingTable = () => {
  const { data, mappings, fileContainsHeaderRow } = useSelector(
    (state: RootState) => {
      return state.createOrUpdateBilkShipmentProfileSlice;
    }
  );

  const dispatch = useDispatch();

  const handleSetMappedData = (mappings: IShipmentProfile["mappings"]) => {
    dispatch(setCreateProfileMappings(mappings));
  };

  const {
    meta: { fields },
    data: originalData,
  } = data;

  const headers = originalData[0] || [];

  const [errors, setErrors] = useState<
    { [key in BulkUploadColumnName]: string }
  >({} as { [key in BulkUploadColumnName]: string });

  const columns: Column<{
    attribute: BulkUploadColumnName;
    mappedAttribute: string;
  }>[] = useMemo(
    () => [
      {
        Header: "Attribute",
        accessor: "attribute",
        Cell: ({ row }) => {
          return (
            <>
              {errors[row.original.attribute] && (
                <div className="absolute w-2 h-2 -ml-3 rounded-full bg-red5"></div>
              )}
              <div className="inline-flex font-semibold">
                {MappingColumns[row.original.attribute].label}
                {MappingColumns[row.original.attribute].required === true && (
                  <div className="text-red9"> *</div>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: "CSV column",
        accessor: "mappedAttribute",
        Cell: ({ row }) => {
          //value could be 0 but in this case should not be falsy
          const isMapped =
            mappings[row.original.attribute] !== null ? true : false;
          return (
            <div className="flex items-center">
              <div className="relative flex">
                <select
                  value={mappings[row.original.attribute]?.toString() || ""}
                  placeholder="Please select"
                  className={cx(
                    "pl-3 pr-11 leading-none rounded-md outline-none appearance-none cursor-pointer h-9 border",
                    {
                      "bg-green10 shadow-sm border-gray7": isMapped,
                      "bg-gray4 text-gray9 hover:bg-gray5 border-gray1":
                        !isMapped,
                    }
                  )}
                  style={{
                    width: 200,
                  }}
                  onChange={(e) => {
                    handleSetMappedData({
                      ...mappings,
                      [row.original.attribute]: isNaN(parseInt(e.target.value))
                        ? null
                        : parseInt(e.target.value),
                    });
                  }}
                >
                  <option>Please choose... {isMapped}</option>
                  {isArray(headers) &&
                    headers.map((header, index) => {
                      const isAlreadyMapped = checkIfColumnMapped(
                        mappings,
                        index
                      );
                      return (
                        <option
                          key={index}
                          value={index}
                          disabled={isAlreadyMapped}
                        >
                          Column {index + 1}{" "}
                          {fileContainsHeaderRow && `- ${header}`}
                        </option>
                      );
                    })}
                </select>
              </div>
              {isMapped && (
                <div className="ml-3">
                  <Popover trigger="hover" placement="bottom-end" maxW={400}>
                    <PopoverTrigger>
                      <div className="text-sm leading-none underline cursor-pointer text-muted whitespace-nowrap">
                        Sample values
                      </div>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <div
                          className="overflow-y-auto"
                          style={{
                            maxHeight: 400,
                          }}
                        >
                          <div className="text-sm">
                            <div className="overflow-scroll max-h-48">
                              {getSampleValues(
                                data.data,
                                mappings[row.original.attribute]
                              ).map((value, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="flex items-center py-2 border-b border-gray5"
                                  >
                                    <div className="w-8 mr-3 text-xs leading-none text-center border-r text-gray9 border-gray6">
                                      {index}
                                    </div>
                                    <div className="leading-none">{value}</div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </div>
              )}
            </div>
          );
        },
      },
    ],
    [errors, fields, mappings, fileContainsHeaderRow, data]
  );

  return <SimpleTable data={generateColumns()} columns={columns} />;
};
