import { FormControl } from "components/molecules/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  onChangeDetailsField,
  setDangerousGoodsOption,
} from "store/createShipmentSlice/index.slice";
import randomstring from "randomstring";
import { RadioGroup } from "components/atoms/RadioGroup";
import { Select } from "components/atoms/Select";
import { ISelectOption } from "components/atoms/Select/UseSelect";
import { ShipmentExportReasons } from "enum/shipment-export-reasons.enum";
import { ShipmentDutyOrTaxPaidBy } from "enum/shipment-duty-or-tax-paid-by.enum";
import { getShipmentDirection } from "utils/getShipmentDirection";
import { ShipmentDirections } from "enum/shipment-directions.enum";
import { Stack } from "components/atoms/Stack";
import { Input } from "components/atoms/Input";
import { Radio } from "components/atoms/Radio";
import { CreateShipmentDocumentUploadComponent } from "../CreateShipmentDocumentUploadComponent/index";
import { ApiPath } from "enum/apiPath.enum";
import { useEffect } from "react";
import { Switch } from "components/atoms/Switch";

export enum CreateShipmentFormDetailsTestIds {
  "SHIPMENT_FORM_DETAILS_REFERENCE" = "SHIPMENT_FORM_DETAILS_REFERENCE",
  "SHIPMENT_FORM_DETAILS_GENERATE_REFERENCE" = "SHIPMENT_FORM_DETAILS_GENERATE_REFERENCE",
  "SHIPMENT_FORM_DETAILS_DUTY_PAID_BY_RADIO" = "SHIPMENT_FORM_DETAILS_GENERATE_REFERENCE",
}

export const CreateShipmentDetails = () => {
  const dispatch = useDispatch();

  const {
    values: { details, sender, receiver },
    submitCount,
    errors,
    selectedRate,
    dangerousGoodsOption,
  } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const { doesAccountHaveDvAgreement } = useSelector((state: RootState) => {
    return state.salesAccountSlice;
  });

  const defaults = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });
  const { financialCompany } = useSelector((state: RootState) => {
    return state.authSlice;
  });
  useEffect(() => {
    // update the first item default settings
    if (!defaults.editingShipmentUUID) {
      dispatch(
        onChangeDetailsField({
          reasonForExport: settings?.reasonForExport,
        })
      );
    }
  }, []);

  const isSelectedRateStarlinks =
    selectedRate?.supplier?.apiPath === ApiPath.Starlinks;

  const isSelectedRateDSV =
    selectedRate?.supplier?.apiPath === ApiPath["Fedex (DSV)"] ||
    selectedRate?.supplier?.apiPath === ApiPath["UPS (DSV)"];

  const exportOptions: ISelectOption<object, ShipmentExportReasons>[] = [
    {
      label: "Commercial",
      value: ShipmentExportReasons.Commercial,
    },
    {
      label: "Gift",
      value: ShipmentExportReasons.Gift,
    },
    {
      label: "Other",
      value: ShipmentExportReasons.Other,
    },
    {
      label: "Personal Effects",
      value: ShipmentExportReasons["Personal Effects"],
    },
    {
      label: "Return",
      value: ShipmentExportReasons.Return,
    },
    {
      label: "Return & Repair",
      value: ShipmentExportReasons["Return & Repair"],
    },
    {
      label: "Sample",
      value: ShipmentExportReasons.Sample,
    },
  ];

  const shipmentDirection = getShipmentDirection(
    sender.countryIsoCode,
    receiver.countryIsoCode
  );

  const carrierName = selectedRate?.carrier?.name?.toLowerCase();
  const shouldShowCustomerReference2 =
    carrierName.includes("evri") ||
    carrierName.includes("yodel") ||
    carrierName.includes("ups") ||
    carrierName.includes("fedex") ||
    carrierName.includes("dsv");

  return (
    <>
      <div>
        <div className="w-full flex">
          <div className="w-1/2">
            <Stack spacing={6}>
              <FormControl
                label="Customer reference (or order number)"
                isRequired={true}
                errorMessage={
                  (submitCount > 0 && errors.details.customerReference) || ""
                }
              >
                <Input
                  value={details.customerReference}
                  onChange={(e) => {
                    dispatch(
                      onChangeDetailsField({
                        customerReference: e.target.value,
                      })
                    );
                  }}
                />
                <div
                  className="mt-2 text-sm leading-none text-gray-600 underline cursor-pointer hover:text-primary-600"
                  onClick={() => {
                    dispatch(
                      onChangeDetailsField({
                        customerReference: randomstring.generate(7),
                      })
                    );
                  }}
                >
                  Generate
                </div>
              </FormControl>
              {shouldShowCustomerReference2 && (
                <FormControl
                  label={
                    financialCompany === 8
                      ? "Customer reference 2 (or po number)"
                      : "Customer reference 2"
                  }
                >
                  <Input
                    value={details.customerReference2}
                    onChange={(e) => {
                      dispatch(
                        onChangeDetailsField({
                          customerReference2: e.target.value,
                        })
                      );
                    }}
                  />
                </FormControl>
              )}
              <FormControl label="Invoice Number">
                <Input
                  value={details.invoiceNumber}
                  onChange={(e) => {
                    dispatch(
                      onChangeDetailsField({
                        invoiceNumber: e.target.value,
                      })
                    );
                  }}
                />
              </FormControl>
              {shipmentDirection !== ShipmentDirections.DOMESTIC && (
                <Stack spacing={6}>
                  <FormControl
                    label="Reason for export"
                    isRequired={true}
                    errorMessage={
                      (submitCount > 0 && errors.details.reasonForExport) || ""
                    }
                  >
                    <Select<ISelectOption<object, ShipmentExportReasons>>
                      value={exportOptions.find(
                        (type) => type.value === details.reasonForExport
                      )}
                      placeholder="Select reason"
                      options={exportOptions}
                      isSearchable={false}
                      onChange={(option) => {
                        dispatch(
                          onChangeDetailsField({
                            reasonForExport: option?.value,
                          })
                        );
                      }}
                    />
                  </FormControl>

                  {/* We don't want to show DDU/DDP selection if Starlinks because it's chosen at the service level */}
                  {doesAccountHaveDvAgreement && !isSelectedRateStarlinks ? (
                    <FormControl label="Duty or tax paid by">
                      <RadioGroup
                        value={details.dutyOrTaxPaidBy}
                        onValueChange={(value: ShipmentDutyOrTaxPaidBy) => {
                          dispatch(
                            onChangeDetailsField({
                              dutyOrTaxPaidBy: value,
                              shouldSplitDutyAndVat:
                                value === "RECEIVER"
                                  ? false
                                  : details.shouldSplitDutyAndVat,
                            })
                          );
                        }}
                      >
                        <Radio value={ShipmentDutyOrTaxPaidBy.RECEIVER}>
                          Receiver (DDU)
                        </Radio>
                        <Radio value={ShipmentDutyOrTaxPaidBy.SENDER}>
                          Sender (DDP)
                        </Radio>
                      </RadioGroup>
                    </FormControl>
                  ) : null}
                  {doesAccountHaveDvAgreement && isSelectedRateDSV ? (
                    <FormControl label="Split duty and VAT (only when using DDP)">
                      <RadioGroup
                        disabled={details.dutyOrTaxPaidBy === "RECEIVER"}
                        value={details.shouldSplitDutyAndVat ? "true" : "false"}
                        onValueChange={(value: "true" | "false") => {
                          dispatch(
                            onChangeDetailsField({
                              shouldSplitDutyAndVat:
                                value === "true" ? true : false,
                            })
                          );
                        }}
                      >
                        <Radio value={"false"}>No</Radio>
                        <Radio value={"true"}>Yes</Radio>
                      </RadioGroup>
                    </FormControl>
                  ) : null}

                  <FormControl label="IOSS Number">
                    <Input
                      value={details.iossNumber}
                      onChange={(e) => {
                        dispatch(
                          onChangeDetailsField({
                            iossNumber: e.target.value,
                          })
                        );
                      }}
                    />
                  </FormControl>
                </Stack>
              )}
              {carrierName === "amazon" ? (
                <FormControl label="Dangerous Goods">
                  <Switch
                    checked={dangerousGoodsOption?.value === 0}
                    disabled={false}
                    onCheckedChange={() => {
                      if (dangerousGoodsOption === null) {
                        dispatch(
                          setDangerousGoodsOption({
                            label: "DGR | Dangerous goods",
                            value: 0,
                          })
                        );
                      } else {
                        dispatch(setDangerousGoodsOption(null));
                      }
                    }}
                  />
                </FormControl>
              ) : null}
            </Stack>
          </div>

          {/* <div className="ml-10 w-1/2">
          <Stack>
            <PickUpForm
              pickUpObject={pickUpObject}
              setPickUp={setPickUp}
              setPickUpFromDate={setPickUpFromDate}
              setPickUpToDate={setPickUpToDate}
              setPickUpInstructions={setPickUpInstructions}
              shouldAllowPickUpForm={shouldAllowPickUpForm}
            />
          </Stack>
        </div> */}
          {isSelectedRateDSV ? (
            <div className="ml-10 w-1/2">
              <CreateShipmentDocumentUploadComponent />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
