import { ZIP_VALIDATION_WHITELIST } from "constants/misc";

/**
 * Some countries, like Ireland, have a zip code system that is not widely used.
 * The user should be able to provide a zip code, and if they do it should be validated if validation is available.
 * They should not be forced to provide if it not required.
 *
 * @param countryIsoCode
 */

export const isZipRequired = (countryIsoCode: string): boolean => {
  console.log("countryIsoCode", countryIsoCode);
  return !ZIP_VALIDATION_WHITELIST.includes(countryIsoCode?.toUpperCase());
};
