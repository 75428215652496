import { Alert } from "components/atoms/Alert";
import { GenericErrorCodes } from "enum/error-codes/generic-error-codes.enum";
import { startCase } from "lodash";
import { CreateShipmentContentsErrors } from "store/createShipmentSlice/createShipmentSlice.types";

export type CreateShipmentFormContentsRowErrors = {
  errors: CreateShipmentContentsErrors;
};

export const CreateShipmentFormContentsRowErrors = (
  props: CreateShipmentFormContentsRowErrors
) => {
  const { errors } = props;

  return (
    <div className="mb-4">
      <Alert>
        {Object.keys(errors).map(
          (error: keyof CreateShipmentContentsErrors, index) => {
            if (errors[error] !== "") {
              return (
                <div key={index}>
                  {startCase(error)}:{" "}
                  {errors[error] === GenericErrorCodes.FIELD_REQUIRED &&
                    "this field is required"}
                </div>
              );
            }
          }
        )}
      </Alert>
    </div>
  );
};
