import {
  configureStore,
  ThunkAction,
  combineReducers,
  EnhancedStore,
} from "@reduxjs/toolkit";
import { Action } from "redux";
import { createWrapper } from "next-redux-wrapper";
import { modalSlice } from "./modalSlice";
import { authSlice } from "./authSlice";
import { quotesSlice } from "./quotesSlice/index.slice";
import { shipmentsSlice } from "./shipmentsSlice/index.slice";
import { countriesSlice } from "./countriesSlice/index.slice";
import { createShipmentSlice } from "./createShipmentSlice/index.slice";
import { addressSlice } from "./addressSlice/index.slice";
import { settingsSlice } from "./settingsSlice/index.slice";
import { bulkShipmentProfilesSlice } from "./bulkShipmentProfilesSlice/index.slice";
import { createBulkShipmentsSlice } from "./createBulkShipmentsSlice/index.slice";
import { bulkShipmentsSlice } from "./bulkShipmentsSlice/index.slice";
import { salesAccountSlice } from "./salesAccountSlice/index.slice";
import { usersSlice } from "./usersSlice/index.slice";
import { rulesSlice } from "./rulesSlice/index.slice";
import { currenciesSlice } from "./currenciesSlice/index.slice";
import { createOrUpdateBulkShipmentProfileSlice } from "./createOrUpdateBulkShipmentProfileSlice/index.slice";
import { createOrEditRuleSlice } from "./createOrEditRuleSlice/index.slice";
import { shippingWhitelistsSlice } from "./shippingWhitelistsSlice/index.slice";
import { currentLabelJobsSlice } from "./currentLabelJobsSlice/index.slice";
import { labelJobsSlice } from "./labelJobsSlice/index.slice";
import { createSingleLabelSlice } from "./createSingleLabelSlice/index.slice";
import { tableSlice } from "./tableSlice/index.slice";
import { createShipmentMiddleware } from "./createShipmentSlice/CreateShipmentSlice.middleware";
import { tickerSlice } from "./tickerSlice/index.slice";
import { useDispatch } from "react-redux";
import {
  integrationsSlice,
  INTEGRATIONS_SLICE_NAME,
} from "./integrationsSlice/index.slice";
import { virtualWarehousesSlice } from "./warehousesSlice/index.slice";
import { changelogsSlice } from "./changelogsSlice/index.slice";
import { pickupSlice } from "./pickupSlice/index.slice";

export const rootReducer = combineReducers({
  addressSlice: addressSlice.reducer,
  authSlice: authSlice.reducer,
  bulkShipmentProfilesSlice: bulkShipmentProfilesSlice.reducer,
  bulkShipmentsSlice: bulkShipmentsSlice.reducer,
  countriesSlice: countriesSlice.reducer,
  createBulkShipmentsSlice: createBulkShipmentsSlice.reducer,
  createOrEditRuleSlice: createOrEditRuleSlice.reducer,
  createOrUpdateBilkShipmentProfileSlice:
    createOrUpdateBulkShipmentProfileSlice.reducer,
  createShipmentSlice: createShipmentSlice.reducer,
  createSingleLabelSlice: createSingleLabelSlice.reducer,
  currenciesSlices: currenciesSlice.reducer,
  currentLabelJobsSlice: currentLabelJobsSlice.reducer,
  [INTEGRATIONS_SLICE_NAME]: integrationsSlice.reducer,
  labelJobsSlice: labelJobsSlice.reducer,
  modalSlice: modalSlice.reducer,
  quotesSlice: quotesSlice.reducer,
  rulesSlice: rulesSlice.reducer,
  salesAccountSlice: salesAccountSlice.reducer,
  settingsSlice: settingsSlice.reducer,
  shipmentsSlice: shipmentsSlice.reducer,
  shippingWhitelistsSlice: shippingWhitelistsSlice.reducer,
  tableSlice: tableSlice.reducer,
  tickerSlice: tickerSlice.reducer,
  usersSlice: usersSlice.reducer,
  virtualWarehousesSlice: virtualWarehousesSlice.reducer,
  changelogsSlice: changelogsSlice.reducer,
  pickupSlice: pickupSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export const makeStore = (): EnhancedStore => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(createShipmentMiddleware),
  });
};

export type AppStore = ReturnType<typeof makeStore>;

export type AppState = ReturnType<AppStore["getState"]>;

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;

export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
