import { IQuotedRate } from "models/quote/quote.model";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const CalculateRateValues = ({
  rate,
  isInternationalShipment,
  financialCompany,
}: {
  rate: IQuotedRate;
  isInternationalShipment: boolean;
  financialCompany: number;
}): {
  vat: number;
  fuelSurcharge: number;
  netPrice: number;
  total: number;
  fuelPercent: number;
} => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fuelSurchargePercent, netPrice, carrier } = rate;

  const fuelSurcharge =
    Math.floor(parseInt(netPrice) * (parseInt(fuelSurchargePercent) / 10000)) ||
    0;

  // const vat =
  //   !isInternationalShipment && financialCompany != 8
  //     ? Math.floor((parseInt(netPrice) + fuelSurcharge) * 0.2)
  //     : 0;

  //Come back to this if we need to calculate the vat
  const vat = 0;
  const total = parseInt(netPrice) + vat + fuelSurcharge || 0;

  const fuelPercent = parseInt(fuelSurchargePercent) / 100;

  return {
    vat,
    fuelSurcharge,
    netPrice: parseInt(netPrice) || 0,
    total,
    fuelPercent,
  };
};
