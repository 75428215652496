import { postcodeValidator } from "postcode-validator";
import { isZipRequired } from "./isZipRequired.util";
import { isZipValidationAvailable } from "./isZipValidationAvailable";

/**
 * Checks whether the zip code for a given country is valid.
 * @param countryIsoCode
 * @param zip
 */

export const isZipValid = (countryIsoCode: string, zip: string): boolean => {
  if (
    isZipValidationAvailable(countryIsoCode) &&
    (isZipRequired(countryIsoCode) || (!isZipRequired(countryIsoCode) && zip))
  ) {
    return postcodeValidator(zip, countryIsoCode);
  }

  return true;
};
