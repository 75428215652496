import { apiPaths, customerApiBase } from "enum/paths.enum";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { IFetchPostResponse, IFetchResponseSingle } from "services/api";
import { post } from "services/post";
import { fetchSingle } from "services/get";
import { FetchCompanyAddressesResponseDto } from "./dtos/fetch-company-addresses.response.dto";
import { ICompanyAddress } from "models/companyAddress/companyAddress.model";

export const fetchCompanyAddresses = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<IFetchResponseSingle<FetchCompanyAddressesResponseDto>> => {
  return await fetchSingle(
    customerApiBase + "/" + salesAccountUUID + apiPaths.companyAddresses
  );
};

export const setDefaultCompanyAddress = async (
  salesAccountUUID: ISalesAccount["uuid"],
  companyAddressId: ICompanyAddress["id"]
): Promise<IFetchPostResponse<FetchCompanyAddressesResponseDto>> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.companyAddresses +
      "/set-default",
    {
      companyAddressId,
    }
  );
};
