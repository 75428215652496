export enum ConfigEnvironmentVariables {
  NEXT_PUBLIC_AUTH0_SCOPE = "NEXT_PUBLIC_AUTH0_SCOPE",
  NEXT_PUBLIC_AUTH0_CLIENT_ID = "NEXT_PUBLIC_AUTH0_CLIENT_ID",
  NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL = "NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL",
  NEXT_PUBLIC_AUTH0_BASE_URL = "NEXT_PUBLIC_AUTH0_BASE_URL",
  NEXT_PUBLIC_AUDIENCE = "NEXT_PUBLIC_AUDIENCE",
  NEXT_PUBLIC_NEST_API_BASE_URL = "NEXT_PUBLIC_NEST_API_BASE_URL",
  NEXT_PUBLIC_MAINTENANCE_MODE = "NEXT_PUBLIC_MAINTENANCE_MODE",
  NEXT_PUBLIC_LABEL_BUCKET_URL = "NEXT_PUBLIC_LABEL_BUCKET_URL",
  NEXT_PUBLIC_PUSHER_KEY = "NEXT_PUBLIC_PUSHER_KEY",
  NEXT_PUBLIC_PUSHER_CLUSTER = "NEXT_PUBLIC_PUSHER_CLUSTER",
}
