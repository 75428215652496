import { ReactNode } from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps,
} from "react-error-boundary";
import { runIfFunction } from "utils/react/runIfFunction";
import { MaybeRenderProp } from "utils/react/maybeRenderProp";

type ErrorBoundaryProps = ReactErrorBoundaryProps & {
  children: ReactNode;
};

export const ErrorBoundary = (
  props: ErrorBoundaryProps & {
    children: MaybeRenderProp<Omit<ErrorBoundaryProps, "children">>;
  }
) => {
  const { children, ...rest } = props;

  return (
    <ReactErrorBoundary {...rest}>
      {runIfFunction(children, { ...rest })}
    </ReactErrorBoundary>
  );
};
