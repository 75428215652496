import { AttributeTextCell } from "components/atoms/Attribute";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { format, parseISO } from "date-fns";
import { DATE_FORMAT } from "constants/dateFormat";

export const DateAndSource = () => {
  const { currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const sourceName = currentShipment.shipmentSourceName;

  return (
    <AttributeTextCell>
      {format(parseISO(currentShipment.createdDate), DATE_FORMAT)}{" "}
      {sourceName
        ? ` via ${currentShipment.shipmentSourceName?.toLocaleUpperCase()}`
        : null}
    </AttributeTextCell>
  );
};
