import { Error } from "components/atoms/Error";
import { ViewShipmentModalTestIds } from "../ViewShipmentTestIds.enum";

export const ViewShipmentLoadingError = () => {
  return (
    <div
      data-testid={ViewShipmentModalTestIds.VIEW_SHIPMENT_LOADING_ERROR_PANEL}
    >
      <Error />
    </div>
  );
};
