/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Select } from "components/atoms/Select";
import { ISelectOption } from "components/atoms/Select/UseSelect";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { FormControl, IFormControl } from "../FormControl";
import { FoundDropOffLocationsDistance } from "store/createShipmentSlice/createShipmentSlice.types";
import { SelectedLocationDetails } from './popUp';

export const DropOffSelect = ({
  onChange,
  value,
  label,
}: {
  onChange(value: FoundDropOffLocationsDistance["address"]): void;
  value: FoundDropOffLocationsDistance["address"];
} & IFormControl) => {
  const { dropOffObject } = useSelector((state: RootState) => state.createShipmentSlice);
  const { shouldDropOff, dropOffLocations } = dropOffObject;

  const selectedLocation = dropOffLocations.find((location) => location.address === value);
  const [activeTab, setActiveTab] = useState<'storeHours' | 'lastPickup'>('storeHours');
  const [isHovered, setIsHovered] = useState(false);
  return (
    <FormControl label={label}>
      <div className="w-full relative" >
        <Select<ISelectOption<any, string>>
          value={selectedLocation ? { label: selectedLocation.distance, value: selectedLocation } : undefined}
          onChange={(option) => onChange(option.value.address)}
          placeholder="Select"
          optionTemplate={({ value }) => {
            const { address, locationDetail } = value;
            const { contact, addressAncillaryDetail } = locationDetail.contactAndAddress;

            return (
              <div className="flex flex-col p-2 space-y-1 relative">
                <div className="font-bold">
                  <span>{addressAncillaryDetail.displayName}</span> - <span>{addressAncillaryDetail.locationInProperty}</span>
                </div>
                <div className="text-gray-700">
                  <span>{contact.companyName}</span> - <span>{contact.phoneNumber}</span>
                </div>
                <div className="text-gray-500">
                  <span>{address.city}</span> - <span>{address.countryCode}</span> - <span>{address.postalCode}</span>
                </div>
                <div className="text-gray-400">
                  {value.distance}
                </div>
              </div>
            );
          }}
          options={dropOffLocations.map((location) => ({
            label: location.distance,
            value: { ...location, displayName: location.locationDetail.contactAndAddress.addressAncillaryDetail.displayName },
          }))}
          isDisabled={!shouldDropOff}
          itemHeight={80} // Passing itemHeight of 80
          buttonHeight="120px" // Passing buttonHeight of 120px
        />
        {selectedLocation && (
          <div 
            className="absolute top-0 right-0 mt-2 mr-2 cursor-pointer font-bold border-b border-gray-300 text-sm" 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
          >
            Store info
          </div>
        )}
        {isHovered && (
          <SelectedLocationDetails selectedLocation={selectedLocation} activeTab={activeTab} setActiveTab={setActiveTab} />
        )}
      </div>
    </FormControl>
  );
};
