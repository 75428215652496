import { Alert } from "components/atoms/Alert";
import { Button } from "components/atoms/Button";

import { ModalBody, ModalFooter, ModalHeader } from "components/atoms/Modal";
import { Stack } from "components/atoms/Stack";
import { useDispatch } from "react-redux";
import { closeModal } from "store/modalSlice";

export const ConfirmCloseCreateShipmentModal = () => {
  const dispatch = useDispatch();

  return (
    <>
      <ModalHeader>Close?</ModalHeader>
      <ModalBody>
        <Alert>
          Are you sure you want to close this? You will lose any unsaved
          changes.
        </Alert>
      </ModalBody>
      <ModalFooter>
        <div className="ml-auto">
          <Button
            colorScheme="danger"
            onClick={() => {
              dispatch(closeModal({ modalName: "createShipment" }));
            }}
          >
            Yes
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
