import React, { useState } from "react";

export interface IWizardStep {
  name: string;
  testId?: string;
  component: React.ReactNode;
}

export const useWizard = ({ steps }: { steps: IWizardStep[] }) => {
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

  const stepsCount = steps.length;

  const canGoBack = activeStepIndex > 0;

  const canGoForwards = activeStepIndex < steps.length;

  const onNextStep = () => {
    setActiveStepIndex(activeStepIndex + 1);
  };

  const onPrevStep = () => {
    setActiveStepIndex(activeStepIndex - 1);
  };

  return {
    activeStepIndex,
    canGoBack,
    canGoForwards,
    onNextStep,
    onPrevStep,
    stepsCount,
  };
};

export type UseWizardReturn = ReturnType<typeof useWizard>;
