import { Button } from "../Button";
import { Center } from "../Center";

export const Error = ({
  onClick,
  buttonLabel = "Close",
}: {
  onClick?(): void;
  buttonLabel?: string;
}) => {
  return (
    <Center>
      <div className="text-center">
        <h1 className="mb-2 text-2xl">Something went wrong...</h1>
        <p className="max-w-sm text-muted">
          Our team has been notified. If this problem persists, please contact
          your account manager.
        </p>
        {onClick && (
          <div className="mt-4">
            <Button size="lg" colorScheme="primary" onClick={onClick}>
              {buttonLabel}
            </Button>
          </div>
        )}
      </div>
    </Center>
  );
};
