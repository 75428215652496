import { ModalHeader } from "components/atoms/Modal/ModalHeader";
import { Table } from "components/atoms/Table";
import { AddressModel, IAddress } from "models/address/address.model";
import { Modal } from "components/atoms/Modal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  fetchAddressesThunk,
  searchAddressThunk,
} from "store/addressSlice/index.slice";

export const SelectAddressModal = ({
  onSelect,
  handleClose,
}: {
  onSelect(address: IAddress): void;
  handleClose(): void;
}): JSX.Element => {
  const dispatch = useDispatch();

  const { loadingStatus, items, meta } = useSelector((state: RootState) => {
    return state.addressSlice;
  });

  useEffect(() => {
    dispatch(fetchAddressesThunk({ offset: 0 }));
  }, []);

  const handleFetchAddresses = () => {
    dispatch(fetchAddressesThunk({ offset: 0 }));
  };

  const handleSearchSubmit = (query: string) => {
    dispatch(searchAddressThunk(query));
  };
  const itemsToSort = [...items];
  itemsToSort.sort((a, b) => a.firstName?.localeCompare(b.firstName));

  return (
    <Modal
      isOpen={true}
      handleClose={() => {
        handleClose();
      }}
      size={"2xl"}
    >
      <ModalHeader>Select address</ModalHeader>
      <div
        className="flex overflow-y-auto"
        style={{
          maxHeight: 600,
        }}
      >
        <Table<IAddress>
          allowExport={true}
          data={itemsToSort || []}
          isLoading={loadingStatus === "LOADING" || loadingStatus === "IDLE"}
          recordCount={meta?.recordCount}
          pageCount={meta?.pageCount}
          pageIndex={meta?.offset / meta?.limit}
          manualPagination={true}
          columns={AddressModel.columns}
          onRowClick={onSelect}
          onNextPage={() => {
            dispatch(
              fetchAddressesThunk({ offset: meta?.offset + meta?.limit })
            );
          }}
          onPreviousPage={() => {
            dispatch(
              fetchAddressesThunk({ offset: meta?.offset - meta?.limit })
            );
          }}
          pageSize={meta?.limit}
          onSearchSubmit={handleSearchSubmit}
          tableRowQuantityChange={handleFetchAddresses}
        />
      </div>
    </Modal>
  );
};
