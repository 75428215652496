import { useDropzone } from "react-dropzone";
import { Center } from "components/atoms/Center";
import { FaFilePdf, FaTimes } from "react-icons/fa";
import styled, { ThemeProvider, useTheme } from "styled-components";
import { IconButton } from "components/atoms/IconButton";
import { ThemeDarkElevation1 } from "theme/dark/dark.theme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ThemeLightElevation1 } from "theme/light/light.theme";
import { DocumentSelect } from "components/molecules/DocumentSelect";
import { FormControl } from "components/molecules/FormControl";
import {
  setFiles,
  setDocumentOption,
} from "store/createShipmentSlice/index.slice";

const StyledDropzone = styled.div`
  border: 1px dashed;
  border-color: ${(props) => props.theme.borderColorStrong};
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: pointer;
  padding: ${(props) => props.theme.space[12]};
  text-align: center;
  background-color: ${(props) => props.theme.backgroundColor};
  transition: all ${(props) => props.theme.transitionSpeedDefault};

  &:hover {
    background-color: ${(props) => props.theme.backgroundColorHover};
  }
`;

const StyledDropzoneFile = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
  border: 1px solid;
  border-color: ${(props) => props.theme.borderColorStrong};
`;

export const CreateShipmentDocumentUploadComponent = () => {
  const dispatch = useDispatch();

  const { files, documentOption } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const handleSetFiles = (dropFiles: File[]) => {
    const newFiles = files.map((element) => {
      let file = element.file;
      if (element.label === documentOption.label) {
        file = dropFiles[0];
      }
      return { ...element, file };
    });
    dispatch(setFiles(newFiles));
    dispatch(setDocumentOption(null));
  };
  const theme = useTheme();

  const { theme: selectedTheme } = useSelector((state: RootState) => {
    return state.tableSlice;
  });

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop: handleSetFiles,
    maxFiles: 1,
    accept: { "text/pdf": [`.pdf`] },
  });

  const handleRemoveFile = (fileIndex: number) => {
    const newFiles = files.map((element, index) => {
      let file = element.file;
      if (index === fileIndex) {
        file = null;
      }
      return { ...element, file };
    });
    dispatch(setFiles(newFiles));
  };

  let filesAddedCount = 0;
  files.forEach((element) => {
    if (element.file) {
      filesAddedCount = filesAddedCount + 1;
    }
  });

  return (
    <div>
      {files.map((file, index) => {
        return file.file ? (
          <StyledDropzoneFile
            key={index}
            className={`flex items-center px-4 py-3 ${
              index ? "mt-2" : ""
            } justify-between`}
            style={{
              borderRadius: theme.borderRadius,
            }}
          >
            <div className="flex flex-col truncate">
              <div className="text-md font-bold mb-2">{file.label}</div>
              <div className="text-sm leading-none">{file.file.name}</div>
            </div>
            <div className="ml-4">
              <IconButton
                icon={FaTimes}
                hoverColorScheme="danger"
                onClick={() => handleRemoveFile(index)}
                className="ml-auto"
              />
            </div>
          </StyledDropzoneFile>
        ) : null;
      })}
      <div className={`flex flex-col ${filesAddedCount !== 0 ? "mt-4" : ""}`}>
        <FormControl label="Upload documents">
          <div className="flex flex-row justify-between">
            <div className="w-full">
              <DocumentSelect
                value={documentOption}
                onChange={(option) => {
                  dispatch(setDocumentOption(option));
                }}
              />
            </div>
            {documentOption && filesAddedCount !== 0 ? (
              <IconButton
                icon={FaTimes}
                hoverColorScheme="danger"
                onClick={() => {
                  dispatch(setDocumentOption(null));
                }}
                className="ml-4"
              />
            ) : null}
          </div>
        </FormControl>
        {documentOption ? (
          <div className="mt-4">
            <ThemeProvider
              theme={
                selectedTheme === "dark"
                  ? {
                      ...ThemeDarkElevation1,
                    }
                  : {
                      ...ThemeLightElevation1,
                    }
              }
            >
              <StyledDropzone {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                  className="inline-block w-12 h-12 mb-3 rounded-full text-muted"
                  style={{ backgroundColor: theme.backgroundColor }}
                >
                  <Center>
                    <FaFilePdf />
                  </Center>
                </div>
                <div className="text-sm leading-none text-muted">
                  {isDragReject && <div>{`Only PDFs are supported`}</div>}
                  {`Select PDF file`}
                </div>
              </StyledDropzone>
            </ThemeProvider>
          </div>
        ) : null}
      </div>
    </div>
  );
};
