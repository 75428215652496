import { ReactNode } from "react";

export const TableScrollContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-col flex-1 overflow-hidden TableScrollContainer">
      <div className="flex flex-1 w-full px-4 overflow-x-scroll overflow-y-hidden">
        {children}
      </div>
    </div>
  );
};
