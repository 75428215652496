import { isUserAdmin } from "utils/isUserAdmin";
import { CreateQuoteSelectCustomer } from "../CreateQuoteForm/CreateQuoteFormSelectCustomer";
import { CreateQuoteSenderReceiver } from "../CreateQuoteForm/CreateQuoteFormSenderReceiver";
import { CreateShipmentRecipient } from "./CreateShipmentAddressSelector/CreateShipmentRecipient";
import { CreateShipmentSender } from "./CreateShipmentAddressSelector/CreateShipmentSender";
import { CreateShipmentDetails } from "./CreateShipmentFormDetails";
import { CreateShipmentPackages } from "../common/CreateShipmentFormPackages";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { PickUpForm } from "components/organisms/PickUpForm";
import { InsuranceFormMain } from "components/organisms/insuranceForm/insuranceFormMain";

export type CreateFlowSteps = {
  [key: string]: {
    title: string;
    component: JSX.Element;
    condition?(): boolean;
    stepOrder?: number;
  };
};

export const CreateShipmentFormStepsConfig = () => {
  return {
    sender: {
      title: "Sender",
      component: <CreateShipmentSender />,
      stepOrder: 0,
    },
    receiver: {
      title: "Receiver",
      component: <CreateShipmentRecipient />,
      stepOrder: 1,
    },
    packages: {
      title: "Packages",
      component: <CreateShipmentPackages showContents={true} />,
      stepOrder: 2,
    },
    // details: {
    //   title: "Details",
    //   component: <CreateShipmentDetails />,
    //   stepOrder: 3,
    // },
  };
};

export const CreateShipmentFormStepDetails = (stepNumber: number) => {
  return {
    details: {
      title: "Details",
      component: <CreateShipmentDetails />,
      stepOrder: stepNumber,
    },
  };
};

export const CreateShipmentFormStepPickUp = (pickUpFormProps, financialCompany) => {

  return {
    pickup: {
      title: financialCompany == 8 ? "Pickup or Drop-off" : "Pickup",
      component: <PickUpForm {...pickUpFormProps} />,
      stepOrder: 5,
    },
  };
};

export const CreateShipmentFormStepInsurance = () => {
  return {
    pickup: {
      title: "Extended liability",
      component: <InsuranceFormMain />,
      stepOrder: 3,
    },
  };
};

export enum CreateQuoteSectionNames {
  "CREATE_QUOTE_SELECT_CUSTOMER" = "CREATE_QUOTE_SELECT_CUSTOMER",
  "CREATE_QUOTE_SENDER_RECEIVER" = "CREATE_QUOTE_SENDER_RECEIVER",
  "CREATE_QUOTE_PACKAGES" = "CREATE_QUOTE_PACKAGES",
}

export const CreateQuoteSteps = (): CreateFlowSteps => {
  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  return {
    [CreateQuoteSectionNames.CREATE_QUOTE_SELECT_CUSTOMER]: {
      title: "Select customer",
      component: (
        <CreateQuoteSelectCustomer selectedCustomerUUID={salesAccountUUID} />
      ),
      condition: () => {
        return isUserAdmin(loggedInUser);
      },
    },
    [CreateQuoteSectionNames.CREATE_QUOTE_SENDER_RECEIVER]: {
      title: "Sender & destination locations",
      component: <CreateQuoteSenderReceiver />,
    },
    [CreateQuoteSectionNames.CREATE_QUOTE_PACKAGES]: {
      title: "Packages",
      component: <CreateShipmentPackages showContents={false} />,
    },
  };
};

export const getVisibleSteps = (steps: CreateFlowSteps): CreateFlowSteps => {
  const visibleSteps: CreateFlowSteps = {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.keys(steps).map((key, index) => {
    if (
      !steps[key].condition ||
      (steps[key].condition && steps[key].condition())
    ) {
      visibleSteps[key] = steps[key];
    }
  });

  return visibleSteps;
};
