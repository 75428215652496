interface IModalBodyProps {
  children: React.ReactNode;
}

import styles from './ModalBody.module.scss';

export const ModalBody = ({ children }: IModalBodyProps): JSX.Element => {
  return <div className={styles.ModalBody}>{children}</div>;
};

ModalBody.displayName = 'ModalBody';
