import { useDispatch, useSelector } from "react-redux";
import { getSalesAccountChannel } from "services/pusher/pusher-utils";
import { RootState } from "store";
import { PusherEvents } from "services/pusher/pusher-events.enum";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import { Environments, getEnvironment } from "utils/getEnvironment";
import { apiPaths } from "enum/paths.enum";
import { fetchCurrentLabelJobsThunk } from "store/currentLabelJobsSlice/index.slice";
import { fetchShipmentsThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import { fetchQuotesThunk } from "store/quotesSlice/QuotesSliceFetchQuotesThunk.Thunk";
import { toast } from "react-hot-toast";

if (getEnvironment() !== Environments.production) {
  Pusher.logToConsole = false;
}

export const PusherData = ({ children }: { children: React.ReactNode }) => {
  const { salesAccountUUID, isAuthenticated } = useSelector(
    (state: RootState) => {
      return state.authSlice;
    }
  );

  const dispatch = useDispatch();

  const [pusher, setPusher] = useState<Pusher | null>(null);

  useEffect(() => {
    if (salesAccountUUID && isAuthenticated) {
      const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY || "", {
        cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
        forceTLS: false,
        authEndpoint:
          process.env.NEXT_PUBLIC_NEST_API_BASE_URL + apiPaths.pusher,
        auth: {
          headers: {
            authorization: "Bearer " + window["accessToken"],
          },
        },
      });

      setPusher(pusher);
    }
  }, [salesAccountUUID, isAuthenticated]);

  useEffect(() => {
    if (pusher) {
      const channel = pusher.subscribe(
        getSalesAccountChannel(salesAccountUUID)
      );

      channel.bind(PusherEvents.LABEL_JOB_COMPLETED, () => {
        // toast.success("Your labels are ready");
        dispatch(fetchCurrentLabelJobsThunk());
        dispatch(fetchShipmentsThunk());
      });

      channel.bind(PusherEvents.QUOTE_CREATED, () => {
        dispatch(fetchQuotesThunk());
      });
    }
  }, [pusher, dispatch]);

  return <>{children}</>;
};
