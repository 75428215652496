import { Select } from "components/atoms/Select";
import {
  generateSelectOptions,
  ISelectOption,
} from "components/atoms/Select/UseSelect";
import {
  CustomerStatusesConfig,
  ISalesAccount,
} from "models/salesAccount/salesAccount.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { sortBy } from "lodash";
import { Label } from "components/atoms/Label";
import { setSalesAccountUUID } from "store/createShipmentSlice/index.slice";
import { setFinancialCompany } from "store/authSlice";

export const CreateQuoteSelectCustomer = ({
  selectedCustomerUUID,
}: {
  selectedCustomerUUID: ISalesAccount["uuid"];
}) => {
  const { salesAccounts } = useSelector((state: RootState) => {
    return state.salesAccountSlice;
  });

  const options = generateSelectOptions<
    Pick<
      ISalesAccount,
      | "uuid"
      | "status"
      | "salesAccountReference"
      | "tradeName"
      | "financialCompany"
    >
  >({
    data: sortBy<
      Pick<
        ISalesAccount,
        | "uuid"
        | "status"
        | "salesAccountReference"
        | "tradeName"
        | "financialCompany"
      >
    >(
      salesAccounts,
      [(customer) => customer?.tradeName?.toLowerCase()],
      "customerName"
    ),
    valueKey: "uuid",
    labelKey: "tradeName",
  });

  const selected =
    options.find(
      (customer) =>
        customer.uuid !== null && customer.uuid === selectedCustomerUUID
    ) || null;

  const dispatch = useDispatch();

  const handleChange = (uuid, financialCompany) => {
    dispatch(setSalesAccountUUID(uuid));
    dispatch(setFinancialCompany(financialCompany));
  };

  return (
    <div>
      <Select<
        ISelectOption<
          Pick<
            ISalesAccount,
            | "uuid"
            | "status"
            | "salesAccountReference"
            | "tradeName"
            | "financialCompany"
          >
        >
      >
        options={options}
        placeholder="Select customer"
        value={selected}
        customFilter={(option, query) => {
          return (
            (option.tradeName &&
              option.tradeName.toLowerCase().includes(query.toLowerCase())) ||
            (option.salesAccountReference &&
              option.salesAccountReference
                .toLowerCase()
                .includes(query.toLowerCase()))
          );
        }}
        optionTemplate={({ tradeName, salesAccountReference, status }) => {
          return (
            <div className="flex items-center">
              {tradeName} – {salesAccountReference}
              <div className="ml-2">
                <Label color={CustomerStatusesConfig[status]?.color || "gray"}>
                  {CustomerStatusesConfig[status]?.label || status}
                </Label>
              </div>
            </div>
          );
        }}
        onChange={(option) => {
          handleChange(option?.uuid, option?.financialCompany);
          // setFinancialCompany(option.financialCompany)
        }}
      />
    </div>
  );
};
