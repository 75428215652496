import { FORM_REQUIRED_FIELD_ERROR } from "constants/strings";
import { IAddress } from "models/address/address.model";
import * as Yup from "yup";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";

export const CreateAddressValidationSchema = Yup.object({
  lastName: Yup.string().required(FORM_REQUIRED_FIELD_ERROR),
  addressLine1: Yup.string().required(FORM_REQUIRED_FIELD_ERROR),
  addressLine2: Yup.string(),
  city: Yup.string().required(FORM_REQUIRED_FIELD_ERROR),
  countryIsoCode: Yup.string().required(FORM_REQUIRED_FIELD_ERROR),
  company: Yup.string(),
  countyState: Yup.string(),
  phone: Yup.string(),
  email: Yup.string()
    .required(FORM_REQUIRED_FIELD_ERROR)
    .email("Please enter a valid email."),
  zip: Yup.string()
    .required(FORM_REQUIRED_FIELD_ERROR)
    .test("isValid", "Zip code is not valid.", (value, ctx: any) => {
      // Find correct type for this
      const { from } = ctx;

      const countryIsoCode = from[0].value.countryIsoCode;

      const zip = from[0].value.zip;

      if (
        postcodeValidatorExistsForCountry(countryIsoCode) &&
        !postcodeValidator(zip, countryIsoCode)
      ) {
        return false;
      }

      return true;
    }),
});

export type ICreateAddressFormValues = Pick<
  IAddress,
  | "firstName"
  | "lastName"
  | "addressLine1"
  | "addressLine2"
  | "city"
  | "company"
  | "phone"
  | "email"
  | "countryIsoCode"
  | "countyState"
  | "zip"
  | "code"
  | "branch"
  | "eoriNumber"
  | "mobile"
  | "residentialAddress"
  | "vatNumber"
>;

export type ICreateAddressDto = ICreateAddressFormValues;
