import { apiPaths } from "enum/paths.enum";
import { fetcher, IFetchResponseSingle } from "services/api";
import { FetchCurrentUserResponseDto } from "./dtos/fetch-current-user-response.dto";

export const fetchMe = async (): Promise<
  IFetchResponseSingle<FetchCurrentUserResponseDto>
> => {
  return await fetcher({
    url: apiPaths.fetchMe,
    method: "GET",
  });
};

export const userSalesAccountSwitch = async (values: {
  salesAccountID: string;
}) => {
  return await fetcher({
    url: "/users/sales-account-switch",
    method: "POST",
    body: values,
  });
};

export const updateUserColumns = async (values: Object) => {
  return await fetcher({
    url: "/users/update-columns",
    method: "POST",
    body: values,
  });
};
