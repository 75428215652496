import { motion, Variants } from "framer-motion";
import { ReactNode } from "react";

const variants: Variants = {
  visible: { opacity: 1, scale: 1 },
  hidden: { opacity: 0.5, scale: 0.96 },
};

export const AnimationPulse = ({ children }: { children: ReactNode }) => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{
        repeat: Infinity,
        duration: 0.75,
        repeatType: "reverse",
      }}
    >
      {children}
    </motion.div>
  );
};
