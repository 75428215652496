import { apiPaths, customerApiBase } from "enum/paths.enum";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { IFetchResponseSingle } from "services/api";
import { fetchAll } from "services/get";
import { FetchVirtualWarehousesResponseDto } from "./dtos/fetch-virtual-warehouses-response.dto";

export const fetchVirtualWarehouses = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<IFetchResponseSingle<FetchVirtualWarehousesResponseDto>> => {
  return await fetchAll(
    customerApiBase + "/" + salesAccountUUID + apiPaths.virtualWarehouses,
    null
  );
};
