import { ModalBody } from "components/atoms/Modal";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsNavigation,
  TabsNavigationItem,
} from "components/molecules/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeModal, openModal } from "store/modalSlice";
import { WizardHeader } from "../../Wizard";
import { useMemo } from "react";
import { generateColumns } from "../utils/generateColumns";
import { LabelSizes } from "enum/label-sizes.enum";
import {
  setSubmitCount,
  setSubmitStatus,
} from "store/createBulkShipmentsSlice/index.slice";
import { Table } from "components/atoms/Table";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { CreateBulkShipmentStep2Skipped } from "./CreateBulkShipmentStep2Skipped";
import { CreateBulkShipmentStep2Footer } from "./CreateBulkShipmentStep2Footer";
import { createBulkShipment } from "services/api/bulkShipments";
import { toast } from "react-hot-toast";
import { statuses } from "enum/shipment-statuses.enum";
import { createLabelJob } from "services/api/labels";
import { fetchShipmentsThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import { setQueryToDefault } from "store/shipmentsSlice/index.slice";
import { useTheme } from "styled-components";
import { UNHANDLED_ERROR_MESSAGE } from "components/organisms/ShipmentErrors/ShipmentErrors.utils";
import { flattenAll } from "../utils/flattenShipmentObjects";

export const CreateBulkShipmentStep2 = () => {
  const {
    selectedProfileUUID,
    submitStatus,
    validatedData,
    filename,
    submitCount,
  } = useSelector((state: RootState) => {
    return state.createBulkShipmentsSlice;
  });

  const theme = useTheme();
  const { profiles } = useSelector((state: RootState) => {
    return state.bulkShipmentProfilesSlice;
  });

  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const selectedProfile =
    (profiles &&
      profiles.find((profile) => profile.uuid === selectedProfileUUID)) ||
    null;

  const dispatch = useDispatch();

  const isSubmitting = submitStatus === ApiLoadingStatus.LOADING;

  const handleSubmit = async ({
    printLater,
    labelSize = LabelSizes.x6,
  }: {
    printLater: boolean;
    labelSize: LabelSizes;
  }) => {
    toast.success("Shipments imported");

    await dispatch(setSubmitStatus(ApiLoadingStatus.LOADING));

    await dispatch(setSubmitCount(submitCount + 1));

    const { error, data: response } = await createBulkShipment(
      salesAccountUUID,
      {
        filename,
        shipmentImportProfileUUID: selectedProfileUUID,

        // @ts-expect-error expect
        shipments: [
          ...validatedData.errors.map((item) => {
            // Remove these props as the server DTO will reject the request otherwise.

            const { isValid, errors, ...rest } = item;

            return {
              ...rest,
            };
          }),
          ...validatedData.valid.map((item) => {
            const { isValid, errors, ...rest } = item;

            return {
              ...rest,
            };
          }),
        ],
      }
    );

    if (error) {
      await dispatch(setSubmitStatus(ApiLoadingStatus.FAILED));

      toast.error(UNHANDLED_ERROR_MESSAGE);
    }

    if (!error) {
      await dispatch(setSubmitStatus(ApiLoadingStatus.SUCCESS));

      if (!printLater) {
        const shipments = response.connexxShipments;

        const validShipments = shipments.filter(
          (shipment) => shipment.status === statuses.ready_for_print
        );

        if (validShipments.length === 0)
          toast.success(
            "Shipments imported, but none were valid. Please fix these to create labels."
          );

        if (validShipments.length < shipments.length)
          toast.success(
            "Shipments imported. Your labels are being created. Some are not valid, these need to be fixed before labels can be generated."
          );

        if (validShipments.length === shipments.length)
          toast.success("Shipments imported. Your labels are being created.");

        if (validShipments.length !== 0) {
          createLabelJob(salesAccountUUID, {
            shipmentIds: validShipments.map((shipment) => shipment.id),
            labelSize,
            processAsync: true,
          }).then(() => {
            dispatch(openModal({ name: "labels", type: "drawer" }));
          });
        }
      } else {
        toast.success("Shipments imported");
      }

      dispatch(closeModal({ modalName: "createBulkShipment" }));

      // Reset the shipments query
      await dispatch(setQueryToDefault());

      await dispatch(fetchShipmentsThunk());
    }
  };
  const erroredList = [];
  const listOfAllErrors = [];
  validatedData?.errors?.forEach((element, index) => {
    const arrKeys = Object.keys(element?.errors.consignment);
    listOfAllErrors.push(arrKeys);
    arrKeys.forEach((key) => {
      if (!erroredList.includes(key)) {
        erroredList.push(key);
      }
    });
  });
  const erroredColumns = erroredList.map((column) => {
    const indices = [];
    listOfAllErrors.forEach((row, index) => {
      if (row.includes(column)) {
        indices.push(index);
      }
    });
    return { column, indices };
  });

  const validDataMemoed = useMemo(
    () => flattenAll(validatedData.valid),
    [validatedData.valid]
  );

  const erroredDataMemoed = useMemo(
    () => flattenAll(validatedData.errors),
    [validatedData.errors]
  );

  return (
    <>
      <ModalBody>
        <WizardHeader
          title="Bulk shipment upload"
          onClose={() =>
            dispatch(closeModal({ modalName: "createBulkShipment" }))
          }
        />
      </ModalBody>
      <Tabs isFitted>
        <TabsNavigation>
          <Tab>
            <TabsNavigationItem>
              Valid ({validatedData?.valid?.length || 0})
            </TabsNavigationItem>
          </Tab>
          <Tab>
            <TabsNavigationItem
              style={{
                backgroundColor: theme.colors.red[10],
                color: "white",
              }}
            >
              Errors ({validatedData?.errors?.length || 0})
            </TabsNavigationItem>
          </Tab>
        </TabsNavigation>
        <CreateBulkShipmentStep2Skipped skipped={validatedData?.skipped} />
        <TabPanel>
          <div
            className="flex overflow-hidden"
            style={{
              height: 500,
            }}
          >
            <Table
              columns={generateColumns({
                showErrors: false,
                settings,
                selectedProfile,
              })}
              pageSize={20}
              allowExport={false}
              manualPagination={false}
              data={validDataMemoed}
              isLoading={false}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div
            className="flex overflow-hidden"
            style={{
              height: 500,
            }}
          >
            <Table
              columns={generateColumns({
                showErrors: true,
                settings,
                selectedProfile,
              })}
              manualPagination={false}
              allowExport={false}
              pageSize={20}
              data={erroredDataMemoed}
              isLoading={false}
              erroredColumns={erroredColumns}
            />
          </div>
        </TabPanel>
      </Tabs>
      <CreateBulkShipmentStep2Footer
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        valid={validatedData.valid}
        ready={validatedData.ready}
        errors={validatedData.errors}
      />
    </>
  );
};
