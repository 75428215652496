import { fetcher, IFetcher, IFetchPostResponse } from "./api";

export async function patch<T>(
  url: string,
  data
): Promise<IFetchPostResponse<T>> {
  return await fetcher<T>({
    url: url,
    method: "PATCH",
    body: data,
  });
}
