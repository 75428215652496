import { useSelectContextProvider } from "./SelectContextProvider";

export const SelectDropdownSearch = (): JSX.Element => {
  const { onSearchKeyDown } = useSelectContextProvider();

  return (
    <input
      onKeyUp={(e) => onSearchKeyDown((e.target as HTMLInputElement).value)}
      placeholder="Search"
      className="w-full px-3 text-sm leading-none bg-transparent border-b outline-none h-9 border-gray6"
    />
  );
};

export const DropdownMenuSearchNoResults = (): JSX.Element => {
  return <div className="p-3 text-sm opacity-50">Nothing found...</div>;
};
