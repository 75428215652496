export enum Environments {
  "development" = "development",
  "staging" = "staging",
  "production" = "production",
}

export const getEnvironment = (): Environments => {
  return process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
    ? Environments.staging
    : process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
    ? Environments.production
    : Environments.development;
};
