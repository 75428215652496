import { ShipmentStatusCategoryStrings } from "enum/shipment-status-category-string.enum";
import { statuses } from "enum/shipment-statuses.enum";

export const ShipmentStatusCategories: {
  [key in ShipmentStatusCategoryStrings]: statuses[];
} = {
  [ShipmentStatusCategoryStrings.READY]: [statuses.ready_for_print],
  [ShipmentStatusCategoryStrings.TO_FIX]: [statuses.error],
  [ShipmentStatusCategoryStrings.COMPLETE]: [statuses.label_created],
  [ShipmentStatusCategoryStrings.CANCELLED]: [statuses.cancelled],
};
