import {
  ThemeColorVariableNames,
  ThemeFontSizesVariablesNames,
  ThemeRadiiVariableNames,
  ThemeSpacingVariableNames,
  ThemeVariableNames,
} from "./theme-variable-names.theme";

export const getThemeVariable = (
  key:
    | ThemeVariableNames
    | ThemeFontSizesVariablesNames
    | ThemeColorVariableNames
    | ThemeSpacingVariableNames
    | ThemeRadiiVariableNames
) => {
  return `var(--${key})`;
};
