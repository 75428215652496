import * as RadixTooltip from "@radix-ui/react-tooltip";
import { ReactNode } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const StyledTooltipContent = styled(motion.div)`
  padding-left: ${(props) => props.theme.space[4]};
  padding-right: ${(props) => props.theme.space[4]};
  padding-top: ${(props) => props.theme.space[1]};
  padding-bottom: ${(props) => props.theme.space[1]};
  font-size: ${(props) => props.theme.fontSizes.xs};
  background-color: ${(props) => props.theme.tooltipBackgroundColor};
  color: ${(props) => props.theme.tooltipTextColor};
  border-radius: 100px;
  border: 1px solid;
  border-color: ${(prosp) => prosp.theme.borderColor};
  box-shadow: ${(props) => props.theme.shadows["2xl"]};
`;

export const Tooltip = ({
  children,
  content,
  isDisabled,
  triggerClassNames,
}: {
  isDisabled?: boolean;
  children: ReactNode;
  content: ReactNode;
  triggerClassNames?: string;
}) => {
  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger
          {...(isDisabled && {
            onPointerMove: (event) => event.preventDefault(),
            onFocus: (event) => event.preventDefault(),
          })}
          className={triggerClassNames}
        >
          <>{children}</>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content>
            <StyledTooltipContent
              initial={{ opacity: 0, scale: 0.85 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.25 }}
            >
              {content}
            </StyledTooltipContent>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
