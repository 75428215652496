import React from "react";
import { Logo } from "components/atoms/LogoCircle";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  tabs: { name: string; current: boolean; onClick: () => void }[];
}

export const Tabs = ({ tabs }: Props) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex justify-between space-x-8"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <div
                key={tab.name}
                style={{ cursor: "pointer" }}
                className={classNames(
                  tab.current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium w-1/2 flex flex-row"
                )}
                aria-current={tab.current ? "page" : undefined}
                onClick={tab.onClick}
              >
                <Logo
                  size="md"
                  imageUrl={`/images/logos/${tab.name.toLowerCase()}.png`}
                />
                <div className="ml-4 self-center font-semibold text-xl">
                  {tab.name}
                </div>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
