import { Help16 } from "@carbon/icons-react";
import { Tooltip } from "components/atoms/Tooltip";

export const CreateBulkShipmentStep2Skipped = ({ skipped }) => {
  return (
    <>
      {skipped?.length > 0 && (
        <div className="flex items-center justify-center py-2 text-sm text-center border-b bg-gray3 border-gray6">
          {skipped.length} rows were skipped because they do not appear to be
          valid orders{" "}
          <div className="flex items-center ml-1">
            <Tooltip content="Any rows missing address line 1, country, or zip code are automatically skipped">
              <Help16 />
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
};
