import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeModal } from "store/modalSlice";
import { markLabelJobAsDownloaded } from "services/api/labels";
import { fetchCurrentLabelJobsThunk } from "store/currentLabelJobsSlice/index.slice";
import { getLabelUrl } from "utils/getLabelUrl";
import { ModalClose } from "components/atoms/Modal";
import { useEffect } from "react";
import { Button } from "components/atoms/Button";
import { Heading } from "components/atoms/Heading";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { useRouter } from "next/router";
import { paths } from "enum/paths.enum";
import { ShipmentStatusCategoryStrings } from "enum/shipment-status-category-string.enum";
import { Stack } from "components/atoms/Stack";
import { fetchSingleShipmentThunk } from "store/shipmentsSlice/thunks/fetchSingleShipmentThunk.thunk";

export const CreateSingleLabelModal = () => {
  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const router = useRouter();

  const { loadingStatus, labelJob } = useSelector((state: RootState) => {
    return state.createSingleLabelSlice;
  });

  const dispatch = useDispatch();

  const handlePrintLabel = () => {
    window.open(getLabelUrl(labelJob.labelUrl), "_blank");

    dispatch(closeModal({ modalName: "createSingleLabel" }));

    markLabelJobAsDownloaded(salesAccountUUID, labelJob.uuid).then(() => {
      dispatch(fetchCurrentLabelJobsThunk());
    });
  };

  // useEffect(() => {
  // if (currentShipment.uuid) {
  //   dispatch(fetchSingleShipmentThunk(currentShipment.uuid));
  // }
  // }, [loadingStatus]);

  const handleViewShipment = async () => {
    dispatch(closeModal({ modalName: "createSingleLabel" }));

    router.push({
      pathname: paths.SHIPMENTS_INDEX,
      query: { status: ShipmentStatusCategoryStrings.TO_FIX },
    });
  };

  return (
    <div className="p-12 text-center">
      <ModalClose
        position="absolute"
        onClick={() => {
          dispatch(closeModal({ modalName: "createSingleLabel" }));
        }}
      />

      <Stack spacing={6}>
        <Heading size="2xl">
          {loadingStatus === ApiLoadingStatus.LOADING
            ? "Your labels are being created"
            : loadingStatus === ApiLoadingStatus.FAILED
            ? "Labels failed to be generated"
            : loadingStatus === ApiLoadingStatus.SUCCESS
            ? "Your labels are ready!"
            : ""}
        </Heading>

        {loadingStatus === ApiLoadingStatus.FAILED && (
          <div className="text-muted">
            Your labels couldn't be generated. Please review the errors, correct
            them, and then try again.
          </div>
        )}

        {loadingStatus === ApiLoadingStatus.FAILED ? (
          <Button
            onClick={handleViewShipment}
            isBlock
            size="lg"
            colorScheme="danger"
          >
            Review errors
          </Button>
        ) : null}
        {loadingStatus === ApiLoadingStatus.SUCCESS ? (
          <Button
            isBlock
            colorScheme="primary"
            // isLoading={loadingStatus === "LOADING"}
            size="lg"
            onClick={handlePrintLabel}
          >
            Print label
          </Button>
        ) : null}
      </Stack>
    </div>
  );
};
