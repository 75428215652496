import { ModalSizes } from "components/atoms/Modal";
import { modals } from "./modalSlice.types";

export const IModalComponents: {
  [key in modals]: {
    defaultSize: ModalSizes;
    closeOnOverlayClick?: boolean;
    showBackdrop?: boolean;
  };
} = {
  confirmCancelShipment: {
    defaultSize: "sm",
  },
  confirmCancelPickup: {
    defaultSize: "sm",
  },
  createBulkShipment: {
    defaultSize: "sm",
    closeOnOverlayClick: false,
  },
  pickUpRequestModal: {
    defaultSize: "sm",
    closeOnOverlayClick: false,
  },
  createShipment: {
    defaultSize: "2xl",
    closeOnOverlayClick: false,
  },
  createQuotePalletSwitchWarningModal: {
    defaultSize: "xs",
  },
  createBulkShipmentProfile: {
    defaultSize: "md",
  },
  createSingleLabel: {
    defaultSize: "sm",
    closeOnOverlayClick: false,
  },
  selectReceiverAddress: {
    defaultSize: "sm",
  },
  selectSenderAddress: {
    defaultSize: "sm",
  },
  editBulkShipmentProfile: {
    defaultSize: "md",
  },
  labels: {
    defaultSize: "sm",
    closeOnOverlayClick: false,
  },
  confirmCloseCreateShipmentModal: {
    defaultSize: "sm",
  },
  confirmBulkDeleteShipmentsModal: {
    defaultSize: "sm",
  },
  confirmRefetchShipmentRates: {
    defaultSize: "sm",
  },
  confirmDeleteShipmentProfile: {
    defaultSize: "sm",
  },
  createRule: { defaultSize: "md" },
  createReceiverAddress: {
    defaultSize: "sm",
  },
  createCompanyAddress: {
    defaultSize: "sm",
  },
  editRule: { defaultSize: "md" },
  customerOnStopWarning: {
    defaultSize: "sm",
  },
  viewShipment: { defaultSize: "md", showBackdrop: false },
  viewQuote: { defaultSize: "lg" },
  shipmentReport: { defaultSize: "md" },
  quoteReport: { defaultSize: "md" },
  helpContactInfo: { defaultSize: "md", closeOnOverlayClick: true },
  pickUpModal: { defaultSize: "full", closeOnOverlayClick: true },
  viewPickupModal: { defaultSize: "lg" },
};
