import { Middleware } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  addPackage,
  onChangeReceiverCountry,
  onChangeSenderCountry,
  removePackage,
  setPackages,
  setReceiverAddressType,
  setReceiverSavedAddress,
  setRecipientAddressZip,
  setSenderAddressType,
  setSenderAddressZip,
  setSenderSavedAddress,
} from "./index.slice";

import {
  setConvertingFromQuoteStatus,
  setIsConvertingFromQuote,
  setRates,
  setRatesLoadingStatus,
  setSelectedRate,
} from "./index.slice";

import { ApiLoadingStatus } from "enum/api-loading-status.enum";

export const createShipmentMiddleware: Middleware<RootState, RootState> =
  (storeAPI) => (next) => (action) => {
    // const dispatch = useDispatch();

    const refetchRates = async () => {
      storeAPI.dispatch(setRates([]));
      storeAPI.dispatch(setSelectedRate(null));
      storeAPI.dispatch(setRatesLoadingStatus(ApiLoadingStatus.IDLE));
      storeAPI.dispatch(setConvertingFromQuoteStatus(ApiLoadingStatus.IDLE));
      storeAPI.dispatch(setIsConvertingFromQuote(false));
    };

    if (
      (storeAPI.getState().createShipmentSlice.isConvertingFromQuote &&
        storeAPI.getState().createShipmentSlice.convertingFromQuoteStatus ===
          "READY") ||
      (!storeAPI.getState().createShipmentSlice.isConvertingFromQuote &&
        storeAPI.getState().createShipmentSlice.ratesLoadingStatus ===
          "SUCCESS")
    ) {
      const { values } = storeAPI.getState().createShipmentSlice;

      if (
        addPackage.match(action) ||
        removePackage.match(action) ||
        setPackages.match(action) ||
        setSenderAddressType.match(action) ||
        setReceiverAddressType.match(action) ||
        onChangeSenderCountry.match(action) ||
        onChangeReceiverCountry.match(action) ||
        setRecipientAddressZip.match(action) ||
        setSenderAddressZip.match(action)
      ) {
        refetchRates();
        // storeAPI.dispatch(openModal({ name: "confirmRefetchShipmentRates" }));
        return;
      }

      if (
        setReceiverSavedAddress.match(action) &&
        (action.payload.countryIsoCode !== values.receiver.countryIsoCode ||
          action.payload.zip !== values.receiver.zip)
      ) {
        refetchRates();
        // storeAPI.dispatch(openModal({ name: "confirmRefetchShipmentRates" }));
        return;
      }

      if (
        setSenderSavedAddress.match(action) &&
        (action.payload.countryIsoCode !== values.sender.countryIsoCode ||
          action.payload.zip !== values.sender.zip)
      ) {
        refetchRates();
        // storeAPI.dispatch(openModal({ name: "confirmRefetchShipmentRates" }));
        return;
      }
    }

    return next(action);
  };
