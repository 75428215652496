import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { IShipment } from "models/shipment/shipment.model";
import { packingSlipBase64 } from "services/api/packingSlipPdf";
import { AppThunk, RootState } from "store";
import { setLoadingPackingSlipStatus } from "../index.slice";

export const createPackingSlipBase64Thunk = (
  shipmentId: IShipment["id"],
  shipmentReference: IShipment["customerReference"]
): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      await dispatch(
        setLoadingPackingSlipStatus({ status: ApiLoadingStatus.LOADING })
      );

      try {
        const { data, error } = await packingSlipBase64(salesAccountUUID, {
          shipmentId: shipmentId,
        });

        if (error) {
          throw error;
        } else {
          dispatch(
            setLoadingPackingSlipStatus({
              status: ApiLoadingStatus.SUCCESS,
            })
          );
          const linkSource = `data:application/pdf;base64,${data.packingSlipBase64}`;
          const downloadLink = document.createElement("a");
          const fileName = `Packing Slip ${shipmentReference}`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      } catch (e) {
        dispatch(
          setLoadingPackingSlipStatus({ status: ApiLoadingStatus.FAILED })
        );
      }
    }
  };
};
