import flatten from "flat";

const flattened = (theme) => {
  const flat = flatten(theme, {
    delimiter: ".",
    transformKey: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  });

  const result = {};

  Object.keys(flat).map((key) => {
    let attr = key.replace(/\./g, "");

    attr = attr.charAt(0).toLowerCase() + attr.slice(1);

    result[attr] = flat[key];
  });

  return result;
};

export const GlobalStyle = (theme) => {
  const flattenedTheme = flattened(theme);

  return Object.keys(flattenedTheme).map((key) => {
    return `--${key}: ${flattenedTheme[key]};`;
  });
};
