import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  CreateAddress,
  CreateShipmentAddressType,
} from "store/createShipmentSlice/createShipmentSlice.types";
import {
  onChangeSenderAddressField,
  setSenderAddressType,
  clearSenderAddress,
  onChangeSenderCountry,
  onTouchSenderAddressField,
  setSenderAddressZip,
  setSenderSaveToAddressBook,
  setSavedSenderAddressEditMode,
  setDefaultFromISO_GA,
} from "store/createShipmentSlice/index.slice";
import { openModal } from "store/modalSlice";
import { AddressDirection, AddressSelector } from ".";
import { useEffect } from "react";
import { fetchDefaultAddressThunk } from "store/addressSlice/index.slice";

export const CreateShipmentSender = () => {
  const dispatch = useDispatch();

  const { values, errors, touched, submitCount, editingShipmentUUID } =
    useSelector((state: RootState) => {
      return state.createShipmentSlice;
    });
  const { financialCompany } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  useEffect(() => {
    //don't fetch default address if we are in edit mode
    if (!editingShipmentUUID) {
      dispatch(fetchDefaultAddressThunk());
    }
    if (financialCompany == 8) {
      dispatch(setDefaultFromISO_GA());
    }
  }, []);

  return (
    <AddressSelector
      editModeSavedAddress={values.sender.editModeSavedAddress}
      onEditSavedAddress={() => {
        dispatch(setSavedSenderAddressEditMode());
      }}
      saveToAddressBook={values.sender.saveToAddressBook}
      direction={AddressDirection.SENDER}
      id={values.sender.id}
      isSenderDefault={values.sender.isSenderDefault}
      firstName={values.sender.firstName}
      vatNumber={values.sender.vatNumber}
      eoriNumber={values.sender.eoriNumber}
      lastName={values.sender.lastName}
      touched={touched.sender}
      showErrors={submitCount > 0}
      errors={errors.sender}
      addressLine1={values.sender.addressLine1}
      addressLine2={values.sender.addressLine2}
      addressLine3={values.sender.addressLine3}
      onChangeSaveAddress={(value) => {
        dispatch(setSenderSaveToAddressBook(value));
      }}
      onChangeType={(type) => {
        dispatch(setSenderAddressType(type));

        if (
          !values.receiver.id &&
          type === CreateShipmentAddressType.ADDRESS_BOOK
        ) {
          dispatch(openModal({ name: "selectSenderAddress" }));
        }
      }}
      type={values.sender.type}
      city={values.sender.city}
      zip={values.sender.zip}
      countyState={values.sender.countyState}
      countryIsoCode={values.sender.countryIsoCode}
      onChange={(key, value) => {
        dispatch(
          onChangeSenderAddressField<Partial<CreateAddress>>({
            [key]: value,
          })
        );
      }}
      onChangeZip={(value) => {
        dispatch(setSenderAddressZip(value));
      }}
      onTouch={(key) => dispatch(onTouchSenderAddressField(key))}
      onRemoveAddress={() => {
        dispatch(clearSenderAddress());
      }}
      onToggleSelectAddress={() => {
        dispatch(setSenderAddressType(CreateShipmentAddressType.ADDRESS_BOOK));
        dispatch(openModal({ name: "selectSenderAddress" }));
      }}
      onChangeCountry={(value) => {
        dispatch(onChangeSenderCountry(value));
      }}
      company={values.sender.company}
      email={values.sender.email}
      phone={values.sender.phone}
    />
  );
};
