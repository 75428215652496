import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { IShippingWhitelist } from "models/shippingWhitelist/shippingWhitelist.model";
import { fetchShippingWhitelists } from "services/api/shippingWhitelists";
import { AppThunk, RootState } from "store";

export interface IShippingWhitelistsSlice {
  loadingStatus: ApiLoadingStatus;
  shippingWhitelists: IShippingWhitelist[];
}

export const ShippingWhitelistsSliceInitialState: IShippingWhitelistsSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  shippingWhitelists: [],
};

export const shippingWhitelistsSlice = createSlice({
  name: "shippingWhitelistsSlice",
  initialState: ShippingWhitelistsSliceInitialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<IShippingWhitelistsSlice["shippingWhitelists"]>
    ) => {
      state.shippingWhitelists = action.payload;
      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },
    setStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
  },
});

export const { setData, setStatus } = shippingWhitelistsSlice.actions;

export const fetchShippingWhitelistsThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const { data, error } = await fetchShippingWhitelists(salesAccountUUID);

      if (!error && data) {
        dispatch(setData(data));
      }
    }
  };
};
