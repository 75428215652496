import { Divider } from "components/atoms/Divider";
import { Input } from "components/atoms/Input";
import { ModalBody, ModalFooter, ModalHeader } from "components/atoms/Modal";
import { ModalFooterButtons } from "components/atoms/Modal/ModalFooterButtons";
import { FormControl } from "components/molecules/FormControl";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBulkShipmentProfile } from "services/api/shipmentProfiles";
import { RootState } from "store";
import {
  setCreateProfileData,
  setCreateProfileMappings,
  setCreateProfileTitle,
  updateBulkShipmentProfileThunk,
} from "store/createOrUpdateBulkShipmentProfileSlice/index.slice";
import { closeModal } from "store/modalSlice";
import { BulkShipmentProfileMappingTable } from "../BulkShipmentProfileWizard/BulkShipmentProfileMappingTable";
import { generateDefaultMappings } from "store/createOrUpdateBulkShipmentProfileSlice/createOrUpdateBulkShipmentProfileSliceInitialState.const";

export const EditBulkShipmentProfile = () => {
  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { editingProfileUUID, title, submittingStatus } = useSelector(
    (state: RootState) => {
      return state.createOrUpdateBilkShipmentProfileSlice;
    }
  );

  const dispatch = useDispatch();

  const handleSetTitle = (value: string) => {
    dispatch(setCreateProfileTitle(value));
  };

  const handleSubmit = async () => {
    dispatch(updateBulkShipmentProfileThunk());
  };

  useEffect(() => {
    if (salesAccountUUID && editingProfileUUID) {
      fetchBulkShipmentProfile(salesAccountUUID, editingProfileUUID).then(
        ({ data }) => {
          if (data) {
            dispatch(setCreateProfileData(data.originalData));
            dispatch(setCreateProfileTitle(data.profileName));
            //generating default mappings to make sure we don't miss any columns that are newly added.
            dispatch(
              setCreateProfileMappings({
                ...generateDefaultMappings(),
                ...data.mappings,
              })
            );
          }
        }
      );
    }
  }, [editingProfileUUID, dispatch]);

  return (
    <>
      <ModalHeader>Update profile</ModalHeader>
      <ModalBody>
        <FormControl label="Name" isRequired={true}>
          <Input
            value={title}
            onChange={(e) => handleSetTitle(e.target.value)}
            placeholder="Profile name"
          />
        </FormControl>
        <Divider className="my-6" />
        <BulkShipmentProfileMappingTable />
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons
          onCancel={() =>
            dispatch(closeModal({ modalName: "editBulkShipmentProfile" }))
          }
          onSubmit={() => handleSubmit()}
          isLoading={submittingStatus === "LOADING"}
          primaryLabel="Update"
        />
      </ModalFooter>
    </>
  );
};
