import { Alert } from "components/atoms/Alert";
import { Button } from "components/atoms/Button";
import { ModalBody, ModalFooter, ModalHeader } from "components/atoms/Modal";
import { useDispatch } from "react-redux";
import { closeModal } from "store/modalSlice";
import { cancelPickUpRequestThunk } from "store/pickupSlice/thunks/cancelPickUpRequestThunk";

export const ConfirmCancelPickupModal = () => {
  const dispatch = useDispatch();

  return (
    <>
      <ModalHeader>Cancel pickup warning</ModalHeader>
      <ModalBody>
        <Alert>Are you sure you want to cancel this pickup?</Alert>
      </ModalBody>
      <ModalFooter>
        <div className="ml-auto">
          <Button
            colorScheme="danger"
            onClick={() => {
              dispatch(cancelPickUpRequestThunk());
            }}
          >
            Yes
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
