import { HTMLProps, Ref } from "react";
import { Loader } from "../Loader";
import styled, { useTheme } from "styled-components";
import { FaChevronDown } from "react-icons/fa";
import { createElement } from "react";

type state = "hover" | "active" | "disabled" | "default";

export type IButton = {
  innerRef?: Ref<HTMLButtonElement>;
  isBlock?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  variant?: "solid" | "outline" | "ghost";
  type?: "submit" | "button";
  size?: "xs" | "sm" | "default" | "lg";
  colorScheme?: "primary" | "default" | "danger" | "selected";
  hoverColorScheme?: "danger" | "success";
  testId?: string;
  isDownload?: boolean;
} & Pick<
  HTMLProps<HTMLButtonElement>,
  "id" | "onClick" | "children" | "className"
>;

export const getButtonHeight = (size: IButton["size"]) => {
  const theme = useTheme();

  switch (size) {
    case "xs":
      return theme.formControlHeightXSmall;
    case "sm":
      return theme.formControlHeightSmall;
    case "default":
      return theme.formControlHeightDefault;
    case "lg":
      return theme.formControlHeightLarge;
  }
};

export const getButtonFontSize = (size: IButton["size"]) => {
  const theme = useTheme();

  switch (size) {
    case "sm":
      return theme.formControlFontSizeSmall;
    case "default":
      return theme.formControlFontSizeSmall;
    case "lg":
      return theme.formControlFontSizeSmall;
  }
};

export const getButtonBackgroundColor = (
  colorScheme: IButton["colorScheme"],
  state: state,
  variant: IButton["variant"],
  hoverColorScheme?: IButton["hoverColorScheme"]
) => {
  const theme = useTheme();

  switch (colorScheme) {
    case "default":
      switch (variant) {
        case "outline":
          switch (state) {
            default:
              return "transparent";
          }
        case "solid":
          switch (state) {
            case "default":
              return theme.buttonDefaultBackgroundColor;
            case "hover":
              switch (hoverColorScheme) {
                case "danger":
                  return theme.colors.red[10];

                default:
                  return theme.buttonDefaultBackgroundColorHover;
              }
          }
      }

    case "primary":
      return theme.buttonPrimaryBackgroundColor;

    case "selected":
      return theme.colors.green[9];

    case "danger":
      switch (state) {
        case "default":
          return theme.colors.red[10];

        case "hover":
          return theme.colors.red[8];
      }
  }
};

export const getButtonBorderColor = (
  colorScheme: IButton["colorScheme"],
  state: state,
  variant: IButton["variant"],
  hoverColorScheme?: IButton["hoverColorScheme"]
) => {
  const theme = useTheme();

  switch (colorScheme) {
    case "default":
      switch (variant) {
        case "outline":
          switch (state) {
            case "default":
              return theme.buttonDefaultBorderColor;

            case "hover":
              return theme.buttonDefaultBorderColorHover;
          }

        case "solid":
          switch (state) {
            case "default":
              return theme.buttonDefaultBackgroundColor;

            case "hover":
              return theme.buttonDefaultBackgroundColorHover;
          }
      }

    case "primary":
      switch (variant) {
        case "outline":

        case "solid":
          switch (state) {
            case "default":
              return theme.buttonPrimaryBorderColor;

            case "hover":
              return theme.buttonPrimaryBackgroundColor;
          }
      }

    case "danger": {
      return theme.colors.red[10];
    }

    case "selected": {
      return "white";
    }
  }
};

export const getButtonTextColor = (colorScheme: IButton["colorScheme"]) => {
  const theme = useTheme();

  switch (colorScheme) {
    case "default":
      return theme.buttonDefaultTextColor;

    case "primary":
      return "white";

    case "selected":
      return "white";
  }
};

export const StyledButton = styled.button<IButton>`
  height: ${(props) => getButtonHeight(props.size)};
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  line-height: 1;
  color: ${(props) => getButtonTextColor(props.colorScheme)};
  background-color: ${(props) =>
    getButtonBackgroundColor(props.colorScheme, "default", props.variant)};
  font-size: ${(props) => getButtonFontSize(props.size)};
  padding-left: ${(props) => props.theme.space[4]};
  padding-right: ${(props) => props.theme.space[4]};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid;
  border-color: ${(props) =>
    getButtonBorderColor(props.colorScheme, "default", props.variant)};
  transition: color, background-color,
    border-color ${(props) => props.theme.transitionSpeedDefault};

  :hover {
    border-color: ${(props) =>
      getButtonBorderColor(
        props.colorScheme,
        "hover",
        props.variant,
        props.hoverColorScheme
      )};
    background-color: ${(props) =>
      getButtonBackgroundColor(
        props.colorScheme,
        "hover",
        props.variant,
        props.hoverColorScheme
      )};
  }

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;

    :hover {
      background-color: ${(props) =>
        getButtonBackgroundColor(props.colorScheme, "default", props.variant)};
      border-color: ${(props) =>
        getButtonBorderColor(props.colorScheme, "default", props.variant)};
    }
  }

  ${({ isBlock }) =>
    isBlock === true &&
    `
    width: 100%;
    display: block;
  `}
`;

export const Button = ({
  id,
  children,
  isBlock = false,
  isDisabled = false,
  isLoading = false,
  innerRef,
  className,
  colorScheme = "default",
  variant = "solid",
  type = "button",
  testId,
  size = "default",
  isDownload = false,
  ...rest
}: IButton) => {
  return (
    <StyledButton
      id={id}
      isBlock={isBlock}
      isLoading={isLoading}
      disabled={isLoading || isDisabled}
      className={className}
      type={type}
      variant={variant}
      size={size}
      colorScheme={colorScheme}
      {...(innerRef && { ref: innerRef })}
      {...(testId && { "data-testid": testId })}
      {...rest}
    >
      {isLoading ? (
        <div
          className="flex items-center justify-center h-full"
          style={{ minWidth: 30 }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {isDownload ? (
            <div className={"flex justify-between"}>
              <div></div>
              <div className={"flex"}>{children}</div>
              <div className={"flex"}>
                {createElement(FaChevronDown, { size: 12 })}
              </div>
            </div>
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </StyledButton>
  );
};
