import {
  MAX_PACKAGE_GIRTH_CM,
  MAX_PACKAGE_HEIGHT_CM,
  MAX_PARCEL_METRIC_WEIGHT_GRAMS,
  MAX_TOTAL_WEIGHT_BEFORE_FREIGHT_WARNING,
} from "constants/misc";
import { IShipmentPackageGrouped } from "models/shipmentPackage/shipmentPackage.model";
import { CreateShipmentItemErrors } from "store/createShipmentSlice/createShipmentSlice.types";
import { calculateGirth } from "utils/calculate/calculateGirth.util";
import { calculateTotalWeight } from "utils/calculateWeights";

export const checkMaxGirthMet = (
  shipmentPackage: IShipmentPackageGrouped
): boolean => {
  return calculateGirth(shipmentPackage) >= MAX_PACKAGE_GIRTH_CM;
};

export const checkMaxHeightMet = (shipmentPackage: IShipmentPackageGrouped) => {
  return shipmentPackage.height >= MAX_PACKAGE_HEIGHT_CM;
};

export const checkFreightWarningThresholdMet = (
  packages: IShipmentPackageGrouped[]
): boolean => {
  return (
    calculateTotalWeight(packages) >= MAX_TOTAL_WEIGHT_BEFORE_FREIGHT_WARNING
  );
};

export const checkMaxPackageWeightMet = (weightInGrams: number): boolean => {
  return weightInGrams >= MAX_PARCEL_METRIC_WEIGHT_GRAMS;
};

export const isPackageRowValid = (
  errors: CreateShipmentItemErrors
): boolean => {
  let isValid = true;

  errors &&
    Object.keys(errors).map((error) => {
      if (errors[error]) isValid = false;
    });

  return isValid;
};
