import { ReactNode } from "react";

export enum ModalTitleTestIds {
  "MODAL_TITLE" = "MODAL_TITLE",
}

export const ModalTitle = ({ children }: { children: ReactNode }) => {
  return (
    <h2
      className="leading-none"
      data-testid={ModalTitleTestIds.MODAL_TITLE}
    >
      {children}
    </h2>
  );
};
