import { apiPaths, customerApiBase } from "enum/paths.enum";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { IFetchResponseSingle } from "services/api";
import { fetchAll } from "services/get";
import { FetchWhitelistsResponseDto } from "./dtos/fetch-whitelists.response.dto";

export const fetchShippingWhitelists = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<IFetchResponseSingle<FetchWhitelistsResponseDto>> => {
  return await fetchAll(
    customerApiBase + "/" + salesAccountUUID + apiPaths.shippingWhitelists,
    null
  );
};
