import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLoadingStatus } from "interfaces/api.interface";
import { IRule, IRuleCondition, RuleCondition } from "models/rule/rule.model";
import { IVirtualWarehouse } from "models/virtualWarehouse/virtualWarehouse.model";
import { createRule, updateRule } from "services/api/rules";
import {
  CreateRuleAppliesTo,
  CreateRuleValidationSchema,
} from "services/api/rules/dtos/create-rule.request.dto";
import { AppThunk, RootState } from "store";
import { closeModal } from "store/modalSlice";
import { isModalClosed } from "store/modalSlice/modalSlice.actions";
import { modals } from "store/modalSlice/modalSlice.types";
import { fetchRulesThunk } from "store/rulesSlice/index.slice";

interface ICreateOrEditRuleSliceValues {
  name: string;
  virtualWarehouseId: IVirtualWarehouse["id"] | null;
  carrierId: string | null;
  appliesTo: CreateRuleAppliesTo;
  conditions: RuleCondition[];
  shippingWhitelistId: string | null;
}

type ICreateOrEditRuleSliceErrors = {
  [key in keyof ICreateOrEditRuleSliceValues]: string;
};

interface ICreateOrEditRuleSlice {
  values: ICreateOrEditRuleSliceValues;
  errors: ICreateOrEditRuleSliceErrors;
  touched: any;
  submittingStatus: ApiLoadingStatus;
  editingRuleUUID: IRule["uuid"] | null;
}

export const DEFAULT_CONDITION: RuleCondition = {
  operator: null,
  value: "",
  fact: null,
};

const CreateOrEditRuleSliceInitialState: ICreateOrEditRuleSlice = {
  values: {
    name: "",
    virtualWarehouseId: null,
    appliesTo: CreateRuleAppliesTo.ALL,
    conditions: [{ ...DEFAULT_CONDITION }],
    carrierId: null,
    shippingWhitelistId: null,
  },
  errors: {
    name: "",
    virtualWarehouseId: "",
    appliesTo: "",
    conditions: "",
    carrierId: "",
    shippingWhitelistId: "",
  },
  touched: "",
  submittingStatus: "IDLE",
  editingRuleUUID: null,
};

export const createOrEditRuleSlice = createSlice({
  name: "createOrEditRuleSlice",
  initialState: CreateOrEditRuleSliceInitialState,
  reducers: {
    setSubmittingStatus: (
      state,
      action: PayloadAction<ICreateOrEditRuleSlice["submittingStatus"]>
    ) => {
      state.submittingStatus = action.payload;
    },
    setEditingRuleUUID: (state, action: PayloadAction<IRule["uuid"]>) => {
      state.editingRuleUUID = action.payload;
    },
    setAppliesTo: (
      state,
      action: PayloadAction<ICreateOrEditRuleSlice["values"]["appliesTo"]>
    ) => {
      state.values.appliesTo = action.payload;
    },
    setName: (
      state,
      action: PayloadAction<ICreateOrEditRuleSlice["values"]["name"]>
    ) => {
      state.values.name = action.payload;
    },
    setVirtualWarehouseId: (
      state,
      action: PayloadAction<
        ICreateOrEditRuleSlice["values"]["virtualWarehouseId"]
      >
    ) => {
      state.values.virtualWarehouseId = action.payload;

      if (state.values.shippingWhitelistId) {
        state.values.shippingWhitelistId = null;
        state.values.carrierId = null;
      }
    },
    setConditions: (
      state,
      action: PayloadAction<ICreateOrEditRuleSlice["values"]["conditions"]>
    ) => {
      state.values.conditions = action.payload;
    },
    setShippingWhitelistId: (
      state,
      action: PayloadAction<
        ICreateOrEditRuleSlice["values"]["shippingWhitelistId"]
      >
    ) => {
      state.values.shippingWhitelistId = action.payload;
    },
    setCarrierId: (
      state,
      action: PayloadAction<ICreateOrEditRuleSlice["values"]["carrierId"]>
    ) => {
      state.values.carrierId = action.payload;
    },
    setErrors: (
      state,
      action: PayloadAction<ICreateOrEditRuleSlice["errors"]>
    ) => {
      state.errors = action.payload;
    },
    updateCondition: (
      state,
      action: PayloadAction<{ index: number; updatedCondition: IRuleCondition }>
    ) => {
      state.values.conditions = [
        ...state.values.conditions,
        { ...DEFAULT_CONDITION },
      ];
    },
    addCondition: (state, action: PayloadAction<number>) => {
      state.values.conditions = [
        ...state.values.conditions,
        { ...DEFAULT_CONDITION },
      ];
    },
    deleteCondition: (state, action: PayloadAction<number>) => {
      if (action.payload >= 0)
        state.values.conditions = [...state.values.conditions].splice(
          action.payload,
          1
        );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isModalClosed,
      (state, action: { type; payload: { modalName: modals } }) => {
        if (
          action.payload.modalName === "editRule" ||
          action.payload.modalName === "createRule"
        ) {
          Object.assign(state, CreateOrEditRuleSliceInitialState);
        }
      }
    );
  },
});

export const {
  setAppliesTo,
  setEditingRuleUUID,
  setSubmittingStatus,
  setShippingWhitelistId,
  deleteCondition,
  setErrors,
  setConditions,
  addCondition,
  updateCondition,
  setName,
  setVirtualWarehouseId,
  setCarrierId,
} = createOrEditRuleSlice.actions;

export const saveRuleThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
      createOrEditRuleSlice: { editingRuleUUID, values, errors },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const {
        shippingWhitelistId,
        conditions,
        appliesTo,
        name,
        virtualWarehouseId,
      } = values;

      const schema = CreateRuleValidationSchema;

      let updatedErrors = { ...errors };

      let isValid = true;

      try {
        schema.validateSync(values, { abortEarly: false });
      } catch (err: any) {
        err.inner &&
          err.inner.forEach((e: { path: string; message: string }) => {
            updatedErrors[e.path] = e.message;
          });

        isValid = false;
      }

      if (!isValid) {
        dispatch(setErrors(updatedErrors));
      } else {
        dispatch(setSubmittingStatus("LOADING"));

        const isEditing = editingRuleUUID;

        const payload = {
          conditions: appliesTo === CreateRuleAppliesTo.ALL ? [] : conditions,
          name,
          virtualWarehouseId,
          shippingWhitelistId: shippingWhitelistId || "",
        };

        const { error } = isEditing
          ? await updateRule(salesAccountUUID, editingRuleUUID, payload)
          : await createRule(salesAccountUUID, payload);

        if (!error) {
          dispatch(closeModal({ modalName: "editRule" }));
          dispatch(closeModal({ modalName: "createRule" }));
          dispatch(fetchRulesThunk());
        }

        dispatch(setSubmittingStatus("IDLE"));
      }
    }
  };
};
