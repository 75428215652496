export enum ShipmentContentsFieldNames {
  "shipmentPackageIndex" = "shipmentPackageIndex",
  "description" = "description",
  "commodityCode" = "commodityCode",
  "sku" = "sku",
  "quantity" = "quantity",
  "itemValue" = "itemValue",
  "currency" = "currency",
  "weight" = "weight",
  "countryOfOrigin" = "countryOfOrigin",
  "manufacturerDetails" = "manufacturerDetails",
  "shippingMethod" = "shippingMethod",
  "custom" = "custom",
  "totalShipmentItemQuantity" = "totalShipmentItemQuantity",
  "shipmentId" = "shipmentId",
}

export enum ShipmentDetailFieldNames {
  "customerReference" = "customerReference",
  "customerReference2" = "customerReference2",
  "reasonForExport" = "reasonForExport",
  "dutyOrTaxPaidBy" = "dutyOrTaxPaidBy",
  "invoiceNumber" = "invoiceNumber",
  "iossNumber" = "iossNumber",
  "dangerousGoods" = "dangerousGoods",
  "shipmentSourceName" = "shipmentSourceName",
  "shouldSplitDutyAndVat" = "shouldSplitDutyAndVat",
  "orderNumber" = "orderNumber",
  "integrationStoreID" = "integrationStoreID",
  "shipmentIntegrationID" = "shipmentIntegrationID",
}
