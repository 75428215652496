import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store";
import { fetchUsers } from "services/api/admin/index";
import { IDBUser } from "models/user/user.model";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";

interface IUsersSlice {
  loadingStatus: ApiLoadingStatus;
  users: IDBUser[];
}

const initialState: IUsersSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  users: [],
};

export const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<IDBUser[]>) => {
      state.users = action.payload;
      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },
    setStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
  },
});

export const { setData, setStatus } = usersSlice.actions;

export const fetchUsersThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));

    const { data, error } = await fetchUsers();

    if (error) {
      dispatch(setStatus(ApiLoadingStatus.FAILED));
    } else {
      dispatch(setData(data));
    }
  };
};
