import { SortDirection } from "interfaces/api.interface";
import { Column } from "react-table";
import { Filter } from "../TableFilter/types/filter.types";

export type ITableColumn<T extends object> = Column<T>;

export enum TableCellIds {
  "MENU" = "menu",
  "CHECKBOX" = "checkbox",
}

export interface TablePropsCommon<D extends object> {
  data: D[];
  filters?: { [x: string]: Filter<object> };
  allowExport?: boolean;
  columns: Column<D>[];
  pageSize: number;
  manualPagination?: boolean;
  isLoading: boolean;
  //hiddenColumns?: keyof D[];
  hiddenColumns?: string[];
  defaultSort?: keyof D;
  handleColumnSort?(order: { [x: string]: SortDirection }): void;
  sort?: {
    [x: string]: string;
  };
  onRowClick?: (row: D) => void;
  onSearchSubmit?(value: string): void;
  onRefresh?: () => void;
  erroredColumns?: { column: string; indices: number[] }[];
  downloadOptions?: { label: string; onClick: () => void }[];
  TableButtons?: React.ElementType;
  FilterComponent?: React.ElementType;
  defaultColumn?: {
    minWidth: number;
    width: number;
  };
  userColumns?: Object;
  hideSearch?: boolean;
  hideDownload?: boolean;
}

export interface TablePropsManualPagination {
  pageIndex: number;
  recordCount: number;
  pageCount?: number;
  onNextPage(): void;
  onPreviousPage(): void;
  tableRowQuantityChange?(): void;
}

export type TableProps<D extends object> =
  | (TablePropsCommon<D> & {
      manualPagination: true;
    } & TablePropsManualPagination)
  | (TablePropsCommon<D> & {
      manualPagination: false;
    } & Partial<TablePropsManualPagination>);
