import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { ICreateBulkShipmentsSlice } from "./CreateBulkShipmentInitialState.interface";

export const CreateBulkShipmentInitialState: ICreateBulkShipmentsSlice = {
  printLater: true,
  validatingStatus: ApiLoadingStatus.IDLE,
  validatedData: {
    errors: [],
    ready: [],
    skipped: [],
    valid: [],
  },
  submitStatus: ApiLoadingStatus.IDLE,
  submitCount: 0,
  filename: null,
  fileContainsHeaderRow: true,
  data: {
    data: [],
    errors: [],
    meta: {
      fields: [],
      delimiter: "",
      linebreak: "",
      aborted: false,
      truncated: false,
      cursor: 0,
    },
  },
  selectedProfileUUID: null,
};
