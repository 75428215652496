import { Meta, Query, SortDirection } from "interfaces/api.interface";
import fetch from "isomorphic-unfetch";
import { camelcaseObjectDeep } from "utils/camelcaseKeys";

export const getApiBaseUrl = () => {
  return process.env.NEXT_PUBLIC_NEST_API_BASE_URL || "http://localhost:3002";
};

const BuildQuery = <T>(query: Query<T> = {}): string => {
  let result = [];

  if (query.limit) result.push(`limit=${query.limit}`);

  if (query.offset) result.push(`offset=${query.offset}`);

  if (query.sort) {
    Object.keys(query.sort).map((key) => {
      const order = query.sort[key];
      result.push(`sort=${order === SortDirection.ASC ? key : `-${key}`}`);
    });
  }

  if (query.filters)
    Object.keys(query.filters).map((key) => {
      if (query.filters[key]) result.push(`${key}=${query.filters[key]}`);
    });

  return result.length > 0 ? "?" + result.join("&") : "";
};

export type IFetchResponseBase = {
  message?: string;
  error?: boolean;
  statusCode?: any;
};

export type IFetchPostResponse<T> = {
  data?: T;
} & IFetchResponseBase;

export type IFetchResponseSingle<T> = {
  data?: T;
  message?: string;
} & IFetchResponseBase;

export type IFetchResponsePaginated<T, M = {}> = {
  data?: T;
  meta?: Meta<M>;
} & IFetchResponseBase;

export type IFetcher<T> = {
  url: string;
  method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
  body?: object;
  query?: Query<T>;
};

export async function fetcher<T>({
  url,
  method = "GET",
  body,
  query,
}: IFetcher<T>): Promise<IFetchResponseSingle<T> | IFetchResponsePaginated<T>> {

  try {
    const fetchUrl = `${getApiBaseUrl()}${url}${BuildQuery(query)}`;

    const options: RequestInit = {
      method,
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + window["accessToken"],
      },
      ...(body && { body: JSON.stringify(body || {}) }),
    };

    const response = await fetch(fetchUrl, options);

    const { data, message, statusCode, meta } = await response.json();

    if (!response.ok) {
      return {
        error: true,
        statusCode,
        message,
      };
    }

    return {
      ...(data && { data: camelcaseObjectDeep(data), meta }),
      ...(message && { message }),
      error: false,
      statusCode,
    };
  } catch (err) {
    return {
      error: true,
      message: err,
    };
  }
}
