import { ModalHeader } from "components/atoms/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { RuleForm } from "../RuleForm";
import { closeModal } from "store/modalSlice";
import { saveRuleThunk } from "store/createOrEditRuleSlice/index.slice";

export const CreateRuleModal = () => {
  const { submittingStatus } = useSelector((state: RootState) => {
    return state.createOrEditRuleSlice;
  });

  const dispatch = useDispatch();

  return (
    <>
      <ModalHeader>Create rule</ModalHeader>
      <RuleForm
        handleSubmit={() => dispatch(saveRuleThunk())}
        isSubmitting={submittingStatus === "LOADING"}
        submitButtonLabel="Create rule"
        onCancel={() => {
          dispatch(closeModal({ modalName: "createRule" }));
        }}
      />
    </>
  );
};
