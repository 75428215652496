import { Roles } from "enum/roles.enum";

export const roleGuard = (
  userRoles: Roles[] = [],
  showForRoles: Roles[]
): boolean => {
  let show = false;

  userRoles.forEach((role) => {
    if (showForRoles.includes(role)) {
      show = true;
    }
  });

  return show;
};
