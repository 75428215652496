import { fetcher, IFetchPostResponse } from "./api";

export async function post<R>(
  url: string,
  data?: object
): Promise<IFetchPostResponse<R>> {
  return await fetcher<R>({
    url: url,
    method: "POST",
    ...(data && { body: data }),
  });
}
