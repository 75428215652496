import { IShipmentPackageGrouped } from "models/shipmentPackage/shipmentPackage.model";

export const calculateGirth = (
  shipmentPackage: IShipmentPackageGrouped
): number => {
  const { length, width, height } = shipmentPackage;

  const sortedDimensions = [length, width, height].sort((a, b) => b - a);

  return sortedDimensions[0] + 2 * (sortedDimensions[1] + sortedDimensions[2]);
};
