import { Loader } from "components/atoms/Loader";
import { RatesTestIds } from "./RatesTestIds.enum";
import { Center } from "components/atoms/Center";

export const RatesLoadingPanel = () => {
  return (
    <div className="h-24" data-testid={RatesTestIds.LOADING_RATES_PANEL}>
      <Center>
        <div className="text-center">
          <div className="inline-block mb-2">
            <Loader />
          </div>
          <div className="text-sm">Fetching rates...</div>
        </div>
      </Center>
    </div>
  );
};
