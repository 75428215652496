import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GENERIC_TOAST_DELETED_MESSAGE,
  GENERIC_TOAST_SAVED_MESSAGE,
  GENERIC_TOAST_ERROR_MESSAGE,
} from "constants/strings";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { IRule } from "models/rule/rule.model";
import { toast } from "react-hot-toast";
import { deleteRule, fetchRules, toggleRuleStatus } from "services/api/rules";
import { AppThunk, RootState } from "store";

interface IRulesSlice {
  loadingStatus: ApiLoadingStatus;
  rules: IRule[];
}

export const RulesSliceInitialState: IRulesSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  rules: [],
};

export const rulesSlice = createSlice({
  name: "rulesSlice",
  initialState: RulesSliceInitialState,
  reducers: {
    setData: (state, action: PayloadAction<IRule[]>) => {
      state.rules = action.payload;
      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },

    setStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
  },
});

export const { setData, setStatus } = rulesSlice.actions;

export const fetchRulesThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const { data, error } = await fetchRules(salesAccountUUID);

      if (data && !error) {
        dispatch(setData(data));
      }
    }
  };
};

export const toggleRuleThunk = (ruleUuid: IRule["uuid"]): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const { error } = await toggleRuleStatus(salesAccountUUID, ruleUuid);

      if (!error) {
        dispatch(fetchRulesThunk());
        toast.success(GENERIC_TOAST_SAVED_MESSAGE);
      }

      if (error) {
        toast.error(GENERIC_TOAST_ERROR_MESSAGE);
      }
    }
  };
};

export const deleteRuleThunk = (ruleUuid: IRule["uuid"]): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const { error } = await deleteRule(salesAccountUUID, ruleUuid);

      if (!error) {
        toast.success(GENERIC_TOAST_DELETED_MESSAGE);
        dispatch(fetchRulesThunk());
      }

      if (error) {
        toast.error(GENERIC_TOAST_ERROR_MESSAGE);
      }
    }
  };
};
