import { Box, IStyledBoxProps } from "../Box";
import cx from "classnames";
import { useTheme } from "styled-components";

export type ILogoCircleSizes =
  | "xxxs"
  | "xxs"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl";

export const LogoCircleSizes: {
  [key in ILogoCircleSizes]: number;
} = {
  xxxs: 6,
  xxs: 7,
  xs: 8,
  sm: 9,
  md: 12,
  lg: 16,
  xl: 20,
  "2xl": 24,
};

type ILogoProps = IStyledBoxProps & {
  imageUrl: string;
  size: ILogoCircleSizes;
};

export const Logo = (props: ILogoProps) => {
  const { imageUrl, size, className, style, ...rest } = props;

  const theme = useTheme();

  return (
    <Box
      className={cx("p-1 overflow-hidden border rounded-full bg-white", className)}
      style={{
        height: `var(--space${LogoCircleSizes[size]})`,
        width: `var(--space${LogoCircleSizes[size]})`,
        borderColor: theme.borderColor,
        ...style,
      }}
      {...rest}
    >
      <img src={imageUrl} />
    </Box>
  );
};
