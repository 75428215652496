import { Loader } from "components/atoms/Loader";
import { useEffect } from "react";
import { ModalBody, ModalHeader } from "components/atoms/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { openModal } from "store/modalSlice";
import { setShipmentsFromPickupsQueryOffset } from "store/pickupSlice/index.slice";
import { Button } from "components/atoms/Button";
import { formatDate } from "utils/formatDate";
import dayjs from "dayjs";
import { Data } from "components/organisms/Data";
import { PickupShipmentsColumns } from "./PickupShipmentsColumns";
import { fetchShipmentsRelatedToPickupsThunk } from "store/pickupSlice/thunks/fetchPickUpShipmentsThunk";

export const ViewPickupModal = () => {
  const {
    selectedShipment,
    loadingShipmentsFromPickupsStatus,
    shipmentsFromPickupsMeta,
    shipmentsFromPickupsData,
  } = useSelector((state: RootState) => {
    return state.pickupSlice;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchShipmentsRelatedToPickupsThunk());
  }, []);

  const handleRefresh = () => {
    dispatch(fetchShipmentsRelatedToPickupsThunk());
  };

  const handleChangePage = async (page: number) => {
    await dispatch(setShipmentsFromPickupsQueryOffset(page));

    dispatch(fetchShipmentsRelatedToPickupsThunk());
  };

  return (
    <>
      <ModalHeader>
        {selectedShipment?.isCancelled ? "(CANCELLED)" : ""}{" "}
        {selectedShipment?.prn}
      </ModalHeader>
      <ModalBody>
        <div>Address: {selectedShipment?.address}</div>
        <div>Carrier: {selectedShipment?.carrier}</div>
        <div>Created date: {formatDate(selectedShipment?.createdDate)}</div>
        <>
          <div>
            Pickup date/time:{" "}
            {dayjs(new Date(selectedShipment?.pickUpFromDateLocalTime)).format(
              "DD MMM YYYY"
            )}{" "}
            - From:{" "}
            {dayjs(new Date(selectedShipment?.pickUpFromDateLocalTime)).format(
              "HH:mm"
            )}{" "}
            {">"} To:{" "}
            {dayjs(new Date(selectedShipment?.pickUpToDateLocalTime)).format(
              "HH:mm"
            )}
          </div>
          <div>Pickup Instructions: {selectedShipment?.pickUpInstructions}</div>
        </>
        <div className="flex flex-col mt-4">
          <Data
            meta={{
              ...shipmentsFromPickupsMeta,
            }}
            data={shipmentsFromPickupsData}
            columns={PickupShipmentsColumns}
            onNextPage={handleChangePage}
            onPrevPage={handleChangePage}
            loadingStatus={loadingShipmentsFromPickupsStatus}
            onRefresh={handleRefresh}
            manualPagination={true}
            tableRowQuantityChange={() => {
              dispatch(fetchShipmentsRelatedToPickupsThunk());
            }}
          />
        </div>
        {selectedShipment?.isCancelled ? null : (
          <div className="mt-4">
            <Button
              isBlock
              onClick={() => {
                dispatch(
                  openModal({
                    name: "confirmCancelPickup",
                  })
                );
              }}
              size="lg"
              isDisabled={false}
              colorScheme="primary"
            >
              Cancel Pickup
            </Button>
          </div>
        )}
      </ModalBody>
    </>
  );
};
