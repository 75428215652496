import { Center } from "components/atoms/Center";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/atoms/Popover";
import { TableCellLeftSlot } from "components/atoms/Table/TableCellLeftSlot";
import { IShipment } from "models/shipment/shipment.model";
import { FaExclamationCircle } from "react-icons/fa";
import { ShipmentErrors } from ".";
import { getFirstShipmentError } from "./ShipmentErrors.utils";

export const ShipmentErrorsTableCell = ({
  shipment,
}: {
  shipment: IShipment;
}) => {
  return (
    <div className="flex items-center w-full">
      <Popover trigger="hover" placement="right-start" maxW={340}>
        <PopoverTrigger>
          <TableCellLeftSlot>
            <div className="w-full h-full rounded-sm bg-red3">
              <Center>
                <FaExclamationCircle className="text-red9" />
              </Center>
            </div>
          </TableCellLeftSlot>
        </PopoverTrigger>
        <PopoverContent>
          <div className="p-4 text-xs">
            <ShipmentErrors shipment={shipment} />
          </div>
        </PopoverContent>
      </Popover>
      <div className="overflow-hidden leading-none whitespace-nowrap">
        {getFirstShipmentError(shipment)}
      </div>
    </div>
  );
};
