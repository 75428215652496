import { HTMLProps } from "react";
import { useTheme } from "styled-components";
import cx from "classnames";

export type IDividerProps = {
  color?: "weak" | "strong";
  orientation?: "horizontal" | "vertical";
} & HTMLProps<HTMLDivElement>;

export const Divider = (props: IDividerProps) => {
  const theme = useTheme();

  const {
    style,
    className,
    color = "weak",
    orientation = "horizontal",
    ...rest
  } = props;

  return (
    <div
      className={cx(
        {
          "border-r": orientation === "vertical",
          "border-t": orientation === "horizontal",
        },
        className
      )}
      style={{
        borderColor:
          color === "weak" ? theme.borderColor : theme.borderColorStrong,
        ...style,
      }}
      {...rest}
    />
  );
};
