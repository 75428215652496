import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { ICreateSingleLabelSlice } from "./createSingleLabelSlice.interface";

export const CreateSingleLabelSliceInitialState: ICreateSingleLabelSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  loadingCommercialInvoiceStatus: ApiLoadingStatus.IDLE,
  loadingCN22Status: ApiLoadingStatus.IDLE,
  loadingPackingSlipStatus: ApiLoadingStatus.IDLE,
  createLabelInBrowserStatus: ApiLoadingStatus.IDLE,
  shipmentId: null,
  labelJob: null,
};
