import { createContext } from "utils/react/createContext";

export interface IDropdownContext {
  isOpen: boolean;
  mode: "onClick" | "onHover";
  closeOnClick: boolean;
  triggerElement: HTMLElement | null;
  setTriggerElement(target: HTMLElement): void;
  setPopoverElement(target: HTMLElement): void;
  popperPosition: any;
  setOpen(): void;
  onToggle(): void;
  onClose(): void;
}

export const [DropdownContext, useDropdownContext] =
  createContext<IDropdownContext>();
