import { Panel } from "components/atoms/Panel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { getLabelUrl } from "utils/getLabelUrl";
import { markLabelJobAsDownloaded } from "services/api/labels";
import { ILabelJob } from "models/labelJob/labelJob.model";
import { fetchCurrentLabelJobsThunk } from "store/currentLabelJobsSlice/index.slice";
import {
  setQueryLabelJob,
  setQueryToDefault,
} from "store/shipmentsSlice/index.slice";
import { closeModal } from "store/modalSlice";
import { useRouter } from "next/router";
import { paths } from "enum/paths.enum";
import { createLabelJobThunk } from "store/labelJobsSlice/index.slice";
import { ShipmentStatusCategoryStrings } from "enum/shipment-status-category-string.enum";
import { LabelJobCardHeader } from "./LabelDrawerCardHeader";
import { LabelJobCardTitle } from "./LabelDrawerCardTitle";
import { LabelJobCardFooter } from "./LabelDrawerCardFooter";
import { LabelJobCardErrorMessage } from "./LabelDrawerCardErrorMessage";
import { openModal } from "store/modalSlice";

export const LabelJob = ({ item }: { item: ILabelJob }) => {
  const router = useRouter();

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { alertType } = useSelector((state: RootState) => {
    return state.authSlice;
  });
  const isOnstop = alertType === "onstop";

  const dispatch = useDispatch();

  const handleDownloadClick = (labelJob: ILabelJob) => {
    if (isOnstop) {
      dispatch(openModal({ name: "customerOnStopWarning" }));
    } else {
      window.open(getLabelUrl(labelJob.labelUrl), "_blank");

      markLabelJobAsDownloaded(salesAccountUUID, labelJob.uuid).then(() => {
        dispatch(fetchCurrentLabelJobsThunk());
      });
    }
  };

  const handleViewShipments = () => {
    router.push(
      paths.SHIPMENTS_INDEX + `?status=${ShipmentStatusCategoryStrings.TO_FIX}`
    );

    dispatch(setQueryToDefault());

    dispatch(setQueryLabelJob(item));

    dispatch(closeModal({ modalName: "labels" }));
  };

  const handleViewErrors = () => {
    router.push(
      paths.SHIPMENTS_INDEX + `?status=${ShipmentStatusCategoryStrings.TO_FIX}`
    );

    dispatch(setQueryToDefault());

    dispatch(setQueryLabelJob(item));

    dispatch(closeModal({ modalName: "labels" }));
  };

  const handleRetry = () => {
    dispatch(createLabelJobThunk(item.shipmentIds));
  };

  return (
    <Panel key={item.uuid}>
      <LabelJobCardHeader item={item} />
      <div className="p-5">
        <LabelJobCardTitle
          item={item}
          handleViewShipments={handleViewShipments}
        />

        <LabelJobCardErrorMessage
          item={item}
          handleRetry={handleRetry}
          handleViewErrors={handleViewErrors}
        />
        <LabelJobCardFooter
          item={item}
          handleDownloadClick={handleDownloadClick}
        />
      </div>
    </Panel>
  );
};
