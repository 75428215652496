import { useSelect, UseSelectProps, UseSelectReturn } from "./UseSelect";
import { SelectInput } from "./SelectInput";
import { SelectDropdown } from "./SelectDropdown";
import { SelectMenuItems } from "./SelectMenuItems";
import { SelectContextProvider } from "./SelectContextProvider";
import { Portal } from "../Portal";
import OutsideClickHandler from "react-outside-click-handler";
import { FocusLock } from "../FocusLock";

export type SelectContext = UseSelectReturn;

export const Select = <T,>(props: UseSelectProps<T> & { buttonHeight?: string }): JSX.Element => {
  const context = useSelect<T>(props);

  const { onClose, isOpen, popoverElement, dropdownWidth, itemHeight } = context;

  const handleOutsideClick = (e: MouseEvent) => {
    if (isOpen) {
      if (popoverElement && !popoverElement.contains(e.target as Node)) {
        onClose();
      }
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <SelectContextProvider value={context}>
        <SelectInput buttonHeight={props.buttonHeight} />
        {isOpen && (
          <Portal>
            <FocusLock disabled={!isOpen} autoFocus={true} returnFocus={true}>
              <SelectDropdown isOpen={isOpen} width={dropdownWidth}>
                <SelectMenuItems<T> itemHeight={itemHeight} />
              </SelectDropdown>
            </FocusLock>
          </Portal>
        )}
      </SelectContextProvider>
    </OutsideClickHandler>
  );
};
