import cx from "classnames";

interface ICenterProps {
  testId?: string;
  children: React.ReactNode;
  className?: string;
}

export const Center = (props: ICenterProps) => {
  const { children, className, testId } = props;

  return (
    <div
      className={cx(
        "flex items-center justify-center h-full w-full",
        className
      )}
      {...(testId && { "data-testid": testId })}
    >
      {children}
    </div>
  );
};
