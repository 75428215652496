import { Panel } from "components/atoms/Panel";
import { PrintDimension } from "components/atoms/PrintDimension";
import { PrintWeight } from "components/atoms/PrintWeight";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { PageSection } from "../../PageSection";
import cx from "classnames";
import { getTrackingUrl } from "utils/getTrackingUrl";
import { DEFAULT_DIMENSIONS_UNIT, DEFAULT_WEIGHT_UNIT } from "constants/misc";
import { getShipmentDirection } from "utils/getShipmentDirection";
import { Stack } from "components/atoms/Stack";
import { Divider } from "components/atoms/Divider";
import { ViewShipmentModalItemContents } from "./ViewShipmentContents";
import { PanelTitle } from "components/atoms/Panel/PanelTitle";
import { ShipmentTracking } from "../ViewShipmentTracking";

export const ViewShipmentPackages = () => {
  const { currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });

  return (
    <PageSection title="Packages">
      <Panel>
        {currentShipment && (
          <Stack spacing={0} divider={<Divider />}>
            {currentShipment.packages.map((item, i) => {
              return (
                <div key={i} className={cx("px-5 py-5")}>
                  <div className="flex">
                    <div>
                      <PanelTitle>Package {i + 1}</PanelTitle>
                      <div className="text-sm leading-none text-gray10">
                        <PrintDimension
                          dimensionUnit={
                            settings?.dimensionsUnit || DEFAULT_DIMENSIONS_UNIT
                          }
                          value={item.length}
                        />
                        (L) x{" "}
                        <PrintDimension
                          dimensionUnit={
                            settings?.dimensionsUnit || DEFAULT_DIMENSIONS_UNIT
                          }
                          value={item.width}
                        />
                        (W) x{" "}
                        <PrintDimension
                          dimensionUnit={
                            settings?.dimensionsUnit || DEFAULT_DIMENSIONS_UNIT
                          }
                          value={item.height}
                        />{" "}
                        (H) x{" "}
                        <PrintWeight
                          weightUnit={
                            settings?.weightUnit || DEFAULT_WEIGHT_UNIT
                          }
                          value={item.weight}
                        />
                      </div>
                    </div>
                    <div className="flex items-center ml-auto">
                      <a
                        href={
                          item.trackingCode
                            ? getTrackingUrl(
                                item.trackingCode,
                                currentShipment?.carrier?.id || null,
                                getShipmentDirection(
                                  currentShipment.fromAddressCountryIso,
                                  currentShipment.toAddressCountryIso
                                )
                              )
                            : "#"
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="inline-block"
                      >
                        <div className="mr-3 text-sm font-bold flex cursor-pointer text-blue10 hover:underline w-fit">
                          {item.trackingCode
                            ? item.trackingCode
                            : "Tracking code not available"}
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="my-4" />
                  <ViewShipmentModalItemContents packageId={item.index} />
                  {currentShipment.status === "label_created" ? (
                    <>
                      <Divider className="my-4" />
                      <div>
                        <div
                          className={cx("flex", {
                            "justify-End":
                              currentShipment.estimatedDeliveryDate,
                          })}
                        >
                          <PanelTitle>Tracking</PanelTitle>
                          {currentShipment.estimatedDeliveryDate ? (
                            <div className="flex items-center ml-auto text-sm">
                              <span className="mr-1">Est. Delivery: </span>
                              {currentShipment.estimatedDeliveryDate}
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                        <ShipmentTracking trackingCode={item.trackingCode} />
                      </div>
                    </>
                  ) : null}
                </div>
              );
            })}
          </Stack>
        )}
      </Panel>
    </PageSection>
  );
};
