import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISelectOption } from "components/atoms/Select/UseSelect";
import { ICountry } from "models/country/country.model";
import { fetchCountries } from "services/api/countries";
import { FetchCountriesResponseDto } from "services/api/countries/dtos/fetch-countries.response.dto";
import { AppThunk } from "store";
import { sortBy } from "lodash";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";

export interface ICountriesSlice {
  loadingStatus: ApiLoadingStatus;
  countriesSelectOptions: ISelectOption<ICountry, string>[];
  countries: FetchCountriesResponseDto;
}

export const CountriesSliceInitialState: ICountriesSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  countriesSelectOptions: [],
  countries: [],
};

const generateOptions = (
  countries: FetchCountriesResponseDto
): ISelectOption<ICountry, string>[] => {
  const result: ISelectOption<ICountry, string>[] = [];

  countries.map((country) => {
    result.push({
      label: country.name,
      value: country.isoCode,
      ...country,
    });
  });

  return sortBy(result, "label");
};

export const countriesSlice = createSlice({
  name: "countriesSlice",
  initialState: CountriesSliceInitialState,
  reducers: {
    setData: (state, action: PayloadAction<FetchCountriesResponseDto>) => {
      state.countries = action.payload;
      state.countriesSelectOptions = generateOptions(action.payload);
      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },
    setStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
  },
});

export const { setData, setStatus } = countriesSlice.actions;

export const fetchCountriesThunk = (): AppThunk => {
  return async (dispatch) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));

    const { data, error } = await fetchCountries();

    if (error || !data) {
      dispatch(setStatus(ApiLoadingStatus.FAILED));
    } else {
      dispatch(setData(data));
    }
  };
};
