import { IWizardStep, useWizard } from "hooks/useWizard";
import { WizardContextProvider } from "../Wizard";
import { BulkShipmentProfileWizardMapColumns } from "./BulkShipmentProfileWizardMapColumns";
import { BulkShipmentProfileWizardSelectFile } from "./BulkShipmentProfileWizardSelectFile";
import { BulkShipmentProfileWizardFinalize } from "./BulkShipmentProfileWizardFinalize";
import cx from "classnames";
import { CreateShipmentProfileRequestDto } from "services/api/shipmentProfiles/dtos/create-shipment-profile.request.dto";
import { createBulkShipmentProfile } from "services/api/shipmentProfiles";
import { useDispatch, useSelector } from "react-redux";
import { fetchBulkShipmentProfilesThunk } from "store/bulkShipmentProfilesSlice/index.slice";
import toast from "react-hot-toast";
import { closeModal } from "store/modalSlice";
import { RootState } from "store";
import { setLoadingStatus } from "store/createOrUpdateBulkShipmentProfileSlice/index.slice";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";

export const BulkShipmentProfileWizard = () => {
  const dispatch = useDispatch();

  const { data, title, mappings } = useSelector((state: RootState) => {
    return state.createOrUpdateBilkShipmentProfileSlice;
  });

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const profiles = useSelector((state: RootState) => {
    return state.bulkShipmentProfilesSlice.profiles;
  });

  const handleSubmit = async () => {
    const duplicate = profiles.some((value) => value.profileName === title);

    if (duplicate) {
      toast.error("Profile name already exists");
      return;
    }

    const payload: CreateShipmentProfileRequestDto = {
      profileName: title,
      mappings: mappings,
      originalData: data,
    };

    dispatch(setLoadingStatus(ApiLoadingStatus.LOADING));

    const { error } = await createBulkShipmentProfile(
      salesAccountUUID,
      payload
    );

    if (!error) {
      dispatch(setLoadingStatus(ApiLoadingStatus.IDLE));
      dispatch(fetchBulkShipmentProfilesThunk());
      dispatch(closeModal({ modalName: "createBulkShipmentProfile" }));
      toast.success("Profile created");
    }
  };

  const steps: IWizardStep[] = [
    {
      name: "selectFile",
      component: <BulkShipmentProfileWizardSelectFile />,
    },
    {
      name: "mapFiles",
      component: <BulkShipmentProfileWizardMapColumns />,
    },
    {
      name: "finalize",
      component: (
        <BulkShipmentProfileWizardFinalize handleSubmit={handleSubmit} />
      ),
    },
  ];

  const wizard = useWizard({
    steps,
  });

  return (
    <div className="p-8">
      <WizardContextProvider value={{ ...wizard }}>
        {steps.map((step, i) => {
          return (
            <div
              key={i}
              className={cx({
                hidden: i !== wizard.activeStepIndex,
              })}
            >
              {step.component}
            </div>
          );
        })}
      </WizardContextProvider>
    </div>
  );
};
