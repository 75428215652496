import { AnyAction } from "@reduxjs/toolkit";

export enum ModalSliceActions {
  "OPEN_MODAL" = "openModal",
  "SET_MODAL_SIZE" = "setModalSize",
  "CLOSE_MODAL" = "closeModal",
}

export function isModalClosed(action: AnyAction) {
  return action.type === `modalSlice/${ModalSliceActions.CLOSE_MODAL}`;
}

export function isModalOpened(action: AnyAction) {
  return action.type === `modalSlice/${ModalSliceActions.OPEN_MODAL}`;
}
