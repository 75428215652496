import {
  useTable,
  TableInstance,
  Column,
  ColumnInstance,
  HeaderGroup,
  Cell,
  Row,
} from "react-table";
import { useTheme } from "styled-components";

export interface TableProps<D extends object> {
  data: D[];
  columns: Column<D>[];
}

export const defaultColumn = {
  width: 150, // width is used for both the flex-basis and flex-grow
};

export const SimpleTableHeaderCell = <T extends object>({
  column,
}: {
  column: ColumnInstance<T>;
}) => {
  const { style } = column.getHeaderProps();

  const theme = useTheme();

  return (
    <th
      {...column.getHeaderProps()}
      className="pb-3 font-medium leading-none text-left border-b text-muted"
      style={{
        ...style,
        borderColor: theme.borderColor,
      }}
    >
      {column.render("Header")}
    </th>
  );
};

export const SimpleTableHeader = <T extends object>({
  headerGroups,
}: {
  headerGroups: HeaderGroup<T>[];
}) => {
  return (
    <thead>
      {headerGroups.map((headerGroup, i) => (
        <tr {...headerGroup.getHeaderGroupProps()} key={i}>
          {headerGroup.headers.map((column) => (
            <SimpleTableHeaderCell column={column} key={column.id} />
          ))}
        </tr>
      ))}
    </thead>
  );
};

export const SimpleTableBodyCell = <T extends object>({
  cell,
}: {
  cell: Cell<T>;
}) => {
  const theme = useTheme();

  return (
    <td
      {...cell.getCellProps()}
      className="border-b h-11"
      style={{
        ...cell.getCellProps().style,
        borderColor: theme.borderColor,
      }}
    >
      {cell.render("Cell")}
    </td>
  );
};

export const SimpleTableRow = <T extends object>({ row }: { row: Row<T> }) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell, index) => {
        return <SimpleTableBodyCell cell={cell} key={index} />;
      })}
    </tr>
  );
};

export const SimpleTableBody = <T extends object>({
  getTableBodyProps,
  prepareRow,
  rows,
}: Pick<TableInstance<T>, "getTableBodyProps" | "prepareRow" | "rows">) => {
  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row: Row<T>, i) => {
        prepareRow(row);
        return <SimpleTableRow row={row} key={i} />;
      })}
    </tbody>
  );
};

export const SimpleTable = <T extends object>({
  data,
  columns,
}: TableProps<T>) => {
  const extendedUseTable = useTable as (...args: any[]) => TableInstance<T>;

  const useTableData = extendedUseTable({
    columns,
    data,
    defaultColumn,
  });

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTableData;

  return (
    <table className="w-full max-w-full text-sm table-fixed">
      <SimpleTableHeader headerGroups={headerGroups} />
      <SimpleTableBody
        rows={rows}
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
      />
    </table>
  );
};
