export const formatAddressee = ({
  firstName,
  lastName,
  company,
  street1,
  city,
  zip,
  countryIsoCode,
}: {
  firstName: string | null;
  lastName: string | null;
  company: string | null;
  street1?: string | null;
  zip: string | null;
  city?: string | null;
  countryIsoCode: string | null;
}) => {
  let addressee = [];

  if (company) addressee.push(company);

  if (firstName && lastName) addressee.push(firstName + " " + lastName);

  if (!firstName && lastName) addressee.push(lastName);

  if (street1) addressee.push(street1);

  if (city) addressee.push(city);

  if (zip) addressee.push(zip);

  if (countryIsoCode) addressee.push(countryIsoCode);

  return addressee.join(", ");
};
