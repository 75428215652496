import cx from "classnames";

export const TableTextCell = ({
  value,
  className,
  title,
}: {
  value: string | number | null | undefined;
  className?: string;
  title?: string;
}) => {
  return (
    <div
      className={cx("w-full overflow-hidden whitespace-nowrap", className)}
      title={title ? title : value?.toString()}
    >
      {value}
    </div>
  );
};
