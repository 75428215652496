import { Roles } from "enum/roles.enum";
import { IUser } from "models/user/user.model";
import { roleGuard } from "utils/roleGuard";

export class PermissionCheck {
  user: IUser | null;

  constructor({ user }: { user: IUser | null }) {
    this.user = user;
  }

  addresses = {
    canCreate: (): boolean => {
      return true;
    },

    canEdit: (): boolean => {
      return true;
    },

    canDelete: (): boolean => {
      return true;
    },
  };

  bulkShipments = {
    canCreate: (): boolean => {
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
            Roles.ITD_SALES_PEOPLE_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },

    canView: (): boolean => {
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
            Roles.ITD_SALES_PEOPLE_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },
  };

  pickup = {
    canCreate: (): boolean => {
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
            Roles.ITD_SALES_PEOPLE_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },

    canView: (): boolean => {
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
            Roles.ITD_SALES_PEOPLE_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },
  };

  bulkShipmentProfiles = {
    canCreate: (): boolean => {
      return true;
    },

    canEdit: (): boolean => {
      return true;
    },

    canDelete: (): boolean => {
      return true;
    },
  };

  labelJobs = {
    canView: (): boolean => {
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
            Roles.ITD_SALES_PEOPLE_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },
  };

  quotes = {
    canCreate: (): boolean => {
      return true;
    },

    canEmailQuote: (): boolean => {
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_STAFF,
          ])) ||
        false
      );
    },

    canConvertQuoteToShipment: (): boolean => {
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },
  };

  rules = {
    canCreate: (): boolean => {
      return true;
    },

    canEdit: (): boolean => {
      return true;
    },

    canDelete: (): boolean => {
      return true;
    },

    canSaveRule: (): boolean => {
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
            Roles.ITD_STAFF,
          ])) ||
        false
      );
    },
  };

  shipments = {
    canGenerate: (): boolean => {
      // this is the actual function to generate a real label.
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },

    canDelete: (): boolean => {
      // this is the actual function to generate a real label.
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },

    canCreate: (): boolean => {
      // this is the function to show the create shipment button in the header
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
            Roles.ITD_SALES_PEOPLE_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },

    canView: (): boolean => {
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
            Roles.ITD_SALES_PEOPLE_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },
  };

  settings = {
    canEdit: (): boolean => {
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_CUSTOMER,
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },
  };

  salesAccounts = {
    canChange: (): boolean => {
      return (
        (this.user &&
          roleGuard(this.user["https://connexx.com/roles"], [
            Roles.ITD_ADMIN_FOR_SALES_ACCOUNTS,
            Roles.ITD_SALES_PEOPLE_FOR_SALES_ACCOUNTS,
          ])) ||
        false
      );
    },
  };
}
