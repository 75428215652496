import { Stack } from "components/atoms/Stack";
import { Attribute, AttributeTextCell } from "components/atoms/Attribute";
import { Panel } from "components/atoms/Panel";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { formatName } from "./ViewShipmentUtils.utils";
import { formatAddressee } from "utils/formatAddressee";
import { Divider } from "components/atoms/Divider";
import { FaChevronRight } from "react-icons/fa";
import { Flag } from "components/atoms/Flag";
import { PanelBody } from "components/atoms/Panel/PanelBody";
import { useDisclosure } from "hooks/useDisclosure";

const AddressRow = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => {
  return (
    <Attribute label={label}>
      <AttributeTextCell>{children}</AttributeTextCell>
    </Attribute>
  );
};

const Address = ({
  company,
  name,
  phone,
  email,
  title,
  street1,
  street2,
  city,
  zip,
  countyState,
  countryIsoCode,
  eori,
  vat,
}: {
  company: string;
  name: string;
  phone: string;
  email: string;
  title: string;
  street1: string;
  street2: string;
  city: string;
  zip: string;
  countyState: string;
  countryIsoCode: string;
  eori: string;
  vat: string;
}) => {
  const { countries } = useSelector((state: RootState) => {
    return state.countriesSlice;
  });

  const country = countries.find((country) => {
    return country.isoCode === countryIsoCode;
  });

  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <div className="flex items-center px-5 py-4" onClick={onToggle}>
        <div className="w-20">{title}</div>
        <Stack spacing={2} direction="horizontal">
          <Flag size="space5" countryCode={countryIsoCode} />
          <div>
            {formatAddressee({
              firstName: name,
              lastName: name,
              company,

              countryIsoCode,
              zip,
            })}
          </div>
        </Stack>

        <div className="ml-auto text-muted">
          <FaChevronRight />
        </div>
      </div>

      <PanelBody
        style={{
          display: isOpen ? "block" : "none",
        }}
      >
        <Stack spacing={3}>
          <AddressRow label="Name">{name || "-"}</AddressRow>
          <AddressRow label="Phone">{phone || "-"}</AddressRow>
          <AddressRow label="Email">{email || "-"}</AddressRow>
          <AddressRow label="Company">{company || "-"}</AddressRow>
          <AddressRow label="Address line 1">{street1 || "-"}</AddressRow>
          <AddressRow label="Address line 2">{street2 || "-"}</AddressRow>
          <AddressRow label="City">{city || "-"}</AddressRow>
          <AddressRow label="County / state">{countyState || "-"}</AddressRow>
          <AddressRow label="Postcode / zip">{zip || "-"}</AddressRow>
          <AddressRow label="Country">
            {country?.name || countryIsoCode || "-"}
          </AddressRow>
          <AddressRow label="Eori">{eori || eori || "-"}</AddressRow>
          <AddressRow label="Vat">{vat || vat || "-"}</AddressRow>
        </Stack>
      </PanelBody>
    </>
  );
};

export const ShipmentToFrom = () => {
  const { currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  return (
    <Panel className="text-sm">
      <Stack divider={<Divider />}>
        <Address
          title="From"
          company={currentShipment?.fromAddressCompany || ""}
          name={formatName(
            currentShipment?.fromAddressFirstName,
            currentShipment?.fromAddressLastName
          )}
          email={currentShipment?.fromAddressEmail || ""}
          phone={currentShipment?.fromAddressPhone || ""}
          street1={currentShipment?.fromAddressStreet1 || ""}
          street2={currentShipment?.fromAddressStreet2 || ""}
          city={currentShipment?.fromAddressCity || ""}
          zip={currentShipment?.fromAddressZip || ""}
          countyState={currentShipment?.fromAddressCountyState || ""}
          countryIsoCode={currentShipment?.fromAddressCountryIso || ""}
          eori={currentShipment?.fromAddressEoriNumber || ""}
          vat={currentShipment?.fromAddressVatNumber || ""}
        />
        <Address
          title="To"
          company={currentShipment?.toAddressCompany || ""}
          name={formatName(
            currentShipment?.toAddressFirstName,
            currentShipment?.toAddressLastName
          )}
          email={currentShipment?.toAddressEmail || ""}
          phone={currentShipment?.toAddressPhone || ""}
          street1={currentShipment?.toAddressStreet1 || ""}
          street2={currentShipment?.toAddressStreet2 || ""}
          city={currentShipment?.toAddressCity || ""}
          zip={currentShipment?.toAddressZip || ""}
          countyState={currentShipment?.toAddressCountyState || ""}
          countryIsoCode={currentShipment?.toAddressCountryIso || ""}
          eori={currentShipment?.toAddressEoriNumber || ""}
          vat={currentShipment?.toAddressVatNumber || ""}
        />
      </Stack>
    </Panel>
  );
};
