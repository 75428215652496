import { Select } from "components/atoms/Select";
import { ISelectOption } from "components/atoms/Select/UseSelect";

export const PackingGroupSelect = ({
  value: valueProp,
  isDisabled,
  onChange,
}: {
  value: string;
  isDisabled?: boolean;
  onChange(value: ISelectOption<object>);
}) => {
  const typeOptions: ISelectOption<object>[] = [
    { label: "I", value: 0 },
    { label: "II", value: 1 },
    { label: "III", value: 2 },
  ];
  const value = valueProp
    ? typeOptions.find((type) => type.label === valueProp)
    : null;

  return (
    <Select<ISelectOption<object>>
      value={value}
      options={typeOptions}
      isDisabled={isDisabled}
      isSearchable={false}
      onChange={onChange}
    />
  );
};
