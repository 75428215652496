import { Dropdown } from "components/molecules/Dropdown";
import { DropdownToggle } from "components/molecules/Dropdown";
import { DropdownMenu } from "components/molecules/Dropdown";
import { DropdownMenuItem } from "components/molecules/Dropdown";
import { NavigationLinks } from "../NavigationLinks";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useAuth0 } from "@auth0/auth0-react";
import { NavigationItem } from "../NavigationItem";
import { Portal } from "react-portal";
import { Avatar, AvatarSizes } from "components/atoms/Avatar";
import { Divider } from "components/atoms/Divider";
import { DropdownMenuGroup } from "components/molecules/Dropdown/DropdownMenuGroup";
import { InternalLink } from "components/atoms/InternalLink";
import { paths } from "enum/paths.enum";

export const NavigationUser = () => {
  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { logout } = useAuth0();

  return (
    <NavigationLinks>
      <Dropdown mode="onClick" placement="bottom-end" offset={[0, 5]}>
        <DropdownToggle>
          <NavigationItem>
            <div className="flex items-center cursor-pointer">
              <Avatar
                initial={loggedInUser.email.charAt(0)}
                size={AvatarSizes.md}
              />
            </div>
          </NavigationItem>
        </DropdownToggle>
        <Portal>
          <DropdownMenu width={240}>
            <div className="px-4 py-2.5 text-xs text-muted">
              {loggedInUser.email}
            </div>
            <Divider />

            <DropdownMenuGroup>
              <InternalLink href={paths.COMPANY_SETTINGS_GENERAL}>
                <DropdownMenuItem>Settings</DropdownMenuItem>
              </InternalLink>
              <DropdownMenuItem
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Logout
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenu>
        </Portal>
      </Dropdown>
    </NavigationLinks>
  );
};
