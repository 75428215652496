import { sortBy } from "lodash";
import { IRatesProps } from ".";
import { RateRow } from "./RatesRow";
import { RatesTestIds } from "./RatesTestIds.enum";

export const RatesList = (props: IRatesProps) => {
  const {
    rates,
    checkedRates,
    allowSelect,
    isInternationalShipment,
    onCheckRate,
    onSelectRate,
    isCheckable,
    selectedRate,
  } = props;

  const sortedRates = sortBy(rates, (item) => {
    return parseInt(item.netPrice);
  });

  return (
    <div data-testid={RatesTestIds.FETCHED_RATES_PANEL}>
      {sortedRates.map((rate, i) => {
        const isChecked =
          checkedRates &&
          checkedRates.find((checkedRate) => checkedRate.id === rate.id);

        const shouldShow =
          (rate?.allowLabels === undefined
            ? true
            : allowSelect && rate?.allowLabels) ||
          (rate?.allowQuotes === undefined
            ? true
            : !allowSelect && rate?.allowQuotes);

        return (
          <div key={i}>
            {shouldShow ? (
              <RateRow
                rate={rate}
                number={i}
                key={i}
                isInternationalShipment={isInternationalShipment}
                allowSelect={allowSelect || false}
                isChecked={isChecked ? true : false}
                isCheckable={isCheckable || false}
                isSelected={selectedRate?.id === rate.id || false}
                {...(onCheckRate && { onCheck: onCheckRate })}
                {...(onSelectRate && { onSelect: onSelectRate })}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
