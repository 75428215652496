export enum CreateBulkShipmentTestIds {
  "CREATE_BULK_SHIPMENT_STEP_1" = "CREATE_BULK_SHIPMENT_STEP_1",
  "CREATE_BULK_SHIPMENT_STEP_1_NO_PROFILES_PANEL" = "CREATE_BULK_SHIPMENT_STEP_1_NO_PROFILES_PANEL",
  "CREATE_BULK_SHIPMENT_STEP_1_LOADING_PANEL" = "CREATE_BULK_SHIPMENT_STEP_1_LOADING_PANEL",
  "CREATE_BULK_SHIPMENT_STEP_1_FORM_PANEL" = "CREATE_BULK_SHIPMENT_STEP_1_FORM_PANEL",
  "CREATE_BULK_SHIPMENT_STEP_1_CREATE_PROFILE_LINK" = "CREATE_BULK_SHIPMENT_STEP_1_CREATE_PROFILE_LINK",
  "CREATE_BULK_SHIPMENT_STEP_1_HEADER_ROW_CHECKBOX" = "CREATE_BULK_SHIPMENT_STEP_1_HEADER_ROW_CHECKBOX",
  "CREATE_BULK_SHIPMENT_STEP_1_FILE_DROPZONE" = "CREATE_BULK_SHIPMENT_STEP_1_FILE_DROPZONE",
  "CREATE_BULK_SHIPMENT_STEP_1_SUBMIT_BUTTON" = "CREATE_BULK_SHIPMENT_STEP_1_SUBMIT_BUTTON",
  "CREATE_BULK_SHIPMENT_STEP_1_FORM_ERRORS" = "CREATE_BULK_SHIPMENT_STEP_1_FORM_ERRORS",
  "CREATE_BULK_SHIPMENT_STEP_2" = "CREATE_BULK_SHIPMENT_STEP_2",
  "CREATE_BULK_SHIPMENT_STEP_2_SUBMIT_BUTTON" = "CREATE_BULK_SHIPMENT_STEP_2_SUBMIT_BUTTON",
  "CREATE_BULK_SHIPMENT_STEP_2_SUBMIT_BUTTON_MORE" = "CREATE_BULK_SHIPMENT_STEP_2_SUBMIT_BUTTON_MORE",
  "CREATE_BULK_SHIPMENT_STEP_2_SUBMIT_AND_PRINT_BUTTON" = "CREATE_BULK_SHIPMENT_STEP_2_SUBMIT_AND_PRINT_BUTTON",
}
