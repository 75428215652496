import { CreateAddressModal } from "components/organisms/CreateAddressModal";
import { useDispatch } from "react-redux";
import { closeModal } from "store/modalSlice";
import { fetchCompanyAddressesThunk } from "store/settingsSlice/index.slice";

export const CreateCompanyAddressModal = () => {
  const dispatch = useDispatch();

  return (
    <CreateAddressModal
      onClose={() => {
        dispatch(closeModal({ modalName: "createCompanyAddress" }));
      }}
      onSuccess={() => {
        dispatch(fetchCompanyAddressesThunk());
        dispatch(closeModal({ modalName: "createCompanyAddress" }));
      }}
    />
  );
};
