import { AppThunk, RootState } from "store";
import { cancelPickup } from "services/api/shipments";
import { closeModal } from "store/modalSlice";
import { fetchPickupsThunk } from "store/pickupSlice/thunks/fetchPickUpShipmentsThunk";
import { PickupShipmentRequestDto } from "services/api/shipments/dtos/pickup-shipment.request.dto";
import toast from "react-hot-toast";

export const cancelPickUpRequestThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID, loggedInUser },
      pickupSlice: { selectedShipment },
      usersSlice: { users },
    } = getState() as RootState;

    const ShipmentIDs: number[] = selectedShipment.shipmentIDs;

    const UserID = users.find((user) => user.email === loggedInUser.email).id;

    const body: PickupShipmentRequestDto = {
      UserID,
      ShipmentIDs,
    };

    const { data }: any = await cancelPickup(salesAccountUUID, body);
    if (!data.error) {
      dispatch(fetchPickupsThunk());
      dispatch(closeModal({ modalName: "viewPickupModal" }));
      dispatch(
        closeModal({
          modalName: "confirmCancelPickup",
        })
      );
      toast.success("Pickup Cancelled");
    } else {
      toast.error(data.message.message);
    }
  };
};
