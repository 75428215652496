import { runIfFunction } from "utils/react/runIfFunction";
import { usePopover, UsePopoverReturn } from "./UsePopover";
import { MaybeRenderProp } from "utils/react/maybeRenderProp";
import OutsideClickHandler from "react-outside-click-handler";
import { PopoverContext } from "./PopoverContext";

export interface IPopover {
  trigger?: "hover" | "click";
  isOpen?: boolean;
  onClose?(): void;
  offset?: [number, number];
  clickOutsideWhitelist?: HTMLDivElement[];
  triggerElementRef?: HTMLElement;
  strategy?: "absolute" | "fixed";
  placement?:
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "top-start"
    | "top-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "right"
    | "right-start"
    | "right-end"
    | "left"
    | "left-start"
    | "left-end";
  maxW?: number;
}

type IPopoverRenderProps = UsePopoverReturn;

export const Popover = (
  props: IPopover & { children?: MaybeRenderProp<IPopoverRenderProps> }
): JSX.Element => {
  const { children, trigger = "hover", clickOutsideWhitelist = [] } = props;

  const context = usePopover(props);

  const { onClose, isOpen, popoverElement } = context;

  const handleOutsideClick = (e: MouseEvent) => {
    const isClickingPortalledElement = clickOutsideWhitelist[0]
      ? clickOutsideWhitelist[0].contains(e.target as Node)
      : false;

    if (isOpen) {
      if (
        popoverElement &&
        !popoverElement.contains(e.target as Node) &&
        !isClickingPortalledElement
      ) {
        onClose();
      }
    }
  };

  return (
    <PopoverContext value={context}>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div {...(trigger === "hover" && { onMouseLeave: onClose })}>
          {runIfFunction(children, context)}
        </div>
      </OutsideClickHandler>
    </PopoverContext>
  );
};
