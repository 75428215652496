export default function TopBanner() {
  return (
    <div
      style={{ background: "red" }}
      className="flex items-center justify-center gap-x-6 px-6 py-2.5"
    >
      <p className="leading-6 text-white">
        <strong className="font-semibold">
          ON STOP. Please contact ITD UK.
        </strong>
      </p>
    </div>
  );
}
