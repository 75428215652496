export enum WeightUnits {
  "kg" = "kg",
  "pound" = "pound",
}

export const WeightUnitSettings: {
  [key in WeightUnits]: { label: string };
} = {
  [WeightUnits.kg]: {
    label: "kg",
  },
  [WeightUnits.pound]: {
    label: "lb",
  },
};
