import { Add20 } from "@carbon/icons-react";
import { Center } from "components/atoms/Center";
import { PackagesTestIds } from "./CreateShipmentFormPackagesTestIds.enum";

export const CreateShipmentFormPackagesAddRow = ({
  handleAddPackage,
}: {
  handleAddPackage(): void;
}) => {
  return (
    <div className="flex items-center text-sm">
      <div
        data-testid={PackagesTestIds.PACKAGES_ADD_PACKAGE}
        onClick={handleAddPackage}
        className="inline-flex items-center cursor-pointer hover:underline"
      >
        <div className="w-6 h-6 mr-2 text-white rounded-full shadow-sm bg-primary9">
          <Center>
            <Add20 />
          </Center>
        </div>
        Add package
      </div>
    </div>
  );
};
