import { LabelJobStatus } from "enum/label-job-status.enum";
import { IShipment } from "models/shipment/shipment.model";

export const isLabelGeneratingForShipment = (
  shipment: IShipment | null
): boolean => {
  return (
    (shipment &&
      shipment.labelJobs?.filter(
        (labelJob) =>
          labelJob.shipmentIds.includes(shipment.id) &&
          labelJob.status === LabelJobStatus.PENDING
      ).length > 0) ||
    false
  );
};
