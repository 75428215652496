export enum RuleOperators {
    "equal" = "equal",
    "notEqual" = "notEqual",
    "lessThan" = "lessThan",
    "lessThanInclusive" = "lessThanInclusive",
    "greaterThan" = "greaterThan",
    "greaterThanInclusive" = "greaterThanInclusive",
    "in" = "in",
    "notIn" = "notIn",
    "contains" = "contains",
    "doesNotContain" = "doesNotContain",
  }
  