import { NumberInput } from "components/atoms/NumberInput";
import { CurrencySelect } from "components/molecules/CurrencySelect";
import { FormControl } from "components/molecules/FormControl";
import { DEFAULT_WEIGHT_UNIT } from "constants/misc";
import { WeightUnitSettings } from "interfaces/weight-units.type";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { convertDatabaseToValue } from "utils/convertGramsToKilos";
import { getDisplayWeight } from "utils/getDisplayWeight";
import { Input } from "components/atoms/Input";
import { CreateShipmentContentsTestIds } from "./CreateShipmentFormContentsTestIds.enum";
import { ICreateShipmentFormContentsRow } from "./CreateShipmentFormContents.type";
import { isContentsRowValid } from "./CreateShipmentFormContents.utils";
import { isContentsFieldRequired } from "utils/isContentsFieldRequired";
import { CreateShipmentFormContentsRowHeader } from "./CreateShipmentFormContentsRowHeader";
import { CreateShipmentFormContentsRowErrors } from "./CreateShipmentFormContentsRowErrors";
// import { CountrySelectTailwind } from "components/molecules/CountrySelect/tailwindCountrySelect";
import { CountrySelect } from "components/molecules/CountrySelect";

export const CreateShipmentFormContentsRow = ({
  number,
  item,
  errors,
  onRemove,
  onChangeCommodityCode,
  onChangeQuantity,
  onChangeManufacturerDetails,
  onChangeItemDescription,
  onChangeCurrency,
  onChangeItemValue,
  onChangeItemWeight,
  onChangeOriginCountry,
  onChangeSKU,
  onTouchCommodityCode,
  onTouchItemDescription,
  onTouchItemValue,
  onTouchItemWeight,
  onTouchManufacturerDetails,
  onTouchQuantity,
  onTouchSKU,
  showErrors,
}: ICreateShipmentFormContentsRow) => {
  const {
    values: { sender, receiver },
  } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });

  const isRequired = isContentsFieldRequired({
    fromCountryIso: sender.countryIsoCode,
    toCountryIso: receiver.countryIsoCode,
    toCountryZip: receiver.zip,
  });

  return (
    <div
      className="w-full"
      data-testid={`${CreateShipmentContentsTestIds.SHIPMENT_CONTENTS_ROW}_${number}`}
    >
      <CreateShipmentFormContentsRowHeader
        number={number}
        onRemove={() => onRemove(number)}
      />

      {!isContentsRowValid(errors) && showErrors && (
        <CreateShipmentFormContentsRowErrors errors={errors} />
      )}

      <div className="grid grid-cols-3 gap-3 lg:grid-cols-4">
        <FormControl
          label="Description"
          isRequired={isRequired}
          testId={`${CreateShipmentContentsTestIds.SHIPMENT_CONTENTS_ITEM_DESCRIPTION}_${number}`}
        >
          <Input
            value={item.description}
            onBlur={() => onTouchItemDescription()}
            onChange={(e) => onChangeItemDescription(e.target.value)}
          />
        </FormControl>
        <FormControl
          label="Commodity code"
          isRequired={false}
          testId={`${CreateShipmentContentsTestIds.SHIPMENT_CONTENTS_ITEM_COMMODITY_CODE}_${number}`}
        >
          <Input
            value={item.commodityCode}
            onBlur={() => onTouchCommodityCode()}
            onChange={(e) => onChangeCommodityCode(e.target.value)}
          />
        </FormControl>
        <FormControl
          label="SKU"
          testId={`${CreateShipmentContentsTestIds.SHIPMENT_CONTENTS_ITEM_SKU}_${number}`}
        >
          <Input
            value={item.sku}
            onBlur={() => onTouchSKU()}
            onChange={(e) => onChangeSKU(e.target.value)}
          />
        </FormControl>
        <FormControl
          label="Quantity"
          isRequired={isRequired}
          testId={`${CreateShipmentContentsTestIds.SHIPMENT_CONTENTS_ITEM_QUANTITY}_${number}`}
        >
          <NumberInput
            step={1}
            precision={0}
            value={item.quantity}
            onBlur={() => onTouchQuantity()}
            onChange={(value) => onChangeQuantity(value || 0)}
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
              e.target.select();
            }}
          />
        </FormControl>
        <FormControl
          label="Item value"
          // isRequired={isRequired}
          testId={`${CreateShipmentContentsTestIds.SHIPMENT_CONTENTS_ITEM_VALUE}_${number}`}
        >
          <NumberInput
            value={convertDatabaseToValue(item.itemValue)}
            onBlur={() => onTouchItemValue()}
            onChange={(value) => onChangeItemValue(value || 0)}
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
              e.target.select();
            }}
          />
        </FormControl>
        <FormControl label="Currency" isRequired={isRequired}>
          <CurrencySelect
            onChange={(value) => {
              onChangeCurrency(value);
            }}
            value={item.currency}
          />
        </FormControl>
        <FormControl
          label={`Item Weight (${
            settings?.weightUnit
              ? WeightUnitSettings[settings?.weightUnit]?.label
              : WeightUnitSettings[DEFAULT_WEIGHT_UNIT].label
          })`}
          isRequired={isRequired}
          testId={`${CreateShipmentContentsTestIds.SHIPMENT_CONTENTS_ITEM_WEIGHT}_${number}`}
        >
          <NumberInput
            value={getDisplayWeight(settings?.weightUnit, item.weight)}
            onBlur={() => onTouchItemWeight()}
            onChange={(value) => onChangeItemWeight(value || 0)}
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
              e.target.select();
            }}
          />
        </FormControl>
        <FormControl label="Country of origin" isRequired={isRequired}>
          <CountrySelect
            onChange={(value) => {
              onChangeOriginCountry(value);
            }}
            value={item.countryOfOrigin}
          />
        </FormControl>
        <FormControl
          label="Manufacturer details"
          // isRequired={isRequired}
          testId={`${CreateShipmentContentsTestIds.SHIPMENT_CONTENTS_ITEM_MANUFACTURER_DETAILS}_${number}`}
        >
          <Input
            value={item.manufacturerDetails}
            onBlur={() => onTouchManufacturerDetails()}
            onChange={(e) => onChangeManufacturerDetails(e.target.value)}
          />
        </FormControl>
      </div>
    </div>
  );
};
