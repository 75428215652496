import { Button } from "components/atoms/Button";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownToggle,
} from "components/molecules/Dropdown";
import { DropdownMenuGroup } from "components/molecules/Dropdown/DropdownMenuGroup";
import { ShipmentTypes } from "enum/shipment-types.enum";
import { FaBoxOpen, FaPoundSign, FaUpload, FaBox } from "react-icons/fa";
import { Portal } from "react-portal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setFormType } from "store/createShipmentSlice/index.slice";
import { openModal } from "store/modalSlice";
import { PermissionCheck } from "utils/PermissionCheck/PermissionCheck";
import { fetchSingularSalesAccountThunk } from "store/salesAccountSlice/index.slice";

export const NavigationCreateDropdown = () => {
  const dispatch = useDispatch();

  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const permissions = new PermissionCheck({ user: loggedInUser });

  const { shippingWhitelists } = useSelector((state: RootState) => {
    return state.shippingWhitelistsSlice;
  });

  const containsUpsOrFedex = [];
  shippingWhitelists.forEach((element) => {
    if (
      (element.carrierId === "16" && element.supplier.apiPath !== 4) ||
      (element.carrierId === "24" && element.supplier.apiPath !== 6)
    ) {
      containsUpsOrFedex.push(element);
    }
  });

  return (
    <Dropdown mode="onClick" placement="bottom-end">
      <DropdownToggle>
        <Button colorScheme="primary">Create new</Button>
      </DropdownToggle>
      <Portal>
        <DropdownMenu width={240}>
          <DropdownMenuGroup>
            {permissions.shipments.canCreate() && (
              <DropdownMenuItem
                icon={FaBoxOpen}
                onClick={() => {
                  dispatch(setFormType(ShipmentTypes.SHIPMENT));
                  dispatch(openModal({ name: "createShipment" }));
                  dispatch(fetchSingularSalesAccountThunk());
                }}
              >
                Shipment
              </DropdownMenuItem>
            )}
            {permissions.quotes.canCreate() && (
              <DropdownMenuItem
                icon={FaPoundSign}
                onClick={() => {
                  dispatch(setFormType(ShipmentTypes.QUOTE));
                  dispatch(openModal({ name: "createShipment" }));
                }}
              >
                Quote
              </DropdownMenuItem>
            )}
            {permissions.bulkShipments.canCreate() && (
              <DropdownMenuItem
                icon={FaUpload}
                onClick={() => {
                  dispatch(openModal({ name: "createBulkShipment" }));
                }}
              >
                Bulk shipments upload
              </DropdownMenuItem>
            )}
            {permissions.pickup.canCreate() && containsUpsOrFedex.length ? (
              <DropdownMenuItem
                icon={FaBox}
                onClick={() => {
                  dispatch(openModal({ name: "pickUpModal" }));
                }}
              >
                Pick up
              </DropdownMenuItem>
            ) : null}
          </DropdownMenuGroup>
        </DropdownMenu>
      </Portal>
    </Dropdown>
  );
};
