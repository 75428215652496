import { DEFAULT_FROM_COUNTRY_ISO_CODE, DEFAULT_PACKAGE } from "constants/misc";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { ShipmentDutyOrTaxPaidBy } from "enum/shipment-duty-or-tax-paid-by.enum";
import { ShipmentDetailFieldNames } from "enum/shipment-field-names.enum";
import { ShipmentTypes } from "enum/shipment-types.enum";
import {
  CreateShipmentAddress,
  CreateShipmentAddressTouched,
  CreateShipmentAddressType,
  CreateShipmentContents,
  DropoffObjectType,
  ICreateShipmentSlice,
} from "./createShipmentSlice.types";
import { PickUpObjectType } from "./createShipmentSlice.types";

export const DEFAULT_ITEM_CONTENT: CreateShipmentContents = {
  packageId: 0,
  commodityCode: "",
  quantity: 1,
  weight: 0,
  currency: "",
  sku: "",
  description: "",
  itemValue: 0,
  manufacturerDetails: "",
  countryOfOrigin: "",
};

export const DEFAULT_PACKAGE_TOUCHED = {
  height: false,
  length: false,
  quantity: false,
  width: false,
  type: false,
  weight: false,
};

const addHours = (date, hours) => {
  date.setHours(date.getHours() + hours);

  return date;
};

export const DEFAULT_PICKUP_VALUES: PickUpObjectType = {
  shouldPickUp: false,
  pickupDate: new Date().toISOString(),
  pickupFromTime: new Date().toISOString(),
  pickupToTime: addHours(new Date(), 4).toISOString(),
  pickupInstructions: "",
};

export const DEFAULT_DROPOFF_VALUES: DropoffObjectType = {
  shouldDropOff: false,
  dropOffLocations: [],
  selectedDropOffLocation: {
    city: "",
    countryCode: "",
    postalCode: "",
    residential: false,
    stateOrProvinceCode: "",
    displayName: "",
  },
};

export const DEFAULT_DETAILS_ERRORS = {
  [ShipmentDetailFieldNames.customerReference]: "",
  [ShipmentDetailFieldNames.customerReference2]: "",
  [ShipmentDetailFieldNames.dutyOrTaxPaidBy]: "",
  [ShipmentDetailFieldNames.reasonForExport]: "",
  [ShipmentDetailFieldNames.invoiceNumber]: "",
  [ShipmentDetailFieldNames.iossNumber]: "",
  [ShipmentDetailFieldNames.dangerousGoods]: "",
  [ShipmentDetailFieldNames.shipmentSourceName]: "",
  [ShipmentDetailFieldNames.shouldSplitDutyAndVat]: "",
  [ShipmentDetailFieldNames.integrationStoreID]: "",
  [ShipmentDetailFieldNames.orderNumber]: "",
};

export const DEFAULT_DETAILS_TOUCHED = {
  [ShipmentDetailFieldNames.customerReference]: false,
  [ShipmentDetailFieldNames.customerReference2]: false,
  [ShipmentDetailFieldNames.dutyOrTaxPaidBy]: false,
  [ShipmentDetailFieldNames.reasonForExport]: false,
  [ShipmentDetailFieldNames.invoiceNumber]: false,
  [ShipmentDetailFieldNames.iossNumber]: false,
  [ShipmentDetailFieldNames.dangerousGoods]: false,
  [ShipmentDetailFieldNames.shipmentSourceName]: false,
  [ShipmentDetailFieldNames.shouldSplitDutyAndVat]: false,
  [ShipmentDetailFieldNames.integrationStoreID]: false,
  [ShipmentDetailFieldNames.orderNumber]: false,
};

export const DEFAULT_PACKAGE_ERRORS = {
  height: null,
  length: null,
  quantity: null,
  width: null,
  type: null,
  weight: null,
  lithiumCategory: null,
  dryIceWeight: null,
  dgunNumber: null,
  class: null,
  packingGroup: null,
  dgNetWeight: null,
};

export const DEFAULT_ADDRESS_VALUES: CreateShipmentAddress = {
  id: "",
  type: CreateShipmentAddressType.ADDRESS_BOOK,
  firstName: "",
  lastName: "",
  company: "",
  email: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  zip: "",
  city: "",
  countyState: "",
  countryIsoCode: DEFAULT_FROM_COUNTRY_ISO_CODE,
  saveToAddressBook: false,
  eoriNumber: "",
  vatNumber: "",
  editModeSavedAddress: false,
};

export const DEFAULT_ADDRESS_ERRORS = {
  id: "",
  firstName: "",
  lastName: "",
  savedAddress: "",
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  city: "",
  company: "",
  phone: "",
  zip: "",
  countyState: "",
  countryIsoCode: "",
  email: "",
  type: "",
  saveToAddressBook: "",
};

export const DEFAULT_ADDRESS_TOUCHED: CreateShipmentAddressTouched = {
  firstName: false,
  lastName: false,
  addressLine1: false,
  addressLine2: false,
  addressLine3: false,
  city: false,
  company: false,
  phone: false,
  zip: false,
  countyState: false,
  countryIsoCode: false,
  email: false,
  eoriNumber: false,
  vatNumber: false,
  isSenderDefault: false,
};

export const DEFAULT_ITEM_CONTENT_TOUCHED: {
  [key in keyof CreateShipmentContents]: boolean;
} = {
  packageId: false,
  commodityCode: false,
  quantity: false,
  weight: false,
  currency: false,
  sku: false,
  description: false,
  itemValue: false,
  manufacturerDetails: false,
  countryOfOrigin: false,
};

export const DEFAULT_ITEM_CONTENT_ERRORS: {
  [key in keyof CreateShipmentContents]: string;
} = {
  packageId: "",
  commodityCode: "",
  quantity: "",
  weight: "",
  currency: "",
  sku: "",
  description: "",
  itemValue: "",
  manufacturerDetails: "",
  countryOfOrigin: "",
};

export const DEFAULT_DETAILS_VALUES = {
  [ShipmentDetailFieldNames.reasonForExport]: null,
  [ShipmentDetailFieldNames.dutyOrTaxPaidBy]: ShipmentDutyOrTaxPaidBy.RECEIVER,
  [ShipmentDetailFieldNames.shouldSplitDutyAndVat]: false,
  [ShipmentDetailFieldNames.customerReference]: "",
  [ShipmentDetailFieldNames.customerReference2]: null,
  [ShipmentDetailFieldNames.invoiceNumber]: "",
  [ShipmentDetailFieldNames.iossNumber]: "",
  [ShipmentDetailFieldNames.dangerousGoods]: "",
  [ShipmentDetailFieldNames.shipmentSourceName]: "",
  [ShipmentDetailFieldNames.integrationStoreID]: 0,
  [ShipmentDetailFieldNames.orderNumber]: "",
};

export const DEFAULT_CREATE_SHIPMENT_ERRORS = {
  sender: { ...DEFAULT_ADDRESS_ERRORS },
  receiver: { ...DEFAULT_ADDRESS_ERRORS },
  details: {
    ...DEFAULT_DETAILS_ERRORS,
  },
  contents: [
    {
      ...DEFAULT_ITEM_CONTENT_ERRORS,
    },
  ],
  items: [
    {
      ...DEFAULT_PACKAGE_ERRORS,
    },
  ],
};

export const DEFAULT_CREATE_SHIPMENT_VALUES = {
  details: {
    ...DEFAULT_DETAILS_VALUES,
  },
  sender: {
    ...DEFAULT_ADDRESS_VALUES,
  },
  receiver: {
    ...DEFAULT_ADDRESS_VALUES,
  },
  items: [
    {
      ...DEFAULT_PACKAGE,
    },
  ],
  contents: [
    {
      ...DEFAULT_ITEM_CONTENT,
    },
  ],
};

export const CreateShipmentInitialState: ICreateShipmentSlice = {
  isDisclaimerAccepted: false,
  shippingToResidence: false,
  pickUpObject: {
    ...DEFAULT_PICKUP_VALUES,
  },
  dropOffObject: {
    ...DEFAULT_DROPOFF_VALUES,
  },
  insurance: {
    useInsurance: false,
    insuranceAmount: 0,
    insuranceAmountCurrency: "GBP",
  },
  documentOption: { label: "Commercial Invoice", value: 2 },
  files: [
    { fileType: "PROFORMA_INVOICE", label: "Proforma Invoice", file: null },
    { fileType: "COMMERCIAL_INVOICE", label: "Commercial Invoice", file: null },
    {
      fileType: "CUSTOMS_DECLARATION",
      label: "Customs Declaration",
      file: null,
    },
    {
      fileType: "CERTIFICATE_OF_ORIGIN",
      label: "Certificate Of Origin",
      file: null,
    },
    { fileType: "PACKING_LISTADR", label: "Packing List", file: null },
    { fileType: "BANK_DOCUMENTS", label: "Bank Documents", file: null },
    {
      fileType: "DG_GOODS_DECLARATION",
      label: "Dangerous Goods Declaration",
      file: null,
    },
    { fileType: "OTHERS", label: "Other", file: null },
    { fileType: "PICKUP_ORDER", label: "Pickup Order", file: null },
    { fileType: "EXPORT_DECLARATION", label: "Export Declaration", file: null },
    { fileType: "EXPORT_ATTACHMENT", label: "Export Attachment", file: null },
  ],
  dangerousGoodsOption: null,
  dangerousGoodsCategories: [
    { label: "DGR | Dangerous goods", value: 0 },
    { label: "DRY_ICE | Dry ice", value: 1 },
    { label: "LITHIUM | Lithium Battery", value: 2 },
  ],
  editingShipmentID: null,
  editingShipmentUUID: null,
  salesAccountUUID: null,
  editingShipmentJobNumber: null,
  isConvertingFromQuote: false,
  printLater: true,
  convertingFromQuoteStatus: "IDLE",
  rates: [],
  selectedRate: null,
  submitCount: 0,
  type: ShipmentTypes.SHIPMENT,
  ratesError: "",
  ratesLoadingStatus: ApiLoadingStatus.IDLE,
  submittingStatus: ApiLoadingStatus.IDLE,
  submittingErrorMessage: "",
  touched: {
    details: {
      ...DEFAULT_DETAILS_TOUCHED,
    },
    sender: {
      ...DEFAULT_ADDRESS_TOUCHED,
    },
    receiver: {
      ...DEFAULT_ADDRESS_TOUCHED,
    },
    contents: [
      {
        ...DEFAULT_ITEM_CONTENT_TOUCHED,
      },
    ],
    items: [
      {
        ...DEFAULT_PACKAGE_TOUCHED,
      },
    ],
  },
  values: {
    ...DEFAULT_CREATE_SHIPMENT_VALUES,
  },
  errors: {
    ...DEFAULT_CREATE_SHIPMENT_ERRORS,
  },
  currentQuoteReference: "",
  duplicateOrEdit: "",
};
