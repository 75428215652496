import { RadioGroup } from "components/atoms/RadioGroup";
import {
  CreateAddress,
  CreateShipmentAddress,
  CreateShipmentAddressErrors,
  CreateShipmentAddressTouched,
  CreateShipmentAddressType,
} from "store/createShipmentSlice/createShipmentSlice.types";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Button } from "components/atoms/Button";
import { Radio } from "components/atoms/Radio";
import { CreateShipmentAddressSelectorTestIds } from "./CreateShipmentAddressSelectorTestIds.enum";
import { AddressSelectorForm } from "./CreateShipmentAddressForm";
import { formatAddressee } from "utils/formatAddressee";
import { Panel } from "components/atoms/Panel";
import { IconButton } from "components/atoms/IconButton";
import { FaTimes, FaEdit } from "react-icons/fa";
import { PanelBody } from "components/atoms/Panel/PanelBody";
import { useTheme } from "styled-components";
import { Label } from "components/atoms/Label";

export enum AddressDirection {
  "SENDER" = "SENDER",
  "RECIPIENT" = "RECIPIENT",
}

export type IAddressSelector = CreateShipmentAddress & {
  direction: AddressDirection;
  onChange(key, value): void;
  onChangeZip(value: CreateAddress["zip"]): void;
  showErrors: boolean;
  onTouch(key: keyof CreateAddress): void;
  onChangeSaveAddress(value: boolean): void;
  onChangeCountry(value: string): void;
  onChangeType(type: CreateShipmentAddressType): void;
  onToggleSelectAddress(): void;
  onRemoveAddress(): void;
  errors: CreateShipmentAddressErrors;
  touched: CreateShipmentAddressTouched;
  onEditSavedAddress(): void;
  editModeSavedAddress: boolean;
};

export const AddressSelector = (props: IAddressSelector) => {
  const {
    saveToAddressBook,
    onEditSavedAddress,
    id,
    firstName,
    lastName,
    email,
    company,
    phone,
    addressLine1,
    addressLine2,
    addressLine3,
    countyState,
    city,
    countryIsoCode,
    zip,
    type,
    onChange,
    onTouch,
    onChangeZip,
    onChangeCountry,
    onChangeSaveAddress,
    onToggleSelectAddress,
    onRemoveAddress,
    onChangeType,
    errors,
    showErrors,
    touched,
    direction,
    eoriNumber,
    vatNumber,
    editModeSavedAddress,
    isSenderDefault,
  } = props;

  const { submitCount } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const theme = useTheme();

  return (
    <div>
      <RadioGroup
        value={type}
        onValueChange={(type: CreateShipmentAddressType) => {
          onChangeType(type);
        }}
      >
        <div className="">
          <div className="flex mb-3">
            <div className="mt-1.5 mr-4">
              <Radio
                testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_TOGGLE_ADDRESS_BOOK}`}
                value="ADDRESS_BOOK"
              />
            </div>
            <div>
              <Button
                colorScheme="default"
                onClick={onToggleSelectAddress}
                testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_TOGGLE_ADDRESS_SELECTOR_MODAL}`}
              >
                Select from address book
              </Button>

              {submitCount > 0 && errors.id && (
                <div className="mt-1 text-sm text-red10">{errors.id}</div>
              )}
            </div>
          </div>

          {editModeSavedAddress &&
          type === CreateShipmentAddressType.ADDRESS_BOOK ? (
            <div className="pl-9 mb-2">
              <div
                data-testid={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM}`}
              >
                <AddressSelectorForm
                  editModeSavedAddress={editModeSavedAddress}
                  saveAddressToAddressBook={saveToAddressBook}
                  direction={direction}
                  errors={errors}
                  showErrors={showErrors}
                  touched={touched}
                  vatNumber={vatNumber}
                  eoriNumber={eoriNumber}
                  firstName={firstName}
                  lastName={lastName}
                  company={company}
                  email={email}
                  phone={phone}
                  addressLine1={addressLine1}
                  addressLine2={addressLine2}
                  addressLine3={addressLine3}
                  city={city}
                  countyState={countyState}
                  zip={zip}
                  countryIsoCode={countryIsoCode}
                  onChangeZip={onChangeZip}
                  onChange={onChange}
                  onTouch={onTouch}
                  onChangeCountry={onChangeCountry}
                  onChangeSaveAddress={onChangeSaveAddress}
                />
              </div>
            </div>
          ) : null}
          <div className="flex-col">
            {!editModeSavedAddress &&
            type === CreateShipmentAddressType.ADDRESS_BOOK ? (
              <div>
                {id && (
                  <Panel className="mb-3 text-sm">
                    <PanelBody
                      className="flex items-center"
                      style={{
                        paddingLeft: theme.gutter,
                        paddingRight: theme.gutter,
                        paddingTop: theme.space[3],
                        paddingBottom: theme.space[3],
                      }}
                    >
                      <div className="mr-4">
                        {isSenderDefault ? (
                          <Label color="green">Default</Label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="leading-none">
                        {formatAddressee({
                          firstName,
                          lastName,
                          street1: addressLine1,
                          zip,
                          city,
                          countryIsoCode,
                          company,
                        })}
                      </div>

                      <div className="ml-auto">
                        <IconButton
                          className="mr-2"
                          icon={FaEdit}
                          onClick={onEditSavedAddress}
                        />
                        <IconButton
                          icon={FaTimes}
                          testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_REMOVE_ADDRESS_BOOK_ADDRESS}`}
                          onClick={onRemoveAddress}
                        />
                      </div>
                    </PanelBody>
                  </Panel>
                )}
              </div>
            ) : null}
          </div>
        </div>

        <div>
          <div className="flex items-center mb-5">
            <div className="mr-4">
              <Radio
                testId={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_TOGGLE_NEW_ADDRESS_FORM}`}
                value={CreateShipmentAddressType.NEW}
              />
            </div>
            <div className="flex-1">
              <div
                className="text-sm font-medium leading-none cursor-pointer"
                onClick={() => onChangeType(CreateShipmentAddressType.NEW)}
              >
                New address
              </div>
            </div>
          </div>
          <div className="pl-9">
            {type === CreateShipmentAddressType.NEW && (
              <div
                data-testid={`${direction}_${CreateShipmentAddressSelectorTestIds.ADDRESS_SELECTOR_FORM}`}
              >
                <AddressSelectorForm
                  editModeSavedAddress={editModeSavedAddress}
                  saveAddressToAddressBook={saveToAddressBook}
                  direction={direction}
                  errors={errors}
                  showErrors={showErrors}
                  touched={touched}
                  firstName={firstName}
                  lastName={lastName}
                  company={company}
                  email={email}
                  phone={phone}
                  addressLine1={addressLine1}
                  addressLine2={addressLine2}
                  addressLine3={addressLine3}
                  city={city}
                  countyState={countyState}
                  zip={zip}
                  countryIsoCode={countryIsoCode}
                  onChangeZip={onChangeZip}
                  onChange={onChange}
                  onTouch={onTouch}
                  onChangeCountry={onChangeCountry}
                  onChangeSaveAddress={onChangeSaveAddress}
                  vatNumber={vatNumber}
                  eoriNumber={eoriNumber}
                />
              </div>
            )}
          </div>
        </div>
      </RadioGroup>
    </div>
  );
};
