import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { IIntegrationsSlice } from "./IntegrationsSlice.interface";

export const IntegrationsSliceInitialState: IIntegrationsSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  data: {
    integrations: [],
    settingsSchemas: [],
  },
  activeEcartIntegrations: [],
  integration: null,
  showAddStoreUI: false,
  showReauthStoreUI: false,
  accessCode: "",
  storeName: "",
  processIntegration: ApiLoadingStatus.IDLE,
  apiKey: "",
  apiSecret: "",
};
