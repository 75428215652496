import { ReactNode } from "react";
import { useTheme } from "styled-components";
import { IThemeFontSizeScale } from "theme/theme-font-sizes.theme";
import { DrawerClose } from "components/molecules/Drawer/DrawerClose";
import { Button } from "components/atoms/Button";

type IPageSectionProps = {
  title?: string;
  description?: ReactNode;
  headingSize?: IThemeFontSizeScale;
  children: React.ReactNode;
  closePage?: Function;
  mainButtonFunction?: Function;
  mainButtonLabel?: string;
};

export const PageSection = (props: IPageSectionProps) => {
  const {
    title,
    description,
    children,
    headingSize = "lg",
    closePage,
    mainButtonFunction,
    mainButtonLabel,
  } = props;

  const theme = useTheme();

  return (
    <>
      <div className="flex items-center">
        <h2
          className="flex-1 font-medium"
          style={{ fontSize: theme.fontSizes[headingSize] }}
        >
          {title}
        </h2>
        {mainButtonFunction ? (
          <Button
            colorScheme="primary"
            onClick={() => {
              mainButtonFunction();
            }}
          >
            {mainButtonLabel}
          </Button>
        ) : null}
        {closePage ? (
          <div className="ml-4">
            <DrawerClose
              onClick={() => {
                closePage();
              }}
            />
          </div>
        ) : null}
      </div>
      {description && <div className="mb-6">{description}</div>}
      <div className="mt-6">{children}</div>
    </>
  );
};

export const PageSectionDescription = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div className="text-sm text-muted">{children}</div>;
};
