import { Flag } from "components/atoms/Flag";
import { Select } from "components/atoms/Select";
import { ISelectOption } from "components/atoms/Select/UseSelect";
import { ICountry } from "models/country/country.model";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { FormControl, IFormControl } from "../FormControl";

export const CountrySelect = ({
  onChange,
  value,
  label,
  testId,
}: {
  testId?: string;
  onChange(value: ICountry["isoCode"]): void;
  value: ICountry["isoCode"];
} & IFormControl) => {
  const { countriesSelectOptions } = useSelector((state: RootState) => {
    return state.countriesSlice;
  });

  const country =
    countriesSelectOptions.length > 0 &&
    countriesSelectOptions.find((country) => {
      return country.isoCode === value;
    });

  return (
    <FormControl label={label}>
      <Select<ISelectOption<ICountry, string>>
        value={country}
        testId={testId}
        onChange={(option) => onChange(option.isoCode)}
        placeholder="Select country"
        optionTemplate={({ label, ...rest }) => {
          return (
            <div className="flex items-center">
              <div className="mr-2">
                <Flag countryCode={rest?.isoCode?.toLowerCase()} />
              </div>
              <div>{label}</div>
            </div>
          );
        }}
        options={countriesSelectOptions}
      />
    </FormControl>
  );
};
