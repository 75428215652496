import { ThemeFontSizes } from "theme/theme-font-sizes.theme";
import { ThemeRadii } from "theme/theme-radii.theme";
import { ThemeSpacing } from "theme/theme-spacing.theme";
import { IThemeBase, IThemeBaseDefaults } from "theme/theme.type";

export const ThemeBaseDefaults: IThemeBaseDefaults = {
  formControlFontSizeSmall: ThemeFontSizes["sm"],
  
  formControlHeightXSmall: ThemeSpacing[6],
  formControlHeightSmall: ThemeSpacing[7],
  formControlHeightDefault: ThemeSpacing[9],
  formControlHeightLarge: ThemeSpacing[10],

  borderRadius: ThemeRadii.md,

  gutter: ThemeSpacing[5],

  transitionSpeedDefault: ".45s",

  navigationHeight: ThemeSpacing[16],

  modalBorderRadius: ThemeRadii.lg,
};

export const ThemeBase: IThemeBase = {
  ...ThemeBaseDefaults,
  radii: {
    ...ThemeRadii,
  },
  space: {
    ...ThemeSpacing,
  },
  fontSizes: {
    ...ThemeFontSizes,
  },
};
