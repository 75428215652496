import React from 'react';
import { useFormikContext } from 'formik';
import styles from './FormikField.module.scss';

type IFieldRenderProps<T, K extends keyof T> = {
  errorMessage: string;
  value: T[K];
  onChange(value: T[K]): void;
  name: keyof T;
  isInvalid: boolean;
}

interface IField<T, K extends keyof T> {
  id?: string;
  name: K;
  children(props: IFieldRenderProps<T, K>): JSX.Element;
}

export const FormikField = <T, K extends keyof T>(props: IField<T, K>): JSX.Element => {
  const { values, errors, setFieldValue, touched } = useFormikContext<T>();

  const { name, children } = props;

  const isTouched = touched[name];

  const isInvalid = errors[name] && isTouched ? true : false;

  const value: T[K] = values[name as K];

  const errorMessage = errors[name] ? (errors[name] as string) : '';

  const handleChange = (value: T[K]) => {
    setFieldValue(name as string, value);
  };

  const renderProps: IFieldRenderProps<T, K> = {
    name,
    value,
    errorMessage,
    onChange: handleChange,
    isInvalid,
  };

  return (
    <div className={styles.FormikField}>{children({ ...renderProps })}</div>
  );
};
