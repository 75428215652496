import { Center } from "components/atoms/Center";
import { Loader } from "components/atoms/Loader";
import { ViewShipmentModalTestIds } from "../ViewShipmentTestIds.enum";

export const ViewShipmentLoading = () => {
  return (
    <Center testId={ViewShipmentModalTestIds.VIEW_SHIPMENT_LOADING_PANEL}>
      <Loader />
    </Center>
  );
};
