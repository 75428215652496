import { useDropdownContext } from ".";
import cx from "classnames";
import { useHotkeys } from "react-hotkeys-hook";
import { KeyboardKeys } from "enum/keyboardkeys.enum";
import { mergeRefs } from "utils/react/mergeRefs";

export const DropdownToggle = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element => {
  const { mode, onClose, setOpen, isOpen, setTriggerElement } =
    useDropdownContext();

  const ref = useHotkeys(
    KeyboardKeys.ENTER,
    () => {
      isOpen ? onClose() : setOpen();
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  return (
    <div
      className={cx(className, "DropdownToggle focus:bg-yellow-200")}
      ref={mergeRefs([ref, setTriggerElement])}
      {...(mode === "onHover" && { onMouseEnter: () => setOpen() })}
      {...(mode === "onHover" && { onMouseLeave: () => onClose() })}
      {...(mode === "onClick" && {
        onClick: (e) => {
          e.stopPropagation();
          isOpen ? onClose() : setOpen();
        },
      })}
    >
      {children}
    </div>
  );
};
