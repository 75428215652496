import {
  cloneElement,
  Fragment,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from "react";
import { getValidChildren } from "utils/react/getValidChildren";
import cx from "classnames";

export type IStackProps = {
  spacing?: number;
  direction?: "horizontal" | "vertical";
  children: ReactNode;
  childClassName?: string;
  divider?: ReactElement<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;

export const Stack = (props: IStackProps) => {
  const {
    spacing = 0,
    children,
    childClassName,
    divider,
    className,
    direction = "vertical",
    style,
  } = props;

  const validChildren = getValidChildren(children);

  const hasDivider = !!divider;

  const clones = validChildren.map((child, index) => {
    const key = typeof child.key !== "undefined" ? child.key : index;

    const isLast = index + 1 === validChildren.length;

    const wrappedChild = (
      <div
        key={key}
        style={{
          ...(direction === "vertical" && {
            marginBottom: !isLast && !hasDivider ? `var(--space${spacing})` : 0,
          }),
          ...(direction === "horizontal" && {
            marginRight: !isLast && !hasDivider ? `var(--space${spacing})` : 0,
          }),
        }}
        className={childClassName}
      >
        {child}
      </div>
    );

    const _child = wrappedChild;

    if (!hasDivider) return _child;

    divider.props.style;

    const clonedDivider = cloneElement(
      divider as ReactElement<HTMLDivElement>,
      {
        style: {
          ...(direction === "vertical" && {
            marginTop: `var(--space${spacing})`,
            marginBottom: `var(--space${spacing})`,
          }),
          ...(direction === "horizontal" && {
            marginLeft: `var(--space${spacing})`,
            marginRight: `var(--space${spacing})`,
          }),
          ...divider.props.style,
        },
      }
    );

    const _divider = isLast ? null : clonedDivider;

    return (
      <Fragment key={key}>
        {_child}
        {_divider}
      </Fragment>
    );
  });

  return (
    <div
      style={style}
      className={cx("flex", className, {
        "flex-col": direction === "vertical",
      })}
    >
      {clones}
    </div>
  );
};
