import { ArrowsVertical16 } from "@carbon/icons-react";
import { Center } from "components/atoms/Center";
import { Input } from "components/atoms/Input";
import { CountrySelect } from "components/molecules/CountrySelect";
import { FormControl } from "components/molecules/FormControl";
import { DEFAULT_FROM_COUNTRY_ISO_CODE } from "constants/misc";
import { ICountry } from "models/country/country.model";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  onChangeReceiverCountry,
  onChangeSenderCountry,
  onTouchReceiverAddressField,
  onTouchSenderAddressField,
  setRecipientAddressZip,
  setSenderAddressZip,
  setPickUp,
  setSendingToResidence,
  setDefaultFromISO_GA,
} from "store/createShipmentSlice/index.slice";
import { isZipRequired } from "utils/zip/isZipRequired.util";
//import { CreateShipmentRecipient } from "../../CreateShipmentForm/CreateShipmentAddressSelector/CreateShipmentRecipient";
export enum CreateQuoteSenderReceiverTestIds {
  "CREATE_QUOTE_SENDER_RECEIVER_SENDER_ROW" = "CREATE_QUOTE_SENDER_RECEIVER_SENDER_ROW",
  "CREATE_QUOTE_SENDER_RECEIVER_RECEIVER_ROW" = "CREATE_QUOTE_SENDER_RECEIVER_RECEIVER_ROW",
}
import { Checkbox } from "components/atoms/Checkbox";

type ICreateQuoteSenderReceiverInputProps = {
  testId: string;
  label: string;
  onChangeCountryIso(countryIso: ICountry["isoCode"]): void;
  onChangeZip(value: string): void;
  onFocusZip(): void;
  countryIso: ICountry["isoCode"];
  zipValue: string;
  zipTouched: boolean;
  zipErrors: string;
};

const CreateQuoteSenderReceiverInput = ({
  testId,
  onChangeCountryIso,
  onChangeZip,
  onFocusZip,
  countryIso,
  zipErrors,
  zipValue,
  label,
}: ICreateQuoteSenderReceiverInputProps) => {
  return (
    <div className="grid grid-cols-2 gap-3" data-testid={testId}>
      <CountrySelect
        label={label}
        onChange={onChangeCountryIso}
        value={countryIso}
      />
      <FormControl
        label="Zip"
        htmlFor="zip"
        isRequired={isZipRequired(countryIso)}
        errorMessage={zipErrors && "Please enter a valid zip / postcode"}
        errorMessagePosition="absolute"
      >
        <Input
          name="zip"
          value={zipValue}
          onChange={(e) => onChangeZip(e.target.value)}
          onFocus={onFocusZip}
        />
      </FormControl>
    </div>
  );
};

export const CreateQuoteSenderReceiver = () => {
  
  const { touched, errors, values } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const { financialCompany } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  useEffect(() => {
    if(financialCompany == 8) {
      dispatch(setDefaultFromISO_GA());
    }
  }, []);

  const dispatch = useDispatch();

  const handleSwitchCountry = () => {
    dispatch(onChangeSenderCountry(values.receiver.countryIsoCode));
    dispatch(setSenderAddressZip(values.receiver.zip));
    dispatch(onChangeReceiverCountry(values.sender.countryIsoCode));
    dispatch(setRecipientAddressZip(values.sender.zip));
  };

  const handleFocusZip = () => {
    dispatch(onTouchReceiverAddressField("zip"));
  };

  const handleChangeSenderCountry = (countryIso: string) => {
    if (
      values.receiver.countryIsoCode !== DEFAULT_FROM_COUNTRY_ISO_CODE &&
      countryIso !== DEFAULT_FROM_COUNTRY_ISO_CODE &&
      financialCompany === 1
    ) {
      alert(
        "United Kingdom must be set as either the origin or destination country."
      );
    } else {
      dispatch(onChangeSenderCountry(countryIso));
    }
  };

  const handleChangeSenderZip = (zip: string) => {
    dispatch(setSenderAddressZip(zip));
  };

  const handleChangeReceiverCountry = (countryIso: string) => {
    if (
      values.sender.countryIsoCode !== DEFAULT_FROM_COUNTRY_ISO_CODE &&
      countryIso !== DEFAULT_FROM_COUNTRY_ISO_CODE &&
      financialCompany === 1
    ) {
      alert(
        "United Kingdom must be set as either the origin or destination country."
      );
    } else {
      dispatch(onChangeReceiverCountry(countryIso));
    }
  };

  const handleChangeReceiverZip = (zip: string) => {
    dispatch(setRecipientAddressZip(zip));
  };
  const [pickupRequired, setPickupRequired] = useState(false);
  const [sendingToResidence, setSendingToTheResidence] = useState(false);
  return (
    <>
      <CreateQuoteSenderReceiverInput
        testId={
          CreateQuoteSenderReceiverTestIds.CREATE_QUOTE_SENDER_RECEIVER_SENDER_ROW
        }
        label="From"
        onFocusZip={() => {
          dispatch(onTouchSenderAddressField("zip"));
        }}
        zipTouched={touched.sender.zip}
        zipValue={values.sender.zip}
        onChangeCountryIso={handleChangeSenderCountry}
        onChangeZip={handleChangeSenderZip}
        countryIso={values.sender.countryIsoCode}
        zipErrors={errors.sender.zip}
      />
      <div className="relative h-16 my-2 w-7">
        <div className="w-0.5 h-full mx-auto bg-gray3" />
        <div
          className="absolute flex transform -translate-y-1/2 rounded-full cursor-pointer bg-gray3 w-7 h-7 top-1/2"
          onClick={handleSwitchCountry}
        >
          <Center>
            <ArrowsVertical16 />
          </Center>
        </div>
      </div>
      <CreateQuoteSenderReceiverInput
        testId={
          CreateQuoteSenderReceiverTestIds.CREATE_QUOTE_SENDER_RECEIVER_RECEIVER_ROW
        }
        label="To"
        onFocusZip={handleFocusZip}
        zipValue={values.receiver.zip}
        zipTouched={touched.receiver.zip}
        onChangeCountryIso={handleChangeReceiverCountry}
        onChangeZip={handleChangeReceiverZip}
        countryIso={values.receiver.countryIsoCode}
        zipErrors={errors.receiver.zip}
      />
      {financialCompany === 8 && (
        <>
         <div className="mb-4  mt-4">
            <Checkbox
              checked={sendingToResidence}
              onCheckedChange={(checked) => {
                if (typeof checked === "boolean") {
                  setSendingToTheResidence(checked);
                  dispatch(setSendingToResidence(checked));
                }
              }}
            >
              Residential Address
            </Checkbox>
          </div>
          <div className="mb-4 mt-4">
            <Checkbox
              checked={pickupRequired}
              onCheckedChange={(checked) => {
                if (typeof checked === "boolean") {
                  setPickupRequired(checked);
                  dispatch(setPickUp(checked));
                }
              }}
            >
              Request Pickup
            </Checkbox>
          </div>
        </>
      )}
    </>
  );
};
