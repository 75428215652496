import { CellProps, Column } from "react-table";
import { TableTextCell } from "components/atoms/Table/TableTextCell";
import { TableDateCell } from "components/atoms/Table/TableDateCell";
import { Checkbox } from "components/atoms/Checkbox";
import { TableCellIds } from "components/atoms/Table/types";
import {
  setShipmentSelection,
  setShipmentSelectionAll,
  IPickupShipment,
} from "store/pickupSlice/index.slice";
import { useDispatch } from "react-redux";

export const PickupBaseColumns: Column<IPickupShipment>[] = [
  {
    id: TableCellIds.CHECKBOX,
    Header: (props: CellProps<IPickupShipment>) => {
      let selectAll = true;
      props.data.forEach((element) => {
        if (!element.selected) {
          selectAll = false;
        }
      });
      const dispatch = useDispatch();
      return (
        <Checkbox
          checked={selectAll}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onCheckedChange={() => {
            dispatch(setShipmentSelectionAll(!selectAll));
          }}
        />
      );
    },
    width: 46,
    maxWidth: 46,
    minWidth: 46,
    Cell: (props: CellProps<IPickupShipment>) => {
      const dispatch = useDispatch();
      return (
        <Checkbox
          checked={props.row.original.selected}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onCheckedChange={() => {
            dispatch(
              setShipmentSelection({
                shipmentId: props.row.original.shipmentId,
                selected: !props.row.original.selected,
              })
            );
          }}
        />
      );
    },
  },
  {
    Header: "PRN",
    accessor: "prn",
    enableSorting: true,
    Cell: (props: CellProps<IPickupShipment>) => {
      return (
        <TableTextCell
          value={props.row.original.prn}
          className={props.row.original.prn ? "text-orange10" : ""}
        />
      );
    },
  },
  {
    Header: "Reference",
    accessor: "reference",
    enableSorting: true,
    Cell: (props: CellProps<IPickupShipment>) => {
      return <TableTextCell value={props.row.original.reference} />;
    },
  },
  {
    Header: "Tracking",
    accessor: "tracking",
    Cell: (props: CellProps<IPickupShipment>) => {
      return <TableTextCell value={props.row.original.tracking} />;
    },
  },
  {
    Header: "To",
    accessor: "recepient",
    Cell: (props: CellProps<IPickupShipment>) => {
      return <TableTextCell value={props.row.original.recepient} />;
    },
  },
  {
    Header: "Address",
    accessor: "address",
    enableSorting: true,
    Cell: (props: CellProps<IPickupShipment>) => {
      return <TableTextCell value={props.row.original.address} />;
    },
  },
  {
    Header: "Date",
    accessor: "createdDate",
    Cell: (props: CellProps<IPickupShipment>) => {
      return <TableDateCell value={props.row.original.createdDate} />;
    },
  },
  {
    Header: "Weight",
    accessor: "weight",
    width: 100,
    maxWidth: 100,
    minWidth: 100,
    Cell: (props: CellProps<IPickupShipment>) => {
      return <TableTextCell value={props.row.original.weight} />;
    },
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    width: 100,
    maxWidth: 100,
    minWidth: 100,
    Cell: (props: CellProps<IPickupShipment>) => {
      return <TableTextCell value={props.row.original.quantity} />;
    },
  },
];
