import { Alert } from "components/atoms/Alert";
import { PackageErrorCodes } from "enum/error-codes/package-error-codes.enum";
import { startCase } from "lodash";
import { CreateShipmentItemErrors } from "store/createShipmentSlice/createShipmentSlice.types";
import { PackagesTestIds } from "./CreateShipmentFormPackagesTestIds.enum";

export const CreateShipmentFormPackagesRowErrors = ({
  number,
  errors,
}: {
  number: number;
  errors: CreateShipmentItemErrors;
}) => {
  return (
    <div
      className="mb-4"
      data-testid={`${PackagesTestIds.PACKAGES_ROW_ERRORS}_${number}`}
    >
      <Alert>
        {Object.keys(errors).map((error, index) => {
          const message = errors[error] as PackageErrorCodes;

          return (
            <div key={index}>
              {error === "dgunNumber"
                ? "DG/UN Number"
                : error === "dgNetWeight"
                ? "DG Net Weight"
                : startCase(error)}
              :{" "}
              {message === PackageErrorCodes.PACKAGE_MINIMUM_HEIGHT_NOT_MET
                ? "minimum height not met"
                : message === PackageErrorCodes.PACKAGE_MINIMUM_LENGTH_NOT_MET
                ? "minimum length not met"
                : message === PackageErrorCodes.PACKAGE_MINIMUM_WEIGHT_NOT_MET
                ? "minimum weight not met"
                : message === PackageErrorCodes.PACKAGE_MINIMUM_WIDTH_NOT_MET
                ? "minimum width not met"
                : message === PackageErrorCodes.CLASS_REQUIRED ||
                  message === PackageErrorCodes.PACKING_GROUP_REQUIRED ||
                  message === PackageErrorCodes.DG_NET_WEIGHT_REQUIRED ||
                  message === PackageErrorCodes.DG_UN_NUMBER_REQUIRED ||
                  message === PackageErrorCodes.DRY_ICE_WEIGHT_REQUIRED ||
                  message === PackageErrorCodes.LITHIUM_CATEGORY_REQUIRED
                ? "value required"
                : errors[error]}
            </div>
          );
        })}
      </Alert>
    </div>
  );
};
