import { Select } from "components/atoms/Select";
import { ISelectOption } from "components/atoms/Select/UseSelect";

export const LithiumCategorySelect = ({
  value: valueProp,
  isDisabled,
  onChange,
}: {
  value: string;
  isDisabled?: boolean;
  onChange(value: ISelectOption<object>);
}) => {
  const typeOptions: ISelectOption<object>[] = [
    { label: "SII-966", value: 0 },
    { label: "SII-967", value: 1 },
    { label: "SII-969", value: 2 },
    { label: "SII-970", value: 3 },
    { label: "SI-966", value: 4 },
    { label: "SI-969", value: 5 },
    { label: "SI-967", value: 6 },
    { label: "SI-970", value: 7 },
    { label: "SIA-965", value: 8 },
    { label: "SIB-965", value: 9 },
    { label: "SIA-968", value: 10 },
    { label: "SIB-968", value: 11 },
  ];

  const value = valueProp
    ? typeOptions.find((type) => type.label === valueProp)
    : null;

  return (
    <Select<ISelectOption<object>>
      value={value}
      options={typeOptions}
      isDisabled={isDisabled}
      isSearchable={false}
      onChange={onChange}
    />
  );
};
