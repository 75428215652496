export enum UK_SPECIAL_POSTCODE_PREFIXES {
  "GUERNSEY" = "GY",
  "ISLE_OF_MAN" = "IM",
  "JERSEY" = "JE",
  "NORTHERN_IRELAND" = "BT",
  "ISLE_OF_SCILLY_1" = "TR21",
  "ISLE_OF_SCILLY_2" = "TR22",
  "ISLE_OF_SCILLY_3" = "TR23",
  "ISLE_OF_SCILLY_4" = "TR24",
}
