import { ReactNode } from "react";

export interface IFormFieldErrorMessageProps {
  children: ReactNode;
  testId?: string;
}

export const FormFieldErrorMessage = ({
  children,
  testId,
}: IFormFieldErrorMessageProps) => {
  return (
    <div
      data-testid={testId}
      className="w-full px-2 py-1 mt-1 text-xs text-red11"
    >
      {children}
    </div>
  );
};
