import {
  getButtonBackgroundColor,
  getButtonBorderColor,
  getButtonHeight,
  getButtonTextColor,
  IButton,
} from "../Button";
import styled from "styled-components";
import { Center } from "../Center";
import { createElement } from "react";
import { IconType } from "react-icons";

export type IIconButtonProps = {
  icon: IconType;
} & IButton;

export const StyledButton = styled.button<IButton>`
  height: ${(props) => getButtonHeight(props.size)};
  width: ${(props) => getButtonHeight(props.size)};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  line-height: 1;
  color: ${(props) => getButtonTextColor(props.colorScheme)};
  background-color: ${(props) =>
    getButtonBackgroundColor(props.colorScheme, "default", props.variant)};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid;
  border-color: ${(props) =>
    getButtonBorderColor(props.colorScheme, "default", props.variant)};
  transition: all ${(props) => props.theme.transitionSpeedDefault};
  outline: none;

  :hover {
    background-color: ${(props) =>
      getButtonBackgroundColor(
        props.colorScheme,
        "hover",
        props.variant,
        props.hoverColorScheme
      )};
    border-color: ${(props) =>
      getButtonBorderColor(
        props.colorScheme,
        "hover",
        props.variant,
        props.hoverColorScheme
      )};
  }

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const IconButton = (props: IIconButtonProps) => {
  const {
    id,
    isDisabled = false,
    isLoading = false,
    innerRef,
    className,
    variant = "solid",
    colorScheme = "default",
    testId,
    size = "default",
    icon,
    ...rest
  } = props;

  return (
    <StyledButton
      id={id}
      isLoading={isLoading}
      disabled={isLoading || isDisabled}
      className={className}
      type="button"
      size={size}
      variant={variant}
      colorScheme={colorScheme}
      {...(innerRef && { ref: innerRef })}
      {...(testId && { "data-testid": testId })}
      {...rest}
    >
      <Center>{createElement(icon, { size: 12 })}</Center>
    </StyledButton>
  );
};
