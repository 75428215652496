import { Stack } from "components/atoms/Stack";
import { ILabelJob } from "models/labelJob/labelJob.model";
import { formatAddressee } from "utils/formatAddressee";
import { formatDate } from "utils/formatDate";

export const LabelJobCardTitle = ({
  item,
  handleViewShipments,
}: {
  item: ILabelJob;
  handleViewShipments(): void;
}) => {
  const isBulkUpload =
    item.shipments.filter((shipment) => shipment.shipmentBulkUploadId === null)
      .length === 0;

  const isSingleShipment = item.shipments.length === 1;

  return (
    <>
      <div className="mb-4 font-medium">
        {isBulkUpload ? (
          <div className="flex items-center">
            <div
              className="mr-1 underline cursor-pointer underline-offset-4"
              onClick={handleViewShipments}
            >
              {item.shipments[0]?.bulkUpload?.filename}{" "}
            </div>
            <span className="text-sm text-muted">
              ({item.shipments.length} shipments)
            </span>
          </div>
        ) : isSingleShipment ? (
          <div>
            {formatAddressee({
              firstName: item.shipments[0].toAddressFirstName,
              lastName: item.shipments[0].toAddressLastName,
              company: item.shipments[0].toAddressCompany,
              street1: item.shipments[0].toAddressStreet1,
              city: item.shipments[0].toAddressCity,
              zip: item.shipments[0].toAddressZip,
              countryIsoCode: item.shipments[0].toAddressCountryIso,
            })}
          </div>
        ) : (
          <div
            className="leading-none underline cursor-pointer"
            onClick={handleViewShipments}
          >
            {item.shipments.length} shipments
          </div>
        )}
      </div>
      <div className="mb-5 text-sm text-muted">
        <Stack spacing={4}>
          <div className="leading-none">{item.createdBy.email}</div>
          <div className="leading-none">{formatDate(item.createdDate)}</div>
        </Stack>
      </div>
    </>
  );
};
