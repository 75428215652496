import { CellProps, Column } from "react-table";
import { TableTextCell } from "components/atoms/Table/TableTextCell";

export const PickupShipmentsColumns: Column<any>[] = [
  {
    Header: "Reference",
    accessor: "reference",
    enableSorting: true,
    Cell: (props: CellProps<any>) => {
      return <TableTextCell value={props.row.original.reference} />;
    },
  },
  {
    Header: "To",
    accessor: "recepient",
    Cell: (props: CellProps<any>) => {
      return <TableTextCell value={props.row.original.recepient} />;
    },
  },
  {
    Header: "Address",
    accessor: "address",
    enableSorting: true,
    Cell: (props: CellProps<any>) => {
      return <TableTextCell value={props.row.original.address} />;
    },
  },
];
