import { ReactNode } from "react";
import { useTheme } from "styled-components";
import { ModalClose } from "./ModalClose";
import { useModalContext } from "./ModalContext";
import { ModalTitle } from "./ModalTitle";

export type IModalHeaderProps = {
  children: ReactNode;
};

export enum ModalHeaderTestIds {
  "MODAL_HEADER" = "MODAL_HEADER",
}

export const ModalHeader = (props: IModalHeaderProps) => {
  const { children } = props;

  const { handleClose } = useModalContext();

  const theme = useTheme();

  return (
    <header
      className="flex items-center flex-shrink-0 font-medium border-b"
      data-testid={ModalHeaderTestIds.MODAL_HEADER}
      style={{
        borderColor: theme.borderColor,
        height: theme.navigationHeight,
        paddingLeft: theme.gutter,
        paddingRight: theme.gutter,
      }}
    >
      <ModalTitle>{children}</ModalTitle>

      <div className="ml-auto">
        <ModalClose onClick={() => handleClose && handleClose()} />
      </div>
    </header>
  );
};
