import { Avatar, AvatarSizes } from "components/atoms/Avatar";
import { TableCellLeftSlot } from "../TableCellLeftSlot";

type ITableAddressCellProps = {
  email: string | null;
};

export const TableUserCell = (props: ITableAddressCellProps) => {
  const { email } = props;

  return (
    <div className="flex items-center overflow-hidden whitespace-nowrap text-ellipsis">
      <TableCellLeftSlot>
        <Avatar size={AvatarSizes.xs} initial={email?.charAt(0)} />
      </TableCellLeftSlot>
      {email}
    </div>
  );
};
