import { createGlobalStyle, ThemeProvider } from "styled-components";
import { ThemeLight } from "theme/light/light.theme";
import { ThemeDark } from "theme/dark/dark.theme";
import { GlobalStyle } from "theme/theme.utils";
import {
  getLocalStorageItem,
  LocalStorageKeys,
} from "utils/react/localStorage";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setTheme } from "store/tableSlice/index.slice";
import { IThemeNames } from "theme/theme.type";

export const ReduxThemeProvider = ({ children }: { children: ReactNode }) => {
  const { theme } = useSelector((state: RootState) => {
    return state.tableSlice;
  });

  const dispatch = useDispatch();

  const Global = createGlobalStyle`
      :root {
        ${GlobalStyle(theme === "light" ? ThemeLight : ThemeDark)}
      }
    `;

  useEffect(() => {
    dispatch(
      setTheme(
        (getLocalStorageItem(LocalStorageKeys.CONNEXX_THEME) as IThemeNames) ||
          "dark"
      )
    );
  }, []);

  return (
    <>
      <Global />
      <ThemeProvider theme={theme === "light" ? ThemeLight : ThemeDark}>
        {children}
      </ThemeProvider>
    </>
  );
};
