import { RootState } from "store";
import { DatePicker, ConfigProvider, theme } from "antd";
import { useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";

interface Props {
  onChange: (values: Dayjs, formatString: string) => void;
  value?: Dayjs;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

export const DatePickerComponent = ({
  onChange,
  value,
  minDate,
  maxDate,
}: Props) => {
  const { theme: connexxTheme } = useSelector((state: RootState) => {
    return state.tableSlice;
  });

  return (
    <ConfigProvider
      theme={{
        algorithm:
          connexxTheme === "dark"
            ? theme.darkAlgorithm
            : theme.defaultAlgorithm,
      }}
    >
      <DatePicker
        value={value}
        changeOnBlur
        popupClassName="adjustFooterOkBtn"
        onChange={onChange}
        format="DD-MM-YYYY"
        className={"w-full"}
        disabledDate={(current) => {
          let disable = false;
          if (maxDate ? current > dayjs(maxDate).endOf("day") : null) {
            disable = true;
          }

          if (minDate ? current < dayjs(minDate).endOf("day") : null) {
            disable = true;
          }
          return disable;
        }}
        allowClear={false}
      />
    </ConfigProvider>
  );
};
