import { apiPaths, customerApiBase } from "enum/paths.enum";
import { ILabelJob } from "models/labelJob/labelJob.model";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { IFetchPostResponse } from "services/api";
import { fetchAll, fetchSingle } from "services/get";
import { post } from "services/post";
import { CreateLabelJobRequestDto } from "./dtos/create-label-job.request.dto";
import { FetchLabelJobResponseDto } from "./dtos/fetch-label-job.response.dto";
import { FetchLabelJobsQuery } from "./dtos/fetch-label-jobs.query.dto";
import { FetchLabelJobsResponseDto } from "./dtos/fetch-label-jobs.response.dto";

export const createLabelJob = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: CreateLabelJobRequestDto
): Promise<IFetchPostResponse<ILabelJob>> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.labelJobs,
    values
  );
};

export const markLabelJobAsDownloaded = async (
  salesAccountUUID: ISalesAccount["uuid"],
  labelJobUUID: ILabelJob["uuid"]
): Promise<IFetchPostResponse<ILabelJob>> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.labelJobs +
      "/" +
      labelJobUUID +
      "/set-downloaded"
  );
};

export const fetchLabelJobs = async (
  salesAccountUUID: ISalesAccount["uuid"],
  query?: FetchLabelJobsQuery
): Promise<FetchLabelJobsResponseDto> => {
  return await fetchAll(
    customerApiBase + "/" + salesAccountUUID + apiPaths.labelJobs,
    query
  );
};

export const fetchLabelJob = async (
  salesAccountUUID: ISalesAccount["uuid"],
  uuid: ILabelJob["uuid"]
): Promise<FetchLabelJobResponseDto> => {
  return await fetchSingle(
    customerApiBase + "/" + salesAccountUUID + apiPaths.labelJobs + "/" + uuid
  );
};
