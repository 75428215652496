import { groupBy } from "lodash";
import {
  IShipmentPackage,
  IShipmentPackageGrouped,
} from "models/shipmentPackage/shipmentPackage.model";
import { PackageTypes } from "enum/package-types.enum";

/**
 * Every shipment package is stored as a separate row in the database.
 * This function groups packages that have the same attributes and adds a quantity attribute.
 * @param shipmentPackages shipment packages as they are returned from the API
 */

export const groupedItems = (
  shipmentPackages: IShipmentPackage[]
): IShipmentPackageGrouped[] => {
  const grouped = groupBy(
    [...shipmentPackages],
    (p) => `"${p.type}+${p.width}+${p.height}+${p.length}+${p.weight}"`
  );

  const items: IShipmentPackageGrouped[] = [];
  
  Object.keys(grouped).map((key) => {
    const type = grouped[key];

    const quantity = type.length;

    const item = type[0];

    items.push({
      height: item.height,
      length: item.length,
      quantity: quantity,
      type: PackageTypes[item.type],
      weight: item.weight,
      width: item.width,
    });
  });
  return items;
};
