import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, setModalSize } from "store/modalSlice";
import { Dropzone } from "../DropZone";
import { useWizardContextProvider, WizardHeader } from "../Wizard";
import Papa from "papaparse";
import {
  setCreateProfileData,
  setFileContainsHeaderRow,
} from "store/createOrUpdateBulkShipmentProfileSlice/index.slice";
import { Checkbox } from "components/atoms/Checkbox";
import { RootState } from "store";
import { Button } from "components/atoms/Button";

export const BulkShipmentProfileWizardSelectFile = () => {
  const dispatch = useDispatch();

  const { fileContainsHeaderRow } = useSelector((state: RootState) => {
    return state.createOrUpdateBilkShipmentProfileSlice;
  });

  const [files, setFiles] = useState<File[]>([]);

  const isValid = files.length > 0;

  const { onNextStep, activeStepIndex } = useWizardContextProvider();

  useEffect(() => {
    if (activeStepIndex === 0) {
      dispatch(setModalSize({ name: "createBulkShipmentProfile", size: "sm" }));
    }
  }, [activeStepIndex]);

  useEffect(() => {
    if (files.length > 0) {
      Papa.parse(files[0], {
        header: false,
        complete: (results) => {
          dispatch(setCreateProfileData(results));
        },
      });
    } else {
      dispatch(
        setCreateProfileData({
          data: [],
          errors: [],
          meta: {
            fields: [],
            delimiter: "",
            linebreak: "",
            aborted: false,
            truncated: false,
            cursor: 0,
          },
        })
      );
    }
  }, [files]);

  const handleSubmit = () => {
    onNextStep();
  };

  return (
    <div>
      <WizardHeader
        title="Upload sample data"
        onClose={() =>
          dispatch(closeModal({ modalName: "createBulkShipmentProfile" }))
        }
      />
      <div className="mb-4">
        <Dropzone
          files={files}
          onChange={(files) => {
            setFiles(files);
          }}
          fileType={"csv"}
        />
      </div>
      <div className="mb-4">
        <Checkbox
          checked={fileContainsHeaderRow}
          onCheckedChange={() =>
            dispatch(setFileContainsHeaderRow(!fileContainsHeaderRow))
          }
        >
          Does this file have a header row?
        </Checkbox>
      </div>
      <Button
        onClick={handleSubmit}
        isBlock
        size="lg"
        colorScheme="primary"
        variant="solid"
        isDisabled={!isValid}
      >
        Next
      </Button>
    </div>
  );
};
