import { Rates } from "components/organisms/Rates";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { ShipmentTypes } from "enum/shipment-types.enum";
import { IQuotedRate } from "models/quote/quote.model";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {  setDropOffThunk, setSelectedRate } from "store/createShipmentSlice/index.slice";
import { fetchRatesThunk } from "store/createShipmentSlice/thunks/fetchRatesThunk.thunk";
import { PermissionCheck } from "utils/PermissionCheck/PermissionCheck";
import { CreateQuoteFormEmailSummary } from "../../CreateQuoteForm/CreateQuoteFormEmailSummary";
import { CreateShipmentFormTestIds } from "../CreateShipmentFormTestIds.enum";
import {
  setPickUpFromTime,
  setPickUpToTime,
} from "store/createShipmentSlice/index.slice";

export const CreateShipmentRates = () => {
  const createShipment = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });
  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });
  const dispatch = useDispatch();

  const handleRefetchRates = () => {
    dispatch(fetchRatesThunk());
  };

  const handleSelectRate = (rate: IQuotedRate) => {
    if(rate.carrier.name === 'Fedex ') {
      dispatch(setDropOffThunk(createShipment?.values?.sender, salesAccountUUID));
    }
    dispatch(
      setSelectedRate(
        createShipment?.selectedRate?.id === rate.id ? null : rate
      )
    );

    const addHours = (date, hours) => {
      date.setHours(date.getHours() + hours);

      return date;
    };
    dispatch(setPickUpFromTime(new Date().toISOString()));
    dispatch(setPickUpToTime(addHours(new Date(), 4).toISOString()));
  };

  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const permissions = new PermissionCheck({ user: loggedInUser });

  const isInternational =
    createShipment.values.receiver.countryIsoCode !==
    createShipment.values.sender.countryIsoCode;

  const allowSelect = createShipment.type === ShipmentTypes.SHIPMENT;

  const [checkedRates, setCheckedRates] = useState<IQuotedRate[]>([]);

  const handleCheckRate = (rate: IQuotedRate) => {
    const alreadyExists = checkedRates.find((r) => r.id === rate.id);

    if (alreadyExists) {
      setCheckedRates(checkedRates.filter((r) => r.id !== rate.id));
    } else {
      setCheckedRates([...checkedRates, rate]);
    }
  };

  return (
    <div
      data-testid={CreateShipmentFormTestIds.CREATE_SHIPMENT_FORM_RATES_SECTION}
    >
      <div className="px-10 py-6 pl-20">
        <Rates
          loadingStatus={createShipment.ratesLoadingStatus}
          handleRefetch={handleRefetchRates}
          rates={createShipment.rates}
          isInternationalShipment={isInternational}
          allowSelect={allowSelect}
          isCheckable={permissions.quotes.canEmailQuote()}
          onCheckRate={handleCheckRate}
          selectedRate={createShipment.selectedRate}
          onSelectRate={handleSelectRate}
          checkedRates={checkedRates}
        />

        {createShipment.ratesLoadingStatus === ApiLoadingStatus.SUCCESS &&
          permissions.quotes.canEmailQuote() && (
            <div className="mt-10">
              <CreateQuoteFormEmailSummary
                checkedRates={checkedRates}
                packages={createShipment.values.items}
                quoteDate={new Date()}
                fromCountryIso={createShipment.values.sender.countryIsoCode}
                toCountryIso={createShipment.values.receiver.countryIsoCode}
              />
            </div>
          )}
      </div>
    </div>
  );
};
