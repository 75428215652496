import { Add20 } from "@carbon/icons-react";
import { Center } from "components/atoms/Center";
import { CreateShipmentContentsTestIds } from "./CreateShipmentFormContentsTestIds.enum";

export const CreateShipmentFormContentsAddRow = ({
  onClick,
}: {
  onClick(): void;
}) => {
  return (
    <div className="flex items-center px-5 py-3 text-sm border-t bg-gray2 rounded-b-md border-gray8">
      <div
        data-testid={CreateShipmentContentsTestIds.SHIPMENT_CONTENTS_ADD_ROW}
        onClick={onClick}
        className="inline-flex items-center cursor-pointer hover:underline"
      >
        <div className="w-6 h-6 mr-2 text-white rounded-full shadow-sm bg-primary10">
          <Center>
            <Add20 />
          </Center>
        </div>
        Add item
      </div>
    </div>
  );
};
