import { AnyAction } from "@reduxjs/toolkit";
import { SHIPMENTS_SLICE_NAME } from "./index.slice";

export enum ShipmentsSliceActions {
  "SET_DATA" = "setData",
  "SET_QUERY_OFFSET" = "setQueryOffset",
}

export function isDataUpdated(action: AnyAction) {
  return (
    action.type === `${SHIPMENTS_SLICE_NAME}/${ShipmentsSliceActions.SET_DATA}`
  );
}
