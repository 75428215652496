import { CellProps } from "react-table";
import { ITableColumn } from "components/atoms/Table/types/index";
import { TableTextCell } from "components/atoms/Table/TableTextCell";
import { IAddress } from "./address.model";

export const ModalAddressColumns: ITableColumn<IAddress>[] = [
  {
    Header: "Company",
    accessor: "company",
    Cell: (props: CellProps<IAddress>) => {
      return <TableTextCell value={props.row.original.company} />;
    },
  },
  {
    Header: "Name",
    accessor: "firstName",
    Cell: (props: CellProps<IAddress>) => {
      return (
        <TableTextCell
          value={
            props.row.original.firstName ||
            "" + " " + props.row.original.lastName ||
            ""
          }
        />
      );
    },
  },
  {
    Header: "Address",
    accessor: "addressLine1",
    Cell: (props: CellProps<IAddress>) => {
      return <TableTextCell value={props.row.original.addressLine1} />;
    },
  },

  {
    Header: "Postcode",
    accessor: "zip",
    Cell: (props: CellProps<IAddress>) => {
      return <TableTextCell value={props.row.original.zip} />;
    },
  },
  {
    Header: "Country",
    accessor: "countryIsoCode",
    Cell: (props: CellProps<IAddress>) => {
      return <TableTextCell value={props.row.original.countryIsoCode} />;
    },
  },
];

export const SettingsAddressColumns: ITableColumn<IAddress>[] = [
  {
    Header: "Manual Shipment Default",
    accessor: "isSenderDefault",
    Cell: (props: CellProps<IAddress>) => {
      return (
        <TableTextCell
          value={props.row.original.isSenderDefault ? "Yes" : ""}
        />
      );
    },
  },
  {
    Header: "Company",
    accessor: "company",
    Cell: (props: CellProps<IAddress>) => {
      return <TableTextCell value={props.row.original.company} />;
    },
  },
  {
    Header: "Name",
    accessor: "firstName",

    Cell: (props: CellProps<IAddress>) => {
      return (
        <TableTextCell
          value={
            props.row.original.firstName ||
            "" + " " + props.row.original.lastName ||
            ""
          }
        />
      );
    },
  },
  {
    Header: "Address",
    accessor: "addressLine1",
    Cell: (props: CellProps<IAddress>) => {
      return <TableTextCell value={props.row.original.addressLine1} />;
    },
  },
  {
    Header: "City",
    accessor: "city",
    Cell: (props: CellProps<IAddress>) => {
      return <TableTextCell value={props.row.original.city} />;
    },
  },
  {
    Header: "Postcode",
    accessor: "zip",
    Cell: (props: CellProps<IAddress>) => {
      return <TableTextCell value={props.row.original.zip} />;
    },
  },
  {
    Header: "Country",
    accessor: "countryIsoCode",
    Cell: (props: CellProps<IAddress>) => {
      return <TableTextCell value={props.row.original.countryIsoCode} />;
    },
  },
  {
    Header: "Phone",
    accessor: "phone",
    Cell: (props: CellProps<IAddress>) => {
      return <TableTextCell value={props.row.original.phone} />;
    },
  },
];
