import cx from "classnames";
import { FaExclamationCircle } from "react-icons/fa";
import { useTheme } from "styled-components";
import { Center } from "../Center";

type AlertStatusStrings = "success" | "error" | "info" | "warning";

const AlertStatuses: {
  [key in AlertStatusStrings]: {
    color: string;
  };
} = {
  success: { color: "Green" },
  info: { color: "Blue" },
  warning: { color: "Orange" },
  error: { color: "Red" },
};

type IAlertProps = {
  status?: AlertStatusStrings;
  children: React.ReactNode;
  className?: string;
  testId?: string;
};

export const Alert = ({
  children,
  status = "error",
  className,
  testId,
}: IAlertProps) => {
  const { color } = AlertStatuses[status];

  const theme = useTheme();

  return (
    <div
      className={cx(`px-5 py-4 text-sm flex`, className)}
      data-testid={testId}
      style={{
        borderRadius: theme.borderRadius,
        backgroundColor: `var(--colors${color}2)`,
        color: `var(--colors${color}11)`,
      }}
    >
      <div
        className="flex-shrink-0 w-8 h-8 mr-4 border"
        style={{
          borderRadius: theme.borderRadius,
          borderColor: `var(--colors${color}5)`,
          backgroundColor: `var(--colors${color}3)`,
          color: `var(--colors${color}10)`,
        }}
      >
        <Center>
          <FaExclamationCircle />
        </Center>
      </div>
      <div>{children}</div>
    </div>
  );
};
