import { useModalContext } from "../ModalContext";
import { useHotkeys } from "react-hotkeys-hook";
import { KeyboardKeys } from "enum/keyboardkeys.enum";
import { IconButton } from "components/atoms/IconButton";
import { FaTimes } from "react-icons/fa";
import cx from "classnames";

interface IModalClose {
  position?: "inline" | "absolute";
  onClick(): void;
}

export const ModalClose = ({ onClick, position = "inline" }: IModalClose) => {
  const { isOpen, isOnTop, handleClose } = useModalContext();

  const ref = useHotkeys<HTMLButtonElement>(KeyboardKeys.ENTER, () => {
    if (isOpen && isOnTop && handleClose) {
      handleClose();
    }
  });

  return (
    <IconButton
      className={cx({
        "absolute top-3 right-3": position === "absolute",
      })}
      innerRef={ref}
      onClick={onClick}
      icon={FaTimes}
      size="default"
    />
  );
};
