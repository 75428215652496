import * as RadioGroup from "@radix-ui/react-radio-group";
import { ReactNode } from "react";
import styled from "styled-components";

export type IRadio = RadioGroup.RadioGroupItemProps & {
  children?: ReactNode;
  testId?: string;
};

const StyledRadio = styled(RadioGroup.Item)`
  border: 1px solid;
  border-color: ${(props) => props.theme.formControlBorderColorDefault};
  border-radius: 9999px;
  height: ${(props) => props.theme.space[6]};
  width: ${(props) => props.theme.space[6]};

  display: flex;
  align-items: center;
  justify-content: center;

  &[data-state="checked"] {
    border-color: ${(props) => props.theme.colors.primary[10]};
  }
`;

export const Radio = (props: IRadio) => {
  const { children, testId, value, ...rest } = props;

  return (
    <div className="flex items-center">
      <StyledRadio
        value={value}
        {...(testId && { "data-testid": testId })}
        {...rest}
      >
        <RadioGroup.Indicator className="w-3 h-3 rounded-full bg-primary10" />
      </StyledRadio>

      {children && <div className="ml-2">{children}</div>}
    </div>
  );
};
