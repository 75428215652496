import { useEffect, useState } from "react";
import { IFetchPostResponse } from "services/api";

export type UseFormSubmitReturn<T> = {
  onSubmit(values: T): void;
  errors: any;
  isSubmitting: boolean;
  isSubmitted: boolean;
};

export function useFormSubmit<T, Result = any>({
  submit,
  onSuccess,
  onError,
  debug,
}: {
  submit(values: T): Promise<Result>;
  onSuccess(data: Result, message): void;
  onError?(message: string): void;
  debug?: boolean;
}): UseFormSubmitReturn<T> {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<string | null>();
  const [message, setMessage] = useState<string | null>();
  const [data, setData] = useState<Result | null>(null);
  const [isSubmitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (isSubmitted && !errors) {
      onSuccess(data, message);
    }
  }, [isSubmitted]);

  const onSubmit = async (values: T) => {
    if (!isSubmitting) {
      try {
        setSubmitting(true);
        setErrors(null);

        const { error, data, message }: IFetchPostResponse<Result> =
          await submit(values);

        setSubmitting(false);

        if (error) {
          setErrors(message);
          onError && onError(message);
        }

        if (!error) {
          setData(data);
          setMessage(message);
        }

        setSubmitted(true);
      } catch (e) {
        debug && console.log(e);
        setSubmitting(false);
        setErrors(e);
      }
    }
  };

  return {
    onSubmit,
    errors,
    isSubmitted,
    isSubmitting,
  };
}
