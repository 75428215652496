import { UK_SPECIAL_POSTCODE_PREFIXES } from "enum/uk-special-postcode-prefixes.enum";

export type isContentsFieldRequiredParams = {
  fromCountryIso: string;
  toCountryIso: string;
  toCountryZip: string;
};

export const isContentsFieldRequired = (
  params: isContentsFieldRequiredParams
): boolean => {
  const { fromCountryIso, toCountryIso, toCountryZip } = params;

  const isInternational = fromCountryIso !== toCountryIso;

  if (isInternational) return true;

  const isZipRequired = [
    UK_SPECIAL_POSTCODE_PREFIXES.GUERNSEY,
    UK_SPECIAL_POSTCODE_PREFIXES.ISLE_OF_MAN,
    UK_SPECIAL_POSTCODE_PREFIXES.JERSEY,
    UK_SPECIAL_POSTCODE_PREFIXES.NORTHERN_IRELAND,
    UK_SPECIAL_POSTCODE_PREFIXES.ISLE_OF_SCILLY_1,
    UK_SPECIAL_POSTCODE_PREFIXES.ISLE_OF_SCILLY_2,
    UK_SPECIAL_POSTCODE_PREFIXES.ISLE_OF_SCILLY_3,
    UK_SPECIAL_POSTCODE_PREFIXES.ISLE_OF_SCILLY_4,
  ].some((substr) => toCountryZip.startsWith(substr));

  if (isZipRequired) return true;

  return false;
};
