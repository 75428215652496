import { Select } from "components/atoms/Select";
import { ISelectOption } from "components/atoms/Select/UseSelect";
import { PackageTypes } from "enum/package-types.enum";

export const PackageTypeSelect = ({
  value: valueProp,
  isDisabled,
  onChange,
}: {
  value: string | PackageTypes;
  isDisabled?: boolean;
  onChange(value: ISelectOption<object, PackageTypes>);
}) => {
  const typeOptions: ISelectOption<object, PackageTypes>[] = [
    {
      label: "Envelope",
      value: PackageTypes.Envelope,
    },
    {
      label: "Pak",
      value: PackageTypes.Pak,
    },
    {
      label: "Parcel",
      value: PackageTypes.Parcel,
    },
  ];

  const value = typeOptions.find((type) => type.value === valueProp);

  return (
    <Select<ISelectOption<object, PackageTypes>>
      value={value}
      options={typeOptions}
      isDisabled={isDisabled}
      isSearchable={false}
      onChange={onChange}
    />
  );
};
