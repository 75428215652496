import { useDropzone } from "react-dropzone";
import { Center } from "components/atoms/Center";
import { FaFileCsv, FaFilePdf, FaTimes } from "react-icons/fa";
import styled, { ThemeProvider, useTheme } from "styled-components";
import { IconButton } from "components/atoms/IconButton";
import { ThemeDarkElevation1 } from "theme/dark/dark.theme";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ThemeLightElevation1 } from "theme/light/light.theme";

export type IDropzoneProps = {
  testId?: string;
  onChange(file: File[]): void;
  files: File[];
  fileType: "csv" | "pdf";
};

const StyledDropzone = styled.div`
  border: 1px dashed;
  border-color: ${(props) => props.theme.borderColorStrong};
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: pointer;
  padding: ${(props) => props.theme.space[12]};
  text-align: center;
  background-color: ${(props) => props.theme.backgroundColor};
  transition: all ${(props) => props.theme.transitionSpeedDefault};

  &:hover {
    background-color: ${(props) => props.theme.backgroundColorHover};
  }
`;

const StyledDropzoneFile = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
  border: 1px solid;
  border-color: ${(props) => props.theme.borderColorStrong};
`;

export const Dropzone = (props: IDropzoneProps) => {
  const { onChange, files, testId, fileType } = props;

  const theme = useTheme();

  const { theme: selectedTheme } = useSelector((state: RootState) => {
    return state.tableSlice;
  });

  const { acceptedFiles, getRootProps, getInputProps, isDragReject } =
    useDropzone({
      onDrop: onChange,
      maxFiles: 1,
      accept:
        fileType === "csv"
          ? { "text/csv": [`.csv`] }
          : { "text/pdf": [`.pdf`] },
    });

  const handleRemoveFile = (file: number) => {
    onChange(
      acceptedFiles.filter((f, i) => {
        return i !== file && f;
      })
    );
  };

  return (
    <ThemeProvider
      theme={
        selectedTheme === "dark"
          ? {
              ...ThemeDarkElevation1,
            }
          : {
              ...ThemeLightElevation1,
            }
      }
    >
      {files.length === 0 && (
        <StyledDropzone {...getRootProps()}>
          <input
            {...getInputProps()}
            {...(testId && { "data-testid": testId })}
          />
          <div
            className="inline-block w-12 h-12 mb-3 rounded-full text-muted"
            style={{ backgroundColor: theme.backgroundColor }}
          >
            <Center>
              {fileType === "csv" ? <FaFileCsv /> : <FaFilePdf />}
            </Center>
          </div>
          <div className="text-sm leading-none text-muted">
            {isDragReject && (
              <div>{`Only ${
                fileType === "csv" ? "CSV" : "PDF"
              }s are supported`}</div>
            )}
            {`Select ${fileType === "csv" ? "CSV" : "PDF"} file`}
          </div>
        </StyledDropzone>
      )}
      {files.map((file, index) => {
        return (
          <StyledDropzoneFile
            key={file.name}
            className="flex items-center px-4 py-3"
            style={{
              borderRadius: theme.borderRadius,
            }}
          >
            <div className="text-sm leading-none">{file.name}</div>
            <IconButton
              icon={FaTimes}
              hoverColorScheme="danger"
              onClick={() => handleRemoveFile(index)}
              className="ml-auto"
            />
          </StyledDropzoneFile>
        );
      })}
    </ThemeProvider>
  );
};
