import { Select } from "components/atoms/Select";
import { generateSelectOptions } from "components/atoms/Select/UseSelect";
import { FormControl } from "components/molecules/FormControl";
import {
  Conditions,
  IRuleCondition,
  Operators,
  RuleComponentNames,
} from "models/rule/rule.model";
import { tabbable } from "tabbable";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Add20 } from "@carbon/icons-react";
import { IconButton } from "components/atoms/IconButton";
import { CreateRuleAppliesTo } from "services/api/rules/dtos/create-rule.request.dto";
import { RadioGroup } from "components/atoms/RadioGroup";
import { CountrySelect } from "components/molecules/CountrySelect";
import { PackageTypeSelect } from "components/molecules/PackageTypeSelect";
import { PackageTypes } from "enum/package-types.enum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  addCondition,
  setAppliesTo,
  setConditions,
} from "store/createOrEditRuleSlice/index.slice";
import { getDimensionUnitSettings } from "utils/getDimensionUnitSettings";
import { getWeightUnitSettings } from "utils/getWeightUnitSettings";
import {
  BulkUploadItemFieldNames,
  BulkUploadPackageFieldNames,
} from "enum/bulk-upload-column-names.enum";
import {
  convertDatabaseToValue,
  convertGramsToKilos,
  convertKilosToGrams,
  convertValueToDatabase,
} from "utils/convertGramsToKilos";
import { WeightUnits } from "interfaces/weight-units.type";
import {
  convertKilosToPounds,
  convertPoundsToKilos,
} from "utils/convertPoundsToKilos";
import { DimensionUnits } from "interfaces/dimension-units.type";
import { convertCmToInches, convertInchesToCm } from "utils/convertInchesToCm";
import { RuleOperators } from "enum/rule-operators.enum";
import { TagInput } from "components/atoms/TagInput";
import { Panel } from "components/atoms/Panel";
import { Radio } from "components/atoms/Radio";
import { Stack } from "components/atoms/Stack";
import { NumberInput } from "components/atoms/NumberInput";
import { Center } from "components/atoms/Center";
import { Input } from "components/atoms/Input";
import { PanelHeader } from "components/atoms/Panel/PanelHeader";
import { PanelTitle } from "components/atoms/Panel/PanelTitle";
import { PanelBody } from "components/atoms/Panel/PanelBody";
import { FaTrash } from "react-icons/fa";

const TableHeader = ({ children }: { children: ReactNode }) => {
  return (
    <div className="mb-3 text-sm font-semibold leading-none text-gray-600">
      {children}
    </div>
  );
};

export const RuleFormConditions = () => {
  const [lastElementIndex, setLastElementIndex] = useState(0);

  const { values } = useSelector((state: RootState) => {
    return state.createOrEditRuleSlice;
  });

  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });

  const dispatch = useDispatch();

  const OperatorSelectOptions = generateSelectOptions({
    data: Operators,
    labelKey: "name",
    valueKey: "value",
  });

  const conditions = Conditions({
    dimensionUnit: getDimensionUnitSettings(settings.dimensionsUnit).label,
    weightUnit: getWeightUnitSettings(settings.weightUnit).label,
  });

  const ConditionSelectOptions = generateSelectOptions({
    data: conditions,
    labelKey: "name",
    valueKey: "value",
  });

  const ref = useRef<HTMLDivElement>(null);

  const handleAddCondition = () => {
    dispatch(addCondition());
  };

  const handleDeleteCondition = (index: number) => {
    const cloned = [...values.conditions];

    if (index > -1) {
      cloned.splice(index, 1);
      dispatch(setConditions(cloned));
    }
  };

  useEffect(() => {
    if (ref) {
      const tabElements = tabbable(ref?.current);
      setLastElementIndex(tabElements.length);
    }
  }, [ref]);

  return (
    <Panel ref={ref}>
      <PanelHeader>
        <PanelTitle>When</PanelTitle>
      </PanelHeader>
      <PanelBody>
        <RadioGroup
          value={values.appliesTo}
          onValueChange={(value: CreateRuleAppliesTo) => {
            dispatch(setAppliesTo(value));
          }}
        >
          <Radio value={CreateRuleAppliesTo.ALL}>All shipments</Radio>
          <Radio value={CreateRuleAppliesTo.CONDITIONAL}>
            Certain conditions are met
          </Radio>
        </RadioGroup>
        {values.appliesTo === CreateRuleAppliesTo.CONDITIONAL && (
          <div className="mt-4 pl-7">
            <div className="flex">
              <div className="grid flex-1 grid-cols-3 gap-3 mr-1">
                <TableHeader>Condition</TableHeader>
                <TableHeader>Operator</TableHeader>
                <TableHeader>Value</TableHeader>
              </div>
              <div className="w-10" />
            </div>
            <Stack spacing={2}>
              {values.conditions.map((condition, i) => {
                const isRemovable = i > 0;

                const tabIndex = i === 0 ? 1 : i;

                const { component }: IRuleCondition =
                  conditions.find((c) => {
                    return c.value === condition.fact;
                  }) || ({} as IRuleCondition);

                let value = condition.value;

                if (
                  condition.fact === BulkUploadItemFieldNames.itemValue &&
                  typeof value === "number"
                ) {
                  value = convertDatabaseToValue(value);
                }

                if (
                  (condition.fact ===
                    BulkUploadPackageFieldNames.packageHeight ||
                    condition.fact ===
                      BulkUploadPackageFieldNames.packageWidth ||
                    condition.fact ===
                      BulkUploadPackageFieldNames.packageLength) &&
                  typeof value === "number"
                ) {
                  value =
                    settings?.dimensionsUnit === DimensionUnits.inch
                      ? convertDatabaseToValue(convertCmToInches(value))
                      : convertDatabaseToValue(value);
                }

                if (
                  (condition.fact ===
                    BulkUploadPackageFieldNames.packageWeight ||
                    condition.fact === BulkUploadItemFieldNames.itemWeight) &&
                  typeof value === "number"
                ) {
                  value =
                    settings?.weightUnit === WeightUnits.pound
                      ? convertKilosToPounds(
                          convertGramsToKilos(convertDatabaseToValue(value))
                        )
                      : convertGramsToKilos(convertDatabaseToValue(value));
                }

                return (
                  <div className="flex" key={i}>
                    <div className="grid flex-1 grid-cols-3 gap-3 mr-1">
                      <FormControl>
                        <Select
                          dropdownWidth={220}
                          // tabIndex={tabIndex * 1}
                          isSearchable={false}
                          placeholder="Select"
                          value={ConditionSelectOptions.find((option) => {
                            return option.value === values.conditions[i].fact;
                          })}
                          onChange={(value) => {
                            const updated = [...values.conditions];
                            updated[i] = {
                              ...condition,
                              fact: conditions.find((condition) => {
                                return condition.value === value.value;
                              }).value,
                            };
                            dispatch(setConditions(updated));
                          }}
                          options={ConditionSelectOptions}
                        />
                      </FormControl>
                      <FormControl>
                        <Select
                          isSearchable={false}
                          // tabIndex={tabIndex * 2}
                          placeholder="Select"
                          isDisabled={!condition.fact ? true : false}
                          value={OperatorSelectOptions.find((option) => {
                            return (
                              option.value === values.conditions[i].operator
                            );
                          })}
                          onChange={(value) => {
                            const updated = [...values.conditions];

                            updated[i] = {
                              ...condition,
                              operator: Operators.find((operator) => {
                                return operator.value === value.value;
                              }).value,
                            };

                            dispatch(setConditions(updated));
                          }}
                          options={OperatorSelectOptions.filter((option) => {
                            const availableOperators: IRuleCondition =
                              conditions.find((c) => {
                                return c.value === condition.fact;
                              });

                            return availableOperators?.operators.includes(
                              option.value
                            );
                          })}
                        />
                      </FormControl>

                      {condition.fact && condition.operator && (
                        <FormControl>
                          {component === RuleComponentNames.INPUT ? (
                            <>
                              {condition.operator === RuleOperators.in ||
                              condition.operator === RuleOperators.notIn ? (
                                <TagInput
                                  value={condition.value as string[]}
                                  onChange={(v) => {
                                    const updated = [...values.conditions];

                                    updated[i] = {
                                      ...condition,
                                      value: v,
                                    };

                                    dispatch(setConditions(updated));
                                  }}
                                />
                              ) : (
                                <Input
                                  value={condition.value as string}
                                  onChange={(e) => {
                                    const updated = [...values.conditions];

                                    const value = e.target.value;

                                    updated[i] = {
                                      ...condition,
                                      value,
                                    };
                                    dispatch(setConditions(updated));
                                  }}
                                />
                              )}
                            </>
                          ) : component ===
                            RuleComponentNames.COUNTRY_SELECT ? (
                            <CountrySelect
                              value={condition.value as string}
                              onChange={(value) => {
                                const updated = [...values.conditions];
                                updated[i] = {
                                  ...condition,
                                  value,
                                };
                                dispatch(setConditions(updated));
                              }}
                            />
                          ) : component === RuleComponentNames.NUMBER ? (
                            <NumberInput
                              value={value as number}
                              onChange={(value) => {
                                const updated = [...values.conditions];

                                if (
                                  condition.fact ===
                                  BulkUploadItemFieldNames.itemValue
                                ) {
                                  value = convertValueToDatabase(value);
                                }

                                if (
                                  condition.fact ===
                                    BulkUploadPackageFieldNames.packageHeight ||
                                  condition.fact ===
                                    BulkUploadPackageFieldNames.packageWidth ||
                                  condition.fact ===
                                    BulkUploadPackageFieldNames.packageLength
                                ) {
                                  value =
                                    settings?.dimensionsUnit ===
                                    DimensionUnits.inch
                                      ? convertValueToDatabase(
                                          convertInchesToCm(value)
                                        )
                                      : convertValueToDatabase(value);
                                }

                                if (
                                  condition.fact ===
                                    BulkUploadPackageFieldNames.packageWeight ||
                                  condition.fact ===
                                    BulkUploadItemFieldNames.itemWeight
                                ) {
                                  value =
                                    settings?.weightUnit === WeightUnits.pound
                                      ? convertValueToDatabase(
                                          convertKilosToGrams(
                                            convertPoundsToKilos(value)
                                          )
                                        )
                                      : convertValueToDatabase(
                                          convertKilosToGrams(value)
                                        );
                                }

                                updated[i] = {
                                  ...condition,
                                  value,
                                };
                                dispatch(setConditions(updated));
                              }}
                            />
                          ) : component ===
                            RuleComponentNames.PACKAGE_TYPE_SELECT ? (
                            <PackageTypeSelect
                              value={condition.value as PackageTypes}
                              onChange={({ value }) => {
                                const updated = [...values.conditions];
                                updated[i] = {
                                  ...condition,
                                  value,
                                };
                                dispatch(setConditions(updated));
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </FormControl>
                      )}
                    </div>
                    <div
                      tabIndex={i === 0 ? -1 : i * 4}
                      onClick={() => handleDeleteCondition(i)}
                      className="w-10 h-10"
                    >
                      {isRemovable && (
                        <Center>
                          <IconButton
                            icon={FaTrash}
                            hoverColorScheme="danger"
                          />
                        </Center>
                      )}
                    </div>
                  </div>
                );
              })}
            </Stack>
            <div
              className="inline-flex items-center mt-3 text-sm font-medium leading-none rounded-md cursor-pointer"
              onClick={handleAddCondition}
              tabIndex={5}
            >
              <Add20 />
              Add condition
            </div>
          </div>
        )}
      </PanelBody>
    </Panel>
  );
};
