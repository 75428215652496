import { Navigation } from "components/organisms/Navigation";
import styles from "./LayoutDashboard.module.scss";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { Environments, getEnvironment } from "utils/getEnvironment";

export const LayoutDashboard = ({ children }: { children?: ReactNode }) => {
  const router = useRouter();

  return (
    <div className={styles.LayoutDashboard}>
      {/* {getEnvironment() === Environments.staging && (
        <div className="flex items-center justify-center h-6 px-4 text-xs font-bold leading-none text-center text-white uppercase bg-gray10">
          Viewing Staging
        </div>
      )} */}

      <Navigation />
      <motion.main
        key={router.route}
        initial="hidden"
        animate="enter"
        exit="exit"
        className={styles.LayoutDashboard__Body}
        transition={{
          ease: "easeInOut",
          duration: 1,
        }}
      >
        {children}
      </motion.main>
    </div>
  );
};
