import { CircleFlag } from "react-circle-flags";
import { getThemeVariable } from "theme/getThemeVariable.theme";
import { ThemeSpacingVariableNames } from "theme/theme-variable-names.theme";

export const Flag = ({
  countryCode,
  size = "space4",
}: {
  countryCode: string;
  size?: ThemeSpacingVariableNames;
}) => {
  return (
    <CircleFlag
      countryCode={countryCode?.toLowerCase()}
      style={{
        width: getThemeVariable(size),
        height: getThemeVariable(size),
      }}
    />
  );
};
