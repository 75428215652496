import { useState } from "react";
import { runIfFunction } from "utils/react/runIfFunction";
import { isArray } from "lodash";
import cx from "classnames";
import { MaybeRenderProp } from "utils/react/maybeRenderProp";
import { FormFieldLabel } from "components/atoms/FormFieldLabel";
import { FormFieldErrorMessage } from "components/atoms/FormFieldErrorMessage";

export interface IFormControl<T = object> {
  id?: keyof T;
  label?: string;
  children?: MaybeRenderProp<any>;
  htmlFor?: keyof T;
  description?: string;
  errorMessage?: string[] | string | null;
  errorMessagePosition?: "inline" | "absolute";
  tabIndex?: number;
  labelWidth?: number;
  value?: unknown;
  isRequired?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  testId?: string;
  className?: string;
}

export enum FormControlTestIds {
  "FORM_FIELD_WRAPPER" = "FORM_FIELD_WRAPPER",
  "FORM_FIELD_ERROR_MESSAGE" = "FORM_FIELD_ERROR_MESSAGE",
  "FORM_FIELD_REQUIRED" = "FORM_FIELD_REQUIRED",
}

export const FormControl = <T,>(props: IFormControl<T>): JSX.Element => {
  const {
    label,
    children,
    description,
    htmlFor,
    isInvalid = false,
    isRequired = false,
    errorMessagePosition = "inline",
    errorMessage = "",
    testId,
    className = "",
  } = props;

  const [isFocused, setFocused] = useState<boolean>(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const childProps = {
    ...props,
    handleBlur,
    isRequired,
    handleFocus,
    isFocused,
  };

  return (
    <div
      onFocusCapture={handleFocus}
      onBlur={handleBlur}
      className={`relative ${className}`}
      data-testid={
        testId
          ? `${FormControlTestIds.FORM_FIELD_WRAPPER}_${testId}`
          : FormControlTestIds.FORM_FIELD_WRAPPER
      }
    >
      {label && (
        <FormFieldLabel htmlFor={htmlFor as string}>
          {label}{" "}
          {isRequired && (
            <span
              className="text-red10"
              data-testid={FormControlTestIds.FORM_FIELD_REQUIRED}
            >
              *
            </span>
          )}
        </FormFieldLabel>
      )}
      <div className="w-full">{runIfFunction(children, { ...childProps })}</div>
      {description && <div className="text-sm">{description}</div>}
      {isInvalid === true ||
        (errorMessage && errorMessage.length > 0 && (
          <div
            className={cx({ absolute: errorMessagePosition === "absolute" })}
          >
            <FormFieldErrorMessage
              testId={`${testId}_${FormControlTestIds.FORM_FIELD_ERROR_MESSAGE}`}
            >
              {isArray(errorMessage)
                ? errorMessage.map((error, key) => {
                    return <div key={key}>{error}</div>;
                  })
                : errorMessage}
            </FormFieldErrorMessage>
          </div>
        ))}
    </div>
  );
};
