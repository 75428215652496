import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Papa from "papaparse";
import { ModalNames } from "store/modalSlice/modalNames.enum";
import { isModalClosed } from "store/modalSlice/modalSlice.actions";
import { CreateBulkShipmentSliceActions } from "./CreateBulkShipmentActionNames.enum";
import { CreateBulkShipmentInitialState } from "./CreateBulkShipmentInitialState.const";
import { ICreateBulkShipmentsSlice } from "./CreateBulkShipmentInitialState.interface";

const SLICE_NAME = "createBulkShipmentsSlice";

export const createBulkShipmentsSlice = createSlice({
  name: SLICE_NAME,
  initialState: CreateBulkShipmentInitialState,
  reducers: {
    [CreateBulkShipmentSliceActions.SET_DATA]: (
      state,
      action: PayloadAction<Papa.ParseResult<unknown>>
    ) => {
      state.data = action.payload;
    },
    [CreateBulkShipmentSliceActions.SET_FILE_NAME]: (
      state,
      action: PayloadAction<ICreateBulkShipmentsSlice["filename"]>
    ) => {
      state.filename = action.payload;
    },
    [CreateBulkShipmentSliceActions.SET_FILE_CONTAINS_HEADER_ROW]: (
      state,
      action: PayloadAction<ICreateBulkShipmentsSlice["fileContainsHeaderRow"]>
    ) => {
      state.fileContainsHeaderRow = action.payload;
    },
    [CreateBulkShipmentSliceActions.SET_SUBMIT_STATUS]: (
      state,
      action: PayloadAction<ICreateBulkShipmentsSlice["submitStatus"]>
    ) => {
      state.submitStatus = action.payload;
    },
    [CreateBulkShipmentSliceActions.SET_SUBMIT_COUNT]: (
      state,
      action: PayloadAction<ICreateBulkShipmentsSlice["submitCount"]>
    ) => {
      state.submitCount = action.payload;
    },
    [CreateBulkShipmentSliceActions.SET_PROFILE_UUID]: (
      state,
      action: PayloadAction<ICreateBulkShipmentsSlice["selectedProfileUUID"]>
    ) => {
      state.selectedProfileUUID = action.payload;
    },
    [CreateBulkShipmentSliceActions.SET_VALIDATING_STATUS]: (
      state,
      action: PayloadAction<ICreateBulkShipmentsSlice["validatingStatus"]>
    ) => {
      state.validatingStatus = action.payload;
    },
    [CreateBulkShipmentSliceActions.SET_VALIDATED_DATA]: (
      state,
      action: PayloadAction<ICreateBulkShipmentsSlice["validatedData"]>
    ) => {
      state.validatedData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(isModalClosed, (state, action) => {
      if (action.payload.modalName === ModalNames.createBulkShipment) {
        Object.assign(state, CreateBulkShipmentInitialState);
      }
    });
  },
});

export const {
  setData,
  setFileName,
  setFileContainsHeaderRow,
  setProfileUUID,
  setSubmitStatus,
  setSubmitCount,
  setValidatedData,
  setValidatingStatus,
} = createBulkShipmentsSlice.actions;
