import { AnimationPulse } from "components/atoms/Animations/AnimationPulse";
import { Center } from "components/atoms/Center";
import Image from "next/image";
import ConnexxLogo from "../../../public/images/logos/connexx.svg"

export const Loading = () => {
  return (
    <div className="flex items-center w-screen h-screen bg-black">
      <Center>
        <AnimationPulse>
          <div className="text-center">
            <div className="w-24 mb-5">
              <Image
                  src={ConnexxLogo}
                  alt={"Connexx Logo"}
              />
            </div>
          </div>
        </AnimationPulse>
      </Center>
    </div>
  );
};
