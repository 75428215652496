import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { createShipment, updateShipment } from "services/api/shipments";
import { CreateShipmentRequestDto } from "services/api/shipments/dtos/create-shipment.request.dto";
import { AppThunk, RootState } from "store";
import { createSingleLabelThunk } from "store/createSingleLabelSlice/thunks/createSingleLabelThunk.thunk";
import {
  setErrors,
  setPrintLater,
  setSubmitCount,
  setSubmittingErrorMessage,
  setSubmittingStatus,
} from "../index.slice";
import { CreateShipmentValidator } from "../validators/CreateShipmentsSlice.validator";
import { times } from "lodash";
import { ShipmentDutyOrTaxPaidBy } from "enum/shipment-duty-or-tax-paid-by.enum";

export const finaliseShipmentThunk = ({
  printLater,
}: {
  printLater: boolean;
}): AppThunk => {
  return async (dispatch, getState) => {
    const {
      countriesSlice: { countries },
      createShipmentSlice: {
        dangerousGoodsOption,
        values,
        selectedRate,
        editingShipmentUUID,
        pickUpObject,
        dropOffObject,
        files,
        insurance,
        duplicateOrEdit,
      },
      authSlice: { salesAccountUUID },
      settingsSlice,
    } = getState() as RootState;

    const defaultSettings = settingsSlice.settings;

    const divideTotalPackageWeightByQuantityInUi =
      defaultSettings?.divideTotalPackageWeightByQuantityInUi;
    if (salesAccountUUID) {
      dispatch(setSubmitCount());

      const carrierName = selectedRate?.carrier?.name?.toLowerCase();
      const { errors, isValid } = new CreateShipmentValidator({
        countries,
      }).validateAll(values, dangerousGoodsOption, carrierName);

      dispatch(setErrors(errors));

      if (isValid) {
        await dispatch(setSubmittingStatus(ApiLoadingStatus.LOADING));

        await dispatch(setPrintLater(printLater));

        const newDate = pickUpObject.pickupDate.split("T")[0];
        const fromTime = pickUpObject.pickupFromTime.split("T")[1];
        const toTime = pickUpObject.pickupToTime.split("T")[1];

        const fromDate = new Date(newDate + "T" + fromTime);
        const toDate = new Date(newDate + "T" + toTime);
        const pickUpObjectClone = {
          ...pickUpObject,
          pickupFromDate: pickUpObject.shouldPickUp
            ? fromDate.toString()
            : null,
          pickupToDate: pickUpObject.shouldPickUp ? toDate.toString() : null,
        };
        const dropOffLocation = dropOffObject.selectedDropOffLocation;
        // pickUpObjectClone.pickupToDate = format(
        //   toDate,
        //   "yyyy-MM-dd'T'HH:mm:ss"
        // );

        // console.log("pickUpObject", pickUpObject);

        const fromAddress = values.sender;

        const toAddress = values.receiver;

        const getBase64: (file: File) => Promise<any> = (file) => {
          if (file) {
            return new Promise(function (resolve, reject) {
              const reader = new FileReader();
              reader.onload = function () {
                resolve(reader.result);
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            });
          }
          return null;
        };

        const packages = values.items;
        // items should be unique
        let totalPackageCount = 0;

        const items: typeof values.contents = [];

        packages.forEach((p, index) => {
          // const currentPackageCount = totalPackageCount
          const itemsForThisPackage = values.contents.filter(
            (item) => item.packageId === index
          );

          times(p.quantity, () => {
            const itemsForThisPackageMapped = itemsForThisPackage.map(
              (item) => {
                return {
                  ...item,
                  packageId: totalPackageCount,
                };
              }
            );
            items.push(...itemsForThisPackageMapped);
            totalPackageCount++;
          });
          // p.quantity
        });

        const uploadedDocuments: {
          uploadedProformaInvoiceBase64: string;
          uploadedCommercialInvoiceBase64: string;
          uploadedCustomsDeclarationBase64: string;
          uploadedCertificateOfOriginBase64: string;
          uploadedPackingListadrBase64: string;
          uploadedBankDocumentsBase64: string;
          uploadedDgGoodsDeclarationBase64: string;
          uploadedOtherDocumentsBase64: string;
          uploadedPickupOrderBase64: string;
          uploadedExportDeclarationBase64: string;
          uploadedExportAttachmentBase64: string;
        } = await {
          uploadedProformaInvoiceBase64: await getBase64(files[0].file),
          uploadedCommercialInvoiceBase64: await getBase64(files[1].file),
          uploadedCustomsDeclarationBase64: await getBase64(files[2].file),
          uploadedCertificateOfOriginBase64: await getBase64(files[3].file),
          uploadedPackingListadrBase64: await getBase64(files[4].file),
          uploadedBankDocumentsBase64: await getBase64(files[5].file),
          uploadedDgGoodsDeclarationBase64: await getBase64(files[6].file),
          uploadedOtherDocumentsBase64: await getBase64(files[7].file),
          uploadedPickupOrderBase64: await getBase64(files[8].file),
          uploadedExportDeclarationBase64: await getBase64(files[9].file),
          uploadedExportAttachmentBase64: await getBase64(files[10].file),
        };

        let dutyOrTaxPaidBy = values.details.dutyOrTaxPaidBy;

        // starlinks service has DDU/DDP chosen at the service level
        if (selectedRate?.service?.name?.toUpperCase().includes("DDP")) {
          dutyOrTaxPaidBy = ShipmentDutyOrTaxPaidBy.SENDER;
        }

        const payload: CreateShipmentRequestDto = {
          fromAddress: {
            saveToAddressBook: fromAddress.saveToAddressBook,
            firstName: fromAddress.firstName?.trim(),
            lastName: fromAddress.lastName?.trim(),
            email: fromAddress.email?.trim(),
            phone: fromAddress.phone?.trim(),
            company: fromAddress.company?.trim(),
            street1: fromAddress.addressLine1?.trim(),
            street2: fromAddress.addressLine2?.trim(),
            city: fromAddress.city?.trim(),
            countyState: fromAddress.countyState?.trim(),
            eoriNumber: fromAddress.eoriNumber?.trim(),
            vatNumber: fromAddress.vatNumber?.trim(),
          },
          customerReference: values.details.customerReference,
          customerReference2: values.details.customerReference2,
          invoiceNumber: values.details.invoiceNumber || null,
          dutyOrTaxPaidBy: dutyOrTaxPaidBy,
          shouldSplitDutyAndVat: values.details.shouldSplitDutyAndVat,
          reasonForExport: values.details.reasonForExport,
          dangerousGoods: dangerousGoodsOption
            ? dangerousGoodsOption.label
            : null,
          shipmentSourceName: values.details.shipmentSourceName,
          itemDescriptions: items,
          rateId: selectedRate?.id,
          pickUpObject: pickUpObjectClone,
          insurance,
          iossNumber: values.details.iossNumber,
          toAddress: {
            saveToAddressBook: toAddress.saveToAddressBook,
            firstName: toAddress.firstName?.trim(),
            lastName: toAddress.lastName?.trim(),
            email: toAddress.email?.trim(),
            phone: toAddress.phone?.trim(),
            company: toAddress.company?.trim(),
            street1: toAddress.addressLine1?.trim(),
            street2: toAddress.addressLine2?.trim(),
            city: toAddress.city?.trim(),
            countyState: toAddress.countyState?.trim(),
            eoriNumber: toAddress.eoriNumber?.trim(),
            vatNumber: toAddress.vatNumber?.trim(),
          },
          uploadedProformaInvoiceBase64:
            uploadedDocuments.uploadedProformaInvoiceBase64
              ? uploadedDocuments.uploadedProformaInvoiceBase64
                  ?.replace("data:", "")
                  ?.replace(/^.+,/, "")
              : uploadedDocuments.uploadedProformaInvoiceBase64,
          uploadedCommercialInvoiceBase64:
            uploadedDocuments.uploadedCommercialInvoiceBase64
              ? uploadedDocuments.uploadedCommercialInvoiceBase64
                  ?.replace("data:", "")
                  ?.replace(/^.+,/, "")
              : uploadedDocuments.uploadedCommercialInvoiceBase64,
          uploadedCustomsDeclarationBase64:
            uploadedDocuments.uploadedCustomsDeclarationBase64
              ? uploadedDocuments.uploadedCustomsDeclarationBase64
                  ?.replace("data:", "")
                  ?.replace(/^.+,/, "")
              : uploadedDocuments.uploadedCustomsDeclarationBase64,
          uploadedCertificateOfOriginBase64:
            uploadedDocuments.uploadedCertificateOfOriginBase64
              ? uploadedDocuments.uploadedCertificateOfOriginBase64
                  ?.replace("data:", "")
                  ?.replace(/^.+,/, "")
              : uploadedDocuments.uploadedCertificateOfOriginBase64,
          uploadedPackingListadrBase64:
            uploadedDocuments.uploadedPackingListadrBase64
              ? uploadedDocuments.uploadedPackingListadrBase64
                  ?.replace("data:", "")
                  ?.replace(/^.+,/, "")
              : uploadedDocuments.uploadedPackingListadrBase64,
          uploadedBankDocumentsBase64:
            uploadedDocuments.uploadedBankDocumentsBase64
              ? uploadedDocuments.uploadedBankDocumentsBase64
                  ?.replace("data:", "")
                  ?.replace(/^.+,/, "")
              : uploadedDocuments.uploadedBankDocumentsBase64,
          uploadedDgGoodsDeclarationBase64:
            uploadedDocuments.uploadedDgGoodsDeclarationBase64
              ? uploadedDocuments.uploadedDgGoodsDeclarationBase64
                  ?.replace("data:", "")
                  ?.replace(/^.+,/, "")
              : uploadedDocuments.uploadedDgGoodsDeclarationBase64,
          uploadedOtherDocumentsBase64:
            uploadedDocuments.uploadedOtherDocumentsBase64
              ? uploadedDocuments.uploadedOtherDocumentsBase64
                  ?.replace("data:", "")
                  ?.replace(/^.+,/, "")
              : uploadedDocuments.uploadedOtherDocumentsBase64,
          uploadedPickupOrderBase64: uploadedDocuments.uploadedPickupOrderBase64
            ? uploadedDocuments.uploadedPickupOrderBase64
                ?.replace("data:", "")
                ?.replace(/^.+,/, "")
            : uploadedDocuments.uploadedPickupOrderBase64,
          uploadedExportDeclarationBase64:
            uploadedDocuments.uploadedExportDeclarationBase64
              ? uploadedDocuments.uploadedExportDeclarationBase64
                  ?.replace("data:", "")
                  ?.replace(/^.+,/, "")
              : uploadedDocuments.uploadedExportDeclarationBase64,
          uploadedExportAttachmentBase64:
            uploadedDocuments.uploadedExportAttachmentBase64
              ? uploadedDocuments.uploadedExportAttachmentBase64
                  ?.replace("data:", "")
                  ?.replace(/^.+,/, "")
              : uploadedDocuments.uploadedExportAttachmentBase64,
          dropOffLocation: dropOffLocation,

          integrationStoreID: parseInt(
            values.details.integrationStoreID
              ? values.details.integrationStoreID.toString()
              : "".toString()
          ),
          shipmentIntegrationID:
            Number(values.details.shipmentIntegrationID) || null,
          orderNumber: values.details.orderNumber,
        };

        const isEditingShipment = editingShipmentUUID;

        const { error, message, data } = isEditingShipment
          ? await updateShipment(salesAccountUUID, editingShipmentUUID, {
              createShipmentRequest: payload,
              duplicateOrEdit,
            })
          : await createShipment(salesAccountUUID, payload);

        if (error || !data) {
          dispatch(setSubmittingStatus(ApiLoadingStatus.FAILED));

          dispatch(setSubmittingErrorMessage(message || ""));
        } else {
          if (!printLater) {
            dispatch(createSingleLabelThunk(data.id, "new"));
          }

          dispatch(setSubmittingErrorMessage(""));
          dispatch(setSubmittingStatus(ApiLoadingStatus.SUCCESS));
        }
      }
    }
  };
};
