import { AnyAction } from "@reduxjs/toolkit";
import { CREATE_SHIPMENT_SLICE_NAME } from "./index.slice";

export enum CreateShipmentSliceActions {
  SET_DEFAULT_FROM_ISO_GA = "setDefaultFromISO_GA",
  SET_DEFAULT_PACKAGE_DIMENSIONS = "SET_DEFAULT_PACKAGE_DIMENSIONS",
  SET_USE_INSURANCE = "setUseInsurance",
  SET_INSURANCE_AMOUNT = "setInsuranceAmount",
  SET_INSURANCE_CURRENCY = "setInsuranceCurrency",
  SET_DISCLAIMER_CHECKED = "setDisclaimerChecked",
  SET_SAVED_SENDER_ADDRESS_EDIT_MODE = "setSavedSenderAddressEditMode",
  SET_SAVED_RECEIVER_ADDRESS_EDIT_MODE = "setSavedReceiverAddressEditMode",
  "SET_DEFAULT_ITEM_VALUE_INITIAL_STATE" = "setDefaultItemValueInitialState",
  SET_PICKUP = "setPickUp",
  SET_DROPOFF = "setDropOff",
  SET_SELECTED_LOCATION = "setSelectedLocation",
  SET_DROP_OFF_LOCATIONS = "setDropOffLocations",
  SET_SENDING_TO_RESIDENCE = "setSendingToResidence",
  SET_PICKUP_DATE = "setPickUpDate",
  SET_PICKUP_FROM_TIME = "setPickUpFromTime",
  SET_PICKUP_TO_TIME = "setPickUpToTime",
  SET_PICKUP_INSTRUCTIONS = "setPickUpInstructions",
  "SET_ERRORS" = "setErrors",

  "SET_RATES" = "setRates",
  "SET_RATES_LOADING_STATUS" = "setRatesLoadingStatus",

  "SET_SALES_ACCOUNT_UUID" = "setSalesAccountUUID",
  "SET_SELECTED_RATE" = "setSelectedRate",
  "SET_SUBMIT_COUNT" = "setSubmitCount",

  "SET_SENDER_SAVE_TO_ADDRESS_BOOK" = "setSenderSaveToAddressBook",

  "SET_RECEIVER_ADDRESS_TYPE" = "setReceiverAddressType",
  "SET_RECIPIENT_SAVE_TO_ADDRESS_BOOK" = "setRecipientSaveToAddressBook",
  "SET_SENDER_SAVED_ADDRESS" = "setSenderSavedAddress",
  "SET_DEEFAULT_FROM_ADDRESS" = "setDefaultFromAddress",
  "SET_SENDER_ADDRESS_TYPE" = "setSenderAddressType",

  "SET_PACKAGES" = "setPackages",
  "SET_ITEMS" = "setItems",

  "SET_FORM_TYPE" = "setFormType",

  "ON_CHANGE_SENDER_COUNTRY" = "onChangeSenderCountry",
  "ON_CHANGE_RECEIVER_COUNTRY" = "onChangeReceiverCountry",
  "ON_TOUCH_CONTENTS_FIELD" = "onTouchContentsField",
  "ON_CHANGE_CONTENTS_FIELD" = "onChangeContentsField",

  "ON_CHANGE_SENDER_ADDRESS_FIELD" = "onChangeSenderAddressField",

  "ON_TOUCH_SENDER_ADDRESS_FIELD" = "onTouchSenderAddressField",

  "UPDATE_PACKAGE_VALUE" = "updatePackageValue",

  "CLEAR_SENDER_ADDRESS" = "clearSenderAddress",

  "CLEAR_RECEIVER_ADDRESS" = "clearReceiverAddress",

  "ON_TOUCH_ITEM_FIELD" = "onTouchItemField",

  "ON_CHANGE_DETAILS_FIELD" = "onChangeDetailsField",

  "ON_CHANGE_RECEIVER_ADDRESS_FIELD" = "onChangeReceiverAddressField",

  "ON_TOUCH_RECEIVER_ADDRESS_FIELD" = "onTouchReceiverAddressField",

  "SET_VALUES" = "setValues",
  "SET_EDITING_ID" = "setEditingID",
  "SET_EDITING_UUID" = "setEditingUUID",
  "SET_PRINT_LATER" = "setPrintLater",
  "REMOVE_PACKAGE" = "removePackage",
  "ADD_PACKAGE" = "addPackage",
  "SET_RATES_ERROR" = "setRatesError",
  "SET_EDITING_JOB_NUMBER" = "setEditingJobNumber",
  "ADD_CONTENTS_ROW" = "addContentsRow",
  "REMOVE_CONTENTS_ROW" = "removeContentsRow",
  "SET_SUBMITTING_STATUS" = "setSubmittingStatus",
  "SET_SENDER_ADDRESS_ZIP" = "setSenderAddressZip",
  "SET_RECIPIENT_ADDRESS_ZIP" = "setRecipientAddressZip",
  "SET_SUBMITTING_ERROR_MESSAGE" = "setSubmittingErrorMessage",
  "SET_RECEIVER_SAVED_ADDRESS" = "setReceiverSavedAddress",
  "SET_CONVERTING_FROM_QUOTE_STATUS" = "setConvertingFromQuoteStatus",
  "SET_IS_CONVERTING_FROM_QUOTE" = "setIsConvertingFromQuote",
  "SET_FILES" = "setFiles",
  "SET_CURRENT_QUOTE_REFERENCE" = "setCurrentQuoteReference",
}

export function isCountryChanged(action: AnyAction) {
  return (
    action.type ===
    (`${CREATE_SHIPMENT_SLICE_NAME}/${CreateShipmentSliceActions.ON_CHANGE_SENDER_COUNTRY}` ||
      `${CREATE_SHIPMENT_SLICE_NAME}/${CreateShipmentSliceActions.ON_CHANGE_RECEIVER_COUNTRY}`)
  );
}

export function isContentsFieldTouched(action: AnyAction) {
  return (
    action.type ===
    `${CREATE_SHIPMENT_SLICE_NAME}/${CreateShipmentSliceActions.ON_TOUCH_CONTENTS_FIELD}`
  );
}
