export enum CreateShipmentContentsTestIds {
  "SHIPMENT_CONTENTS_ADD_ROW" = "SHIPMENT_CONTENTS_ADD_ROW",
  "SHIPMENT_CONTENTS_REMOVE_ROW" = "SHIPMENT_CONTENTS_REMOVE_ROW",
  "SHIPMENT_CONTENTS_ROW" = "SHIPMENT_CONTENTS_ROW",
  "SHIPMENT_CONTENTS_ITEM_DESCRIPTION" = "SHIPMENT_CONTENTS_ITEM_DESCRIPTION",
  "SHIPMENT_CONTENTS_ITEM_COMMODITY_CODE" = "SHIPMENT_CONTENTS_ITEM_COMMODITY_CODE",
  "SHIPMENT_CONTENTS_ITEM_QUANTITY" = "ITEM_QUANTITY",
  "SHIPMENT_CONTENTS_ITEM_MANUFACTURER_DETAILS" = "ITEM_MANUFACTURER_DETAILS",
  "SHIPMENT_CONTENTS_ITEM_CURRENCY" = "ITEM_CURRENCY",
  "SHIPMENT_CONTENTS_ITEM_WEIGHT" = "SHIPMENT_CONTENTS_ITEM_WEIGHT",
  "SHIPMENT_CONTENTS_ITEM_VALUE" = "SHIPMENT_CONTENTS_ITEM_VALUE",
  "SHIPMENT_CONTENTS_ITEM_SKU" = "ITEM_SKU",
}
