import { Flag } from "components/atoms/Flag";
import { Tooltip } from "components/atoms/Tooltip";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { formatAddressee } from "utils/formatAddressee";
import { TableCellLeftSlot } from "../TableCellLeftSlot";

type ITableAddressCellProps = {
  firstName: string;
  lastName: string;
  company: string;
  zip: string;
  countryIsoCode: string;
};

export const TableAddressCell = (props: ITableAddressCellProps) => {
  const { firstName, lastName, company, zip, countryIsoCode } = props;

  const { countries } = useSelector((state: RootState) => {
    return state.countriesSlice;
  });

  const addressString = formatAddressee({
    firstName,
    lastName,
    company,
    zip,
    countryIsoCode,
  });
  return (
    <div className="flex items-center overflow-hidden whitespace-nowrap text-ellipsis">
      <TableCellLeftSlot>
        <Tooltip
          content={
            countries.find((country) => country.isoCode === countryIsoCode)
              ?.name || ""
          }
        >
          <Flag size="space5" countryCode={countryIsoCode} />
        </Tooltip>
      </TableCellLeftSlot>

      <div className="flex-1" title={addressString}>
        {addressString}
      </div>
    </div>
  );
};
