import { apiPaths, customerApiBase } from "enum/paths.enum";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { IShipment } from "models/shipment/shipment.model";
import { post } from "services/post";
import { CombinePdfsResponseDto } from "./dtos/combine-pdfs-response.dto";
import { CombinePdfsBase64ResponseDto } from "./dtos/combine-pdfs-base64-response.dto";

export const combinePdfs = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: {
    shipmentId: IShipment["id"];
  }
): Promise<CombinePdfsResponseDto> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.combinePdfs,
    values
  );
};

export const combinePdfsBase64 = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: {
    shipmentId: IShipment["id"];
  }
): Promise<CombinePdfsBase64ResponseDto> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.combinePdfsBase64,
    values
  );
};
