import { IQuotedRate } from "models/quote/quote.model";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { RatesLoadingPanel } from "./RatesLoadingPanel";
import { RatesErrorPanel } from "./RatesErrorPanel";
import { RatesEmpty } from "./RatesEmpty";
import { RatesList } from "./RatesList";


export type IRatesProps = {
  rates: IQuotedRate[];
  checkedRates: IQuotedRate[];
  isCheckable?: boolean;
  isInternationalShipment: boolean;
  onSelectRate?(rate: IQuotedRate): void;
  onCheckRate?(rate: IQuotedRate): void;
  allowSelect?: boolean;
  selectedRate?: IQuotedRate | null;
  loadingStatus: ApiLoadingStatus;
  handleRefetch?(): void;
};

export const Rates = (props: IRatesProps) => {
  const { loadingStatus, rates, handleRefetch } = props;

  return (
    <>
      {loadingStatus === ApiLoadingStatus.LOADING ? (
        <RatesLoadingPanel />
      ) : loadingStatus === ApiLoadingStatus.FAILED ? (
        <RatesErrorPanel handleRefetch={handleRefetch} />
      ) : rates.length > 0 ? (
        <div>
          <RatesList {...props} />
          <div className="mt-2">
            <div>Disclaimers:</div>
            <div className="mb-8 mt-3">
              <div style={{ fontSize: "13px" }} className="flex">
                <ul
                  style={{
                    marginLeft: "12px",
                    listStyleType: "initial",
                  }}
                >
                  <li>
                    The above price includes freight & fuel surcharge on the
                    freight cost only.
                  </li>
                  <li>
                    These prices are correct at the time of quotation and is
                    valid till the end of the week when fuel surcharge is
                    subject to change.
                  </li>
                  <li>
                    The prices in some instances may not include local
                    destination / charges.
                  </li>
                  <li>
                    Shipments may incur duty & taxes levied by customs in the
                    destination country.The above quotes do not include duty &
                    taxes charges.
                  </li>

                  <li>
                    These quotes do not include additional surcharges such as
                    Brexit, Covid, Residential, Oversize or Additional handling
                    charges (which may also be subject to fuel surcharge).
                    Please refer to your additional surcharges sheet.
                  </li>
                  <li>
                    Each Carrier reserves the right to audit each parcel. If the
                    Audited Chargeable Weight differs from the quoted weight
                    (based on the weight & dimensions supplied at time of quote)
                    then final billable price will be adjusted according to the
                    Carrier’s Audited weight.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <RatesEmpty />
      )}
    </>
  );
};
