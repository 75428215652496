import { KeyboardKeys } from "enum/keyboardkeys.enum";
import { useHotkeys } from "react-hotkeys-hook";
import { Button } from "../Button";
import { Stack } from "../Stack";
import { useModalContext } from "./ModalContext";
import { useSelector } from "react-redux";
import { PermissionCheck } from "utils/PermissionCheck/PermissionCheck";
import { RootState } from "store";

export type IModalFooterButtonsProps = {
  onSubmit(): void;
  onCancel(): void;
  primaryLabel: string;
  isLoading?: boolean;
};

export const ModalFooterButtons = ({
  onCancel,
  onSubmit,
  primaryLabel,
  isLoading,
}: IModalFooterButtonsProps) => {
  const { handleClose } = useModalContext();

  const ref = useHotkeys<HTMLButtonElement>(KeyboardKeys.ENTER, () => {
    handleClose && handleClose();
  });

  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const permissions = new PermissionCheck({ user: loggedInUser });

  return (
    <div className="ml-auto">
      <Stack direction="horizontal" spacing={3}>
        <Button onClick={onCancel} innerRef={ref}>
          Cancel
        </Button>
        <Button
          isDisabled={!permissions.rules.canSaveRule()}
          onClick={onSubmit}
          isLoading={isLoading}
          colorScheme="primary"
        >
          {primaryLabel}
        </Button>
      </Stack>
    </div>
  );
};
