import { forwardRef, HTMLAttributes, ReactNode, Ref } from "react";
import cx from "classnames";
import { useTheme } from "styled-components";

type IPanelBody = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const PanelBody = forwardRef(
  (props: IPanelBody, ref: Ref<HTMLDivElement>) => {
    const { children, className, style, ...rest } = props;

    const theme = useTheme();

    return (
      <section
        className={cx(className)}
        {...rest}
        ref={ref}
        style={{
          padding: theme.gutter,
          ...style,
        }}
      >
        {children}
      </section>
    );
  }
);

PanelBody.displayName = "PanelBody";
