import {
  Attribute,
  Attributes,
  AttributeTextCell,
} from "components/atoms/Attribute";
import { PageSection } from "../PageSection";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { DATE_FORMAT } from "constants/dateFormat";
import { format, parseISO } from "date-fns";
import { current } from "@reduxjs/toolkit";

export const ViewShipmentPickUp = () => {
  const { currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });
  const { financialCompany } = useSelector((state: RootState) => {
    return state.authSlice;
  });
  return (
    <PageSection title={financialCompany == 8 ? "Pickup / Drop off" : "Pickup"}>
      <Attributes>
        <Attribute label="Pickup">
          <AttributeTextCell>
            {currentShipment?.shouldPickUp ? "Yes" : "No"}
          </AttributeTextCell>
        </Attribute>
        {currentShipment?.shouldPickUp ? (
          <Attribute label="Pickup From">
            <AttributeTextCell>
              {format(
                parseISO(
                  new Date(
                    currentShipment?.pickUpFromDateLocalTime
                  ).toISOString()
                ),
                DATE_FORMAT
              )}
            </AttributeTextCell>
          </Attribute>
        ) : null}
        {currentShipment?.shouldPickUp ? (
          <Attribute label="Pickup To">
            <AttributeTextCell>
              {format(
                parseISO(
                  new Date(
                    currentShipment?.pickUpToDateLocalTime
                  )?.toISOString()
                ),
                DATE_FORMAT
              )}
            </AttributeTextCell>
          </Attribute>
        ) : null}
        {currentShipment?.shouldPickUp ? (
          <Attribute label="Pickup Instructions">
            <AttributeTextCell>
              {currentShipment?.pickUpInstructions}
            </AttributeTextCell>
          </Attribute>
        ) : null}

        {currentShipment?.shouldPickUp &&
        (currentShipment?.fedexPickupPrnNumber ||
          currentShipment?.upsPickupPrnNumber) ? (
          <Attribute label="Pickup Reference">
            <AttributeTextCell>
              {currentShipment?.fedexPickupPrnNumber ||
                currentShipment?.upsPickupPrnNumber}
            </AttributeTextCell>
          </Attribute>
        ) : null}

        {currentShipment?.dropoffInfo?.shouldDropOff ? (
          <div>
            <Attribute label="Drop off">
              <AttributeTextCell>
                {currentShipment?.dropoffInfo?.shouldDropOff ? "Yes" : "No"}
              </AttributeTextCell>
            </Attribute>

            <Attribute label="Drop off location">
              <AttributeTextCell>
                {currentShipment?.dropoffInfo?.selectedDropOffLocation.city ||
                  ""}{" "}
                {currentShipment?.dropoffInfo?.selectedDropOffLocation
                  ?.postalCode || ""}
              </AttributeTextCell>
            </Attribute>
          </div>
        ) : null}
      </Attributes>
    </PageSection>
  );
};
