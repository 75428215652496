import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { LabelJobStatus } from "enum/label-job-status.enum";
import { LabelSizes } from "enum/label-sizes.enum";
import { IShipment } from "models/shipment/shipment.model";
import { combinePdfs } from "services/api/combinePdfs";
import { createLabelJob } from "services/api/labels";
import { AppThunk, RootState } from "store";
import { openModal, closeModal } from "store/modalSlice";
import { getLabelUrl } from "utils/getLabelUrl";
import { setLabelJob, setLoadingStatus } from "../index.slice";

export const createSingleLabelThunk = (
  shipmentId: IShipment["id"],
  type: "new" | "combine"
): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      await dispatch(
        setLoadingStatus({ status: ApiLoadingStatus.LOADING, labelJob: null })
      );

      try {
        if (type === "new") {
          const { data, error } = await createLabelJob(salesAccountUUID, {
            shipmentIds: [shipmentId],
            labelSize: LabelSizes.x6,
            processAsync: false,
          });

          dispatch(closeModal({ modalName: "viewShipment" }));
          dispatch(openModal({ name: "createSingleLabel" }));

          if (error || data.status === LabelJobStatus.ERROR) {
            throw error;
          } else {
            dispatch(setLabelJob(data));
            dispatch(
              setLoadingStatus({
                status: ApiLoadingStatus.SUCCESS,
                labelJob: data,
              })
            );
          }
        }

        if (type === "combine") {
          const { data, error } = await combinePdfs(salesAccountUUID, {
            shipmentId: shipmentId,
          });

          if (error) {
            throw error;
          } else {
            dispatch(
              setLoadingStatus({
                status: ApiLoadingStatus.SUCCESS,
                labelJob: null,
              })
            );
            window.open(getLabelUrl(data.combinedLabelUrl), "_blank");
          }
        }
      } catch (e) {
        dispatch(
          setLoadingStatus({ status: ApiLoadingStatus.FAILED, labelJob: null })
        );
      }
    }
  };
};
