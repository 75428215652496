import { Query } from "interfaces/api.interface";
import { fetchAll, fetchSingle } from "services/get";
import { apiPaths, customerApiBase } from "enum/paths.enum";
import { FetchBulkShipmentsResponseDto } from "./dtos/fetch-bulk-shipments.response.dto";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { CreateBulkShipmentRequestDto } from "./dtos/create-bulk-shipment.request.dto";
import { post } from "services/post";
import { IBulkShipment } from "models/bulkShipment/bulkShipment.model";
import { FetchBulkShipmentResponseDto } from "./dtos/fetch-bulk-shipment.response.dto";
import { CreateBulkShipmentResponseDto } from "./dtos/create-bulk-shipment.response.dto";
import { ValidateBulkShipmentsResponseDto } from "./dtos/validate-bulk-shipments.response.dto";
import { FormatBulkShipmentsRequestDto } from "./dtos/format-bulk-shipments-request.dto";
import { IFetchPostResponse } from "services/api";

export const fetchBulkShipments = async (
  salesAccountUUID: ISalesAccount["uuid"],
  query?: Query
): Promise<FetchBulkShipmentsResponseDto> => {
  return await fetchAll(
    customerApiBase + "/" + salesAccountUUID + apiPaths.bulkShipments,
    query
  );
};

export const fetchBulkShipmentByUUID = async (
  salesAccountUUID: ISalesAccount["uuid"],
  bulkShipmentUUID: IBulkShipment["uuid"]
): Promise<FetchBulkShipmentResponseDto> => {
  return await fetchSingle(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.bulkShipments +
      "/" +
      bulkShipmentUUID
  );
};

export const createBulkShipment = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: CreateBulkShipmentRequestDto
): Promise<CreateBulkShipmentResponseDto> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.bulkShipments,
    values
  );
};

export const formatValidateShipments = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: FormatBulkShipmentsRequestDto
): Promise<IFetchPostResponse<ValidateBulkShipmentsResponseDto>> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.bulkShipmentsFormatValidate,
    values
  );
};
