import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { Meta } from "interfaces/api.interface";
import { ILabelJob } from "models/labelJob/labelJob.model";
import { fetchLabelJobs } from "services/api/labels";
import { AppThunk, RootState } from "store";
import { isModalClosed } from "store/modalSlice/modalSlice.actions";
import { modals } from "store/modalSlice/modalSlice.types";
import { FetchLabelJobsResponseDto } from "services/api/labels/dtos/fetch-label-jobs.response.dto";
import { notifyBugReporter } from "services/bugReporter";
import { DEFAULT_META } from "constants/misc";
import { format } from "date-fns";

export interface ICurrentLabelJobsSlice {
  loadingStatus: ApiLoadingStatus;
  items: ILabelJob[];
  meta: Meta<{ downloaded: number; notDownloaded: number }>;
}

export const CurrentLabelJobsSliceInitialState: ICurrentLabelJobsSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  items: [],
  meta: {
    ...DEFAULT_META,
    downloaded: 0,
    notDownloaded: 0,
  },
};

export const currentLabelJobsSlice = createSlice({
  name: "currentLabelJobsSlice",
  initialState: CurrentLabelJobsSliceInitialState,
  reducers: {
    setData: (state, action: PayloadAction<FetchLabelJobsResponseDto>) => {
      state.items =
        action.payload.data || CurrentLabelJobsSliceInitialState.items;

      state.meta =
        action.payload.meta || CurrentLabelJobsSliceInitialState.meta;

      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },
    setStatus: (
      state,
      action: PayloadAction<ICurrentLabelJobsSlice["loadingStatus"]>
    ) => {
      state.loadingStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isModalClosed,
      (state, action: { payload: { modalName: modals } }) => {
        if (action.payload.modalName === "createSingleLabel") {
          Object.assign(state, CurrentLabelJobsSliceInitialState);
        }
      }
    );
  },
});

export const { setData, setStatus } = currentLabelJobsSlice.actions;

export const fetchCurrentLabelJobsThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setStatus(ApiLoadingStatus.LOADING));
    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const { data, error, meta } = await fetchLabelJobs(salesAccountUUID, {
        filters: {
          createdDate: format(new Date(), "yyyy-MM-dd"),
        },
      });

      try {
        if (error) {
          dispatch(setStatus(ApiLoadingStatus.FAILED));
        }

        if (!error) {
          dispatch(setData({ data, meta }));
          dispatch(setStatus(ApiLoadingStatus.SUCCESS));
        }
      } catch (e) {
        notifyBugReporter(e);
        dispatch(setStatus(ApiLoadingStatus.FAILED));
      }
    }
  };
};
