import { Button } from "components/atoms/Button";
import { ModalBody } from "components/atoms/Modal";
import { ModalHeader } from "components/atoms/Modal/ModalHeader";
import { useDispatch } from "react-redux";
import { fetchRatesThunk } from "store/createShipmentSlice/thunks/fetchRatesThunk.thunk";
import { closeModal } from "store/modalSlice";

export const CustomerOnStopWarning = (): JSX.Element => {
  const dispatch = useDispatch();

  return (
    <>
      <ModalHeader>Customer on stop</ModalHeader>
      <ModalBody>
        <p className="mb-3">Your account is on stop. Please contact ITD.</p>
        <Button
          colorScheme="primary"
          onClick={() => {
            dispatch(closeModal({ modalName: "customerOnStopWarning" }));
            dispatch(fetchRatesThunk());
          }}
        >
          Continue
        </Button>
      </ModalBody>
    </>
  );
};
