import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store";
import { fetchAdminSalesAccounts } from "services/api/admin/index";
import { FetchAdminSalesAccountsResponseDto } from "services/api/admin/dtos/fetch-admin-sales-accounts.response.dto";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { fetchSingleSalesAccounts } from "services/api/admin/index";

interface ISalesAccountSlice {
  loadingStatus: ApiLoadingStatus;
  salesAccounts: FetchAdminSalesAccountsResponseDto;
  doesAccountHaveDvAgreement: boolean;
}

const initialState: ISalesAccountSlice = {
  loadingStatus: ApiLoadingStatus.IDLE,
  salesAccounts: [],
  doesAccountHaveDvAgreement: false,
};

export const salesAccountSlice = createSlice({
  name: "salesAccountSlice",
  initialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<FetchAdminSalesAccountsResponseDto>
    ) => {
      state.salesAccounts = action.payload;
      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },
    setStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
    setDoesAccountHaveDvAgreement: (state, action: PayloadAction<boolean>) => {
      state.doesAccountHaveDvAgreement = action.payload;
    },
  },
});

export const { setData, setStatus, setDoesAccountHaveDvAgreement } =
  salesAccountSlice.actions;

export const fetchSalesAccountsThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const { authSlice } = getState() as RootState;

    await dispatch(setStatus(ApiLoadingStatus.LOADING));

    const { data, error } = await fetchAdminSalesAccounts({
      financialCompany: authSlice.financialCompany
        ? authSlice.financialCompany
        : 1,
    });

    if (error) {
      dispatch(setStatus(ApiLoadingStatus.FAILED));
    } else {
      dispatch(setData(data));
    }
  };
};

export const fetchSingularSalesAccountThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const { authSlice } = getState() as RootState;

    const { data, error } = await fetchSingleSalesAccounts(
      authSlice.salesAccountUUID
    );
    if (!error) {
      dispatch(setDoesAccountHaveDvAgreement(data.dutyVatAgreement));
    }
  };
};
