import { RatesTestIds } from "./RatesTestIds.enum";
import { Center } from "components/atoms/Center";
import { Warning32 } from "@carbon/icons-react";
import { Heading } from "components/atoms/Heading";
import { Button } from "components/atoms/Button";

export const RatesErrorPanel = ({
  handleRefetch,
}: {
  handleRefetch(): void;
}) => {
  return (
    <div
      className="flex items-center justify-center py-6 text-center"
      data-testid={RatesTestIds.ERROR_PANEL}
    >
      <Center>
        <div>
          <div className="inline-block mb-2 text-red9">
            <Warning32 />
          </div>
          <Heading size="lg" className="mb-2">
            Something went wrong
          </Heading>
          <div className="mb-4">
            If the problem persists, please contact us.
          </div>
          <Button
            size="lg"
            onClick={handleRefetch}
            testId={RatesTestIds.REFETCH_RATES_BUTTON}
          >
            Try again
          </Button>
        </div>
      </Center>
    </div>
  );
};
