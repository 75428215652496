import { CurrencySymbol } from "../CurrencySymbol";

export const PrintCurrency = ({
  currencyCode,
  value,
}: {
  currencyCode: string;
  value: number | string;
}) => {
  return (
    <>
      <CurrencySymbol currency={currencyCode} />
      {value}
    </>
  );
};
