import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { fetchBulkShipmentProfiles } from "services/api/shipmentProfiles";
import { AppThunk, RootState } from "store";
import { IBulkShipmentProfilesSlice } from "./bulkShipmentProfilesSlice.interface";
import { BulkShipmentProfilesInitialState } from "./bulkShipmentsProfilesSliceInitialState.const";

export const bulkShipmentProfilesSlice = createSlice({
  name: "bulkShipmentProfilesSlice",
  initialState: BulkShipmentProfilesInitialState,
  reducers: {
    setData: (
      state,
      action: PayloadAction<IBulkShipmentProfilesSlice["profiles"]>
    ) => {
      state.profiles = action.payload;
      state.loadingStatus = ApiLoadingStatus.SUCCESS;
    },
    setLoadingStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
  },
});

export const { setData, setLoadingStatus } = bulkShipmentProfilesSlice.actions;

export const fetchBulkShipmentProfilesThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setLoadingStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    if (salesAccountUUID) {
      const { data, error } = await fetchBulkShipmentProfiles(salesAccountUUID);

      if (!error && data) {
        dispatch(setData(data));
      }

      if (error) {
        dispatch(setLoadingStatus(ApiLoadingStatus.FAILED));
      }
    }
  };
};
