import cx from "classnames";
import { ReactNode } from "react";
import { useTheme } from "styled-components";
import { ThemeColorPalettes } from "theme/theme-colors.theme";

export interface ILabelProps {
  children?: ReactNode;
  color: ThemeColorPalettes;
  size?: string;
  className?: string;
  hasStatusDot?: boolean;
}

export const Label = (props: ILabelProps) => {
  const { children, color, className, hasStatusDot = false } = props;

  const theme = useTheme();

  return (
    <div
      className={cx(
        `inline-flex px-2.5 h-6 leading-none rounded-md text-xs items-center font-medium`,
        className
      )}
      style={{
        color: theme.colors[color][10],
        backgroundColor: theme.colors[color][3],
      }}
    >
      {hasStatusDot && (
        <div
          className="w-1 h-1 mr-1.5 rounded-full"
          style={{
            backgroundColor: theme.colors[color][10],
          }}
        />
      )}
      {children}
    </div>
  );
};
