import React, { ReactNode } from "react";
import { Stack } from "../Stack";
import cx from "classnames";
import { Divider } from "../Divider";

export const Attribute = ({
  label,
  children,
}: {
  children: React.ReactNode;
  label: string;
}) => {
  return (
    <div className="flex items-center">
      <div className="w-1/3 text-sm leading-none text-gray10">{label}</div>
      <div className="w-2/3 leading-none">{children}</div>
    </div>
  );
};

export const Attributes = ({ children }: { children: ReactNode }) => {
  return (
    <Stack spacing={4} divider={<Divider color="weak" />}>
      {children}
    </Stack>
  );
};

export const AttributeEmpty = ({ className }: { className?: string }) => {
  return <div className={cx("text-sm text-gray10", className)}>N/A</div>;
};

export const AttributeTextCell = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return <div className={cx("text-sm", className)}>{children}</div>;
};
