import { Cell } from "react-table";
import cx from "classnames";
import { TableCellIds } from "./types";
import { useTheme } from "styled-components";

export const TableBodyCell = <T extends object>({
  cell,
  erroredColumns,
}: {
  cell: Cell<T>;
  erroredColumns?: { column: string; indices: number[] }[];
}) => {
  const isCheckbox = cell.column.id === TableCellIds.CHECKBOX;

  const isMenu = cell.column.id === TableCellIds.MENU;

  let isError = false;
  erroredColumns.forEach((row) => {
    if (row.column === cell.column.id && row.indices.includes(cell.row.index)) {
      isError = true;
    }
  });
  const theme = useTheme();
  return (
    <div
      {...cell.getCellProps()}
      className={cx(
        `pl-4 h-9 text-sm flex items-center border-r last:border-r-0 ${
          isError ? "bg-red4" : ""
        }`,
        {
          "justify-center": isCheckbox || isMenu,
        }
      )}
      style={{
        ...cell.getCellProps().style,
        ...((isCheckbox || isMenu) && { padding: 0 }),
        borderColor: theme.borderColor,
        maxWidth: cell.column.maxWidth,
      }}
    >
      {cell.render("Cell")}
    </div>
  );
};
