/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "store";
import { FoundDropOffLocationsDistance } from "store/createShipmentSlice/createShipmentSlice.types";

export const SelectedLocationDetails = ({
  selectedLocation,
}: {
  selectedLocation: FoundDropOffLocationsDistance | null;
  activeTab: 'storeHours' | 'lastPickup';
  setActiveTab: React.Dispatch<React.SetStateAction<'storeHours' | 'lastPickup'>>;
}) => {
  const { selectedRate } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });
  if (!selectedLocation) return null;

  const { locationDetail } = selectedLocation;
  const renderedCarriers = new Set();

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
  
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };    
  
    return date.toLocaleTimeString([], options);
  };

  const getAbbreviatedDay = (day) => {
    const daysMap = {
      MONDAY: 'Mon',
      TUESDAY: 'Tue',
      WEDNESDAY: 'Wed',
      THURSDAY: 'Thu',
      FRIDAY: 'Fri',
      SATURDAY: 'Sat',
      SUNDAY: 'Sun'
    };
    return daysMap[day.toUpperCase()] || day;
  };

  return (
    <div style={{ width: 300, backgroundColor: 'rgb(22, 22, 24)', borderColor: 'rgb(62, 62, 68)' }} className="absolute right-full top-0 bg-gray-900 text-white shadow-lg p-4 border z-10 w-120 rounded-lg">
      <div className="text-sm">
        <div className="font-bold mb-2">Store hours</div>
        {locationDetail.storeHours.map((hour, index) => (
          <div key={index} className="flex justify-between mb-1">
            <span>{getAbbreviatedDay(hour.dayOfWeek)}</span>
            <span>{hour.operationalHours ? `${formatTime(hour.operationalHours.begins)} - ${formatTime(hour.operationalHours.ends)}` : 'Closed'}</span>
          </div>
        ))}
      </div>
      <div className="text-sm mt-4">
        <div className="font-bold mb-2">Last pickup</div>
        {locationDetail.carrierDetailList.map((detail, index) => {
          if (renderedCarriers.has(detail.carrierCodeType)) {
            return null; // Skip rendering if already rendered
          }
          renderedCarriers.add(detail.carrierCodeType);

          if (detail.carrierCodeType === 'FDXG') {
            if (selectedRate.service.name !== 'FEDEX_GROUND') {
              return null; 
            }
          }

          return (
            <div key={index} className="mb-2">
              <div className="font-semibold">{detail.carrierCodeType}</div>
              {detail.latestDropoffDetails.map((dropoffDetail, idx) => (
                <div key={idx} className="flex justify-between">
                  <span>{getAbbreviatedDay(dropoffDetail.dayOfWeek)}</span>
                  <span>{formatTime(dropoffDetail.operationTime)}</span>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
