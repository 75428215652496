import { Button } from "components/atoms/Button";
import { Alert } from "components/atoms/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, setModalSize } from "store/modalSlice";
import { useWizardContextProvider, WizardHeader } from "../Wizard";
import { isEmpty } from "lodash";
import { RootState } from "store";
import { BulkUploadColumnName } from "enum/bulk-upload-column-names.enum";
import { BulkShipmentProfileMappingTable } from "./BulkShipmentProfileMappingTable";
import { resetMappedDataThunk } from "store/createOrUpdateBulkShipmentProfileSlice/index.slice";
import toast from "react-hot-toast";
import { MappingColumns } from "store/createOrUpdateBulkShipmentProfileSlice/createOrUpdateBulkShipmentProfileSliceInitialState.const";

type Errors = { [key in BulkUploadColumnName]: string };

export const BulkShipmentProfileWizardMapColumns = () => {
  const dispatch = useDispatch();

  const { onNextStep, activeStepIndex } = useWizardContextProvider();

  const [errors, setErrors] = useState<Errors>({} as Errors);

  const [submitCount, setSubmitCount] = useState(0);

  const handleSubmit = () => {
    setSubmitCount(submitCount + 1);

    const { isValid, errors } = validate();

    setErrors(errors);

    if (isValid) {
      onNextStep();
    } else {
      toast.error("Some required columns are missing");
    }
  };

  const { mappings, data } = useSelector((state: RootState) => {
    return state.createOrUpdateBilkShipmentProfileSlice;
  });

  useEffect(() => {
    setSubmitCount(0);
    dispatch(resetMappedDataThunk());
    setErrors({} as { [key in BulkUploadColumnName]: string });
  }, [data]);

  useEffect(() => {
    if (submitCount > 0) {
      const { errors } = validate();
      setErrors(errors);
    }
  }, [mappings]);

  useEffect(() => {
    if (activeStepIndex === 1) {
      dispatch(
        setModalSize({ name: "createBulkShipmentProfile", size: "2xl" })
      );
    }
  }, [activeStepIndex]);

  const validate = (): {
    errors: Errors;
    isValid: boolean;
  } => {
    let isValid = true;

    const errors = {} as Errors;

    Object.keys(MappingColumns).map((key) => {
      if (MappingColumns[key].required && mappings[key] === null) {
        isValid = false;
        errors[key] = "This field is required";
      }
    });

    return { isValid, errors };
  };

  return (
    <div>
      <div className="mb-8">
        <WizardHeader
          title="Map columns"
          onClose={() =>
            dispatch(closeModal({ modalName: "createBulkShipmentProfile" }))
          }
        />
      </div>
      {!isEmpty(errors) && (
        <div className="mb-4">
          <Alert>
            {Object.keys(errors).map((key: BulkUploadColumnName, index) => {
              return (
                <div key={index} className="flex">
                  <div className="font-semibold">
                    {MappingColumns[key].label}
                  </div>
                  : this field is required
                </div>
              );
            })}
          </Alert>
        </div>
      )}
      <div className="mb-4">
        <BulkShipmentProfileMappingTable />
      </div>
      <Button
        isBlock
        colorScheme="primary"
        size="lg"
        onClick={() => handleSubmit()}
      >
        Next
      </Button>
    </div>
  );
};
