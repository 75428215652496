import { useModalContext } from "../ModalContext";
import { RefObject } from "react";
import { Sizes } from "..";
import styled from "styled-components";
import { IModal } from "store/modalSlice";

interface IModalContentProps {
  children: React.ReactNode;
  innerRef: RefObject<HTMLDivElement>;
}

const StyledModalContent = styled.div<Pick<IModal, "size">>`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  margin: auto;
  border: 1px solid;
  max-width: ${({ size }) => Sizes[size]}px;
  border-radius: ${(props) => props.theme.borderRadius};
  border-color: ${(props) => props.theme.modalBorderColor};
  background-color: ${(props) => props.theme.modalBackgroundColor};
  box-shadow: ${(props) => props.theme.shadows["2xl"]};
`;

export const ModalContent = (props: IModalContentProps) => {
  const { children, innerRef } = props;

  const { size } = useModalContext();

  return (
    <StyledModalContent
      ref={innerRef}
      onClick={(e) => e.stopPropagation()}
      size={size}
    >
      {children}
    </StyledModalContent>
  );
};
