import { WeightUnits } from "interfaces/weight-units.type";
import {
  convertDatabaseToValue,
  convertGramsToKilos,
} from "./convertGramsToKilos";
import { convertKilosToPounds } from "./convertPoundsToKilos";

export const getDisplayWeight = (
  weightUnit: WeightUnits = WeightUnits.kg,
  value: number
) => {
  return weightUnit === WeightUnits.kg
    ? convertGramsToKilos(convertDatabaseToValue(value))
    : convertKilosToPounds(convertGramsToKilos(convertDatabaseToValue(value)));
};
