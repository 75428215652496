import { parseISO } from "date-fns";
import { statuses } from "enum/shipment-statuses.enum";
import { IShipment } from "models/shipment/shipment.model";
import { isLabelCancellable } from "utils/isLabelCancellable";
import { isLabelGeneratingForShipment } from "utils/isLabelGeneratingForShipment";

export const isShipmentEditable = (
  shipment: IShipment,
  currentTimeUTC: string
): boolean => {
  const isNotGeneratingLabel = !isLabelGeneratingForShipment(shipment);
  const doesNotHaveLabel = shipment.status !== statuses.label_created;
  const doesLabelMeetTimeRequirement = isLabelCancellable(
    shipment.packages,
    parseISO(currentTimeUTC)
  );
  const hasLabels = shipment.status === statuses.label_created;

  return (
    (doesNotHaveLabel || (hasLabels && doesLabelMeetTimeRequirement)) &&
    isNotGeneratingLabel
  );
};
