import { ColumnInstance, TableInstance } from "react-table";
import { TableCellIds } from "./types/index";
import { Button } from "../Button";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownToggle,
} from "components/molecules/Dropdown";
import { Switch } from "../Switch";
import { Column16 } from "@carbon/icons-react";
import { DropdownMenuGroup } from "components/molecules/Dropdown/DropdownMenuGroup";
import { createElement, useEffect } from "react";
import { updateUserColumnThunk } from "store/authSlice";
import { useDispatch } from "react-redux";

export const TableColumnToggle = <T extends object>(
  props: TableInstance<T>
) => {
  const {
    allColumns,
    setHiddenColumns,
    state: { hiddenColumns },
    userColumns,
  } = props;

  const dispatch = useDispatch();

  const currentUserHiddenColumns = userColumns
    ? Object.keys(userColumns).filter((key) => !userColumns[key])
    : [];
  useEffect(() => {
    if (userColumns) {
      if (hiddenColumns !== currentUserHiddenColumns) {
        setHiddenColumns(currentUserHiddenColumns);
      }
    }
  }, [userColumns]);

  return (
    <div>
      <Dropdown mode="onClick">
        <DropdownToggle>
          <Button

          // onClick={() => {
          //   dispatch(setFilterSidebarOpenStatus(!isFilterSidebarOpen));
          // }}
          >
            {/* <SettingsAdjust16 className="mr-1" /> */}
            <Column16 className="mr-1" />
            Columns
          </Button>
        </DropdownToggle>
        <DropdownMenu width={240}>
          <DropdownMenuGroup>
            {allColumns.map((column: ColumnInstance<T>, i) => {
              if (
                column.id !== TableCellIds.CHECKBOX &&
                typeof column.Header === "string"
              ) {
                const cID =
                  column.id === "Created by" ? "createdBy" : column.id;
                return (
                  <DropdownMenuItem key={i}>
                    <div className="leading-none">
                      {column.icon && createElement(column.icon)}
                      {column.Header}
                    </div>
                    <div className="flex items-center ml-auto">
                      <Switch
                        checked={
                          userColumns
                            ? !currentUserHiddenColumns.includes(cID)
                            : !hiddenColumns.includes(cID)
                        }
                        onCheckedChange={(checked) => {
                          if (checked) {
                            if (userColumns) {
                              dispatch(
                                updateUserColumnThunk(
                                  currentUserHiddenColumns.filter(
                                    (columnId) => columnId !== cID
                                  ),
                                  "shipmentsTable"
                                )
                              );
                            } else {
                              setHiddenColumns(
                                hiddenColumns.filter(
                                  (columnId) => columnId !== cID
                                )
                              );
                            }
                          } else {
                            if (userColumns) {
                              dispatch(
                                updateUserColumnThunk(
                                  [...currentUserHiddenColumns, cID],
                                  "shipmentsTable"
                                )
                              );
                            } else {
                              setHiddenColumns([...hiddenColumns, cID]);
                            }
                          }
                        }}
                      />
                    </div>
                  </DropdownMenuItem>
                );
              }
            })}
          </DropdownMenuGroup>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
