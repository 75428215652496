import { WarningAltFilled32 } from "@carbon/icons-react";
import { Button } from "components/atoms/Button";
import { Center } from "components/atoms/Center";
import {
  DEFAULT_WEIGHT_UNIT,
  MAX_PARCEL_METRIC_WEIGHT_GRAMS,
} from "constants/misc";
import { WeightUnits, WeightUnitSettings } from "interfaces/weight-units.type";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeModal } from "store/modalSlice";
import {
  convertDatabaseToValue,
  convertGramsToKilos,
} from "utils/convertGramsToKilos";
import { convertKilosToPounds } from "utils/convertPoundsToKilos";

export const CreateQuotePalletSwitchWarningModal = () => {
  const dispatch = useDispatch();

  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });

  return (
    <>
      <div className="p-8 text-center">
        <div>
          <div className="inline-flex w-16 h-16 mb-4 rounded-full text-red10 bg-red3">
            <Center>
              <WarningAltFilled32 />
            </Center>
          </div>
          <div className="mb-4">
            Items weighing{" "}
            {settings?.weightUnit === WeightUnits.pound
              ? convertKilosToPounds(
                  convertGramsToKilos(
                    convertDatabaseToValue(MAX_PARCEL_METRIC_WEIGHT_GRAMS)
                  )
                )
              : convertGramsToKilos(
                  convertDatabaseToValue(MAX_PARCEL_METRIC_WEIGHT_GRAMS)
                )}
            {WeightUnitSettings[settings?.weightUnit]?.label ||
              WeightUnitSettings[DEFAULT_WEIGHT_UNIT].label}
            {" or more "}
            cannot be shipped.
          </div>
          <Button
            colorScheme="primary"
            isBlock
            onClick={() => {
              dispatch(
                closeModal({ modalName: "createQuotePalletSwitchWarningModal" })
              );
            }}
          >
            Okay
          </Button>
        </div>
      </div>
    </>
  );
};
