import * as yup from "yup";
import { ShipmentDetailFieldNames } from "enum/shipment-field-names.enum";
import { ShipmentExportReasons } from "enum/shipment-export-reasons.enum";
import { GenericErrorCodes } from "enum/error-codes/generic-error-codes.enum";

export const CreateShipmentDetailsValidationSchema = (
  isInternational: boolean
) =>
  yup.object({
    [ShipmentDetailFieldNames.customerReference]: yup
      .string()
      .required(GenericErrorCodes.FIELD_REQUIRED),
    [ShipmentDetailFieldNames.reasonForExport]: isInternational
      ? yup
          .mixed<ShipmentExportReasons>()
          .oneOf(
            Object.values(ShipmentExportReasons),
            GenericErrorCodes.FIELD_REQUIRED
          )
      : yup
          .mixed<ShipmentExportReasons>()
          .oneOf([null, ...Object.values(ShipmentExportReasons)])
          .nullable(),
  });
