import { HTMLProps } from "react";
import { styled } from "stitches.config";

export type IStyledBoxProps<T = HTMLDivElement> = {
  as?: keyof JSX.IntrinsicElements;
  testId?: string;
} & Omit<HTMLProps<T>, "ref" | "size">;

const Styled = styled("div");

export const Box = <T extends HTMLDivElement>(props: IStyledBoxProps<T>) => {
  const { testId, ...rest } = props;

  return <Styled data-testid={testId} {...rest} />;
};
