import { Center } from "components/atoms/Center";
import { RatesTestIds } from "./RatesTestIds.enum";

export const RatesEmpty = () => {
  return (
    <div className="h-24" data-testid={RatesTestIds.NO_RATES_FOUND_PANEL}>
      <Center>
        <div className="text-sm text-center">
          No services found. Please contact your sales person to add new
          services.
        </div>
      </Center>
    </div>
  );
};
