import {
  Attribute,
  Attributes,
  AttributeTextCell,
} from "components/atoms/Attribute";
import { PageSection } from "../PageSection";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { convertDatabaseToValue } from "utils/convertGramsToKilos";
import { CurrencySymbol } from "components/atoms/CurrencySymbol";

export const ViewShipmentInsurance = () => {
  const { currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });
  return (
    <PageSection title="Insurance">
      <Attributes>
        <Attribute label="Use Insurance">
          <AttributeTextCell>
            {currentShipment?.useInsurance ? "Yes" : "No"}
          </AttributeTextCell>
        </Attribute>
        {currentShipment?.useInsurance ? (
          <Attribute label="Amount">
            <AttributeTextCell>
              {CurrencySymbol({
                currency: currentShipment?.insuranceAmountCurrencyCode,
              })}
              {currentShipment?.insuranceAmount
                ? convertDatabaseToValue(
                    currentShipment?.insuranceAmount
                  ).toFixed(2)
                : 0}
            </AttributeTextCell>
          </Attribute>
        ) : null}
        {currentShipment?.useInsurance ? (
          <Attribute label="Currency">
            <AttributeTextCell>
              {currentShipment?.insuranceAmountCurrencyCode}
            </AttributeTextCell>
          </Attribute>
        ) : null}
      </Attributes>
    </PageSection>
  );
};
