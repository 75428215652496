import { apiAdminPaths, apiPaths, customerApiBase } from "enum/paths.enum";
import {
  fetcher,
  IFetchResponsePaginated,
  IFetchResponseSingle,
} from "services/api";
import { fetchAll, fetchSingle } from "services/get";
import { FetchAdminCarriersResponseDto } from "./dtos/fetch-admin-carriers.response.dto";
import { FetchAdminSalesAccountsResponseDto } from "./dtos/fetch-admin-sales-accounts.response.dto";
import { FetchUsersResponseDto } from "./dtos/fetch-users.response.dto";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";

export const fetchAdminSalesAccounts = async ({
  financialCompany,
}: {
  financialCompany: number;
}): Promise<IFetchResponsePaginated<FetchAdminSalesAccountsResponseDto>> => {
  return await fetcher({
    url: apiAdminPaths.salesAccounts + "/" + financialCompany,
    method: "GET",
  });
};

export const fetchSingleSalesAccounts = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<any> => {
  return await fetchSingle(
    customerApiBase + "/" + salesAccountUUID + apiPaths.singleSalesAccount
  );
};

export const fetchAdminCarriers = async (): Promise<
  IFetchResponseSingle<FetchAdminCarriersResponseDto>
> => {
  return await fetchAll(apiAdminPaths.carriers, null);
};

export const fetchUsers = async (): Promise<
  IFetchResponsePaginated<FetchUsersResponseDto>
> => {
  return await fetcher({
    url: apiAdminPaths.users,
    method: "GET",
  });
};
