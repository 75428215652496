export enum CreateShipmentAddressSelectorTestIds {
  "ADDRESS_SELECTOR_FORM" = "ADDRESS_SELECTOR_FORM",
  "ADDRESS_SELECTOR_REMOVE_ADDRESS_BOOK_ADDRESS" = "ADDRESS_SELECTOR_REMOVE_ADDRESS_BOOK_ADDRESS",
  "ADDRESS_SELECTOR_TOGGLE_ADDRESS_BOOK" = "ADDRESS_SELECTOR_TOGGLE_ADDRESS_BOOK",
  "ADDRESS_SELECTOR_TOGGLE_ADDRESS_SELECTOR_MODAL" = "ADDRESS_SELECTOR_TOGGLE_ADDRESS_SELECTOR_MODAL",
  "ADDRESS_SELECTOR_TOGGLE_NEW_ADDRESS_FORM" = "ADDRESS_SELECTOR_TOGGLE_NEW_ADDRESS_FORM",
  "ADDRESS_SELECTOR_FORM_ADDRESS_LINE_1" = "ADDRESS_SELECTOR_FORM_ADDRESS_LINE_1",
  "ADDRESS_SELECTOR_FORM_ADDRESS_LINE_2" = "ADDRESS_SELECTOR_FORM_ADDRESS_LINE_2",
  "ADDRESS_SELECTOR_FORM_ADDRESS_LINE_3" = "ADDRESS_SELECTOR_FORM_ADDRESS_LINE_3",
  "ADDRESS_SELECTOR_FORM_ADDRESS_CITY" = "ADDRESS_SELECTOR_FORM_ADDRESS_CITY",
  "ADDRESS_SELECTOR_FORM_ADDRESS_COUNTY_STATE" = "ADDRESS_SELECTOR_FORM_ADDRESS_COUNTY_STATE",
  "ADDRESS_SELECTOR_FORM_ADDRESS_ZIP" = "ADDRESS_SELECTOR_FORM_ADDRESS_ZIP",
  "ADDRESS_SELECTOR_FORM_ADDRESS_COUNTRY" = "ADDRESS_SELECTOR_FORM_ADDRESS_COUNTRY",
  "ADDRESS_SELECTOR_FORM_ADDRESS_FIRSTNAME" = "ADDRESS_SELECTOR_FORM_ADDRESS_FIRSTNAME",
  "ADDRESS_SELECTOR_FORM_ADDRESS_LASTNAME" = "ADDRESS_SELECTOR_FORM_ADDRESS_LASTNAME",
  "ADDRESS_SELECTOR_FORM_ADDRESS_COMPANY" = "ADDRESS_SELECTOR_FORM_ADDRESS_COMPANY",
  "ADDRESS_SELECTOR_FORM_ADDRESS_EMAIL" = "ADDRESS_SELECTOR_FORM_ADDRESS_EMAIL",
  "ADDRESS_SELECTOR_FORM_ADDRESS_PHONE" = "ADDRESS_SELECTOR_FORM_ADDRESS_PHONE",
  "ADDRESS_SELECTOR_FORM_ADDRESS_SAVE_TO_ADDRESS_BOOK" = "ADDRESS_SELECTOR_FORM_ADDRESS_SAVE_TO_ADDRESS_BOOK",
}
