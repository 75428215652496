import { IStyledBoxProps } from "components/atoms/Box";
import { Center } from "components/atoms/Center";
import { Tooltip } from "components/atoms/Tooltip";
import { createElement } from "react";
import { IconType } from "react-icons";

type INavigationIconButtonProps = IStyledBoxProps & {
  tooltip: string;
  icon: IconType;
};

export const NavigationIconButton = (props: INavigationIconButtonProps) => {
  const { icon, tooltip, ...rest } = props;

  return (
    <Tooltip content={tooltip}>
      <div
        className={
          "w-9 h-9 rounded-md cursor-pointer hover:bg-gray3 text-gray10 hover:text-gray12"
        }
        {...rest}
      >
        <Center>{createElement(icon)}</Center>
      </div>
    </Tooltip>
  );
};
