import { useEffect, useRef } from "react";
import { DropdownContext } from "./DropdownContext";
import cx from "classnames";
import { useDisclosure } from "hooks/useDisclosure";
import { isFunction } from "lodash"
import { useState } from "react";
import { usePopper } from "react-popper";
import { IDropdownContext } from "./DropdownContext/DropdownContext";
import { useOnClickOutside } from "hooks/useOutsideClick";

export type MaybeRenderProp<P> =
  | React.ReactNode
  | ((props: P) => React.ReactNode);

export type IDropdown = {
  children: MaybeRenderProp<Omit<IDropdown, "children">>;
  onClose?(): void;
  onToggle?(): void;
  triggerElementRef?: HTMLElement | undefined | null;
  strategy?: "absolute" | "fixed";
  offset?: [number, number];
  isOpen?: boolean;
  closeOnClick?: boolean;
  onOutsideClick?(): void;
  mode?: "onClick" | "onHover";
  className?: string;
  placement?:
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "top-start"
    | "top-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "right"
    | "right-start"
    | "right-end"
    | "left"
    | "left-start"
    | "left-end";
};

export const Dropdown = ({
  children,
  mode = "onHover",
  className,
  offset = [0, 0],
  isOpen: isOpenProp,
  onClose: onCloseProp,
  triggerElementRef,
  closeOnClick = true,
  strategy = "fixed",
  placement = "bottom-start",
}: IDropdown) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure({
    onClose: onCloseProp,

    isOpen: isOpenProp,
  });

  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(
    triggerElementRef || null
  );

  useEffect(() => {
    if (triggerElementRef) {
      setTriggerElement(triggerElementRef);
    }
  }, [triggerElementRef]);

  const [popoverElement, setPopoverElement] =
    useState<HTMLElement | null>(null);

  const { styles: popperPosition } = usePopper(triggerElement, popoverElement, {
    strategy,
    placement,
  
    modifiers: [
      {
        name: "offset",
        options: {
          offset,
        },
      },
    ],
  });

  const handleOutsideClick = (e) => {
    const isClickingPortalledElement =
      popoverElement && popoverElement.contains(e.target);

    if (isOpen) {
      if (!isClickingPortalledElement) {
        onClose();
      }
    }
  };

  const ctx: IDropdownContext = {
    mode,
    closeOnClick,
    onClose,
    onToggle,
    triggerElement,
    setTriggerElement,
    setPopoverElement,
    popperPosition,
    isOpen: isOpen,
    setOpen: onOpen,
  };

  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside([ref], handleOutsideClick);

  return (
    <DropdownContext value={{ ...ctx }}>
      <div className={cx("relative", className)} ref={ref}>
        {isFunction(children) ? children({ ...ctx }) : children}
      </div>
    </DropdownContext>
  );
};
