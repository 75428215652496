import { ModalBody, ModalFooter, ModalHeader } from "components/atoms/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeModal } from "store/modalSlice";
import toast from "react-hot-toast";
import { deleteShipment } from "services/api/shipments";
import { Alert } from "components/atoms/Alert";
import { Button } from "components/atoms/Button";
import { fetchShipmentsThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";

export const ConfirmDeleteShipmentModal = () => {
  const dispatch = useDispatch();

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { currentShipment } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const handleConfirm = async () => {
    dispatch(closeModal({ modalName: "confirmCancelShipment" }));
    const { error } = await deleteShipment(
      salesAccountUUID,
      currentShipment.uuid
    );

    console.log("error", error);

    if (!error) {
      dispatch(fetchShipmentsThunk());
      toast.success("Shipment cancelled");
    } else {
      toast.error("Error cancelling shipment");
    }
  };

  return (
    <>
      <ModalHeader>Confirm cancellation</ModalHeader>
      <ModalBody>
        <Alert>Are you sure you want to cancel this shipment?</Alert>
      </ModalBody>
      <ModalFooter>
        <div className="ml-auto">
          <Button colorScheme="danger" onClick={handleConfirm}>
            Yes, cancel shipment
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
