import { Button } from "components/atoms/Button";
import { ModalBody } from "components/atoms/Modal";
import { ModalHeader } from "components/atoms/Modal/ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "store/modalSlice";
import { RootState } from "store";
import { downloadQuoteReportThunk } from "store/quotesSlice/thunks/downloadQuoteReportThunk.thunk";
import { Select } from "components/atoms/Select";
import { setQuoteDownloadSalesAccount } from "store/quotesSlice/index.slice";

export const ReportQuoteModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const { salesAccounts } = useSelector((state: RootState) => {
    return state.salesAccountSlice;
  });

  const { quoteDownloadSalesAccount } = useSelector((state: RootState) => {
    return state.quotesSlice;
  });

  let value = null;
  const salesAccountsOptions = salesAccounts.map((sa) => {
    if (quoteDownloadSalesAccount === sa.id) {
      value = { label: sa.tradeName, value: sa.id };
    }
    return { label: sa.tradeName, value: sa.id };
  });

  return (
    <>
      <ModalHeader>Generate Quote Report</ModalHeader>
      <ModalBody>
        <div className="flex flex-row">
          <div className="flex w-1/3">
            <div className="w-full">
              <Select
                value={value}
                isNullable={true}
                onChange={(option) => {
                  dispatch(setQuoteDownloadSalesAccount(option?.value || null));
                }}
                placeholder="Select customer"
                options={salesAccountsOptions}
              />
            </div>
          </div>
          <div className="flex w-2/3 justify-end self-end mb-1">
            <Button
              colorScheme="primary"
              onClick={() => {
                dispatch(downloadQuoteReportThunk(parseInt(value.value)));
                dispatch(closeModal({ modalName: "quoteReport" }));
              }}
              isDisabled={!value}
            >
              Generate
            </Button>
          </div>
        </div>
      </ModalBody>
    </>
  );
};
