export enum PackagesTestIds {
  "PACKAGES_ADD_PACKAGE" = "PACKAGES_ADD_PACKAGE",
  "PACKAGES_ROW" = "PACKAGES_ROW",
  "PACKAGES_REMOVE_PACKAGE" = "PACKAGES_REMOVE_PACKAGE",
  "PACKAGES_ROW_ERRORS" = "PACKAGES_ROW_ERRORS",
  "PACKAGES_QUANTITY_FIELD" = "PACKAGES_QUANTITY_FIELD",
  "PACKAGES_WIDTH_FIELD" = "PACKAGES_WIDTH_FIELD",
  "PACKAGES_WEIGHT_FIELD" = "PACKAGES_WEIGHT_FIELD",
  "PACKAGES_HEIGHT_FIELD" = "PACKAGES_HEIGHT_FIELD",
  "PACKAGES_LENGTH_FIELD" = "PACKAGES_LENGTH_FIELD",
  "PACKAGES_TOTAL_PACKAGES" = "PACKAGES_TOTAL_PACKAGES",
  "PACKAGES_TOTAL_WEIGHT" = "PACKAGES_TOTAL_WEIGHT",
  "PACKAGES_TOTAL_CHARGEABLEWEIGHT" = "PACKAGES_TOTAL_CHARGEABLEWEIGHT",
  "PACKAGES_FREIGHT_WARNING" = "PACKAGES_FREIGHT_WARNING",
}
