import * as yup from "yup";
import { isZipValid } from "utils/zip/isZipValid.util";
import { ShipmentConsignmentErrorCodes } from "enum/error-codes/shipment-consignment-error-codes.enum";
import { GenericErrorCodes } from "enum/error-codes/generic-error-codes.enum";

export const CreateShipmentAddressValidationSchema = yup.object({
  firstName: yup.string().nullable(),
  lastName: yup
    .string()
    .when("$direction", (direction, field) =>
      direction === "from"
        ? field
        : field.required(GenericErrorCodes.FIELD_REQUIRED)
    ),
  email: yup
    .string()
    .email(ShipmentConsignmentErrorCodes.INVALID_EMAIL)
    .required(GenericErrorCodes.FIELD_REQUIRED),
  phone: yup.string().required(GenericErrorCodes.FIELD_REQUIRED),
  company: yup
    .string()
    .when("$direction", (direction, field) =>
      direction === "from"
        ? field.required(GenericErrorCodes.FIELD_REQUIRED)
        : field
    ),
  addressLine1: yup
    .string()
    .min(1, GenericErrorCodes.MINIMUM_CHARACTERS_NOT_MET)
    .required(GenericErrorCodes.FIELD_REQUIRED),
  addressLine2: yup.string().nullable(),
  addressLine3: yup.string().nullable(),
  countryIsoCode: yup.string().required(GenericErrorCodes.FIELD_REQUIRED),
  city: yup
    .string()
    .min(3, GenericErrorCodes.MINIMUM_CHARACTERS_NOT_MET)
    .required(GenericErrorCodes.FIELD_REQUIRED),
  zip: yup.string().when("$isZipRequired", (isZipRequired, field) =>
    isZipRequired
      ? field
          .required(GenericErrorCodes.FIELD_REQUIRED)
          .test(
            "isZipValid",
            ShipmentConsignmentErrorCodes.INVALID_ZIP,
            (value, ctx) => {
              const { from } = ctx;

              const countryIsoCode = from[0].value.countryIsoCode;

              const zip = from[0].value.zip;

              return isZipValid(countryIsoCode, zip);
            }
          )
      : field.test(
          "isZipValid",
          ShipmentConsignmentErrorCodes.INVALID_ZIP,
          (value, ctx) => {
            const { from } = ctx;

            const countryIsoCode = from[0].value.countryIsoCode;

            const zip = from[0].value.zip;

            return isZipValid(countryIsoCode, zip);
          }
        )
  ),
});
