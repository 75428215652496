import { Ref } from "react";
import {
  Formik,
  Form,
  FormikProvider,
  useFormik,
  FormikProps,
  FormikValues,
  FormikFormProps,
  FormikConfig,
} from "formik";
import cx from "classnames";
import { runIfFunction } from "utils/react/runIfFunction";
import { MaybeRenderProp } from "utils/react/maybeRenderProp";

export type IForm<T extends FormikValues> = {
  debug?: boolean;
  innerRef?: Ref<HTMLFormElement> | undefined;
  onSubmit(values: T): void;
  children: MaybeRenderProp<FormikProps<T>>;
  validation?: FormikConfig<T>["validationSchema"];
} & Pick<FormikFormProps, "id" | "className"> &
  Omit<FormikConfig<T>, "validationSchema" | "innerRef">;

export const FormikForm = <T extends FormikValues>(props: IForm<T>) => {
  const {
    onSubmit,
    id,
    children,
    className,
    debug,
    initialValues,
    innerRef,
    validation,
    ...rest
  } = props;

  const formikbag = useFormik<T>({
    initialValues,
    onSubmit,
    ...(validation && { validationSchema: validation }),
  });

  return (
    <FormikProvider value={formikbag}>
      <Formik<T>
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
        {...(validation && { validationSchema: validation })}
        {...rest}
      >
        {(formikProps) => {
          return (
            <Form id={id} className={cx("w-full", className)} ref={innerRef}>
              {debug && (
                <>
                  <div>Values: {JSON.stringify(formikProps.values)}</div>
                  <div>Errors: {JSON.stringify(formikProps.errors)}</div>
                </>
              )}
              {runIfFunction(children, { ...formikProps })}
            </Form>
          );
        }}
      </Formik>
    </FormikProvider>
  );
};
