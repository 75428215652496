import { ModalBody, ModalFooter, ModalHeader } from "components/atoms/Modal";
import { useDispatch, useSelector } from "react-redux";
import { deleteBulkShipmentProfile } from "services/api/shipmentProfiles";
import { RootState } from "store";
import { fetchBulkShipmentProfilesThunk } from "store/bulkShipmentProfilesSlice/index.slice";
import { closeModal } from "store/modalSlice";
import toast from "react-hot-toast";
import { Alert } from "components/atoms/Alert";
import { Button } from "components/atoms/Button";
import { Stack } from "components/atoms/Stack";

export const ConfirmDeleteShipmentProfileModal = () => {
  const dispatch = useDispatch();

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { profileToDelete } = useSelector((state: RootState) => {
    return state.createOrUpdateBilkShipmentProfileSlice;
  });

  const handleConfirm = async () => {
    const { error } = await deleteBulkShipmentProfile(
      salesAccountUUID,
      profileToDelete.uuid
    );

    if (!error) {
      dispatch(closeModal({ modalName: "confirmDeleteShipmentProfile" }));
      dispatch(fetchBulkShipmentProfilesThunk());
      toast.success("Profile deleted");
    }
  };

  return (
    <>
      <ModalHeader>Confirm deletion</ModalHeader>
      <ModalBody>
        <Alert>
          Are you sure you want to delete this shipping profile? This action is
          irreversible.
        </Alert>
      </ModalBody>
      <ModalFooter>
        <div className="ml-auto">
          <Stack direction="horizontal" spacing={4}>
            <Button
              onClick={() => {
                dispatch(
                  closeModal({ modalName: "confirmDeleteShipmentProfile" })
                );
              }}
            >
              Cancel
            </Button>
            <Button colorScheme="danger" onClick={handleConfirm}>
              Delete profile
            </Button>
          </Stack>
        </div>
      </ModalFooter>
    </>
  );
};
