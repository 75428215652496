import { IWizardStep } from "hooks/useWizard";
import { CreateBulkShipmentStep1 } from "./CreateBulkShipmentStep1/CreateBulkShipmentStep1";
import { CreateBulkShipmentStep2 } from "./CreateBulkShipmentStep2";
import { CreateBulkShipmentTestIds } from "./CreateBulkShipmentTestIds.enum";

export const CreateBulkShipmentModalSteps: IWizardStep[] = [
  {
    name: "step1",
    testId: CreateBulkShipmentTestIds.CREATE_BULK_SHIPMENT_STEP_1,
    component: <CreateBulkShipmentStep1 />,
  },
  {
    name: "step2",
    testId: CreateBulkShipmentTestIds.CREATE_BULK_SHIPMENT_STEP_2,
    component: <CreateBulkShipmentStep2 />,
  },
];
