export enum LocalStorageKeys {
  CONNEXX_IMPERSONATED_SALES_ACCOUNT_ID = "CONNEXX_IMPERSONATED_SALES_ACCOUNT_ID",
  CONNEXX_SHOW_TABLE_FILTERS = "CONNEXX_SHOW_TABLE_FILTERS",
  CONNEXX_THEME = "CONNEXX_THEME",
}

export const setLocalStorageItem = (key: LocalStorageKeys, value: string) => {
  return localStorage.setItem(key, value);
};

export const removeLocalStorageItem = (key: LocalStorageKeys) => {
  return localStorage.removeItem(key);
};

export const getLocalStorageItem = (key: LocalStorageKeys) => {
  return localStorage.getItem(key);
};
