import { ReactNode } from 'react';
import { usePopoverContext } from '../PopoverContext';

export const PopoverTrigger = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { onOpen, setTriggerElement, trigger, onToggle } = usePopoverContext();

  return (
    <div
      {...(trigger === 'hover' && { onMouseEnter: onOpen })}
      {...(trigger === 'click' && { onClick: onToggle })}
      className="PopoverTrigger"
      ref={setTriggerElement}
    >
      {children}
    </div>
  );
};
