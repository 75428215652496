import { formatDate } from "utils/formatDate";

export const TableDateCell = ({
  value,
  className,
}: {
  value: string;
  className?: string;
}) => {
  return <div className={className}>{formatDate(value)}</div>;
};
