import { ReactNode } from "react";
import { useTheme } from "styled-components";

interface IFormFieldLabelProps {
  children: ReactNode;
  htmlFor: string;
}

export const FormFieldLabel = ({ children, htmlFor }: IFormFieldLabelProps) => {
  const theme = useTheme();

  return (
    <label
      className="block mb-2.5 text-sm font-medium leading-none"
      htmlFor={htmlFor}
      aria-label={htmlFor}
      style={{
        color: theme.textColorMuted,
      }}
    >
      {children}
    </label>
  );
};
