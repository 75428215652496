import { SelectAddressModal } from "components/organisms/SelectAddressModal";
import { DEFAULT_FROM_COUNTRY_ISO_CODE } from "constants/misc";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setReceiverSavedAddress } from "store/createShipmentSlice/index.slice";
import { closeModal } from "store/modalSlice";
import { setQuerySearch } from "store/addressSlice/index.slice";
import toast from "react-hot-toast";

export const SelectReceiverAddressModal = () => {
  const dispatch = useDispatch();

  const { values } = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const { financialCompany } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  return (
    <SelectAddressModal
      onSelect={(address) => {
        if (
          values.sender.countryIsoCode !== DEFAULT_FROM_COUNTRY_ISO_CODE &&
          address.countryIsoCode !== DEFAULT_FROM_COUNTRY_ISO_CODE &&
          financialCompany === 1
        ) {
          toast.error(
            "United Kingdom must be set as either the origin or destination country."
          );
        } else {
          dispatch(setReceiverSavedAddress(address));
          dispatch(closeModal({ modalName: "selectReceiverAddress" }));
        }
      }}
      handleClose={() => {
        dispatch(closeModal({ modalName: "selectReceiverAddress" }));
        dispatch(setQuerySearch(""));
      }}
    />
  );
};
