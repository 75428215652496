import { CarrierTypes } from "enum/carrier-types.enum";
import { ITariff } from "models/tariff/tariff.model";

export const printTariffName = (tariff: ITariff, type: CarrierTypes) => {
  return type === CarrierTypes.INTERNATIONAL
    ? tariff?.directionType === 4
      ? "Domestic"
      : tariff?.directionType === 1
      ? "Export"
      : "Import"
    : tariff.alias;
};
