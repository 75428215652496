import {
  BulkUploadColumnName,
  BulkUploadItemFieldNames,
  BulkUploadPackageFieldNames,
} from "enum/bulk-upload-column-names.enum";
import { CellProps, Column } from "react-table";
import { IShipmentProfile } from "models/shipmentProfile/shipmentProfile.model";
import { Flag } from "components/atoms/Flag";
import cx from "classnames";
import { ISalesAccountDefaults } from "models/salesAccount/salesAccount.model";
import { DimensionUnitSettings } from "interfaces/dimension-units.type";
import { DEFAULT_DIMENSIONS_UNIT, DEFAULT_WEIGHT_UNIT } from "constants/misc";
import { PrintWeight } from "components/atoms/PrintWeight";
import { PrintDimension } from "components/atoms/PrintDimension";
import { WeightUnitSettings } from "interfaces/weight-units.type";
import { PrintCurrency } from "components/atoms/PrintCurrency";
import { Tooltip } from "components/atoms/Tooltip";
import { Warning16 } from "@carbon/icons-react";
import { ValidatedBulkUploadObjectFlat } from "interfaces/validated-bulk-upload-object.interface";
import { ShipmentErrorsTableCell } from "components/organisms/ShipmentErrors/ShipmentErrorsCell";
import { statuses } from "enum/shipment-statuses.enum";
import { PackageTypes } from "enum/package-types.enum";
import { ShipmentDutyOrTaxPaidBy } from "enum/shipment-duty-or-tax-paid-by.enum";
import { ShipmentTypes } from "enum/shipment-types.enum";
import { MappingColumns } from "store/createOrUpdateBulkShipmentProfileSlice/createOrUpdateBulkShipmentProfileSliceInitialState.const";
import { convertDatabaseToValue } from "utils/convertGramsToKilos";

const Error = ({ content }: { content: string }) => {
  return (
    <Tooltip content={content}>
      <Warning16 />
    </Tooltip>
  );
};

export const generateColumns = ({
  selectedProfile,
  showErrors,
  settings,
}: {
  selectedProfile: IShipmentProfile;
  showErrors: boolean;
  settings: ISalesAccountDefaults;
}): Column<ValidatedBulkUploadObjectFlat>[] => {
  const columns: Column<ValidatedBulkUploadObjectFlat>[] = [];

  if (showErrors) {
    const errorColumn: Column<ValidatedBulkUploadObjectFlat> = {
      Header: () => {
        return <div className="flex items-center w-full h-12">Errors</div>;
      },
      accessor: "errors",
      Cell: (props: CellProps<ValidatedBulkUploadObjectFlat>) => {
        const {
          customerReference,
          customerReference2,
          reasonForExport,
          fromAddressFirstName,
          fromAddressLastName,
          fromAddressEmail,
          fromAddressPhone,
          fromAddressCompany,
          fromAddressStreet1,
          fromAddressStreet2,
          fromAddressStreet3,
          fromAddressCity,
          fromAddressCountyState,
          fromAddressCountryIso,
          fromAddressZip,
          fromAddressEoriNumber,
          fromAddressVatNumber,
          toAddressFirstName,
          toAddressLastName,
          toAddressEmail,
          toAddressPhone,
          toAddressCompany,
          toAddressStreet1,
          toAddressStreet2,
          toAddressStreet3,
          toAddressCity,
          toAddressCountyState,
          toAddressCountryIso,
          toAddressZip,
          toAddressEoriNumber,
          toAddressVatNumber,
          packageHeight,
          packageLength,
          packageWeight,
          packageWidth,
          itemDescription,
          itemHsCode,
          itemOriginCountryIso,
          itemQuantity,
          itemSku,
          itemValue,
          itemValueCurrency,
          itemWeight,
          manufacturerDetails,
          shippingMethod,
          errors,
        } = props.row.original;

        return (
          <ShipmentErrorsTableCell
            shipment={{
              useInsurance: false,
              estimatedDeliveryDate: null,
              insuranceAmount: 0,
              insuranceAmountCurrencyCode: "",
              id: "",
              invoiceNumber: "",
              iossNumber: "",
              uuid: "",
              shipmentType: ShipmentTypes.SHIPMENT,
              createdBy: "",
              salesAccountId: 1,
              createdDate: "",
              shipmentSourceName: "",
              shipmentBulkUploadId: "",
              generatedByAdmin: false,
              combinedLabelUrl: "",
              labelJobs: [],
              trackingCodes: [],
              rates: [],
              dutyOrTaxPaidBy: ShipmentDutyOrTaxPaidBy.RECEIVER,
              errors,
              jobNumber: "",
              shouldSplitDutyAndVat: false,
              service: {
                id: "",
                name: "",
              },
              carrier: {
                id: "",
                name: "",
                alias: "",
              },
              tariff: {
                id: "",
                name: "",
                alias: "",
                carrierId: "",
              },
              supplier: {
                id: "",
                name: "",
                alias: "",
                carrierId: "",
              },
              status: statuses.ready_for_print,
              customerReference,
              customerReference2,
              reasonForExport,
              fromAddressFirstName,
              fromAddressLastName,
              fromAddressEmail,
              fromAddressPhone,
              fromAddressCompany,
              fromAddressStreet1,
              fromAddressStreet2,
              fromAddressStreet3,
              fromAddressCity,
              fromAddressCountyState,
              fromAddressCountryIso,
              fromAddressZip,
              fromAddressEoriNumber,
              fromAddressVatNumber,
              toAddressFirstName,
              toAddressLastName,
              toAddressEmail,
              toAddressPhone,
              toAddressCompany,
              toAddressStreet1,
              toAddressStreet2,
              toAddressStreet3,
              toAddressCity,
              toAddressCountyState,
              toAddressCountryIso,
              toAddressZip,
              toAddressEoriNumber,
              toAddressVatNumber,
              packages: [
                {
                  id: "",
                  index: 0,
                  errors: [],
                  weight: packageWeight,
                  height: packageHeight,
                  length: packageLength,
                  width: packageWidth,
                  type: PackageTypes.Parcel,
                },
              ],
              itemDescriptions: [
                {
                  shipmentPackageIndex: 0,
                  description: itemDescription,
                  countryOfOrigin: itemOriginCountryIso,
                  quantity: itemQuantity,
                  sku: itemSku,
                  weight: itemWeight,
                  currency: itemValueCurrency,
                  commodityCode: itemHsCode,
                  itemValue: itemValue,
                  manufacturerDetails: manufacturerDetails,
                  shippingMethod: shippingMethod,
                },
              ],
              packageTrackingData: null,
              sku: "",
              description: "",
              packageQuantity: "",
              itemQuantity: "",
              integrationStoreName: "",
            }}
          />
        );
      },
    };
    columns.push(errorColumn);
  }

  Object.keys(MappingColumns).map((record) => {
    columns.push({
      Header: () => {
        const getColumnMapping = selectedProfile?.mappings[record];

        const label = MappingColumns[record].label;

        return (
          <div className="flex items-center w-full py-3">
            <div>
              <div className="mb-1.5 font-semibold leading-none">
                {record === BulkUploadPackageFieldNames.packageHeight ||
                record === BulkUploadPackageFieldNames.packageLength ||
                record === BulkUploadPackageFieldNames.packageWidth ? (
                  <>
                    {label} (
                    {
                      DimensionUnitSettings[
                        settings?.dimensionsUnit || DEFAULT_DIMENSIONS_UNIT
                      ].label
                    }
                    )
                  </>
                ) : record === BulkUploadPackageFieldNames.packageWeight ? (
                  <>
                    {label} (
                    {
                      WeightUnitSettings[
                        settings?.weightUnit || DEFAULT_WEIGHT_UNIT
                      ].label
                    }
                    )
                  </>
                ) : (
                  <>{label}</>
                )}
              </div>
              <div className="opacity-75 text-muted">
                {getColumnMapping !== null && getColumnMapping !== undefined
                  ? "From column " + (getColumnMapping + 1)
                  : "Not mapped"}
              </div>
            </div>
          </div>
        );
      },
      accessor: record as any,
      Cell: (props: CellProps<ValidatedBulkUploadObjectFlat>) => {
        const isInvalidConsignment =
          props.row.original?.errors &&
          props.row.original?.errors.consignment[record];
        const isInvalid =
          props.row.original?.errors && props.row.original?.errors[record];

        const value = props.row.original[record];

        return (
          <div
            className={cx(
              "overflow-hidden flex items-center whitespace-nowrap text-ellipsis w-full",
              {
                "text-red9": (isInvalid || isInvalidConsignment) && showErrors,
              }
            )}
          >
            {record === BulkUploadColumnName.fromAddressCountryIso ||
            record === BulkUploadColumnName.toAddressCountryIso ? (
              <>
                <div className="flex items-center leading-none">
                  <div className="mr-2">
                    {value ? (
                      <Flag countryCode={(value as string)?.toLowerCase()} />
                    ) : null}
                  </div>
                  {value}
                </div>
              </>
            ) : record === BulkUploadPackageFieldNames.packageWeight ||
              record === BulkUploadItemFieldNames.itemWeight ? (
              <PrintWeight
                value={props.row.original[record]}
                weightUnit={settings?.weightUnit}
              />
            ) : record === BulkUploadPackageFieldNames.packageLength ||
              record === BulkUploadPackageFieldNames.packageHeight ||
              record === BulkUploadPackageFieldNames.packageWidth ? (
              <PrintDimension
                value={props.row.original[record]}
                dimensionUnit={settings?.dimensionsUnit}
              />
            ) : record === BulkUploadItemFieldNames.itemValue ? (
              <PrintCurrency
                value={
                  props.row.original[record]
                    ? convertDatabaseToValue(props.row.original[record])
                    : 0
                }
                currencyCode={props.row.original.itemValueCurrency}
              />
            ) : (
              <>{value}</>
            )}

            {isInvalid && showErrors && (
              <div className="flex items-center ml-auto">
                <Error content={props.row.original?.errors[record]} />
              </div>
            )}
            {isInvalidConsignment && showErrors && (
              <div className="flex items-center ml-auto">
                <Error
                  content={
                    props.row.original?.errors.consignment[record][0].code
                  }
                />
              </div>
            )}
          </div>
        );
      },
    });
  });

  return columns;
};
