import { Button } from "components/atoms/Button";
import { ModalBody } from "components/atoms/Modal";
import { ModalHeader } from "components/atoms/Modal/ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "store/modalSlice";
import { FormControl } from "components/molecules/FormControl";
import { DatePickerComponent } from "components/atoms/datePicker";
import { RootState } from "store";
import {
  setReportShipmentFrom,
  setReportShipmentTo,
} from "store/shipmentsSlice/index.slice";
import { downloadShipmentReportThunk } from "store/shipmentsSlice/thunks/downloadShipmentReportThunk.thunk";

export const ReportShipmentModal = (): JSX.Element => {
  const dispatch = useDispatch();

  const { reportShipmentFrom, reportShipmentTo } = useSelector(
    (state: RootState) => {
      return state.shipmentsSlice;
    }
  );

  return (
    <>
      <ModalHeader>Generate Shipment Report</ModalHeader>
      <ModalBody>
        <div className="flex flex-row">
          <div className="flex w-1/2">
            <div className="flex mr-8">
              <FormControl label="From" isRequired={false}>
                <DatePickerComponent
                  showTimeSelect={false}
                  date={new Date(reportShipmentFrom)}
                  setDate={(date) =>
                    dispatch(setReportShipmentFrom(date.toISOString()))
                  }
                  dateFormat="dd-MM-yyyy"
                />
              </FormControl>
            </div>
            <FormControl label="To" isRequired={false}>
              <DatePickerComponent
                showTimeSelect={false}
                date={new Date(reportShipmentTo)}
                setDate={(date) =>
                  dispatch(setReportShipmentTo(date.toISOString()))
                }
                dateFormat="dd-MM-yyyy"
              />
            </FormControl>
          </div>
          <div className="flex w-1/2 justify-end self-end mb-1">
            <Button
              colorScheme="primary"
              onClick={() => {
                dispatch(downloadShipmentReportThunk());
                dispatch(closeModal({ modalName: "shipmentReport" }));
              }}
            >
              Generate
            </Button>
          </div>
        </div>
      </ModalBody>
    </>
  );
};
