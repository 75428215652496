import { Select } from "components/atoms/Select";
import {
  generateSelectOptions,
  ISelectOption,
} from "components/atoms/Select/UseSelect";
import {
  CustomerStatusesConfig,
  ISalesAccount,
} from "models/salesAccount/salesAccount.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { sortBy } from "lodash";
import { Label } from "components/atoms/Label";
import { changeSalesAccountThunk } from "store/authSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { IUser } from "models/user/user.model";

export const CustomerSelect = () => {
  const { salesAccounts } = useSelector((state: RootState) => {
    return state.salesAccountSlice;
  });

  const { salesAccountID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { user, getAccessTokenSilently } = useAuth0<IUser>();
  const filteredSalesAccounts = salesAccounts.filter(
    (salesAccount) =>
      salesAccount.status === "A" &&
      salesAccount.salesAccountReference[0] === "1"
  );
  const options = generateSelectOptions<
    Pick<
      ISalesAccount,
      "id" | "uuid" | "status" | "salesAccountReference" | "tradeName"
    >
  >({
    data: sortBy<
      Pick<
        ISalesAccount,
        "id" | "uuid" | "status" | "salesAccountReference" | "tradeName"
      >
    >(
      filteredSalesAccounts,
      [(customer) => customer?.tradeName?.toLowerCase()],
      "customerName"
    ),
    valueKey: "uuid",
    labelKey: "tradeName",
  });
  const selected =
    options.find(
      (customer) => customer.id !== null && customer.id === salesAccountID
    ) || null;
  const dispatch = useDispatch();
  const handleSetCustomer = async (
    salesAccountID: ISalesAccount["id"] | null
  ) => {
    if (user && getAccessTokenSilently) {
      getAccessTokenSilently({
        audience: process.env.NEXT_PUBLIC_AUDIENCE,
      }).then((resp) => {
        window["accessToken"] = resp;
        dispatch(changeSalesAccountThunk(user, resp, salesAccountID));
      });
    }
  };
  return (
    <div>
      <Select<
        ISelectOption<
          Pick<
            ISalesAccount,
            "id" | "uuid" | "status" | "salesAccountReference" | "tradeName"
          >
        >
      >
        options={options}
        placeholder="Select customer"
        value={selected}
        customFilter={(option, query) => {
          return (
            (option.tradeName &&
              option.tradeName.toLowerCase().includes(query.toLowerCase())) ||
            (option.salesAccountReference &&
              option.salesAccountReference
                .toLowerCase()
                .includes(query.toLowerCase()))
          );
        }}
        optionTemplate={({ tradeName, salesAccountReference, status }) => {
          return (
            <div className="flex items-center">
              {tradeName} – {salesAccountReference}
              <div className="ml-2">
                <Label color={CustomerStatusesConfig[status]?.color || "gray"}>
                  {CustomerStatusesConfig[status]?.label || status}
                </Label>
              </div>
            </div>
          );
        }}
        onChange={(option) => {
          if (option) {
            handleSetCustomer(option?.id);
          }
        }}
      />
    </div>
  );
};
