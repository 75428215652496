import { NavigationUser } from "./NavigationUser";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "store/modalSlice";
import { RootState } from "store";
import { NavigationIconButton } from "./NavigationIconButton";
import { PermissionCheck } from "utils/PermissionCheck/PermissionCheck";
import { InternalLink } from "components/atoms/InternalLink";
import { Stack } from "components/atoms/Stack";
import { FaCog, FaMoon, FaPrint, FaQuestion } from "react-icons/fa";
import styled from "styled-components";
import { NavigationAnnouncement } from "./NavigationAnnouncement";
import { NavigationPrimaryLinks } from "./NavigationPrimaryLinks";
import { NavigationCreateDropdown } from "./NavaigationCreateDropdown";
import { paths } from "enum/paths.enum";
import { setTheme } from "store/tableSlice/index.slice";
import Image from "next/image";
import ConnexxLogo from "../../../public/images/logos/connexx.svg";

const StyledNavigation = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  border-bottom: 1px solid;
  border-color: ${(props) => `${props.theme.borderColor}`};
  padding-left: ${(props) => `${props.theme.gutter}`};
  padding-right: ${(props) => `${props.theme.gutter}`};
  background-color: ${(props) => `${props.theme.navigationBackgroundColor}`};
  height: ${(props) => `${props.theme.navigationHeight}`};
`;

export const Navigation = () => {
  const dispatch = useDispatch();

  const { loggedInUser, alertType } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { theme } = useSelector((state: RootState) => {
    return state.tableSlice;
  });

  const permissions = new PermissionCheck({ user: loggedInUser });

  const { items } = useSelector((state: RootState) => {
    return state.currentLabelJobsSlice;
  });

  const pendingItems = items.length > 0;

  const isOnStop = alertType === "onstop";

  return (
    <>
      {/* {pendingItems && <NavigationAnnouncement />} */}
      <StyledNavigation>
        <div className="mr-4">
          <Image
            src={ConnexxLogo}
            alt={"Connexx Logo"}
            width={80}
            height={62.8}
          />
        </div>
        <NavigationPrimaryLinks />
        <div className="ml-auto">
          <Stack
            spacing={3}
            direction="horizontal"
            divider={<div className="border-r border-gray3" />}
          >
            {isOnStop ? null : <NavigationCreateDropdown />}

            <NavigationIconButton
              tooltip="Labels"
              icon={FaPrint}
              onClick={() => {
                dispatch(openModal({ name: "labels", type: "drawer" }));
              }}
            />
            {permissions.settings.canEdit() && (
              <InternalLink href={paths.COMPANY_SETTINGS_GENERAL}>
                <NavigationIconButton tooltip="Settings" icon={FaCog} />
              </InternalLink>
            )}
            <NavigationIconButton
              icon={FaMoon}
              tooltip="Theme"
              onClick={() =>
                dispatch(setTheme(theme === "light" ? "dark" : "light"))
              }
            />
            <NavigationIconButton
              icon={FaQuestion}
              tooltip="Help"
              onClick={() =>
                dispatch(
                  openModal({
                    name: "helpContactInfo",
                    type: "modal",
                    size: "sm",
                    closeOnOverlayClick: true,
                  })
                )
              }
            />
            <NavigationUser />
          </Stack>
        </div>
      </StyledNavigation>
    </>
  );
};
