import { KILOS_TO_GRAMS_CONVERSION } from "constants/unit-conversions.constant";

// All values are multiplied by 100 when saved in the DB to avoid rounding errors.
// Any values pulled from the DB must therefore first be converted into g, cm or pennies / cents
// before they can be used.
export const convertDatabaseToValue = (unit: number): number => {
  return parseFloat((unit / 100).toFixed(2));
};

export const convertValueToDatabase = (unit: number): number => {
  return parseFloat((unit * 100).toFixed(2));
};

export const convertGramsToKilos = (grams: number): number => {
  return parseFloat((grams / KILOS_TO_GRAMS_CONVERSION).toFixed(2));
}
export const convertKilosToGrams = (kilos: number): number => {
  return parseFloat((kilos * KILOS_TO_GRAMS_CONVERSION).toFixed(2));
};
