import React from "react";
import { useSelectContextProvider } from "./SelectContextProvider";
import { createElement } from "react";
import { HiSelector } from "react-icons/hi";
import styles from "./Select.module.scss";
import { Close16 } from "@carbon/icons-react";
import { useHotkeys } from "react-hotkeys-hook";
import { KeyboardKeys } from "enum/keyboardkeys.enum";
import { Center } from "../Center";
import styled from "styled-components";
import { getButtonHeight } from "../Button";
import { InputSizes } from "../Input";

const StyledSelectInput = styled.div<{ size: InputSizes; buttonHeight?: string }>`
  width: 100%;
  display: inline-flex;
  align-items: center;
  appearance: none;
  font-weight: normal;
  cursor: pointer;
  outline: none;
  border: 1px solid;
  border-color: ${(props) => props.theme.formControlBorderColorDefault};
  background-color: ${(props) => props.theme.formControlBackgroundColorDefault};
  padding-left: 12px;
  font-size: 14px;
  border-radius: ${(props) => props.theme.borderRadius};
  height: ${(props) => props.buttonHeight || getButtonHeight(props.size)};
`;

export const SelectInput = ({ buttonHeight }: { buttonHeight?: string }) => {
  const {
    value,
    size,
    onToggle,
    placeholder,
    onChange,
    isOpen,
    setTriggerElement,
    name,
    onClose,
    tabIndex,
    isNullable,
    isDisabled,
    optionTemplate,
    testId,
    onOpen,
    hideArrows,
  } = useSelectContextProvider();

  useHotkeys(
    KeyboardKeys.TAB,
    () => {
      if (isOpen) {
        onClose();
      }
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  const ref = useHotkeys<HTMLDivElement>(
    KeyboardKeys.ENTER,
    () => {
      if (!isOpen) {
        onOpen();
      }
    },
    {
      enableOnTags: ["INPUT"],
    }
  );
  
  return (
    <div
      className="relative group"
      ref={setTriggerElement}
      {...(testId && { "data-testid": `${testId}_SelectInput` })}
    >
      <input type="hidden" name={name} />
      <StyledSelectInput
        size={size}
        buttonHeight={buttonHeight}
        onMouseDown={() => {
          if (!isDisabled) {
            onToggle();
          }
        }}
        onFocus={(e) => {
          if (!isOpen && !isDisabled) {
            // Open when we receive focus, but prevent the onClick event from firing.
            e.preventDefault();
            e.stopPropagation();
            onOpen();
          }
        }}
        tabIndex={tabIndex}
        ref={ref}
      >
        {value ? (
          optionTemplate ? (
            createElement(optionTemplate, { ...value })
          ) : (
            value.label
          )
        ) : (
          <div className={styles.SelectInputPlaceholder}>{placeholder}</div>
        )}
      </StyledSelectInput>
      <div className={styles.SelectInputRightElementContainer}>
        {value && isNullable && (
          <div
            className="w-5 h-5 mr-1 bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300"
            onClick={(e) => {
              e.stopPropagation();
              onChange(null);
            }}
          >
            <Center>
              <Close16 />
            </Center>
          </div>
        )}
        {hideArrows !== true && (
          <div
            className={styles.SelectInputArrow}
            style={{
              opacity: 0.5,
            }}
            onClick={() => {
              if (!isDisabled) {
                onToggle();
              }
            }}
          >
            <HiSelector />
          </div>
        )}
      </div>
    </div>
  );
};
