export enum DimensionUnits {
  "cm" = "cm",
  "inch" = "inch",
}

export const DimensionUnitSettings: {
  [key in DimensionUnits]: { label: string };
} = {
  [DimensionUnits.cm]: {
    label: "cm",
  },
  [DimensionUnits.inch]: {
    label: "in",
  },
};
