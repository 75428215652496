import { Center } from "components/atoms/Center";
import { Heading } from "components/atoms/Heading";

export const Maintenance = () => {
  return (
    <div className="justify-center w-screen h-screen">
      <Center>
        <div className="text-center">
          <div className="mb-4">
            <Heading size="2xl" className="mb-3">
              Undergoing maintenance
            </Heading>
            <p className="text-lg">We&apos;ll be back shortly.</p>
          </div>
        </div>
      </Center>
    </div>
  );
};
