import { Alert } from "components/atoms/Alert";
import { Button } from "components/atoms/Button";
import { ButtonGroup } from "components/atoms/ButtonGroup";
import { Heading } from "components/atoms/Heading";
import { IconButton } from "components/atoms/IconButton";
import { ModalFooter } from "components/atoms/Modal";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/atoms/Popover";
import { Radio } from "components/atoms/Radio";
import { RadioGroup } from "components/atoms/RadioGroup";
import { Stack } from "components/atoms/Stack";
import { LabelSizes } from "enum/label-sizes.enum";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { CreateBulkShipmentTestIds } from "../../CreateBulkShipmentTestIds.enum";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { PermissionCheck } from "utils/PermissionCheck/PermissionCheck";
import { openModal } from "store/modalSlice";

export const CreateBulkShipmentStep2ImportSummary = (
  props: Pick<ICreateBulkShipmentStep2FooterProps, "errors" | "ready">
) => {
  const { errors, ready } = props;
  const theme = useTheme();

  return (
    <>
      {errors.length > 0 && (
        <div className="mr-3 text-sm text-right">
          {ready.length} shipments will be imported.
          <span style={{ color: theme.colors.red[10] }}>
            {errors.length} will be marked as error.
          </span>
          <br />
          These will need to be fixed before labels can be generated.
        </div>
      )}
    </>
  );
};

export type ICreateBulkShipmentStep2FooterProps = {
  isSubmitting: boolean;
  handleSubmit({
    printLater,
    labelSize,
  }: {
    printLater: boolean;
    labelSize?: LabelSizes;
  }): void;
  errors: any;
  valid: any;
  ready: any;
};

export const CreateBulkShipmentStep2Footer = (
  props: ICreateBulkShipmentStep2FooterProps
) => {
  const { isSubmitting, errors, valid, ready, handleSubmit } = props;

  const [labelSize, setLabelSize] = useState(LabelSizes.x6);

  const { loggedInUser, alertType } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const isOnstop = alertType === "onstop";
  const permissions = new PermissionCheck({ user: loggedInUser });

  return (
    <ModalFooter>
      <div className="inline-flex ml-auto">
        <CreateBulkShipmentStep2ImportSummary errors={errors} ready={ready} />
        <Popover trigger="click" maxW={400} placement="top-end">
          {({ onToggle, onClose }) => {
            return (
              <>
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    onClick={() => handleSubmit({ printLater: true })}
                    size="lg"
                    testId={
                      CreateBulkShipmentTestIds.CREATE_BULK_SHIPMENT_STEP_2_SUBMIT_BUTTON
                    }
                    isLoading={isSubmitting}
                  >
                    Import
                  </Button>
                  {!isSubmitting && (
                    <PopoverTrigger>
                      <IconButton
                        testId={
                          CreateBulkShipmentTestIds.CREATE_BULK_SHIPMENT_STEP_2_SUBMIT_BUTTON_MORE
                        }
                        onClick={onToggle}
                        icon={FaChevronDown}
                        size="lg"
                      />
                    </PopoverTrigger>
                  )}
                </ButtonGroup>
                <PopoverContent>
                  <div className="p-6">
                    <Heading className="mb-5" size="xl">
                      Import &amp; print
                    </Heading>
                    <Stack spacing={6}>
                      <div>
                        <Alert status="warning">
                          Please note that only {valid.length} valid shipments
                          will have labels generated. Shipments with errors will
                          need the error to be corrected.
                        </Alert>
                      </div>
                      <div>
                        <RadioGroup
                          value={labelSize}
                          onValueChange={(value: LabelSizes) =>
                            setLabelSize(value)
                          }
                        >
                          <Radio value={LabelSizes.x6}>As 4x6 PDF</Radio>
                          <Radio value={LabelSizes.x8}>As 4x8 PDF</Radio>
                        </RadioGroup>
                      </div>
                      <Button
                        testId={
                          CreateBulkShipmentTestIds.CREATE_BULK_SHIPMENT_STEP_2_SUBMIT_AND_PRINT_BUTTON
                        }
                        isBlock
                        colorScheme="primary"
                        size="lg"
                        onClick={() => {
                          if (!isOnstop) {
                            if (permissions.shipments.canGenerate()) {
                              onClose();
                              handleSubmit({ printLater: false, labelSize });
                            }
                          }
                        }}
                      >
                        Import & print
                      </Button>
                    </Stack>
                  </div>
                </PopoverContent>
              </>
            );
          }}
        </Popover>
      </div>
    </ModalFooter>
  );
};
