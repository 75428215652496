import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_META } from "constants/misc";
import {
  ApiLoadingStatus,
  Meta,
  Query,
  SortDirection,
} from "interfaces/api.interface";
import { IBulkShipment } from "models/bulkShipment/bulkShipment.model";
import { fetchBulkShipments } from "services/api/bulkShipments";
import { FetchBulkShipmentsResponseDto } from "services/api/bulkShipments/dtos/fetch-bulk-shipments.response.dto";
import { AppThunk, RootState } from "store";

export interface IBulkShipmentsSlice {
  loadingStatus: ApiLoadingStatus;
  items: IBulkShipment[];
  query: Query<IBulkShipment>;
  meta: Meta;
}

export const BulkShipmentsSliceInitialState: IBulkShipmentsSlice = {
  loadingStatus: "IDLE",
  items: [],
  query: {
    sort: {
      createdDate: SortDirection.DESC,
    },
  },
  meta: { ...DEFAULT_META },
};

export const bulkShipmentsSlice = createSlice({
  name: "bulkShipmentsSlice",
  initialState: BulkShipmentsSliceInitialState,
  reducers: {
    setData: (state, action: PayloadAction<FetchBulkShipmentsResponseDto>) => {
      state.items = action.payload.data;
      state.meta = action.payload.meta;
      state.loadingStatus = "SUCCESS";
    },
    setQuerySort: (
      state,
      action: PayloadAction<IBulkShipmentsSlice["query"]["sort"]>
    ) => {
      state.query.sort = action.payload;
    },
    setStatus: (state, action: PayloadAction<ApiLoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
  },
});

export const { setData, setStatus, setQuerySort } = bulkShipmentsSlice.actions;

export const fetchBulkShipmentsThunk = (query: Query): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setStatus("LOADING"));

    const {
      authSlice: { salesAccountUUID },
    } = getState() as RootState;

    try {
      const { data, error, meta } = await fetchBulkShipments(
        salesAccountUUID,
        query
      );

      if (!error) {
        dispatch(
          setData({
            meta,
            data,
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
};
