import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "styled-components";
import { useDropdownContext } from "..";

const list = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

type IDropdownMenu = {
  children: React.ReactNode;
  width?: number;
};

export const DropdownMenu = (props: IDropdownMenu) => {
  const { children, width = 300 } = props;

  const { isOpen, popperPosition, setPopoverElement } = useDropdownContext();

  const theme = useTheme();

  return (
    <AnimatePresence>
      {isOpen && (
        <div
          ref={setPopoverElement}
          style={{
            ...popperPosition.popper,
            zIndex: 1,
          }}
        >
          <motion.div
            key={1}
            variants={list}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ duration: 0.15, stiffness: 30 }}
            style={{
              width,
              borderRadius: theme.borderRadius,
              backgroundColor: theme.dropdownBackgroundColor,
              borderColor: theme.dropdownBorderColor,
              boxShadow: theme.shadows["2xl"],
            }}
            className="border"
          >
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};
