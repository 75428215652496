import { ILabelJob } from "models/labelJob/labelJob.model";
import cx from "classnames";
import { LabelJobStatus } from "enum/label-job-status.enum";
import { useTheme } from "styled-components";

export const LabelJobCardHeader = ({ item }: { item: ILabelJob }) => {
  const theme = useTheme();

  return (
    <div
      className={cx(
        "leading-none text-center rounded-t-md flex items-center h-10 px-4"
      )}
      style={{
        backgroundColor:
          item.status === LabelJobStatus.COMPLETE
            ? theme.colors.green[5]
            : item.status === LabelJobStatus.ERROR
            ? theme.colors.red[5]
            : theme.colors.gray[5],
      }}
    >
      <div className="flex items-center rounded-md">
        <div
          className="text-sm font-semibold"
          style={{
            color:
              item.status === LabelJobStatus.PENDING
                ? "var(--colors-gray10)"
                : item.status === LabelJobStatus.ERROR
                ? "var(--colors-red10)"
                : "var(--colors-green10)",
          }}
        >
          {item.status === LabelJobStatus.PENDING
            ? "Pending"
            : item.status === LabelJobStatus.ERROR
            ? "Error"
            : "Complete"}
        </div>
      </div>
      <div className="ml-auto text-sm leading-none text-gray10">#{item.id}</div>
    </div>
  );
};
