import { IShipmentPackageGrouped } from "models/shipmentPackage/shipmentPackage.model";
import { convertDatabaseToValue } from "./convertGramsToKilos";
import { ICreateShipmentSliceValues } from "store/createShipmentSlice/createShipmentSlice.types";
import currencySymbol from "currency-symbol-map";

// Total chargeable weight is calculated by taking the higher of either the volumetric weight or the total weight FOR EACH ITEM.
// If two items have been added, then for each item, we need to calculate which of the two is higher, and add those together.
// Example:
// Item 1: 1 x 10 (H) x 10 (L) x 10 (W) x 1000g
// Item 2: 1 x 50 (H) x 50 (L) x 50 (W) x 1000g
//
// Total volumetric weight for the first row is 200g or 0.2kg. Total weight is 1000g, or 1kg. So 1000g is the value we use.
// Total volumetric weight for the second row is 25000g or 25kg. Total weight is 1000g or 1kg. In this case, 25000g is the value we use.
//
// So our total chargeable weight in this case is going to be 26000g (1000g + 25000g).

export const calculateVolumetricWeight = (
  item: IShipmentPackageGrouped,
  divideTotalPackageWeightByQuantityInUi?: boolean
): number => {
  const volumeMetricWeight =
    (convertDatabaseToValue(item.height) *
      convertDatabaseToValue(item.length) *
      convertDatabaseToValue(item.width) *
      item.quantity) /
    5;

  return volumeMetricWeight;
};

export const calculateTotalChargeableWeight = (
  items: IShipmentPackageGrouped[],
  carrierName: string,
  divideTotalPackageWeightByQuantityInUi?: boolean
): number => {
  let result = 0;

  items.forEach((item) => {
    let weight = calculateWeight(item);

    if (divideTotalPackageWeightByQuantityInUi) {
      //https://github.com/ITD-Global/itd-connexx/issues/1732
      weight = weight / item.quantity;
    }

    const volumetricWeight = calculateVolumetricWeight(
      item,
      divideTotalPackageWeightByQuantityInUi
    );

    if (carrierName === "Amazon") {
      result += weight;
    } else {
      result += volumetricWeight > weight ? volumetricWeight : weight;
    }
  });

  return result;
};

export const calculateWeight = (item: IShipmentPackageGrouped): number => {
  return convertDatabaseToValue(item.weight) * item.quantity;
};

export const calculateTotalWeight = (
  items: IShipmentPackageGrouped[],
  divideTotalPackageWeightByQuantityInUi?: boolean
): number => {
  let value = 0;

  items.forEach((item) => {
    if (divideTotalPackageWeightByQuantityInUi) {
      //https://github.com/ITD-Global/itd-connexx/issues/1732
      value += item.weight;
    } else {
      value += item.weight * item.quantity;
    }
  });

  return value;
};

export const calculateTotalItems = (
  items: IShipmentPackageGrouped[]
): number => {
  let quantity = 0;

  items.forEach((item) => {
    quantity += item.quantity;
  });

  return quantity;
};

export const calculateTotalShipmentValue = (
  values: ICreateShipmentSliceValues,
  totalPackages?: number
) => {
  const contents = values.items;
  // check if all the items have same currency
  const currency = values.contents[0]?.currency;
  let sameCurrency = true;
  values.contents?.forEach((item) => {
    if (item.currency !== currency) {
      sameCurrency = false;
    }
  });

  if (!sameCurrency) {
    return "Multiple Currencies";
  }

  let value = 0;

  values.contents?.forEach((item, index) => {
    const packageIndex = item.packageId;
    const numberOfPackages = contents.find(
      (content, i) => i === packageIndex
    )?.quantity;

    const itemValue = item.itemValue ? item.itemValue : 0;
    value += itemValue * item.quantity * numberOfPackages;
    if (values.items[index]?.quantity) {
      value * values.items[index]?.quantity;
    }
  });

  const symbol = currencySymbol(currency) || "£";
  return `${symbol}${convertDatabaseToValue(value)}`;
};
