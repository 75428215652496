import { TableHeaderCell } from "./TableHeaderCell";
import { HeaderGroup } from "react-table";
import { TableProps } from "./types";

export type ITableHeader<T extends object> = {
  headerGroups: HeaderGroup<T>[];
} & Pick<TableProps<T>, "handleColumnSort" | "sort" | "erroredColumns">;

export const TableHeader = <T extends object>({
  headerGroups,
  handleColumnSort,
  sort,
  erroredColumns,
}: ITableHeader<T>) => {
  return (
    <div>
      {headerGroups.map((headerGroup, index) => (
        <div {...headerGroup.getHeaderGroupProps()} key={index}>
          {headerGroup.headers.map((column) => (
            <TableHeaderCell
              column={column}
              key={column.id}
              sort={sort}
              handleColumnSort={handleColumnSort}
              erroredColumns={erroredColumns}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
