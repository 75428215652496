import { ChevronLeft20, ChevronRight20 } from "@carbon/icons-react";
import { Row } from "react-table";
import { Center } from "../Center";
import { TableProps } from "./types";
import cx from "classnames";
import { formatNumberWithCommas } from "utils/formatNumberWithCommas";
import { Stack } from "../Stack";
import { useTheme } from "styled-components";
import { Divider } from "../Divider";
import { TableRowQuantitySelect } from "./TableRowQuantitySelect";
import { useDispatch } from "react-redux";
import { setNumberOfRowsSelected } from "store/tableSlice/index.slice";
import { fetchShipmentsThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";

export interface PaginationProps<D extends object> {
  canPreviousPage?: boolean;
  canNextPage?: boolean;
  gotoPage?: (pageNumber: number) => void;
  page: Array<Row<D>>;
  nextPage(): void;
  previousPage(): void;
  pageIndex: number;
  pageCount: TableProps<D>["pageCount"];
  pageSize: TableProps<D>["pageSize"];
  total: TableProps<D>["recordCount"];
  tableRowQuantityChange?: TableProps<D>["tableRowQuantityChange"];
}

const PageButton = ({
  children,
  isDisabled,
  onClick,
}: {
  children: React.ReactNode;
  isDisabled: boolean;
  onClick?(): void;
}) => {
  return (
    <div
      className={cx("w-12 h-8", {
        "cursor-pointer": !isDisabled,
        "opacity-50": isDisabled,
        "hover:bg-gray3": !isDisabled,
        "cursor-not-allowed": isDisabled,
      })}
      {...(onClick && { onClick })}
    >
      <Center>{children}</Center>
    </div>
  );
};

export const TablePagination = <D extends object>({
  previousPage,
  nextPage,
  pageIndex,
  pageSize,
  total,
  tableRowQuantityChange,
}: PaginationProps<D>) => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const canPreviousPage = pageIndex === 0;
  const canNextPage = (pageIndex + 1) * pageSize >= total;
  return (
    <div
      className="flex items-center w-full h-16 border-t"
      style={{
        borderColor: theme.borderColor,
      }}
    >
      <div className="flex flex-row text-sm text-muted">
        <div className="flex">
          Showing {total === 0 ? 0 : pageIndex * pageSize + 1} –{" "}
          {!canNextPage ? (pageIndex + 1) * pageSize : total} of{" "}
          {formatNumberWithCommas(total || 0)} results
        </div>
      </div>
      <Stack
        className="ml-auto overflow-hidden border rounded-md mr-4"
        direction="horizontal"
        spacing={0}
        divider={<Divider orientation="vertical" color="strong" />}
        style={{
          borderColor: theme.borderColorStrong,
        }}
      >
        <PageButton
          isDisabled={canPreviousPage}
          onClick={!canPreviousPage ? previousPage : null}
        >
          <ChevronLeft20 />
        </PageButton>

        <PageButton
          isDisabled={canNextPage}
          onClick={!canNextPage ? nextPage : null}
        >
          <ChevronRight20 />
        </PageButton>
      </Stack>

      <div className="flex flex-row">
        <TableRowQuantitySelect
          onChange={(e) => {
            dispatch(setNumberOfRowsSelected(e));
            if (tableRowQuantityChange) {
              tableRowQuantityChange();
            }
          }}
        />
        <div className="self-center ml-2 text-sm text-muted">Per Page</div>
      </div>
    </div>
  );
};
