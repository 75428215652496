import { str, url, bool } from "envalid";
import { ConfigEnvironmentVariables } from "./config.constants";

export const ConfigEnvironmentSchema = {
  [ConfigEnvironmentVariables.NEXT_PUBLIC_AUTH0_SCOPE]: str(),
  [ConfigEnvironmentVariables.NEXT_PUBLIC_AUTH0_CLIENT_ID]: str(),
  [ConfigEnvironmentVariables.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL]: url(),
  [ConfigEnvironmentVariables.NEXT_PUBLIC_AUTH0_BASE_URL]: url(),
  [ConfigEnvironmentVariables.NEXT_PUBLIC_AUDIENCE]: str(),
  [ConfigEnvironmentVariables.NEXT_PUBLIC_NEST_API_BASE_URL]: url(),
  [ConfigEnvironmentVariables.NEXT_PUBLIC_MAINTENANCE_MODE]: bool(),
  [ConfigEnvironmentVariables.NEXT_PUBLIC_LABEL_BUCKET_URL]: url(),
  [ConfigEnvironmentVariables.NEXT_PUBLIC_PUSHER_CLUSTER]: str(),
  [ConfigEnvironmentVariables.NEXT_PUBLIC_PUSHER_KEY]: str(),
};
