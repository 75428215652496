import cx from "classnames";
import { Checkmark16 } from "@carbon/icons-react";
import { useDropdownContext } from "../DropdownContext";
import { createElement, ReactNode } from "react";
import { Center } from "components/atoms/Center";
import { Icon } from "components/atoms/Icon";
import styled from "styled-components";
import { IconType } from "react-icons/lib";

export type DropdownMenuItemProps = {
  children: ReactNode;
  className?: string;
  hoverColorScheme?: "gray" | "red";
  icon?: IconType;
  size?: "Flush" | "Narrow" | "Default" | "Tall";
  isChecked?: boolean;
  onClick?(): void;
};

const StyledDropdownMenuItem = styled.div`
  padding-left: ${(props) => props.theme.gutter};
  padding-right: ${(props) => props.theme.gutter};
  height: ${(props) => props.theme.space[10]};

  &:hover {
    background-color: ${(props) => props.theme.backgroundColorHover};
  }
`;

export const DropdownMenuItem = ({
  children,
  icon,
  className,
  onClick,
  isChecked,
}: DropdownMenuItemProps) => {
  const { onClose, closeOnClick } = useDropdownContext();

  return (
    <StyledDropdownMenuItem
      className={cx(
        "leading-none flex items-center cursor-pointer text-sm",
        className
      )}
      onClick={() => {
        if (onClick) {
          onClick();
          closeOnClick && onClose();
        }
      }}
    >
      {icon && (
        <div className="mr-2 text-sm text-gray10">{createElement(icon)}</div>
      )}
      {children}

      {isChecked && (
        <div className="ml-auto">
          <div className="w-6 h-6 rounded-full">
            <Center>
              <Icon icon={Checkmark16} />
            </Center>
          </div>
        </div>
      )}
    </StyledDropdownMenuItem>
  );
};
